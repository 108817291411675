import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BrowserService } from '@cjm/shared/core';

@Injectable()
export class MediaQueryService implements OnDestroy {
	private mql: MediaQueryList;
	private mqlListener: EventListenerOrEventListenerObject;
	private readonly matchesQuerySubject$ = new BehaviorSubject<boolean>(false);

	public readonly matchesQuery$ = this.matchesQuerySubject$.asObservable();

	constructor(private readonly browserService: BrowserService) {}

	public setMatchingQuery(query: string): void {
		this.browserService.runInBrowser(({ browserWindow }) => {
			this.mql = browserWindow.matchMedia(query);

			// Iben: register listener
			this.mqlListener = (mq: MediaQueryListEvent) => {
				this.matchesQuerySubject$.next(mq.matches);
			};

			// Iben: Add listener
			this.mql.addEventListener('change', this.mqlListener);

			// Wouter: Trigger the subject again
			this.matchesQuerySubject$.next(this.mql.matches);
		});
	}

	public ngOnDestroy(): void {
		this.browserService.runInBrowser(() => {
			this.mql.removeEventListener('change', this.mqlListener);
			this.mql = this.mqlListener = null;
		});
	}
}
