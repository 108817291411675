// Frontend interface
export interface ZipCodeEntity {
	municipality: string;
	code: number | string;
	id?: string;
}

// Backend interface
export interface ZipCodeResultEntity {
	gemeente: string;
	postcode: number;
}
