import { Pipe, PipeTransform } from '@angular/core';

import { AssociationActivityEntity } from '../../data';

@Pipe({
	name: 'parseAssociationActivities'
})
export class ParseAssoctiationActivitiesPipe implements PipeTransform {
	public transform(value: AssociationActivityEntity[]): string {
		if (value) {
			return value.map((activity) => activity.name).join(', ');
		}
	}
}
