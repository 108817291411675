import { Injectable, Renderer2 } from '@angular/core';

import { AbstractScriptsProviderService } from '../abstracts';

import { BrowserService } from './browser.service';

@Injectable()
export class ScriptsService {
	constructor(
		private readonly browserService: BrowserService,
		private readonly scriptsProviderService: AbstractScriptsProviderService
	) {}

	/**
	 * Attaches all scripts to the dom when the dom is available (only in browser)
	 *
	 * @param renderer - Renderer used to attach the scripts to the dom
	 * @memberof ScriptsService
	 */
	public attachScriptsToDom(renderer: Renderer2) {
		// Iben: Loop over all scripts we need to add
		this.browserService.runInBrowser(({ browserDocument }) => {
			this.scriptsProviderService.scripts.forEach((item) => {
				// Iben: Create a new script tag
				const script = renderer.createElement('script');
				script.type = 'text/javascript';
				script.src = item.url;

				// Iben: Log when the script was loaded correctly and log potential errors
				script.onload = () => {
					console.log(`Script ${item.name} was loaded dynamically`);
				};
				script.onerror = (err) => {
					console.error(`Script ${item.name} was not loaded. Check the error below for more info.`);
					console.dir(err);
				};

				// Iben: Attach script to the dom
				renderer.appendChild(browserDocument.head, script);
			});
		});
	}
}
