import { Directive, Input, HostListener } from '@angular/core';
import { filter, switchMap, take, tap } from 'rxjs';

import { ActionModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';

import { AuthenticationService, EidRedirectWarning } from '../../data';

/**
 * A directive that allows a user to switch their identity via ACM.
 *
 * When the host element is clicked, the directive will prevent the default click action and
 * initiate the identity switch process using the provided callback.
 *
 * **Example usage**
 *
 * ```
 * <button cjmUserEIDIdentitySwitch [callback]="'/dashboard'">
 * 	Switch identity in ACM
 * </button>
 * ```
 *
 */
@Directive({
	selector: '[cjmUserEIDIdentitySwitch]'
})
export class UserEIDIdentitySwitchDirective {
	/**
	 * The callback URL to navigate to after a successful identity switch.
	 */
	@Input() public callback: string;
	/**
	 * The withWarning attribute will show an optional confirmation modal.
	 *
	 * Default value: false
	 */
	@Input() public withWarning: boolean = false;
	/**
	 * The warningLabels attribute can take an EidRedirectWarning object for the modal labels.
	 */
	@Input() public warningLabels: EidRedirectWarning;

	constructor(
		private readonly authService: AuthenticationService,
		private readonly modalService: ModalDialogService
	) {}

	/**
	 * Handles the click event on the host element.
	 */
	@HostListener('click', ['$event']) public onClick(e) {
		// Prevent default
		e.preventDefault();
		e.stopPropagation();

		if (this.withWarning && this.warningLabels) {
			const modal = this.modalService.openModal(ActionModalComponent);
			modal.component.title = this.warningLabels.title;
			modal.component.text = this.warningLabels.text;
			modal.component.confirmLabel = this.warningLabels.confirmLabel;
			modal.component.cancelLabel = this.warningLabels.cancelLabel;

			modal.component.buttonClicked
				.pipe(
					// Denis: Only take the first result in the stream
					take(1),
					// Denis: When the cancel button is clicked, close the modal
					tap((value: 'confirm' | 'cancel') => {
						if (value === 'cancel') {
							this.modalService.instantCloseModal();
						}
					}),
					// Denis: Only proceed if the action has been confirmed
					filter((value: 'confirm' | 'cancel') => value === 'confirm'),
					// Denis: Close the modal
					tap(() => this.modalService.closeModal()),
					switchMap(() =>
						// Redirect to login page with product callback if needed
						this.authService.switch({
							customCallBack: this.callback
						})
					)
				)
				.subscribe();

			return;
		}

		// Redirect to login page with product callback if needed
		this.authService
			.switch({
				customCallBack: this.callback
			})
			.subscribe();
	}
}
