<ng-container *ngIf="contactItem">
	<ng-container *ngIf="contactItem.contactType === contactItemType.Email">
		<fa-icon icon="fa-envelope" iconType="fa-regular" />
		<cjm-link [type]="linkType.email" [to]="contactItem.contactValue">{{ contactItem.contactValue }}</cjm-link>
	</ng-container>

	<ng-container *ngIf="contactItem.contactType === contactItemType.Phone">
		<fa-icon icon="fa-phone" />
		<cjm-link [type]="linkType.phone" [to]="contactItem.contactValue">{{ contactItem.contactValue }}</cjm-link>
	</ng-container>

	<ng-container *ngIf="contactItem.contactType === contactItemType.SocialMedia">
		<fa-icon icon="fa-hashtag" iconType="fa-regular" />
		<cjm-link [type]="linkType.external" [to]="contactItem.contactValue">{{ contactItem.contactValue }}</cjm-link>
	</ng-container>

	<ng-container *ngIf="contactItem.contactType === contactItemType.Website">
		<fa-icon icon="fa-link" iconType="fa-solid" />
		<cjm-link [type]="linkType.external" [to]="contactItem.contactValue">{{ contactItem.contactValue }}</cjm-link>
	</ng-container>
</ng-container>
