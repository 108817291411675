import { AlertType, VloketLink, VloketLinkIcon } from '@cjm/shared/ui/common';

export enum ActionType {
	PRIMARY = 'primair',
	SECONDARY = 'secondair'
}
export interface ToasterLink extends VloketLink {
	actionType?: ActionType;
}
export interface Toaster {
	toasterId?: string;
	toasterType?: AlertType;
	toasterTitle?: string;
	toasterIcon?: VloketLinkIcon;
	toasterSubtitle?: string;
	toasterMessage: string;
	toasterLinks?: ToasterLink[];
	isClosable?: boolean;
	persistClosure?: 'session' | 'local';
}
