import { AdviceDetailPageEntity, AdvicePageImageEntity, TilesEntity } from '@cjm/v-loket/shared';

export interface AdviceDetailPageDataEntity extends AdviceDetailPageEntity {
	components: AdvicePageComponentEntity[];
}

export type AdvicePageComponentEntity =
	| AdvicePageTextEntity
	| TilesEntity
	| AdvicePageImageAndTextEntity
	| AdvicePageDividerEntity
	| AdvicePageLinksEntity
	| AdvicePageRoadmapEntity
	| AdvicePageRecommendationEntity;

export interface AdvicePageTextEntity {
	type: string;
	title: string;
	text: string;
	variant: AdvicePageBackgroundVariant;
}

export interface AdvicePageTileEntity {
	title: string;
	text: string;
}

export interface AdvicePageImageAndTextEntity {
	type: string;
	title: string;
	text: string;
	image: AdvicePageImageEntity;
	variant: AdvicePageImageAndTextVariant;
}

export interface AdvicePageDividerEntity {
	type: string;
	variant: AdvicePageDividerVariant;
}

export interface AdvicePageLinksEntity {
	type: string;
	title: string;
	linkgroups: AdvicePageLinkGroupEntity[];
	variant: AdvicePageBackgroundVariant;
}

export interface AdvicePageLinkGroupEntity {
	title: string;
	links: AdvicePageLinkEntity[];
}

export interface AdvicePageLinkEntity {
	title: string;
	url: string;
}

export interface AdvicePageRoadmapEntity {
	type: string;
	title: string;
	text: string;
	steps?: AdvicePageStepEntity[];
}

export interface AdvicePageStepEntity {
	title: string;
	text: string;
}

export interface AdvicePageRecommendationEntity {
	type: string;
	title: string;
	variant: AdvicePageBackgroundVariant;
	pages: AdviceDetailPageEntity[];
}

export enum AdvicePageBackgroundVariant {
	White = 'WitteAchtergrond',
	Gray = 'LichtgrijzeAchtergrond',
	Visual = 'AchtergrondMetVisual'
}

export enum AdvicePageDividerVariant {
	BottomRight = 'GeelVlakOnderaanRechts',
	BottomLeft = 'GeelVlakOnderaanLinks',
	TopRight = 'GeelVlakBovenaanRechts',
	TopLeft = 'GeelVlakBovenaanLinks'
}

export enum AdvicePageImageAndTextVariant {
	Left = 'AfbeeldingLinks',
	Right = 'AfbeeldingRechts'
}

export enum AdvicePageTileBackgroundVariant {
	YellowBackgroundOnTop = 'GeleAchtergrondBovenaan',
	YellowBackgroundOnBottom = 'GeleAchtergrondOnderaan',
	LightGrayBackgroundOnTop = 'LichtgrijzeAchtergrondBovenaan',
	LightGrayBackgroundOnBottom = 'LichtgrijzeAchtergrondOnderaan'
}
