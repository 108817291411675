<!-- TODO: if this component is used again in the future, convert .c-input to cjm-input-wrapper -->
<fieldset [formGroup]="form">
	<fieldset class="c-vloket-form__fieldset">
		<h3 class="c-vloket-header c-vloket-header--h4">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">
				{{ i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset.Title | translate }}
			</span>
		</h3>
		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<div class="c-input">
				<label for="associationCallname"
					>{{
						i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset.AssociationCallname
							.Label | translate
					}}<small>{{ i18nKeys.FormLabels.OptionalMarker | translate }}</small></label
				>
				<input
					class="c-input--as-textfield"
					id="associationCallname"
					type="text"
					formControlName="associationCallname"
					[placeholder]="
						i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset.AssociationCallname
							.Placeholder | translate
					"
				/>
			</div>
		</fieldset>
		<fieldset>
			<div class="c-input">
				<label for="associationDescription">{{
					i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset.AssociationDescription
						.Label | translate
				}}</label>
				<textarea
					class="c-input--as-textfield"
					id="associationDescription"
					type="text"
					formControlName="associationDescription"
					[placeholder]="
						i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset
							.AssociationDescription.Placeholder | translate
					"
				></textarea>
			</div>
		</fieldset>
		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<div class="c-input">
				<label for="associationFoundingDate"
					>{{
						i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset
							.AssociationFoundingDate.Label | translate
					}}<small>{{ i18nKeys.FormLabels.OptionalMarker | translate }}</small></label
				>
				<input
					class="c-input--as-textfield"
					id="associationFoundingDate"
					type="date"
					formControlName="associationFoundingDate"
					[placeholder]="
						i18nKeys.Registration.Register.AdditionalData.AssociationBackgroundFieldset
							.AssociationFoundingDate.Placeholder | translate
					"
				/>
			</div>
		</fieldset>
	</fieldset>
</fieldset>
