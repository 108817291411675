import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { TagType } from '@cjm/shared/ui/common';
import { AssociationEntity, AssociationRelationEntity, AssociationRelationType, I18nKeys } from '@cjm/v-loket/shared';

@Component({
	selector: 'vloket-association-overview-card',
	templateUrl: './association-overview-card.component.html',
	styleUrls: ['./association-overview-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationOverviewCardComponent {
	@HostBinding('class.c-association-overview-card') private readonly hasRootClass: boolean = true;
	@Input({
		transform: (association: AssociationEntity): AssociationEntity & { parentOf: number } => ({
			...association,
			parentOf: (association?.relations || []).filter(
				(relation: AssociationRelationEntity) => relation.type === AssociationRelationType.ParentOf
			).length
		})
	})
	public association: AssociationEntity & { parentOf: number };

	public readonly tagType: typeof TagType = TagType;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	protected readonly VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(protected i18nService: I18nService) {}
}
