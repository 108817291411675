import { FormArray, FormControl } from '@angular/forms';

import { CJMIdMeta, CJMElementsResult, CJMResult, CJMTypeMeta } from './cjm-result';

// External API Interfaces
// ------------------------------------------------------------------------- /
export interface ProductClassificationEntityResult extends CJMIdMeta, CJMTypeMeta<'Productclassificatie'> {
	naam: string;
}

export interface FacetEntityResult extends CJMTypeMeta<'Facet'> {
	aantal: number;
	productclassificatie: ProductClassificationEntityResult;
}

export interface FacetGroupEntityResult extends CJMElementsResult<FacetEntityResult>, CJMTypeMeta<'Facetgroep'> {
	productclassificatietypelink?: string;
}

export type FacetCollectionResult = CJMResult<FacetGroupEntityResult, 'Facetverzameling'>;

interface FacetSubjectResult {
	id: string;
	naam: string;
	'@type': string;
}

export type FacetSubjectResultItem = RefinableFacetSubjectResult | NonRefinableFacetSubjectResult;

export interface RefinableFacetSubjectResult extends FacetSubjectResult {
	verfijningen: FacetSubjectResultItem[];
}

export interface NonRefinableFacetSubjectResult extends FacetSubjectResult {
	aantal: number;
	aangevinkt: boolean;
}

export interface FacetResult {
	id: string;
	naam: string;
	onderwerpen: FacetSubjectResultItem[];
}

export interface FacetsResult {
	elementen: FacetResult[];
}

// Internal Interfaces
// ------------------------------------------------------------------------- /

interface FacetSubjectEntity {
	name: string;
	id: string;
}

export type FacetSubjectItemEntity = NonRefinableFacetSubjectEntity | RefinableFacetSubjectEntity;

export interface NonRefinableFacetSubjectEntity extends FacetSubjectEntity {
	amount: number;
	checked: boolean;
}

export interface RefinableFacetSubjectEntity extends FacetSubjectEntity {
	refinements: FacetSubjectItemEntity[];
}

export interface FacetEntity {
	id: string;
	name: string;
	items: FacetSubjectItemEntity[];
	isRefinable: boolean;
}

export interface FacetFilter {
	[key: string]: string[];
}

export type FacetFilterForm = Record<string, FormArray<FormControl<string>>>;

export interface FacetFilterResult {
	facetNaam: string;
	namenNietVerfijnbareOnderwerpen: string[];
}

export interface CustomSearchQueryFacetEntity<TypeEntity = unknown> {
	id: string;
	value: string;
	type?: TypeEntity;
}
