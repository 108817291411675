import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { OnDestroyComponent, UserTargetCodeEntity } from '@cjm/shared/types';
import { UserEntity, UserService } from '@cjm/shared/user';

@Component({
	templateUrl: './redirect-authentication.component.html',
	styleUrls: ['./redirect-authentication.component.scss']
})
export class RedirectAuthenticationComponent extends OnDestroyComponent implements OnInit {
	constructor(
		private readonly userService: UserService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly i18nService: I18nService
	) {
		super();
	}

	public ngOnInit(): void {
		this.userService.loading$
			.pipe(
				filter((loading: boolean) => !loading),
				switchMap(() => this.userService.user$),
				tap((user: UserEntity) => {
					const selectedType: UserTargetCodeEntity = this.route.snapshot?.params?.type;
					const userType: UserTargetCodeEntity = user?.targetCode;
					const hasVCode: boolean = typeof user?.company?.vCode === 'string' && user.company.vCode.length > 0;

					if (userType === UserTargetCodeEntity.ECONOMIC_ACTOR && userType === selectedType && !hasVCode) {
						this.router.navigate([
							'/',
							this.i18nService.currentLanguage,
							VLoketAppRoutePaths.Registration,
							VLoketAppRoutePaths.EARegistrationForm
						]);

						return;
					}

					if (userType === UserTargetCodeEntity.CIVILIAN && userType === selectedType) {
						this.router.navigate([
							'/',
							this.i18nService.currentLanguage,
							VLoketAppRoutePaths.Registration,
							VLoketAppRoutePaths.RegistrationForm
						]);

						return;
					}

					this.router.navigate(
						[
							'/',
							this.i18nService.currentLanguage,
							VLoketAppRoutePaths.Redirects,
							VLoketAppRoutePaths.Stop
						],
						{
							queryParams: {
								selectedType,
								hasVCode
							}
						}
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
