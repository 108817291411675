import { LocationStrategy } from '@angular/common';
import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@cjm/shared/authentication/auth';
import { BrowserService } from '@cjm/shared/core';

import { AuthenticationFailedPageComponent } from '../pages';

/**
 * Checks whether or not the authentication has failed
 */
export const isAuthenticationSuccessGuard: CanDeactivateFn<
	AuthenticationFailedPageComponent
> = (): ObservableBoolean => {
	// Iben: Fetch all injectables
	const authService: AuthenticationService = inject(AuthenticationService);
	const browserService: BrowserService = inject(BrowserService);
	const location: LocationStrategy = inject(LocationStrategy);

	// Iben: Overwrite the history pushState
	browserService.runInBrowser(({ browserWindow }) => {
		history.pushState(null, null, browserWindow.location.href);
		location.onPopState(() => {
			history.pushState(null, null, browserWindow.location.href);
		});
	});

	// Iben: Check if the authentication has failed
	return authService.authenticationFailed$.pipe(map((authenticationFailedType) => !authenticationFailedType));
};
