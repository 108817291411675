import { IndexedPaginationResult } from '@cjm/shared/types';

// Advice pages
export interface AdvicePagesDataResultEntity {
	'@type': 'Pagina';
	inhoud: InhoudResultEntity;
	paginatie: IndexedPaginationResult;
}

export interface InhoudResultEntity {
	'@type': 'Adviespaginasverzameling';
	elementen: AdvicePageResultEntity[];
}

// Advice page
export interface AdvicePageResultEntity {
	'@type': string;
	id: string;
	titel: string;
	omschrijving: string;
	afbeelding: AfbeeldingResultEntity;
	trefwoorden: Trefwoord[];
	componenten: ComponentResultEntity[];
	leestijd: string;
	bijgewerkt: string;
}

interface Trefwoord {
	'@type': string;
	id: string;
	naam: string;
}

export type ComponentResultEntity =
	| TekstResultEntity
	| TegelsResultEntity
	| AfbeeldingEnTekstResultEntity
	| ScheidingslijnResultEntity
	| LinksResultEntity
	| StappenplanResultEntity
	| AanbevolenAdviezenResultEntity;

export interface TekstResultEntity {
	'@type': string;
	titel: string;
	tekst: string;
	variant: BackgroundVariant;
}

export interface TegelsResultEntity {
	'@type': string;
	titel: string;
	tekst: string;
	variant:
		| 'GeleAchtergrondBovenaan'
		| 'GeleAchtergrondOnderaan'
		| 'LichtgrijzeAchtergrondBovenaan'
		| 'LichtgrijzeAchtergrondOnderaan';
	tegels: TegelResultEntity[];
}

interface TegelResultEntity {
	'@type': string;
	titel: string;
	tekst: string;
}

export interface AfbeeldingEnTekstResultEntity {
	'@type': string;
	titel: string;
	tekst: string;
	afbeelding: AfbeeldingResultEntity;
	variant: 'AfbeeldingRechts' | 'AfbeelingLinks';
}

export interface AfbeeldingResultEntity {
	'@type': string;
	type: string;
	locatieUrl: string;
	altTekst: string;
	hoogte: string;
	breedte: string;
}

export interface ScheidingslijnResultEntity {
	'@type': string;
	variant: 'GeelVlakOnderaanRechts' | 'GeelVlakOnderaanLinks' | 'GeelVlakBovenaanRechts' | 'GeelVlakBovenaanLinks';
}

export interface LinksResultEntity {
	'@type': string;
	titel: string;
	linkgroepen: LinkgroepResultEntity[];
	variant: string;
}

interface LinkgroepResultEntity {
	'@type': string;
	titel: string;
	links: LinkResultEntity[];
}

interface LinkResultEntity {
	'@type': string;
	titel: string;
	link: string;
}

export interface StappenplanResultEntity {
	'@type': string;
	titel: string;
	tekst: string;
	stappen?: StapResultEntity[];
}

interface StapResultEntity {
	'@type': string;
	titel: string;
	tekst: string;
}

export interface AanbevolenAdviezenResultEntity {
	'@type': string;
	titel: string;
	variant: BackgroundVariant;
	adviezen: Omit<AdvicePageResultEntity, 'componenten'>[];
}

type BackgroundVariant = 'WitteAchtergrond' | 'LichtgrijzeAchtergrond' | 'AchtergrondMetVisual';
