import { Component, HostBinding } from '@angular/core';

import { BrowserService } from '@cjm/shared/core';
import { ButtonClasses } from '@cjm/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'cjm-maintenance-page',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss']
})
export class MaintenancePageComponent {
	@HostBinding('class.p-maintenance') private readonly hasRootClass: boolean = true;

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly redirectI18nKeys: typeof RedirectI18nKeys = RedirectI18nKeys;

	constructor(private readonly browserService: BrowserService) {}

	/**
	 * Reload the document
	 */
	public retryStatus(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.location.reload();
		});
	}
}
