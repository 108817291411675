import { Features } from '@cjm/shared/features';
import { CJMResult } from '@cjm/shared/types';
export interface AppStatusResultEntity {
	onderhoudsmodus: boolean;
	onderhoudsmodusBericht: string;
	features?: CJMResult<FeatureResultEntity>;
}

export interface AppStatusEntity {
	isActive: boolean;
	message?: string;
	features?: FeatureResultEntity[];
}

export interface FeatureResultEntity {
	naam: Features;
	waarde: boolean;
}
