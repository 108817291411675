import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { NgxMaskModule } from 'ngx-mask';

import { CypressModule } from '@cjm/cypress/core';
import { SharedUiModule } from '@cjm/shared/ui/common';
import { SharedUtilsModule } from '@cjm/shared/utils';

import { SharedFormsTranslationLoader } from '../translate.loader';

import { sharedInputComponents } from './components';
import { FormPipes } from './pipes';

@NgModule({
	imports: [
		CommonModule,
		SharedUiModule,
		SharedUtilsModule,
		FormsModule,
		ReactiveFormsModule,
		NgxI18nModule.forChild(SharedFormsTranslationLoader),
		MatTooltipModule,
		NgxErrorsModule,
		CypressModule,
		SharedUtilsModule,
		NgxMaskModule.forRoot()
	],
	declarations: [...sharedInputComponents, FormPipes],
	providers: [FormPipes],
	exports: [...sharedInputComponents, FormsModule, ReactiveFormsModule, FormPipes]
})
export class SharedFormsModule {}
