import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { ACMTargetGroups } from '@cjm/shared/types';
import { ButtonClasses } from '@cjm/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	templateUrl: './stop.page.html',
	styleUrls: ['./stop.page.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopPageComponent {
	@HostBinding('class.p-stop') private readonly hasPageClass: boolean = true;

	public readonly i18nKeys: typeof RedirectI18nKeys = RedirectI18nKeys;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly callbackUrlAfterIdetitySwitch: string = `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.EARegistrationForm}`;
	public readonly acmTargetGroups = ACMTargetGroups;

	constructor(
		public readonly i18nService: I18nService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {}
}
