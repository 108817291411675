// Backend interface

import { AddressEntity, AddressResultEntity, CJMResult } from '@cjm/shared/types';

import { CompanyLinkEntity, CompanyLinkResultEntity } from './company-links.interface';

export interface CompanyResultEntity {
	ondernemingsnummer: CompanyNumberResultEntity;
	namen: CompanyNamesResultEntity;
	maatschappelijkeZetel?: CompanyAddressResultEntity;
	inschrijving: string;
	start: string;
	rechtstoestand: string;
	vennootschapsvorm: string;
	activiteiten: CompanyActivityResultEntity[];
	vestigingen: CompanyBranchResultEntity[];
	ondernemingslinks?: CJMResult<CompanyLinkResultEntity>;
}

export interface CompanyAddressResultEntity extends AddressResultEntity {
	coordinaten: {
		lon: number;
		lat: number;
	};
}

export interface CompanyNamesResultEntity {
	commercieleNaam: string;
	maatschappelijkeNaam: string;
	afgekorteNaam: string;
}

export interface CompanyNumberResultEntity {
	nummer: string;
}

export interface CompanyBranchResultEntity {
	vestigingseenheidsnummer: CompanyNumberResultEntity;
	namen: CompanyNamesResultEntity;
	adres: CompanyAddressResultEntity;
}

export interface CompanyActivityResultEntity {
	begin: string;
	code: string;
	groep: string;
	naam: string;
	type: string;
	versie: string;
}

// Frontend interface

/**
 * The entity used to define a company
 */
export interface CompanyEntity {
	number: string;
	names: CompanyNamesEntity;
	registeredOffice?: CompanyAddressEntity;
	registrationDate: string;
	startDate: string;
	legalStatus: string;
	form: string;
	branches: CompanyBranchEntity[];
	activities: CompanyActivityEntity[];
	links?: CompanyLinkEntity[];
}

/**
 * The CompanyEntity where each key is optional.
 */
export type PartialCompanyEntity = Partial<CompanyEntity>;

export interface CompanyNamesEntity {
	commercial: string;
	short: string;
	public: string;
}

export interface CompanyActivityEntity {
	start: string;
	code: string;
	group: string;
	name: string;
	type: string;
	version: string;
}

export interface CompanyBranchEntity {
	number: string;
	names: CompanyNamesEntity;
	address: CompanyAddressEntity;
}

export interface CompanyAddressEntity extends AddressEntity {
	coordinates: {
		long: number;
		lat: number;
	};
}

export interface CompanyDataEntity {
	SME: boolean;
	socialStatute: string;
}

export interface CompanyActivitiesByBranchEntity {
	branch: string;
	activities: CompanyActivityEntity[];
}

export type CompanyActivitiesByBranchRecord = Record<string, CompanyActivityEntity[]>;
export type CompanyAddressRecord = Record<string, CompanyAddressEntity>;
