// ---
// A module containing all of the ACM header functionality.
// -------------------------------------------------------------------------- /

import { NgModule } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

// Components
import { AcmHeaderComponent } from './components';

@NgModule({
	// Modules
	imports: [NgxI18nModule.forChild()],
	// Services
	providers: [],

	// Components
	declarations: [AcmHeaderComponent],

	// Re-export
	exports: [AcmHeaderComponent]
})

// Exports
// -------------------------------------------------------------------------- /
export class AcmHeaderModule {}

export { AcmHeaderComponent };
