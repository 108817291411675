<cjm-layout-container layoutType="split-equal" asidePosition="right" alignItems="start">
	<ng-container main>
		<h1 class="c-vloket-header c-vloket-header--h6">
			{{ redirectI18nKeys.Maintenance.Subtitle | translate }}
		</h1>
		<h2 class="c-vloket-header c-vloket-header--h2">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ redirectI18nKeys.Maintenance.Title | translate }}
			</span>
		</h2>

		<div class="u-margin-bottom-xl">
			{{ redirectI18nKeys.Maintenance.Text | translate }}
		</div>

		<div class="p-maintenance__buttons">
			<cjm-button
				[classes]="buttonClasses.LinkButton"
				[title]="this.redirectI18nKeys.Maintenance.Button.Title | translate"
				(handleClick)="retryStatus()"
			>
				{{ this.redirectI18nKeys.Maintenance.Button.Text | translate }}
			</cjm-button>
		</div>
	</ng-container>

	<div class="p-maintenance__visual" asideRight>
		<cjm-img src="img/server-fout.svg" />
	</div>
</cjm-layout-container>
