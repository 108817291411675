import { BaseStoreAssets, createBaseStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';

import {
	IEARegisterFormDataEntity,
	IPostRegistrationResponseEntity,
	IRegisterFormDataEntity,
	IRegisterAssociationData
} from './registration.store.types';

type registrationStoreSlice = {
	registerFormData: BaseStoreAssets<IRegisterFormDataEntity | IEARegisterFormDataEntity>;
	postRegistrationResponse: BaseStoreAssets<IPostRegistrationResponseEntity>;
	associationData: BaseStoreAssets<IRegisterAssociationData>;
};

export const { actions, reducers, selectors } = createStoreAssets<registrationStoreSlice>(StoreSlice.Registration, [
	{
		subSlice: 'registerFormData',
		generator: createBaseStoreAssets<IRegisterFormDataEntity | IEARegisterFormDataEntity>
	},
	{
		subSlice: 'postRegistrationResponse',
		generator: createBaseStoreAssets<IPostRegistrationResponseEntity>
	},
	{
		subSlice: 'associationData',
		generator: createBaseStoreAssets<IRegisterAssociationData>
	}
]);
