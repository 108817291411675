// Production-server environment variables
// Test-server environment variables
import { cookieConfig } from './cookie';
import { EnvironmentType } from './interfaces';
cookieConfig.cookie.domain = 'www.verenigingsloket.be';

export const environment = {
	environment: EnvironmentType.PRODUCTION,
	version: '5.1.0',
	production: true,
	domain: 'https://www.verenigingsloket.be',
	metaDomain: 'www.verenigingsloket.be',
	loketType: 'vLoket',

	matomo: {
		trackerUrl: 'https://cjm.matomo.cloud/',
		siteId: '15',
		dimensions: {
			outlinkReferrerPage: 1,
			userCapacity: 2
		}
	},

	// The location and settings for the Drupal8 Headless CMS
	api: {
		protocol: 'https',
		hostname: 'cms.verenigingsloket.be/',
		apiPath: 'api',
		fullPath: 'https://cms.verenigingsloket.be/api/v1.0/'
	},

	assets: {
		fullPath: 'https://www.verenigingsloket.be/assets/',
		styleExtension: '.min.css'
	},

	acmidm: {
		protocol: 'https',
		hostname: 'cms.verenigingsloket.be/',
		loginPath: 'vlaio/login',
		logoutPath: 'vlaio/logout',
		switchPath: 'vlaio/switch',
		keepAliveUrl: 'https://authenticatie.vlaanderen.be/favicon.ico',
		widgetsUrl: 'https://prod.widgets.burgerprofiel.vlaanderen.be/',
		headerWidgetId: 'c5045e57-bb76-4786-85c5-c2ecdf3badd4',
		footerWidgetId: '8c776d54-9ccc-4301-84b4-e2c16bdad75b'
	},

	sas: {
		enabled: false,
		url: '',
		sourceUuid: '',
		targetUuid: ''
	},

	bpost: {
		host: 'https://api.mailops.bpost.cloud',
		autoCompletePath: '/roa-info/externalMailingAddressProofingRest/autocomplete',
		apiKey: 'OgDWpgfw763sI4FuUYnFBxI9Nqi8pjv87WJXLre9'
	},

	hasServiceWorker: true,
	whatsNewPageId: 'Wat is nieuw?',

	logErrors: false,

	cookieConfig
};
