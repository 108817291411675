import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { transformJsonToObject } from '@cjm/v-loket/shared';

import { AdvicePageDividerEntity } from '../../../data';

import { DividerVariants } from './advice-detail-page-divider.types';

@Component({
	selector: 'vloket-advice-detail-page-divider',
	templateUrl: './advice-detail-page-divider.component.html',
	styleUrls: ['./advice-detail-page-divider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdviceDetailPageDividerComponent {
	@Input({
		required: true,
		transform: (value: string) => {
			return transformJsonToObject<AdvicePageDividerEntity>(value);
		}
	})
	public data: AdvicePageDividerEntity;

	public readonly variants: typeof DividerVariants = DividerVariants;
}
