export interface LeafletMarkerEntity {
	id: number;
	lat: number;
	lng: number;
	type?: string;
	active?: boolean;
	title?: string;
	popup?: string;
}

export interface TileLayerConfig {
	url: string;
	options: {
		// The following config is not strictly typed. Check the config for the Leaflet Layer you wish to add, to have a correct interface for your config.
		[key: string]: any;
	};
}
