import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonClasses, VloketLink } from '@cjm/shared/ui/common';

import { I18nKeys } from '../../../i18n';

import { TimelineItemDescriptionEntity } from './timeline-item.types';

@Component({
	selector: 'vloket-timeline-item',
	templateUrl: 'timeline-item.component.html',
	styleUrls: ['timeline-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemComponent {
	/**
	 * index
	 *
	 * Provide the index of the current item.
	 */
	@Input() public index: number;
	/**
	 * isUpcoming
	 *
	 * Mark this step in the timeline as a upcoming step.
	 *
	 * Default value: false
	 */
	@Input() public isCurrent: boolean = false;
	/**
	 * isUpcoming
	 *
	 * Mark this step in the timeline as a upcoming step.
	 *
	 * Default value: false
	 */
	@Input() public isUpcoming: boolean = false;
	/**
	 * isFirst
	 *
	 * Mark this step in the timeline as the current step.
	 *
	 * Default value: false
	 */
	@Input() public isFirst: boolean = false;
	/**
	 * isLast
	 *
	 * Mark this step in the timeline as the current step.
	 *
	 * Default value: false
	 */
	@Input() public isLast: boolean = false;
	/**
	 * isCondensed
	 *
	 * Mark if the timeline item should be shown in its condensed form.
	 *
	 * Default value: false
	 */
	@Input() public isCondensed: boolean = false;
	/**
	 * step
	 *
	 * Set the step number.
	 *
	 * Default value: undefined
	 */
	@Input() public step: number;
	/**
	 * title
	 *
	 * Set the step title.
	 *
	 * Default value: undefined
	 */
	@Input() public title: string;
	/**
	 * date
	 *
	 * Set the step date.
	 *
	 * Default value: undefined
	 */
	@Input() public date: string;
	/**
	 * description
	 *
	 * Set an optional description.
	 *
	 * Default value: undefined
	 */
	@Input() public descriptions: TimelineItemDescriptionEntity[];
	/**
	 * links
	 *
	 * Provide an array of links in the VloketLink format.
	 *
	 * Default value: []
	 */
	@Input() public links: VloketLink[] = [];

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
}
