<form *ngIf="registerForm" class="c-vloket-form" [formGroup]="registerForm" (submit)="validateAndSubmit()">
	<cjm-layout-container layoutType="split-equal" asidePosition="left">
		<ng-container asideLeft>
			<h2 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.RegisterEa.BasicData.Title | translate }}
				</span>
			</h2>
			<p
				class="c-vloket-text--lg"
				[innerHTML]="
					i18nKeys.Registration.RegisterEa.BasicData.Description | translate : { number: company()?.number }
				"
			></p>
		</ng-container>
		<ng-container main>
			<ng-container *ngIf="company() as company">
				<cjm-company-info class="u-margin-bottom-xl" [company]="company" [isUserCompany]="true" />

				<h3 class="c-vloket-header c-vloket-header--h4">
					<span class="c-vloket-text-marking c-vloket-text-marking--underline">
						{{ i18nKeys.Registration.RegisterEa.RepresentativeData.Title | translate }}
					</span>
				</h3>

				<p class="c-vloket-text--light u-margin-bottom-sm">
					<fa-icon icon="fa-eye-slash" iconType="fa-regular" />
					{{ i18nKeys.Registration.RegisterEa.RepresentativeData.Notice | translate }}
				</p>

				<vloket-representative-form
					#formAccessor
					[formControl]="representativeControl"
					[showTitle]="false"
					[isKBO]="true"
					[disableFields]="[
						'representativeFirstnameField',
						'representativeSurnameField',
						'representativeRRNField'
					]"
				></vloket-representative-form>
			</ng-container>
		</ng-container>
	</cjm-layout-container>

	<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="top">
		<ng-container asideLeft>
			<h2 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.RegisterEa.AdditionalData.Title | translate }}
				</span>
			</h2>
			<p
				class="c-vloket-text--lg"
				[innerHTML]="i18nKeys.Registration.RegisterEa.AdditionalData.Description | translate"
			></p>
		</ng-container>
		<ng-container main>
			<vloket-ea-registration-data-form
				*ngIf="mainActivities$ | async as data"
				#formAccessor
				formControlName="basicInfo"
				[data]="data"
				(initialized$)="basicDataFormInitialized($event)"
			></vloket-ea-registration-data-form>
		</ng-container>
	</cjm-layout-container>

	<cjm-layout-container *ngIf="company()" layoutType="split-equal" asidePosition="left" preventSpacing="top">
		<ng-container main>
			<div class="c-vloket-form__actions">
				<cjm-button
					type="submit"
					[title]="i18nKeys.Registration.RegisterEa.FormActions.RegisterAssociation.Title | translate"
					[classes]="buttonClasses.LinkButton"
					[isDisabled]="actionInProgress$ | async"
				>
					{{ i18nKeys.Registration.RegisterEa.FormActions.RegisterAssociation.Text | translate }}
				</cjm-button>
				<cjm-button
					[classes]="buttonClasses.LinkButtonOutline"
					[title]="i18nKeys.Forms.Actions.Cancel.Title | translate"
					[isDisabled]="actionInProgress$ | async"
					[routerLink]="['..', this.appRoutePaths.RegistrationStart]"
				>
					{{ i18nKeys.Forms.Actions.Cancel.Text | translate }}
				</cjm-button>
			</div>
		</ng-container>
	</cjm-layout-container>
</form>
