import { catchAndCallThrough } from '@studiohyperdrive/rxjs-utils';
import { catchError } from 'rxjs/operators';

import { AlertType, LinkType } from '@cjm/shared/ui/common';
import { SnackBarService } from '@cjm/shared/ui/toast';

import { SnackBarComponent } from '../../components';
import { generateSnackbarConfig } from '../../helpers';

/**
 * catchWithSnackBar
 *
 * The catchWithSnackBar will use the provided SnackBarService instance and the catchAndCallThrough operator
 * to catch an error and show it as a toast message.
 *
 * @param snackBarService
 * @param config
 * @param config.type is set to AlertType.Error by default
 * @param config.title is set to '' by default
 * @param handle whether to throw, continue or complete the observable stream (see catchAndCallThrough operator)
 */
export function catchWithSnackBar(
	snackBarService: SnackBarService,
	config: {
		type?: AlertType;
		title?: string;
	} = {
		type: AlertType.Error,
		title: ''
	},
	// Denis: mimic the handle of the catchAndCallThrough
	handle: 'throw' | 'continue' | 'complete' = 'continue'
): ReturnType<typeof catchError> {
	return catchAndCallThrough((res) => {
		const error = res?.error;

		if (!error) {
			return;
		}

		return snackBarService.openFromComponent(
			SnackBarComponent,
			generateSnackbarConfig({
				message: error?.errorMessage,
				title: config.title ? config.title : '',
				type: config.type ? config.type : AlertType.Error,
				...(Array.isArray(error?.acties) && error.acties.length > 0
					? {
							links: error.acties.map((actie) => ({
								type: LinkType.external,
								keepInTab: true,
								title: actie.actietekst,
								text: actie.actietekst,
								url: actie.actielink,
								actionType: actie.actietype
							}))
					  }
					: {})
			})
		);
	}, handle);
}
