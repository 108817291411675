import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';

import { NotificationSlice } from './notification.types';

export const { actions, reducers, selectors } = createStoreAssets<NotificationSlice>(StoreSlice.Notifications, [
	{
		subSlice: 'notifications',
		generator: createEntityAdapterStoreAssets<MatSnackBarConfig & { id: string }>
	}
]);
