import { IconType } from '@cjm/shared/ui/common';

import { LinkType } from './link.const';

export interface VloketLink {
	url: string;
	text: string;
	title?: string;
	type: `${LinkType}`;
	icon?: VloketLinkIcon;
	keepInTab?: boolean;
	disabled?: boolean;
	tooltip?: string;
}

export interface VloketLinkIcon {
	icon: string;
	type: `${IconType}`;
	position?: 'left' | 'right';
}
