// Iben: Types to type all paths of an object
// See: https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object/58436959#58436959
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number
	? P extends string | number
		? `${K}${'' extends P ? '' : '.'}${P}`
		: never
	: never;

type Paths<T, D extends number = 10> = [D] extends [never]
	? never
	: T extends object
	? {
			[K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
	  }[keyof T]
	: '';

const HomeTags = {
	Home: {
		Nav: {
			Home: 'home-nav-home',
			Offers: 'home-nav-offers',
			AboutUs: 'home-nav-about-us',
			Associations: 'home-nav-associations',
			MyAssociation: 'home-nav-my-association',
			Advice: 'home-nav-advice'
		},
		Header: {
			Search: {
				Field: 'home-search-field',
				ButtonSubmit: 'home-search-button-submit',
				ButtonClear: 'home-search-button-clear'
			},
			LinkGroup: {
				Group: 'home-header-link-group',
				Item: 'home-header-link-group-item',
				All: 'home-header-link-group-all'
			}
		}
	},
	Breadcrumbs: {
		Breadcrumb: 'breadcrumbs-breadcrumb'
	}
};

const Offers = {
	Sorting: {
		Select: 'offers-sorting-select'
	}
};

const Status = {
	Title: 'status-title',
	Description: 'status-description',
	Button: 'status-button'
};

const Redirects = {
	AuthenticationFailed: {
		Button: 'authentication-failed-button'
	}
};

const General = {
	Loading: 'general-loading',
	Input: {
		Checkbox: 'general-checkbox'
	},
	// libs/shared/ui/common/src/lib/components/tag/tag.component.html
	Tag: {
		Clear: 'general-tag-clear'
	}
};

const Header = {
	Header: 'acm-header'
};

const Products = {
	Detail: {
		Heading: 'products-detail-heading',
		Sidebar: 'products-detail-sidebar',
		SidebarInfo: 'products-detail-sidebar-info',
		SidebarUserAction: 'products-detail-sidebar-user-action',
		MoreInfo: 'products-detail-more-info',
		Wysiwyg: 'products-detail-wysiwyg',
		ExtraCta: 'products-detail-extra-cta'
	}
};

// libs/shared/ui/toast/src/lib/ui/components/toaster/toaster.component.html
const Toaster = {
	Title: 'toaster-title',
	Subtitle: 'toaster-subtitle',
	Message: 'toaster-message',
	Link: 'toaster-link',
	Close: 'toaster-close'
};

// libs/shared/ui/modal/src/lib/ui/components/action-modal/action-modal.component.html
const ActionModal = {
	Actions: {
		Confirm: 'action-modal-actions-confirm',
		Cancel: 'action-modal-actions-cancel'
	}
};

// libs/v-loket/shared/src/lib/ui/components/filter-bar/filter-bar.component.html
const FilterBar = {
	UnrefinableFacets: {
		Item: 'filter-bar-unrefinable-facets-item'
	}
};

// libs/v-loket/shared/src/lib/ui/components/filter-selection-tags/filter-selection-tags.component.html
const FilterSelectionTags = {
	Item: 'filter-selection-tags-item'
};

// libs/v-loket/associations/src/lib/ui/pages/association-overview/association-overview-page.component.html
const Associations = {
	Search: {
		Input: 'associations-search-input',
		Clear: 'associations-search-clear',
		Submit: 'associations-search-submit'
	},
	Location: {
		Input: 'associations-location-input',
		Submit: 'associations-location-submit'
	},
	Actions: {
		ClearAll: 'associations-filter-bar-actions-clear-all'
	}
};

// libs/v-loket/registration/src/lib/ui/pages/register/register.component.html
export const FVRegistration = {
	Actions: {
		Submit: 'fv-registration-actions-submit'
	}
};

// libs/v-loket/registration/src/lib/ui/components/basic-registration-data-form/basic-registration-data-form.component.html
export const BasicRegistrationDataForm = {
	AssociationName: {
		Input: 'basic-registration-data-form-association-name-input'
	}
};

// libs/v-loket/shared/src/lib/ui/components/location-form/location-form.component.html
export const LocationForm = {
	Type: {
		Input: 'location-form-type-input'
	},
	Description: {
		Input: 'location-form-description-input'
	},
	Zipcode: {
		Input: 'location-form-zipcode-input'
	},
	Locality: {
		Input: 'location-form-locality-input'
	},
	Street: {
		Input: 'location-form-street-input'
	},
	Number: {
		Input: 'location-form-number-input'
	},
	Box: {
		Input: 'location-form-box-input'
	},
	Country: {
		Input: 'location-form-country-input'
	}
};

// libs/shared/ui/forms/src/lib/ui/components/country-select/country-select.component.html
export const CountrySelect = {
	Select: {
		Input: 'location-form-select-input'
	},
	Other: {
		Input: 'location-form-other-input'
	}
};

// libs/v-loket/shared/src/lib/ui/components/representative-registration-form/representative-form.component.html
export const RepresentativeForm = {
	Firstname: {
		Input: 'representative-firstname-input'
	},
	Surname: {
		Input: 'representative-surname-input'
	},
	Callname: {
		Input: 'representative-callname-input'
	},
	Type: {
		Input: 'representative-type-input'
	},
	Role: {
		Input: 'representative-role-input'
	},
	Email: {
		Input: 'representative-email-input'
	},
	Phone: {
		Input: 'representative-phone-input'
	},
	MobilePhone: {
		Input: 'representative-mobile-phone-input'
	},
	SocialMedia: {
		Input: 'representative-social-media-input'
	},
	RRN: {
		Input: 'representative-rrn-input'
	},
	InviteMessage: {
		Input: 'representative-invite-message-input'
	}
};

// libs/v-loket/shared/src/lib/ui/components/send-copy/send-copy.component.html
export const SendCopy = {
	IsActive: {
		Input: 'send-copy-is-active-input'
	},
	Recipient: {
		Input: 'send-copy-recipient-input'
	}
};

// libs/v-loket/my-association/shared/src/lib/ui/components/association-detail-info-list-card/detail-info-list-card.html
export const DetailInfoListCard = {
	Wrapper: 'detail-info-list-card',
	Address: 'detail-info-list-card-address',
	Website: 'detail-info-list-card-website',
	Phone: 'detail-info-list-card-phone',
	SocialMedia: 'detail-info-list-card-social-media',
	Email: 'detail-info-list-card-email',
	Actions: {
		Delete: 'detail-info-list-card-action-delete',
		Revoke: 'detail-info-list-card-action-revoke',
		Edit: 'detail-info-list-card-action-edit'
	}
};

// libs/v-loket/my-association/shared/src/lib/ui/components/association-data-banner/association-data-banner.component.html
export const MyAssociationAssociationDataBanner = {
	Fields: {
		Name: 'my-association-data-banner-name',
		Description: 'my-association-data-banner-description',
		StartDate: 'my-association-data-banner-start-date',
		MainActivityTag: 'my-association-data-banner-main-activity-tag'
	},
	Actions: {
		Edit: 'my-association-data-banner-edit'
	}
};

// libs/v-loket/my-association/shared/src/lib/ui/components/association-data-banner/association-data-banner.component.html
export const MyAssociationAssociationData = {
	Actions: {
		// Match DetailInfoSection.Contact
		AddContact: 'my-association-data-add-contact',
		// Match DetailInfoSection.Location
		AddLocation: 'my-association-data-add-location'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/components/basic-association-data-form/basic-association-data-form.component.html
export const MyAssociationAssociationDataForm = {
	Name: {
		Input: 'my-association-association-data-form-name-input'
	},
	Shortname: {
		Input: 'my-association-association-data-form-shortname-input'
	},
	Description: {
		Input: 'my-association-association-data-form-description-input'
	},
	StartDate: {
		Input: 'my-association-association-data-form-start-date-input'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/pages/association-data-edit/association-data-edit.component.html
export const MyAssociationAssociationDataEdit = {
	Actions: {
		Save: 'my-association-association-data-edit-actions-save'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/components/contact-item-form/contact-item-form.component.html
export const MyAssociationAssociationContactItemForm = {
	Wrapper: 'my-association-association-contact-item-form-wrapper',
	Fields: {
		Type: {
			Input: 'my-association-association-contact-item-form-type-input'
		},
		Value: {
			Input: 'my-association-association-contact-item-form-value-input'
		}
	},
	Actions: {
		New: {
			Update: 'my-association-association-contact-item-form-actions-new-update',
			Delete: 'my-association-association-contact-item-form-actions-new-discard'
		},
		Existing: {
			Enable: 'my-association-association-contact-item-form-actions-existing-enable',
			Disable: 'my-association-association-contact-item-form-actions-existing-disable',
			Update: 'my-association-association-contact-item-form-actions-existing-update',
			Delete: 'my-association-association-contact-item-form-actions-existing-delete',
			Undo: 'my-association-association-contact-item-form-actions-existing-undo'
		}
	}
};

// libs/v-loket/my-association/association/src/lib/ui/components/contact-description-form/contact-description-form.component.html
export const MyAssociationAssociationContactDescriptionForm = {
	Fields: {
		Description: {
			Input: 'my-association-association-contact-description-form-description-input'
		}
	},
	Actions: {
		Enable: 'my-association-association-contact-description-form-actions-existing-enable',
		Disable: 'my-association-association-contact-description-form-actions-existing-disable',
		Update: 'my-association-association-contact-description-form-actions-existing-update',
		Delete: 'my-association-association-contact-description-form-actions-existing-delete',
		Undo: 'my-association-association-contact-description-form-actions-existing-undo'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/pages/association-contact/association-contact.component.html
export const MyAssociationAssociationContactAdd = {
	Actions: {
		Cancel: 'my-association-association-contact-cancel'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/pages/association-contact-edit/association-contact-edit.component.html
export const MyAssociationAssociationContactEdit = {
	Actions: {
		Add: 'my-association-association-contact-edit-add',
		Cancel: 'my-association-association-contact-edit-cancel'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/pages/association-location/association-location.component.html
export const MyAssociationAssociationLocationAdd = {
	Actions: {
		Submit: 'my-association-association-location-add',
		Cancel: 'my-association-association-location-cancel'
	}
};

// libs/v-loket/my-association/association/src/lib/ui/pages/association-location-edit/association-location-edit.component.html
export const MyAssociationAssociationLocationEdit = {
	Actions: {
		Submit: 'my-association-association-location-edit',
		Cancel: 'my-association-association-location-cancel'
	}
};

// libs/v-loket/my-association/representatives/src/lib/ui/pages/representatives/representatives.component.html
export const MyAssociationRepresentatives = {
	Actions: {
		Add: 'my-association-representatives-actions-add'
	}
};

// libs/v-loket/my-association/representatives/src/lib/ui/pages/representative-invite/representative-invite.component.html
export const MyAssociationRepresentativesInvite = {
	Actions: {
		Invite: 'my-association-representatives-invite-actions-invite',
		Cancel: 'my-association-representatives-invite-actions-cancel'
	}
};

export const CypressTags = {
	...HomeTags,
	Header,
	Offers,
	Status,
	Redirects,
	General,
	Products,
	Toaster,
	ActionModal,
	FilterBar,
	FilterSelectionTags,
	Associations,
	FVRegistration,
	BasicRegistrationDataForm,
	LocationForm,
	CountrySelect,
	RepresentativeForm,
	SendCopy,
	DetailInfoListCard,
	MyAssociationAssociationDataBanner,
	MyAssociationAssociationData,
	MyAssociationAssociationDataForm,
	MyAssociationAssociationDataEdit,
	MyAssociationAssociationContactItemForm,
	MyAssociationAssociationContactDescriptionForm,
	MyAssociationAssociationContactAdd,
	MyAssociationAssociationContactEdit,
	MyAssociationAssociationLocationAdd,
	MyAssociationAssociationLocationEdit,
	MyAssociationRepresentatives,
	MyAssociationRepresentativesInvite
};
export type CypressTagsPaths = Paths<typeof CypressTags>;
