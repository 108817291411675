import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProgressBarLabelAlign } from './progress-bar.const';

@Component({
	selector: 'vloket-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
	/**
	 * The title input attribute will display the text inside the label.
	 *
	 * default is set to ''
	 */
	@Input() public title: string = '';

	/**
	 * The progress input attribute will display the progress of the progress bar.
	 *
	 * default is set to 0
	 */
	@Input() public progress: number = 0;

	/**
	 * The maxValue input attribute will display the maximum value of the progress bar.
	 *
	 * default is set to 100
	 */
	@Input() public maxValue: number = 100;

	/**
	 * The align input attribute will determine the aligment of the label element.
	 *
	 * default is set to 'full'
	 */
	@Input() public align: `${ProgressBarLabelAlign}` = ProgressBarLabelAlign.Full;
}
