import { CJMIdMeta, CJMTypeMeta } from './cjm-result';

export interface ProductPartnerResult extends CJMTypeMeta, CJMIdMeta {
	naam: string;
	url: string;
}

export interface ProductPartnerEntity {
	name: string;
	url: string;
}
