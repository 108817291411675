import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ButtonClasses } from '@cjm/shared/ui/common';
import { transformJsonToObject } from '@cjm/v-loket/shared';

import { TileBackgroundVariants } from './tile-block.const';
import { TilesEntity } from './tile-block.types';

@Component({
	selector: 'vloket-tile-block',
	templateUrl: './tile-block.component.html',
	styleUrls: ['./tile-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileBlockComponent {
	@HostBinding('class.c-advice-detail-page-tile-block') private readonly rootClass: boolean = true;

	@Input({
		required: true,
		transform: (value: unknown) => {
			return transformJsonToObject<TilesEntity>(value);
		}
	})
	public data: TilesEntity;

	public readonly backgroundVariants: typeof TileBackgroundVariants = TileBackgroundVariants;
	protected readonly buttonClasses = ButtonClasses;
}
