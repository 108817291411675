import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	Results: {
		SingleResult: marker('results.single-result'),
		MultipleResults: marker('results.multiple-results'),
		SingleResultWoAmount: marker('results.single-result-wo-amount'),
		MultipleResultsWoAmount: marker('results.multiple-results-wo-amount'),
		NoResults: marker('results.no-results'),
		Of: marker('results.of')
	},
	Hamburger: {
		Title: marker('hamburger.title')
	},
	Link: {
		Extern: marker('link.extern')
	},
	SkipLinks: {
		MainContent: marker('skip-links.main-content'),
		Footer: marker('skip-links.footer')
	},
	ProgressMeter: {
		Step: marker('progress-meter.step')
	}
};
