import { Component } from '@angular/core';

import { BrowserService, SessionService } from '@cjm/shared/core';
import { Language } from '@cjm/shared/i18n';

@Component({
	selector: 'cjm-no-resources',
	templateUrl: './no-resources.component.html',
	styleUrls: ['./no-resources.component.scss']
})
export class NoResourcesComponent {
	public readonly language: Language = this.sessionService.language;

	/**
	 * The translation record of the title.
	 */
	public readonly titleTranslations: Record<Language, string> = {
		nl: 'Er is iets misgelopen',
		en: 'Something went wrong'
	};

	/**
	 * The translation record of the description.
	 */
	public readonly descriptionTranslations: Record<Language, string> = {
		nl: 'Er zijn bepaalde belangrijke elementen die niet geladen konden worden. Gelieve opnieuw te proberen.',
		en: 'Some key features could not be loaded. Please try again below.'
	};

	/**
	 * The translation record of the button title.
	 */
	public readonly buttonTitleTranslations: Record<Language, string> = {
		nl: 'Klik hier om de belangrijke diensten opnieuw te laden.',
		en: 'Click here to reload the important features by refreshing the page.'
	};

	/**
	 * The translation record of the button text.
	 */
	public readonly buttonTextTranslations: Record<Language, string> = {
		nl: 'Opnieuw laden',
		en: 'Reload'
	};

	constructor(private readonly sessionService: SessionService, private readonly browserService: BrowserService) {}

	/**
	 * Reload the document
	 */
	public retryStatus(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.location.reload();
		});
	}
}
