<li *ngIf="item">
	<fa-icon
		*ngIf="item.icon"
		class="c-association-detail-contact__icon"
		[iconType]="item.iconType || 'fa-regular'"
		[icon]="item.icon"
	></fa-icon>
	<a *ngIf="item.url" target="_blank" [href]="item.url">
		<span [class.u-font-bold]="item.bold">{{ item.label }}</span>
	</a>
	<span *ngIf="!item.url" [class.u-font-bold]="item.bold">{{ item.label }}</span>
</li>
