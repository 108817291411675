export interface ToastEntity {
	id: number;
	type: ToastTypes;
	message: string;
	duration: number;
}

export enum ToastTypes {
	Error = 'error',
	Success = 'success'
}
