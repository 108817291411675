import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'vloket-detail-footer',
	templateUrl: './detail-footer.component.html',
	styleUrls: ['./detail-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailFooterComponent {
	@HostBinding('class.c-detail-footer') private readonly hasRootClass: boolean = true;
}
