<div
	*ngIf="cardInfo"
	class="c-vloket-association-card {{ classes }}"
	[ngClass]="{ 'c-vloket-association-card--with-shadow': disableShadow }"
>
	<h2 *ngIf="!withLink && cardInfo.name" class="c-vloket-header c-vloket-header--h4">{{ cardInfo?.name }}</h2>
	<h2 *ngIf="withLink && cardInfo.name" class="c-vloket-header c-vloket-header--h4">
		<a
			class="u-link--no-decoration"
			[ngClass]="{ 'icon-open-externally': target === '_blank' }"
			[title]="cardInfo?.name"
			[routerLink]="link"
			[target]="target"
			>{{ cardInfo?.name }}</a
		>
	</h2>

	<cjm-tag *ngIf="cardInfo.type" icon="fa-tag" tagType="light">
		<ng-container *ngIf="!cardInfo?.organisationId">
			{{ cardInfo?.type }}
		</ng-container>
		<ng-container *ngIf="cardInfo?.organisationId">
			{{ cardInfo?.type }}: {{ cardInfo.organisationId | btw }}
		</ng-container>
		<ng-container *ngIf="cardInfo?.id"> | vCode: {{ cardInfo.id }}</ng-container>
	</cjm-tag>

	<cjm-tag-group *ngIf="cardInfo.labels.length > 0" class="u-margin-top u-margin-bottom">
		<cjm-tag *ngFor="let label of cardInfo.labels" icon="fa-person" tagType="yellow">{{ label }}</cjm-tag>
	</cjm-tag-group>

	<cjm-tag *ngIf="cardInfo.location" class="u-margin-bottom-xl" icon="fa-map" iconType="fa-regular">{{
		cardInfo.location
	}}</cjm-tag>
	<h3 *ngIf="cardInfo.representatives?.length" class="c-vloket-association-card__subtitle">
		<span class="c-vloket-text-marking c-vloket-text-marking--underline">
			{{ i18nKeys.SharedAssociations.Card.RepresentativesSectionTitle | translate }}
		</span>
	</h3>
	<div *ngIf="cardInfo.representatives?.length" class="c-vloket-association-card__main-representatives">
		<div
			*ngFor="let mainRepresentative of cardInfo.representatives"
			class="c-vloket-association-card__main-representatives__list__item"
		>
			<div
				*ngIf="mainRepresentative.name"
				class="c-vloket-association-card__main-representatives__name c-vloket-association-card__main-representatives__item"
			>
				{{ mainRepresentative.name }}
			</div>
			<span *ngIf="mainRepresentative.email" class="c-vloket-association-card__main-representatives__item">
				<fa-icon icon="fa-envelope" />
				<cjm-link *ngIf="mainRepresentative.email" type="email">
					{{ mainRepresentative.email }}
				</cjm-link>
			</span>

			<span *ngIf="mainRepresentative.phone" class="c-vloket-association-card__main-representatives__item">
				<fa-icon icon="fa-phone" />
				<cjm-link type="phone">
					{{ mainRepresentative.phone }}
				</cjm-link>
			</span>
		</div>
	</div>

	<!-- Denis: Optional slot to add additional content like buttons -->
	<ng-content />
</div>
