import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';

import { BrowserService } from '@cjm/shared/core';
import { environment } from '~environment';

import { LinkType } from './link.const';
import { VloketLinkIcon } from './link.types';

// TODO: component needs to be renamed to cjm-anchor and moved to the shared library so that it can be accessed by e-loket
@Component({
	selector: 'cjm-link',
	templateUrl: 'link.component.html',
	styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
	@HostBinding('class.c-cjm-link') private readonly hasLinkClass = true;
	@HostBinding('class.c-cjm-link--no-decoration') @Input() public noDecoration = false;
	@Input() public to: string | string[] = '';
	@Input() public fragment: string;
	@Input() public queryParams: Params;
	@Input() public title: string = '';
	@Input() public classes: string = '';
	@Input() public type: `${LinkType}` = LinkType.internal;
	@Input() public keepInTab: boolean = false;
	@Input() public icon: VloketLinkIcon;
	@Input() public disabled: boolean = false;
	@Input() public tooltip: string;

	public linkTypes: typeof LinkType = LinkType;

	constructor(private readonly browserService: BrowserService, private readonly matomoTracker: MatomoTracker) {}

	public ngOnInit(): void {
		if (this.type === LinkType.external) {
			this.browserService.runInBrowser(() => {
				this.matomoTracker.setCustomDimension(
					environment.matomo.dimensions.outlinkReferrerPage,
					this.browserService.getWindowLocation()
				);
			});
		}
	}
}
