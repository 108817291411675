import {
	Component,
	Input,
	Output,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	OnInit,
	OnChanges,
	EventEmitter
} from '@angular/core';
import { isNil } from 'lodash';

@Component({
	selector: 'cjm-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent implements OnInit, OnChanges {
	@Input() public current: number;
	@Input() public max: number;

	@Output() public handleIndexChange: EventEmitter<number> = new EventEmitter();

	public min: number = 1;
	public range: number[] = [];
	public showSkipToFirst: boolean = false;
	public showSkipToFirstEllipsis: boolean = false;
	public showSkipToLast: boolean = false;
	public showSkipToLastEllipsis: boolean = false;

	constructor(private changeDetector: ChangeDetectorRef) {}

	public ngOnInit(): void {
		this.calculateRange();
		this.changeDetector.markForCheck();
	}

	public ngOnChanges(): void {
		// Any change triggers a recalculation
		this.calculateRange();
		this.changeDetector.markForCheck();
	}

	/**
	 * prevIndex
	 *
	 * The prevIndex method will trigger notifyChange with the current value minus one.
	 */
	public prevIndex(): void {
		if (this.current === this.min) {
			return;
		}

		this.notifyChange(this.current - 1);
	}

	/**
	 * nextIndex
	 *
	 * The nextIndex method will trigger notifyChange with the current value plus one.
	 */
	public nextIndex(): void {
		if (this.current === this.max) {
			return;
		}

		this.notifyChange(this.current + 1);
	}

	/**
	 * goToIndex
	 *
	 * The goToIndex method will trigger notifyChange with a given index.
	 */
	public goToIndex(index: number): void {
		if (this.current === index) {
			return;
		}

		this.notifyChange(index);
	}

	/**
	 * notifyChange
	 *
	 * The notifyChange method will emit the given value.
	 */
	public notifyChange(index: number): void {
		this.handleIndexChange.emit(index);
	}

	/**
	 * calculateRange
	 *
	 * The calculateRange method will calculate the range and which controls to show (see unit testing for details)
	 */
	private calculateRange(): void {
		if (isNil(this.min) || isNil(this.max)) {
			return;
		}

		if (this.max - this.min <= 2) {
			this.range = this.numberToArray(this.max);
			this.showSkipToFirst = false;
			this.showSkipToFirstEllipsis = false;
			this.showSkipToLast = false;
			this.showSkipToLastEllipsis = false;

			return;
		}

		if (this.current === this.min || this.current === this.min + 1) {
			this.range = this.numberToArray(this.min + 2);
			this.showSkipToFirst = false;
			this.showSkipToLast = this.range[2] < this.max;
			this.showSkipToLastEllipsis = this.range[2] + 1 < this.max;

			if (this.current === this.min) {
				this.showSkipToFirstEllipsis = false;
			}

			return;
		}

		if (this.current === this.max || this.current === this.max - 1) {
			this.range = this.numberToArray(this.max).slice(-3);
			this.showSkipToFirst = this.range[0] > this.min;
			this.showSkipToFirstEllipsis = this.range[0] - 1 > this.min;
			this.showSkipToLast = false;

			if (this.current === this.max) {
				this.showSkipToLastEllipsis = false;
			}

			return;
		}

		this.range = this.numberToArray(this.max).filter(
			(number: number) =>
				number >= this.current - 1 && number <= this.current + 1 && number >= this.min && number <= this.max
		);
		this.showSkipToFirst = this.range[0] > this.min;
		this.showSkipToFirstEllipsis = this.range[0] - 1 > this.min;
		this.showSkipToLast = this.range[2] < this.max;
		this.showSkipToLastEllipsis = this.range[2] + 1 < this.max;
	}

	/**
	 * numberToArray
	 *
	 * The numberToArray will convert a given numeric value into an array of numbers.
	 *
	 * @param number
	 * @param offset
	 *
	 * @returns number[]
	 */
	private numberToArray(number: number, offset: number = 1): number[] {
		return Array(number)
			.fill(0)
			.map((_, index: number) => index + offset);
	}
}
