import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { AssociationTargetAudienceEntity, AssociationTargetAudience } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vloket-association-target-audience-tags',
	templateUrl: './association-target-audience-tags.component.html',
	styleUrls: ['./association-target-audience-tags.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationTargetAudienceTagsComponent {
	@HostBinding('class.c-association-target-audience-tags') private readonly hasRootClass: boolean = true;
	/**
	 * targetAudience
	 *
	 * The targetAudience attribute will accept an object of type AssociationTargetAudienceEntity.
	 */
	@Input({ required: true }) public readonly targetAudience: AssociationTargetAudienceEntity;
	/**
	 * showUnset
	 *
	 * The showUnset attribute will decide on showing the 'not set' tag.
	 */
	@Input() public showNotSet: boolean = false;

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly associationTargetAudience: typeof AssociationTargetAudience = AssociationTargetAudience;

	constructor(public readonly i18nService: I18nService) {}
}
