// External Interfaces
import {
	CJMIdMeta,
	CJMElementsResult,
	CJMTypeMeta,
	ProductEntityResult,
	IndexedPaginationResult
} from '@cjm/shared/types';

// External Interfaces
// ------------------------------------------------------------------------- /
export interface OfferedProductEntityResult extends CJMTypeMeta<'AangebodenProduct'> {
	product: ProductEntityResult;
}

export interface OfferedProductEntityCollection
	extends CJMTypeMeta<'AangebodenProductverzameling'>,
		CJMIdMeta,
		CJMElementsResult<OfferedProductEntityResult> {}

export type OfferedProductEntity = OfferedProductEntityResult;

export interface OfferedProductCollectionResult {
	paginaVan: string;
	volgendePagina?: string;
	paginatie: IndexedPaginationResult;
	inhoud: {
		elementen: { product: ProductEntityResult }[];
	};
}
