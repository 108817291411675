import { Directive, Input, HostListener } from '@angular/core';
import { filter, switchMap, take, tap } from 'rxjs';

import { ACMTargetGroups } from '@cjm/shared/types';
import { ActionModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';

import { AuthenticationService, EidRedirectWarning } from '../../data';

/**
 * A directive that allows a user to log in via ACM.
 *
 * When the host element is clicked, the directive will prevent the default click action and
 * initiate the login process using the provided callback, ACM Target Group and organisation id.
 *
 * **IMPORTANT: If you set capHint to EA/VER you are required to set codeHint as well**
 *
 * **Example usage**
 *
 * ```
 * <button cjmUserEIDLogin [callback]="'/dashboard'" [capHint]="'ACMTargetGroups.VER'" [codeHint]="'my-code-hint'">
 * 	Log in via ACM
 * </button>
 * ```
 *
 */
@Directive({
	selector: '[cjmUserEIDLogin]'
})
export class UserEIDLoginDirective {
	/**
	 * The callback URL to navigate to after a successful login.
	 */
	@Input() public callback: string;
	/**
	 * The capHint is ACM Target Group and necessary when you want a directed-switch.
	 */
	@Input() public capHint: `${ACMTargetGroups}`;
	/**
	 * The codeHint is organisation id and required when capHint is EA/VER.
	 */
	@Input() public codeHint: string;
	/**
	 * The withWarning attribute will show an optional confirmation modal.
	 *
	 * Default value: false
	 */
	@Input() public withWarning: boolean = false;
	/**
	 * The warningLabels attribute can take an EidRedirectWarning object for the modal labels.
	 */
	@Input() public warningLabels: EidRedirectWarning;

	constructor(
		private readonly authService: AuthenticationService,
		private readonly modalService: ModalDialogService
	) {}

	/**
	 * Handles the click event on the host element.
	 */
	@HostListener('click', ['$event']) public onClick(e) {
		// Iben: Prevent default
		e.preventDefault();
		e.stopPropagation();

		if (this.withWarning && this.warningLabels) {
			const modal = this.modalService.openModal(ActionModalComponent);
			modal.component.title = this.warningLabels.title;
			modal.component.text = this.warningLabels.text;
			modal.component.confirmLabel = this.warningLabels.confirmLabel;
			modal.component.cancelLabel = this.warningLabels.cancelLabel;

			modal.component.buttonClicked
				.pipe(
					// Denis: Only take the first result in the stream
					take(1),
					// Denis: When the cancel button is clicked, close the modal
					tap((value: 'confirm' | 'cancel') => {
						if (value === 'cancel') {
							this.modalService.instantCloseModal();
						}
					}),
					// Denis: Only proceed if the action has been confirmed
					filter((value: 'confirm' | 'cancel') => value === 'confirm'),
					// Denis: Close the modal
					tap(() => this.modalService.closeModal()),
					switchMap(() =>
						// Redirect to login page with product callback if needed
						this.authService.login({
							customCallBack: this.callback,
							capHint: this.capHint as ACMTargetGroups,
							codeHint: this.codeHint
						})
					)
				)
				.subscribe();

			return;
		}

		// Iben: Redirect to login page with product callback if needed
		this.authService
			.login({
				customCallBack: this.callback,
				capHint: this.capHint as ACMTargetGroups,
				codeHint: this.codeHint
			})
			.subscribe();
	}
}
