import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { Observable, map } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { AssociationDetailEntity, AssociationDetailResultEntity, parseAssociation } from '@cjm/v-loket/shared';

import { IGetMainActivitiesResponse, IGetMainActivitiesResponseItem, IMainActivity } from './association.api.types';

@Injectable()
export class AssociationApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		httpClient.setIncludeLanguage(false);
	}
	/**
	 * getAssociationDetail
	 *
	 * The getAssociationDetail method performs a GET request with association id to the API on the /vereniging endpoint
	 * and use parseAssociation utility function to map association data from AssociationDetailResultEntity to AssociationDetailEntity.
	 *
	 * @param {string} id
	 * @returns  Observable<AssociationDetailEntity>
	 *
	 */
	public getAssociationDetail(id: string): Observable<AssociationDetailEntity> {
		return this.httpClient
			.get<AssociationDetailResultEntity>(VLoketEndpoints.Associations.GetAssociationDetail(id))
			.pipe(map(parseAssociation<AssociationDetailEntity>));
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities method performs a GET request to the API on the /vereniging/hoofdactiviteiten endpoint.
	 * Return type is set to Observable<any> which is the response of the API.
	 *
	 * @returns Observable:IMainActivities
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.httpClient
			.get(VLoketEndpoints.Associations.GetMainActivities(), {}, true)
			.pipe(
				map((data: IGetMainActivitiesResponse) => this.translateMainActivitiesPayload(data?.inhoud?.elementen))
			);
	}

	/**
	 * translateMainActivitiesPayload
	 *
	 * The translateMainActivitiesPayload method translates the mainActivities response from NL to EN for usage in the app.
	 *
	 * @param items: IGetMainActivitiesResponseItem[]
	 * @returns IMainActivity[]
	 */
	private translateMainActivitiesPayload(items: IGetMainActivitiesResponseItem[] = []): IMainActivity[] {
		return items.map((item: IGetMainActivitiesResponseItem) => ({
			type: item['@type'],
			id: item.id,
			name: item.naam
		}));
	}
}
