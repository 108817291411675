<div class="c-app-wrapper" [ngClass]="{ 'c-app-wrapper--with-menu-padding': showMenu() }">
	<div class="topbar">
		<div class="c-header__block c-header__container">
			<cjm-acm-header cypressTag="Header.Header" />
		</div>

		<cjm-layout-container
			*ngIf="showMenu()"
			class="c-header__block"
			layoutType="split-equal"
			[preventSpacing]="['top', 'bottom']"
			[preserve]="true"
		>
			<cjm-menu
				class="c-menu--vloket"
				main
				[menuLabel]="i18nKey.Common.Menu | translate"
				[menuItems]="primaryMenuItems"
				[showActiveItem]="true"
				[showHamburger]="false"
			>
				<ng-template #itemTmpl let-item>
					{{ item | translate }}
				</ng-template>
			</cjm-menu>
			<cjm-menu
				class="c-menu--vloket c-menu--vloket--with-background"
				asideRight
				[menuItems]="secondaryMenuItems"
				[showActiveItem]="true"
				[preventCollapse]="true"
			>
				<ng-template #itemTmpl let-item>
					{{ item | translate }}
				</ng-template>
			</cjm-menu>
		</cjm-layout-container>
	</div>

	<cjm-sidebar>
		<!-- Denis: prevent the default padding on the bottom of the layout-container and set it to 20px with u-padding-bottom -->
		<cjm-layout-container
			*ngIf="breadcrumbs()?.length > 0"
			class="c-vloket-breadcrumb"
			layoutType="full-width"
			[preventSpacing]="['top', 'bottom']"
		>
			<cjm-breadcrumb main></cjm-breadcrumb>
		</cjm-layout-container>

		<router-outlet></router-outlet>
	</cjm-sidebar>

	<cjm-vloket-big-footer [data]="bigFooterItems" />

	<cjm-vloket-footer />
</div>
