import { Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { PageEntity } from '../../types';

import { OnDestroyComponent } from './on-destroy.component';

@Directive()
export abstract class PageComponent extends OnDestroyComponent {
	/**
	 * Id of the page
	 */
	protected abstract getPageId(): ObservableString;

	/**
	 * Method to get the page
	 */
	protected abstract getPage(page: string): Observable<PageEntity>;

	constructor(public readonly route: ActivatedRoute) {
		super();
	}

	/**
	 * The content page we wish to render
	 */
	public readonly page$: Observable<PageEntity> = this.getPageId().pipe(
		filter<string>(Boolean),
		distinctUntilChanged(),
		switchMap((page) => this.getPage(page))
	);

	/**
	 * An anchor we wish to scroll to
	 */
	public readonly anchor$: ObservableString = this.route.queryParams.pipe(
		map((params: { anchor?: string }) => params?.anchor)
	);
}
