<cjm-layout-container *ngIf="data" class="c-vloket-big-footer" layoutType="full-width">
	<ng-container main>
		<div class="c-vloket-big-footer__link-group-container">
			<ul *ngFor="let linkgroup of data" class="c-vloket-big-footer__link-group">
				<h3 class="c-vloket-header c-vloket-header--h5 c-vloket-big-footer__link-group__title">
					{{ linkgroup.title | translate }}
				</h3>
				<li *ngFor="let link of linkgroup.links" class="c-vloket-big-footer__link-item">
					<cjm-link
						[classes]="'c-vloket-big-footer__link'"
						[to]="link.url"
						[type]="link.type"
						[queryParams]="link.queryParams"
						[noDecoration]="true"
						[icon]="linkIcon"
					>
						<span>{{ link.title | translate }}</span>
					</cjm-link>
				</li>
			</ul>
		</div>
	</ng-container>
</cjm-layout-container>
