import { IndexedPagination, IndexedPaginationResult } from '@cjm/shared/types';

/**
 * Returns a converted response of the pagination object from the BE
 * @param pagination - the to be converted pagination object;
 */
export const convertPagination = (pagination: IndexedPaginationResult): IndexedPagination => ({
	index: pagination.index,
	limit: pagination.limiet,
	totalAmount: pagination.totaalAantal,
	totalPages: Math.ceil(pagination?.totaalAantal / pagination?.limiet)
});
