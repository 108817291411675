export const transformJsonToObject = <ValueType = any>(input: any): ValueType => {
	let value: ValueType;

	try {
		value = JSON.parse(input);
	} catch (e) {
		value = input;
	}

	return value;
};
