export enum ImgCardColorType {
	Yellow = 'yellow',
	Grey = 'grey'
}

export enum ImgCardAlignment {
	Start = 'start',
	Center = 'center',
	End = 'end'
}
