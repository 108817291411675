import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { AuthUiModule } from '@cjm/shared/authentication/auth';
import { CompanyDataModule, SharedCompanyModule } from '@cjm/shared/company';
import { SharedUiModule } from '@cjm/shared/ui/common';
import { SharedFormsModule } from '@cjm/shared/ui/forms';
import { SharedUtilsModule } from '@cjm/shared/utils';
import { VloketSharedModule } from '@cjm/v-loket/shared';

import { RegistrationDataModule } from '../data';

import { RegistrationComponents } from './components';
import { RegistrationModals } from './modals';
import { RegistrationPages } from './pages';
import { RegistrationTranslationLoader } from './translation.loader';

@NgModule({
	imports: [
		// Angular imports
		CommonModule,
		// CJM imports
		AuthUiModule,
		NgxI18nModule.forChild(RegistrationTranslationLoader),
		SharedFormsModule,
		NgxErrorsModule,
		SharedUiModule,
		// ToastUiModule,
		SharedUtilsModule,
		CompanyDataModule,
		SharedCompanyModule,
		VloketSharedModule,
		RouterModule,
		// Local imports
		RegistrationDataModule
	],
	declarations: [RegistrationComponents, RegistrationPages, RegistrationModals]
})
export class RegistrationModule {}
