import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that transforms a string by replacing newline characters with <br/>.
 *
 * Usage:
 *   value | newlineToBreak
 *
 * Example:
 *   {{ 'Hello\nWorld' | newlineToBreak }}
 *   Output: Hello<br/>World
 */
@Pipe({ name: 'newlineToBreak' })
export class NewlineToBreakPipe implements PipeTransform {
	public transform(value: string): string {
		//  If no value was provided, return an empty string
		if (!value) {
			return '';
		}

		return value.replace(/\n/g, '<br>');
	}
}
