import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { bePhoneBinFormatted } from '@studiohyperdrive/regex-common';

import { PhoneValidatorOptions } from '../interfaces/phone-validator-options.const';

/**
 * PhoneValidator
 *
 * The PhoneValidator will use regex
 * to validate the giving input and check if it is a valid phone number.
 *
 * @param option
 * @returns ValidatorFn
 */
export const PhoneValidator = (option: PhoneValidatorOptions = PhoneValidatorOptions.BOTH): ValidatorFn => {
	const { landlinePattern, mobilePattern } = bePhoneBinFormatted;

	return (control: FormControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}
		switch (option) {
			case PhoneValidatorOptions.BOTH:
				return landlinePattern.test(control.value) || mobilePattern.test(control.value)
					? null
					: { invalidPhoneNumber: { valid: false } };
			case PhoneValidatorOptions.MOBILE:
				return mobilePattern.test(control.value) ? null : { invalidPhoneNumber: { valid: false } };
			case PhoneValidatorOptions.LANDLINE:
				return landlinePattern.test(control.value) ? null : { invalidPhoneNumber: { valid: false } };
		}
	};
};
