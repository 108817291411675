import { format } from 'date-fns';

/**
 * parseStartDate
 *
 * The parseStartDate util will check if the date is null, undefined or an empty string and
 * return an empty string if it is or parse & format the date if it is not.
 *
 * @param date
 * @private
 */
export const parseStartDate = (date: string): string => {
	if (typeof date === 'undefined' || date === null || date === '') {
		return '';
	}

	const startDate = new Date(date);

	return isNaN(startDate.getTime()) ? '' : format(startDate, 'yyyy-MM-dd');
};
