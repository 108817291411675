import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: ''
	},
	position: 'bottom',
	theme: 'classic',
	static: false,
	palette: {
		popup: {
			background: '#F8F8F8',
			text: '#000000',
			link: '#000000',
			border: '#000000'
		},
		button: {
			background: '#0066cc',
			text: '#ffff',
			border: 'transparent'
		},
		highlight: {
			background: '#ffffff',
			text: '#000000',
			border: 'transparent'
		}
	},
	type: 'info',
	revokeBtn: '<span class="cc-revoke {{classes}}" id="cc-revoke"></span>',
	window: '<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="m-cookieconsent cc-window {{classes}}"><!--googleoff: all-->{{children}}<!--googleon: all--></div>',
	elements: {
		header: '<span class="cc-header">{{header}}</span>&nbsp;',
		message: '<span id="cookieconsent:desc" class="cc-message">{{message}}</span>',
		messagelink:
			'<span id="cookieconsent:desc" title="{{message}}" class="cc-message">{{message}} <a title="Ga naar cookiebeleid" role=button tabindex="0" class="cc-link u-outline" href="{{href}}" rel="noopener noreferrer nofollow" target="_blank">{{link}}</a></span>',
		dismiss: '<button title="{{dismiss}}" class="cc-btn cc-dismiss u-outline">{{dismiss}}</button>',
		allow: '<button title="{{allow}}"  class="cc-btn cc-allow u-outline">{{allow}}</button>',
		deny: '<button title="{{deny}}" class="cc-btn cc-deny u-outline">{{deny}}</button>',
		link: '<a [title]="{{link}}" role=button class="cc-link" href="{{href}}" target="_blank" rel="noreferrer">{{link}}</a>',
		close: '<span [title]="{{close}}" role=button class="cc-close">{{close}}</span>'
	},
	animateRevokable: true,
	showLink: true,
	content: {
		header: 'Cookiegebruik',
		message: 'Verenigingsloket gebruikt cookies op deze website om je surfervaring te verbeteren.',
		dismiss: 'Ik begrijp het',
		link: 'Lees meer over het cookiebeleid.',
		href: '/nl/pagina/cookiebeleid'
	}
};
