<label *ngIf="label" class="c-input__label" [for]="id">
	{{ label }}
	<ng-container *ngIf="isOptional">
		&nbsp;<small class="c-input__label__optional">{{ i18nKeys.FormLabels.OptionalMarker | translate }}</small>
	</ng-container>
	<cjm-info *ngIf="tooltip" class="u-margin-left-sm" [description]="tooltip" [tooltipPosition]="'right'" />
</label>

<div *ngIf="hint" class="c-input__hint">{{ hint }}</div>

<ng-content></ng-content>

<div class="c-input__description" [ngClass]="{ 'is-error': hasError }">
	<ng-content select="[description]"></ng-content>
</div>
