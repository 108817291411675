import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';
import { I18nKeys as formsI18nKeys } from '@cjm/shared/ui/forms';

export const I18nKeys = {
	...sharedI18nKeys,
	...formsI18nKeys,
	SharedAssociations: {
		Card: {
			RepresentativesSectionTitle: marker('shared-associations.card.representativesSectionTitle'),
			ChildrenNotice: marker('shared-associations.card.children-notice')
		},
		PrimaryButton: {
			Set: {
				Title: marker('shared-associations.primary-button.set.title'),
				Text: marker('shared-associations.primary-button.set.text')
			},
			Remove: {
				Title: marker('shared-associations.primary-button.remove.title'),
				Text: marker('shared-associations.primary-button.remove.text')
			}
		},
		Forms: {
			RepresentativeData: {
				MainRepresentativeFieldset: {
					Title: marker('shared-associations.forms.representativeData.mainRepresentativeFieldset.title'),
					GeneralInfoTitle: marker(
						'shared-associations.forms.representativeData.mainRepresentativeFieldset.general-info-title'
					),
					ContactInfoTitle: marker(
						'shared-associations.forms.representativeData.mainRepresentativeFieldset.contact-info-title'
					),
					RepresentativeFirstnameField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeFirstnameField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeFirstnameField.placeholder'
						)
					},
					RepresentativeSurnameField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeSurnameField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeSurnameField.placeholder'
						)
					},
					RepresentativeCallnameField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeCallnameField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeCallnameField.placeholder'
						)
					},
					RepresentativeRoleField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeRoleField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeRoleField.placeholder'
						),
						Options: {
							Other: {
								Label: marker(
									'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeRoleField.options.other.label'
								),
								Placeholder: marker(
									'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeRoleField.options.other.placeholder'
								)
							}
						}
					},
					RepresentativeEmailField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeEmailField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeEmailField.placeholder'
						)
					},
					RepresentativePhoneField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativePhoneField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativePhoneField.placeholder'
						)
					},
					RepresentativeMobilePhoneField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeMobilePhoneField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeMobilePhoneField.placeholder'
						)
					},
					RepresentativeSocialMedia: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeSocialMedia.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeSocialMedia.placeholder'
						)
					},
					RepresentativeRRNField: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeRRNField.label'
						),
						Placeholder: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeRRNField.placeholder'
						)
					},
					RepresentativeInviteMessage: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeInviteMessage.label'
						),
						DefaultValue: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeInviteMessage.defaultValue'
						)
					},
					RepresentativeRequestMessage: {
						Label: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeInviteMessage.label'
						),
						DefaultValue: marker(
							'shared-associations.forms.representativeData.mainRepresentativeFieldset.representativeInviteMessage.defaultValue'
						)
					}
				}
			},
			Location: {
				LocationType: {
					Label: marker('shared-associations.forms.location.location-type.label'),
					Placeholder: marker('shared-associations.forms.location.location-type.placeholder')
				},
				Description: {
					Label: marker('shared-associations.forms.location.description.label'),
					Tooltip: marker('shared-associations.forms.location.description.tooltip'),
					Placeholder: marker('shared-associations.forms.location.description.placeholder'),
					CorrespondenceAddressPrefill: marker(
						'shared-associations.forms.location.description.correspondence-address-prefill'
					)
				},
				Street: {
					Label: marker('shared-associations.forms.location.street.label'),
					Placeholder: marker('shared-associations.forms.location.street.placeholder')
				},
				Number: {
					Label: marker('shared-associations.forms.location.number.label'),
					Placeholder: marker('shared-associations.forms.location.number.placeholder')
				},
				Box: {
					Label: marker('shared-associations.forms.location.box.label'),
					Placeholder: marker('shared-associations.forms.location.box.placeholder')
				},
				Locality: {
					Label: marker('shared-associations.forms.location.locality.label'),
					Placeholder: marker('shared-associations.forms.location.locality.placeholder')
				},
				Zipcode: {
					Label: marker('shared-associations.forms.location.zipcode.label'),
					Placeholder: marker('shared-associations.forms.location.zipcode.placeholder')
				},
				Country: {
					Label: marker('shared-associations.forms.location.country.label'),
					Placeholder: marker('shared-associations.forms.location.country.placeholder')
				}
			},
			SendCopy: {
				Label: marker('shared-associations.forms.send-copy.label'),
				Placeholder: marker('shared-associations.forms.send-copy.placeholder')
			}
		},
		Timeline: {
			ActionRequiredLabel: marker('shared-associations.timeline.action-required-label')
		},
		FilterBar: {
			Facets: {
				NoResults: marker('shared-associations.filter-bar.facets.no-results'),
				Hoofdactiviteiten: marker('shared-associations.filter-bar.facets.activities')
			}
		}
	},
	Newsletter: {
		Title: marker('newsletter.title'),
		Text: marker('newsletter.text'),
		Button: {
			Title: marker('newsletter.button.title'),
			Text: marker('newsletter.button.text')
		}
	},
	TargetAudience: {
		Age: {
			Default: marker('target-audience.age.default'),
			NoAgeRestriction: marker('target-audience.age.no-age-restriction'),
			From: marker('target-audience.age.from'),
			UpTo: marker('target-audience.age.up-to'),
			FromTo: marker('target-audience.age.from-to')
		}
	}
};
