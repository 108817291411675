// Interfaces for GET /vereniging/hoofdactiviteiten
export interface IGetMainActivitiesResponseItem {
	'@type': 'VerenigingHoofdactiviteit';
	id: string;
	naam: string;
}

export interface IGetMainActivitiesResponse {
	'@type': 'Pagina';
	'@id': string;
	inhoud: {
		'@type': 'VerenigingHoofdactiviteitenVerzameling';
		elementen: IGetMainActivitiesResponseItem[];
	};
}

export interface IMainActivity {
	type: 'VerenigingHoofdactiviteit';
	id: string;
	name: string;
}
