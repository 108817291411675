<ul class="c-vloket-icon-list">
	<li *ngFor="let item of items" class="c-vloket-text c-vloket-text--lg c-vloket-icon-list__item">
		<fa-icon
			class="c-vloket-icon-list__item__icon c-vloket-icon-list__item__icon--{{ item.iconClass }}"
			[icon]="item.icon"
			[iconType]="item.iconType"
		/>

		<div class="c-vloket-icon-list__content" [ngClass]="{ 'c-vloket-icon-list__no-link': !item?.to }">
			<cjm-link
				*ngIf="item?.to; else noLinkTmpl"
				classes="c-vloket-icon-list__link c-vloket-icon-list__content"
				[to]="item.to"
				[fragment]="item.fragment"
				[title]="item.text"
			>
				{{ item.text }}
			</cjm-link>
		</div>

		<ng-template #noLinkTmpl>
			{{ item.text }}
		</ng-template>
	</li>
</ul>
