import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';
import { ToastUiModule } from '@cjm/shared/ui/toast';

import { NotificationServices } from './services';
import { notificationReducers } from './store';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Notifications, notificationReducers), ToastUiModule],
	providers: [NotificationServices],
	exports: [ToastUiModule]
})
export class NotificationsDataModule {}
