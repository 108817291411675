import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs/operators';

import { SessionService } from '@cjm/shared/core';
import { SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity, UserService } from '@cjm/shared/user';
import { RegisterKboSnackbarComponent, SESSION_KEY } from '@cjm/v-loket/registration';

/**
 * ShowRegisterKboSnackbarGuard
 *
 * The ShowRegisterKboSnackbarGuard is a CanActivate guard that will check if the user has a company without a vCode.
 * If this condition is true, it will open the SnackBarComponent with the information below.
 *
 * @constructor
 * @returns ObservableBoolean
 */
export const ShowRegisterKboSnackbarGuard: CanActivateFn = (): ObservableBoolean => {
	const userService: UserService = inject(UserService);
	const snackBarService: SnackBarService = inject(SnackBarService);
	const sessionService: SessionService = inject(SessionService);
	const hideForSession = sessionService.getSessionItem(SESSION_KEY, (value: string) => value === 'true');

	return userService.user$.pipe(
		// Denis: only move forward when user is defined.
		map((user: UserEntity) => {
			if (!user) {
				return true;
			}

			// Denis: check if the user is a company and contains a vCode and if the user has dismissed the notice
			if (user.company && !user.company?.vCode && !hideForSession) {
				// Denis: if not, show a toast message.
				return snackBarService.openFromComponent(
					RegisterKboSnackbarComponent,
					{
						data: {
							name: user.company.name
						},
						duration: null,
						horizontalPosition: 'center',
						verticalPosition: 'top',
						panelClass: 'c-vloket-snack-bar'
					},
					true
				);
			}
		}),
		map(() => true)
	);
};
