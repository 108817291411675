import { ProductEntity } from './product.entity';

export interface CallToActionResult {
	naam: string;
	doelVanDeActie: CallToActionResultEnum;
	annotaties: string[];
	actieLink: string;
}

export enum CallToActionResultEnum {
	AanvraagStarten = 'AanvraagStarten',
	ToegangVragen = 'ToegangVragen',
	MeerInfoBekijken = 'MeerInfoBekijken',
	Aanmelden = 'Aanmelden'
}

export enum CallToActionEnum {
	START_CASE = 'START_CASE',
	REQUEST_ACCESS = 'REQUEST_ACCESS',
	MORE_INFO = 'MORE_INFO',
	LOGIN = 'LOGIN'
}

export enum CallToActionGTATypeEnum {
	MORE_INFO = 'btn_click_meer_informatie',
	REQUEST_ACCESS = 'btn_click_toegang_vragen',
	START_CASE = 'btn_click_aanvraag_starten'
}

export enum CallToActionGTALabelEnum {
	MORE_INFO = 'Button Meer informatie click',
	REQUEST_ACCESS = 'Button Toegang vragen click',
	START_CASE = 'Button Aanvraag starten click'
}

export interface CallToActionEntity {
	name: string;
	action: CallToActionEnum;
	link: string;
	annotations: string[];
}

export interface CallToActionClickedEvent {
	action: CallToActionEntity;
	product: ProductEntity;
}
