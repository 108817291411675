import { AdvicePageDividerVariant } from '../../../data';

export enum DividerVariantClass {
	BottomRight = 'bottom-right',
	BottomLeft = 'bottom-left',
	TopRight = 'top-right',
	TopLeft = 'top-left'
}

export type DividerVariantKeys = keyof typeof DividerVariants;

export const DividerVariants: Record<AdvicePageDividerVariant, DividerVariantClass> = {
	[AdvicePageDividerVariant.BottomRight]: DividerVariantClass.BottomRight,
	[AdvicePageDividerVariant.BottomLeft]: DividerVariantClass.BottomLeft,
	[AdvicePageDividerVariant.TopRight]: DividerVariantClass.TopRight,
	[AdvicePageDividerVariant.TopLeft]: DividerVariantClass.TopRight
};
