<cjm-layout-container *ngIf="data" class="c-advice-detail-page-roadmap-block" layoutType="full-width">
	<ng-container main>
		<h2 *ngIf="data.title" class="c-vloket-header c-vloket-header--h3">
			{{ data.title }}
		</h2>
		<span [outerHTML]="data.text"></span>
		<ul *ngIf="data.steps" class="c-advice-detail-page-roadmap-block__step-list">
			<li *ngFor="let step of data.steps" class="c-advice-detail-page-roadmap-block__step">
				<h3 *ngIf="step?.title" class="c-vloket-header c-vloket-header--h6">{{ step.title }}</h3>
				<div *ngIf="step?.text" class="u-wysiwyg" [innerHTML]="step.text"></div>
			</li>
		</ul>
	</ng-container>
</cjm-layout-container>
