import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { isEmpty, truncate } from 'lodash';
import { combineLatest, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { CodeToLanguage, Language, Languages } from '@cjm/shared/types';
import { EnvironmentType, environment } from 'environments';

import { AbstractTranslationProviderService } from '../../abstracts';

import { PageImageUrlType, MetaData, PageImage, PageData } from './meta-service.types';

@Injectable()
export class MetaService {
	constructor(
		private readonly i18nService: I18nService,
		private readonly metaService: Meta,
		private readonly titleService: Title,
		private readonly translationProviderService: AbstractTranslationProviderService
	) {}

	/**
	 * Updates the current HTML document meta data
	 *
	 * @param meta - Title and description of HTML document
	 * @param index - To disable indexing by search engines, optional, enabled by default
	 */
	public updateMetaData(pageContent: PageData, index?: boolean): void {
		if (!pageContent) {
			return;
		}

		const pageMetadata = this.generateMetaData(pageContent);

		this.generateMetaTags(pageMetadata, index);
	}

	/**
	 * Adds a canonical url to the dom
	 */
	public addCanonicalLink(browserDocument: Document): void {
		if (!browserDocument) {
			return;
		}

		// Iben: Construct canonical url
		const url = browserDocument.URL.split('?')[0];

		// Iben: Create link element if not existing, or replace current one
		const link = browserDocument.querySelectorAll('[rel="canonical"]')[0] || browserDocument.createElement('link');
		link.setAttribute('rel', 'canonical');
		link.setAttribute('href', url);

		// Iben: Append element to dom
		browserDocument.head.appendChild(link);
	}

	/**
	 *	Add meta tag to the head of the DOM
	 *
	 * @param name Name of the tag
	 * @param content Value of the tag
	 */
	public addTag(name: string, content: string): void {
		this.setTag(name, content);
	}

	/**
	 * Remove a tag by name
	 *
	 * @param name Name of the tag to be removed
	 */
	public removeTagByName(name: string): void {
		this.metaService.removeTag(`name="${name}"`);
	}

	/**
	 * Updates the current HTML document meta data based on translations
	 *
	 * @param meta - Updates only the title and description of HTML document
	 */
	public updateMetaDataWithTranslations(meta: PageData): ObservableArray<string> {
		// Bram: Early exit if meta does note exist
		if (!meta) {
			return;
		}
		// TODO: Check why updateMetaDataWithTranslations is not working, currently not used anywhere
		// Iben: Set translations
		return combineLatest([
			meta.title ? this.translationProviderService.getTranslation(meta.title) : of(''),
			meta.description ? this.translationProviderService.getTranslation(meta.description) : of('')
		]).pipe(
			filter<[string, string]>((result) => Boolean(result[0]) && Boolean(result[1])),
			take(1),
			tap(([title, description]) => {
				this.updateMetaData({ title, description, ...meta });
			})
		);
	}

	/**
	 * Sets a meta data tag on the current HTML document
	 *
	 * @param name - Name of the tag
	 * @param value - Value of the tag
	 */
	private setTag(name: string, content: string): void {
		if (this.metaService.getTag(name)) {
			this.metaService.removeTag(name);
		}

		this.metaService.addTag({ name, content });
	}

	/**
	 * Generates a page title based on the provided title and the domain
	 * If the environment is not production than it will be also added to title
	 *
	 * @param {string} title - Title of the page.
	 * @returns {string} The generated page title.
	 */
	private generatePageTitle(title: string): string {
		// If the title is not provided, return the domain name as the page title.
		if (!title) {
			return environment.metaDomain;
		}

		// The environment name will be added to the title as an indicator for
		// distinguishing the environments from each other except the production
		// Example: [DEV] This is the page title | domain.name
		return `${
			environment.environment !== EnvironmentType.PRODUCTION
				? `[${Object.keys(EnvironmentType)[Object.values(EnvironmentType).indexOf(environment.environment)]}] `
				: ''
		}${title.replace(/%/g, '')} | ${environment.metaDomain}`;
	}

	/**
	 * Generates a image url based on the provided type
	 *
	 * @param {PageImage} url
	 */
	private generateImageUrl(url: PageImage): string {
		// If the url is not provided, return the default image.
		if (!url || isEmpty(url.path)) {
			return `${environment.assets.fullPath}img/home/home-header-image-1.webp`;
		}

		// If the url type is external, return the external image url.
		if (url.type === PageImageUrlType.external) {
			return url.path;
		}

		return `${environment.assets.fullPath}img/${url.path}`;
	}

	/**
	 * Generates meta data for a page based on the provided content.
	 *
	 * @param {PageMetaData} content - The content of the page.
	 * @returns The generated meta data.
	 */
	private generateMetaData(content: PageData): MetaData {
		return {
			title: this.generatePageTitle(content.title),
			// Abdurrahman: Truncate the description to 160 characters if it's longer than 160 characters
			description: truncate(content.description, { length: 160 }),
			pageUrl: content.pageUrl ? `${environment.domain}${content.pageUrl}` : environment.domain,
			imageUrl: this.generateImageUrl(content.imageUrl),
			type: content.type ? content.type : 'website',
			author: content.author && content.author,
			locale:
				this.i18nService.currentLanguage === Language.NL
					? Languages.nl_BE
					: CodeToLanguage[this.i18nService.currentLanguage]
		};
	}

	/**
	 * Generates and sets meta tags for the page based on the provided metadata.
	 *
	 * @param {PageMetaData} metadata - The metadata of the page.
	 * @param {boolean} index - Whether to index or not to index the page (default: true).
	 */
	private generateMetaTags(metadata: MetaData, index: boolean = true): void {
		// Set page title
		this.titleService.setTitle(metadata.title);

		// Generate Meta Definitions
		const metatags: MetaDefinition[] = [
			{ name: 'title', content: metadata.title },
			{ property: 'og:title', content: metadata.title },
			!isEmpty(metadata.description) && { name: 'description', content: metadata.description },
			// Facebook/Meta meta tags
			!isEmpty(metadata.description) && { property: 'og:description', content: metadata.description },
			!isEmpty(metadata.type) && { property: 'og:type', content: metadata.type },
			!isEmpty(metadata.pageUrl) && { property: 'og:url', content: metadata.pageUrl },
			!isEmpty(metadata.imageUrl) && { property: 'og:image', content: metadata.imageUrl },
			!isEmpty(metadata.locale) && { property: 'og:locale', content: metadata.locale },
			!isEmpty(metadata.author) && { name: 'author', content: metadata.author },
			!isEmpty(metadata.author) && { property: 'og:author', content: metadata.author },
			// Twitter/X meta tags
			{ property: 'twitter:card', content: 'summary_large_image' },
			{ property: 'twitter:domain', content: environment.domain },
			{ property: 'twitter:title', content: metadata.title },
			!isEmpty(metadata.description) && { property: 'twitter:description', content: metadata.description },
			!isEmpty(metadata.pageUrl) && { property: 'twitter:url', content: metadata.pageUrl },
			!isEmpty(metadata.imageUrl) && { property: 'twitter:image', content: metadata.imageUrl }
		];

		// Adds meta tags if there is no tags. If meta tags already exists updates tags or removes if new ones content is empty.
		metatags.forEach((tag: MetaDefinition) => {
			isEmpty(tag.content)
				? this.metaService.removeTag(tag.name ? `name='${tag.name}'` : `property='${tag.property}'`)
				: this.metaService.updateTag(tag);
		});

		this.metaService.updateTag({
			name: 'robots',
			content: index ? 'index, follow' : 'noindex'
		});
	}
}
