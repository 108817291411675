import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { WITH_ROUTER_LINKS_CONFIG, WithRouterLinkPipe, WithRouterLinksConfig } from '@studiohyperdrive/ngx-utils';

import { BpostDataModule } from '@cjm/shared/bpost';
import { SharedUiModule } from '@cjm/shared/ui/common';
import { AutoCompleteComponent, SharedFormsModule, SwitchComponent } from '@cjm/shared/ui/forms';
import { ModalUiModule } from '@cjm/shared/ui/modal';
import { ToastUiModule } from '@cjm/shared/ui/toast';
import { SharedUtilsModule } from '@cjm/shared/utils';

import { components } from './components';
import { pipes } from './pipes';
import { AssociationsTranslationLoader } from './translation.loader';
@NgModule({
	imports: [
		CommonModule,
		SharedUiModule,
		SharedFormsModule,
		NgxI18nModule.forChild(AssociationsTranslationLoader),
		RouterLink,
		SharedUtilsModule,
		NgxErrorsModule,
		CdkAccordionModule,
		ToastUiModule,
		AutoCompleteComponent,
		BpostDataModule,
		SwitchComponent,
		ModalUiModule
	],
	declarations: [pipes, components],
	providers: [
		WithRouterLinkPipe,
		{
			provide: WITH_ROUTER_LINKS_CONFIG,
			useValue: {
				replaceElementSelector: 'vlo-link',
				linkAttributeName: 'to',
				dataLinkIdAttributeName: 'data-link-id'
			} as WithRouterLinksConfig
		},
		pipes
	],
	exports: [CommonModule, SharedUiModule, SharedFormsModule, ToastUiModule, pipes, components, BpostDataModule]
})
export class VloketSharedModule {}
