import { Component, Input, Signal } from '@angular/core';

import { MediaQueryMin, MediaQueryMax } from '@cjm/shared/types';
import { VloketLink } from '@cjm/shared/ui/common';

import { SidebarService } from '../../../data';
import { SidebarItemEntity } from '../sidebar-item/sidebar-item.types';

@Component({
	selector: 'cjm-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	// Denis: in order to set the c-sidebar class dynamically based on the
	// isActive$ observable, we need to use the host property.
	// The same effect cannot be achieved through regular HostBinding()
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'[class.c-sidebar]': 'isActive()',
		'[class.c-sidebar--collapsed]': 'isCollapsed()'
	}
})
export class SidebarComponent {
	/**
	 * asNav
	 *
	 * The asNav attribute allows the parent to define if
	 * the sidebar needs to be wrapped in a nav element.
	 *
	 * Default is set to true.
	 */
	@Input() public asNav: boolean = true;

	public showAllItems: boolean = false;
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	public readonly isActive: Signal<boolean> = this.sidebarService.isActive;
	public readonly isCollapsed: Signal<boolean> = this.sidebarService.isCollapsed;
	public readonly sidebarTitle: Signal<string> = this.sidebarService.sidebarHeaderTitle;
	public readonly sidebarHeaderLink: Signal<VloketLink> = this.sidebarService.sidebarHeaderLink;
	public readonly sidebarItems: Signal<SidebarItemEntity[]> = this.sidebarService.sidebarItems;
	public readonly sidebarActiveFirst: Signal<SidebarItemEntity[]> = this.sidebarService.sidebarItemsActiveFirst;

	constructor(private readonly sidebarService: SidebarService) {}

	/**
	 * collapseSidebar
	 *
	 * The collapseSidebar method will trigger the setSidebarCollapsed method on the
	 * SidebarService.
	 */
	public collapseSidebar(): void {
		this.sidebarService.setSidebarCollapsed(!this.isCollapsed());
	}

	/**
	 * toggleAllItems
	 *
	 * The toggleAllItems responds to the 'toggleAll' event on the sidebar-item.
	 * It will then toggle the current 'showAllItems' value.
	 */
	public toggleAllItems(): void {
		this.showAllItems = !this.showAllItems;
	}
}
