<ng-container *ngIf="tableTemplate">
	<ng-template
		*mediaQuery="mediaQueryOverride ? mediaQueryMin[mediaQueryOverride] : mediaQueryMin.Desktop"
		[ngTemplateOutlet]="tableTemplate"
		[ngTemplateOutletContext]="{ $implicit: data, loading: loading }"
	>
	</ng-template>
</ng-container>

<ng-container *ngIf="listItemTemplate">
	<cjm-content
		*mediaQuery="mediaQueryOverride ? mediaQueryMax[mediaQueryOverride] : mediaQueryMax.Desktop"
		[loading]="loading"
		[error]="error"
		[errorText]="errorText"
	>
		<ng-container *ngFor="let item of data">
			<div *ngIf="item" class="c-cjm-table-item u-margin-top" tabindex="0">
				<ng-template [ngTemplateOutlet]="listItemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
				</ng-template>
			</div>
		</ng-container>
	</cjm-content>
</ng-container>
