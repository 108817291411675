import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseFormAccessor, DataFormAccessor } from '@studiohyperdrive/ngx-forms';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { IMainActivity } from '@cjm/v-loket/repositories';
import { AssociationType, ILocationForm } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';

import { IBasicRegistrationDataForm, IBasicRegistrationDataFormGroup } from './basic-registration-data-form.types';

@Component({
	selector: 'vloket-basic-registration-data-form',
	templateUrl: './basic-registration-data-form.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BasicRegistrationDataFormComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => BasicRegistrationDataFormComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => BasicRegistrationDataFormComponent)
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicRegistrationDataFormComponent extends DataFormAccessor<
	IMainActivity[],
	IBasicRegistrationDataForm,
	FormGroup<IBasicRegistrationDataFormGroup>
> {
	public mainActivities: IMainActivity[] = [];
	public associationNameHasErrors$: ObservableBoolean;
	public associationAddressPostcodeHasErrors$: ObservableBoolean;
	public associationAddressCountryHasErrors$: ObservableBoolean;
	public associationAddressStreetHasErrors$: ObservableBoolean;
	public associationAddressNumberHasErrors$: ObservableBoolean;

	public readonly i18nKeys = I18nKeys;
	public readonly associationTypes: typeof AssociationType = AssociationType;

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(data: IMainActivity[]): FormGroup {
		this.mainActivities = !Array.isArray(data) || data.length === 0 ? [] : data;

		return this.buildBasicFormGroup();
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public onChangeMapper(): IBasicRegistrationDataForm {
		// Denis: Because some fields might be disabled, we need the rawValue.
		return this.form.getRawValue();
	}

	/**
	 * buildBasicFormGroup
	 *
	 * The buildBasicFormGroup method will set up a new for with all the basic fields.
	 *
	 * For the mainActivities field, it will use the buildMainActivitiesFormArray method
	 * to set up a FormArray that has a control for each checkbox
	 *
	 * @private
	 * @returns FormGroup
	 */
	private buildBasicFormGroup(): FormGroup {
		return new FormGroup({
			associationType: new FormControl<string>(this.associationTypes.FV),
			associationName: new FormControl<string>('', {
				validators: [Validators.required],
				updateOn: 'blur'
			}),
			associationAddress: new FormControl<ILocationForm>({
				description: '',
				zipcode: '',
				locality: '',
				street: '',
				number: '',
				box: '',
				country: 'België',
				isPrimary: false
			}),
			mainActivities: new FormControl(null)
		});
	}
}
