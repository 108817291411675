import { AssociationDetailResultEntity, RepresentativeStatus } from '@cjm/v-loket/shared';

export interface RepresentativeInvitedResultEntity {
	'@type': 'Uitgenodigde';
	insz: string;
	voornaam: string;
	achternaam: string;
	email: string;
}

export interface RepresentativeInviterResultEntity {
	'@type': 'Uitnodiger';
	objectId: number;
}

export interface RepresentativeInviteResultEntity {
	'@type': 'Uitnodiging';
	'@id': string;
	objectId: string;
	vCode: string;
	vereniging: AssociationDetailResultEntity;
	boodschap: string;
	status: RepresentativeStatus;
	tijdstip: string;
	uitnodiger: RepresentativeInviterResultEntity;
	uitgenodigde: RepresentativeInvitedResultEntity;
}

export interface RepresentativeRequesterResultEntity {
	'@type': 'Aanvrager';
	insz: string;
	voornaam: string;
	achternaam: string;
	email: string;
}

export interface RepresentativeRequestResultEntity {
	'@type': 'Aanvraag';
	'@id': string;
	objectId: string;
	vCode: string;
	boodschap: string;
	status: RepresentativeStatus;
	tijdstip: string;
	aanvrager: RepresentativeRequesterResultEntity;
}

export interface RepresentativePostRequestResultEntity {
	'@type': 'Vertegenwoordigersaanvraag';
	verenigingsnummer: string;
	metadata: {
		notificaties: {
			emailNaarVertegenwoordigersVerzonden: boolean;
		};
	};
}
