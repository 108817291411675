import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@studiohyperdrive/ngx-utils';

import { HttpInterceptorProviders } from './interceptors';
import { sharedPipes } from './pipes';

@NgModule({
	imports: [CommonModule, UtilsModule],
	declarations: [...sharedPipes],
	providers: [HttpInterceptorProviders, sharedPipes],
	exports: [UtilsModule, ...sharedPipes]
})
export class SharedUtilsModule {}
