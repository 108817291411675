<div class="c-association-overview-card__top">
	<div class="c-association-overview-card__top__left">
		<a
			class="c-association-overview-card__title c-vloket-header c-vloket-header--h5 u-no-margin"
			[routerLink]="['/', i18nService.currentLanguage, VLoketAppRoutePaths.Associations, association?.id]"
			>{{ association?.names?.full }}</a
		>
		<div
			*ngIf="association?.parentOf > 0"
			class="c-association-overview-card__child-count"
			[innerHTML]="
				i18nKeys.SharedAssociations.Card.ChildrenNotice
					| translate : { amount: association.parentOf }
					| withRouterLinks
						: [
								{
									dataLinkId: 'childAssociations',
									link: '/'
								}
						  ]
			"
		></div>
	</div>
	<div class="c-association-overview-card__top__right">
		<ng-container *ngIf="association.locations | mainLocation as location">
			<fa-icon class="u-margin-right-sm" icon="fa-location-dot" />
			<span>
				{{ location | oneLineAddress : 'abbreviated' }}
			</span>
		</ng-container>
	</div>
</div>

<div *ngIf="association?.mainActivities?.length > 0" class="c-association-overview-card__bottom">
	<cjm-tag-group>
		<cjm-tag *ngFor="let activity of association?.mainActivities" [tagType]="tagType.yellow">{{
			activity.name
		}}</cjm-tag>
	</cjm-tag-group>
</div>
<vloket-association-target-audience-tags
	*ngIf="association.targetAudience"
	[targetAudience]="association.targetAudience"
/>
