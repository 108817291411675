import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableBlob } from '@studiohyperdrive/rxjs-utils';
import { map, Observable } from 'rxjs';

import { environment } from 'environments';

import { AbstractLanguageProviderService } from '../abstracts';

@Injectable()
export class CJMHttpClientService {
	private _baseUrl: string;
	private includeLanguage = true;

	public set baseUrl(url: string) {
		this._baseUrl = url;
	}

	public setIncludeLanguage(includeLanguage: boolean) {
		this.includeLanguage = includeLanguage;
	}

	constructor(
		private readonly httpClient: HttpClient,
		private readonly i18nService: AbstractLanguageProviderService
	) {
		// [Fabian]: Trim trailing slash, just in case
		const { protocol, hostname } = environment.api;
		this.baseUrl = `${protocol}://${hostname}`.replace(/\/$/, '');
	}

	private composeUrl(url: string): string {
		const { apiPath } = environment.api;

		return [this._baseUrl, ...(this.includeLanguage ? [this.i18nService.currentLanguage] : []), apiPath, url].join(
			'/'
		);
	}

	public delete<T>(url: string, params: any = {}, body?: any, withCredentials: boolean = true): Observable<T> {
		return this.httpClient.delete<T>(this.composeUrl(url), { params, withCredentials, body });
	}

	public get<T>(url: string, params?: any, withCredentials: boolean = true, context?: HttpContext): Observable<T> {
		return this.httpClient.get<T>(this.composeUrl(url), {
			params,
			withCredentials,
			...(context ? { context } : {})
		});
	}

	public download(url: string): ObservableBlob {
		return this.httpClient
			.get(this.composeUrl(url), {
				withCredentials: true,
				responseType: 'blob',
				observe: 'response'
			})
			.pipe(
				map((response) => {
					return {
						fileType: response.headers.get('content-disposition').split('.')[1],
						blob: response.body as Blob
					};
				})
			);
	}

	public directGet<T>(url: string, params?: any, withCredentials: boolean = false) {
		return this.httpClient.get<T>(url, { params, withCredentials });
	}

	public post<T>(url: string, data: any, context?: HttpContext): Observable<T> {
		return this.httpClient.post<T>(this.composeUrl(url), data, {
			withCredentials: true,
			...(context ? { context } : {})
		});
	}

	public directPost<T>(url: string, data: any): Observable<T> {
		return this.httpClient.post<T>(url, data, { withCredentials: true });
	}

	public put<T>(url: string, data: any): Observable<T> {
		return this.httpClient.put<T>(this.composeUrl(url), data, { withCredentials: true });
	}

	public patch<T>(url: string, data: any): Observable<T> {
		return this.httpClient.patch<T>(this.composeUrl(url), data, { withCredentials: true });
	}
}
