import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { FilterSelection } from '../filter-selection-tags/filter-selection-tags.types';

@Component({
	selector: 'vloket-filter-select-tags',
	templateUrl: './filter-selection-tags.component.html',
	styleUrls: ['./filter-selection-tags.component.scss']
})
export class FilterSelectionTagsComponent {
	@HostBinding('c-filter-selection-tags') private readonly hasRootClass: boolean = true;
	@Input() public valueMap: Record<string, string> = {};
	@Input() public selectedValues: FilterSelection[] = [];
	@Input() public additionalValues: string[] = [];
	@Output() public clearFilter: EventEmitter<FilterSelection> = new EventEmitter<FilterSelection>();
	@Output() public clearAdditionalValue: EventEmitter<string> = new EventEmitter<string>();
}
