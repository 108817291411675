// Imports
// -------------------------------------------------------------------------- /
import { Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { bootstrap, widget as acmWidget, session as acmSession } from '@govflanders/vl-widget-client/dist';
import { from, Observable, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BrowserService } from '@cjm/shared/core';
import { OnDestroyComponent } from '@cjm/shared/types';
import { UserService } from '@cjm/shared/user';
import { environment } from 'environments';

// Decorators
// -------------------------------------------------------------------------- /
@Component({
	selector: 'cjm-vloket-footer',
	templateUrl: './vloket-footer.component.html',
	styleUrls: ['./vloket-footer.component.scss']
})

// Class Definition
// -------------------------------------------------------------------------- /
export class VloketFooterComponent extends OnDestroyComponent implements OnInit {
	@HostBinding('class.o-vloket-footer') private readonly hasVloketFooterClass: boolean = true;

	// Private properties
	// -------------------------------------------------------------------------- /
	private readonly widgetUrl = environment.acmidm.widgetsUrl + 'api/v1/widget/' + environment.acmidm.footerWidgetId;
	private session$: Observable<acmSession>;

	// Dependency Injection
	// -------------------------------------------------------------------------- /
	constructor(
		private readonly renderer: Renderer2,
		private readonly userService: UserService,
		private readonly browserService: BrowserService
	) {
		super();
	}

	// Public methods
	// -------------------------------------------------------------------------- /

	private setupWidgetSession(): Observable<acmSession> {
		// Robbe: Create an instance of the widget session
		return from(bootstrap(this.widgetUrl)).pipe(
			tap((widget: acmWidget) => {
				this.browserService.runInBrowser(({ browserDocument }) => {
					const element = browserDocument.getElementById('o-vloket-footer__container');
					widget.setMountElement(element).mount();
				});
			})
		);
	}

	// Implementations
	// ------------------------------------------------------------------------ /
	public ngOnInit() {
		// Robbe: Create a session
		this.session$ = this.setupWidgetSession();
		this.session$.pipe(takeUntil(this.destroyed$)).subscribe();
	}
}
