export interface AddressResultEntity {
	straat: string;
	nummer: string;
	postcode: string;
	gemeente: string;
	bus?: string;
	land?: string;
}

export interface AddressEntity {
	street?: string;
	number?: string;
	zipcode?: string;
	municipality?: string;
	box?: string;
	country?: string;
	isPrimary?: boolean;
}

export const AddressResultMock: AddressResultEntity = {
	gemeente: 'Kontich',
	nummer: '35',
	postcode: '2550',
	straat: 'Veldkant',
	bus: 'c',
	land: 'Belgium'
};

export const AddressMock: AddressEntity = {
	municipality: 'Kontich',
	number: '35',
	zipcode: '2550',
	street: 'Veldkant',
	box: 'c',
	country: 'Belgium'
};
