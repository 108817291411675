import { ACMTargetGroups } from '@cjm/shared/types';

interface LoginOptionsBaseEntity {
	customCallBack?: string;
	capHint?: ACMTargetGroups;
	codeHint?: string;
}

interface LoginAsCivilianOptionsEntity extends LoginOptionsBaseEntity {
	capHint: ACMTargetGroups.BUR;
}

export interface LoginAsEconomicActorOptionsEntity extends LoginOptionsBaseEntity {
	capHint: ACMTargetGroups.EA;
	codeHint: string;
}

export interface LoginAsAssociationOptionsEntity extends LoginOptionsBaseEntity {
	capHint: ACMTargetGroups.VER;
	codeHint: string;
}

export type LoginOptionsEntity =
	| LoginOptionsBaseEntity
	| LoginAsCivilianOptionsEntity
	| LoginAsAssociationOptionsEntity
	| LoginAsEconomicActorOptionsEntity;

export type IdentitySwitchOptionsEntity = {
	customCallBack?: string;
};
