import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseFormAccessor, FormAccessor } from '@studiohyperdrive/ngx-forms';
import { UtilsModule } from '@studiohyperdrive/ngx-utils';
import { distinctUntilChanged, filter } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { SharedFormsModule } from '../../shared-ui-forms.module';

import { AutoCompleteOption } from './auto-complete.types';

@Component({
	selector: 'cjm-auto-complete',
	templateUrl: './auto-complete.component.html',
	styleUrls: ['./auto-complete.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AutoCompleteComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => AutoCompleteComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => AutoCompleteComponent)
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule, SharedFormsModule, UtilsModule]
})
export class AutoCompleteComponent extends FormAccessor implements OnInit {
	@HostBinding('class.c-auto-complete') private readonly hasAutoCompleteClass: boolean = true;
	@Input({ required: true }) public options: AutoCompleteOption[] = [];
	@Input() public placeholder: string;
	@Output() public readonly searchOptions: EventEmitter<string> = new EventEmitter<string>();
	@Output() public readonly valueSelected: EventEmitter<MatAutocompleteSelectedEvent> =
		new EventEmitter<MatAutocompleteSelectedEvent>();

	public initForm(): FormControl {
		return new FormControl<string>('');
	}

	public ngOnInit(): void {
		this.initialized$
			.pipe(
				// Denis: only proceed when the form is initialized.
				filter(Boolean),
				// Denis: switch to the valueChanges of the form.
				switchMap(() => this.form.valueChanges),
				// Denis: do not proceed when the value hasn't changed.
				distinctUntilChanged(),
				// Denis: provide a sensible debounceTime to wait for user input.
				debounceTime(500),
				// Denis: filter out string values (only string input)
				filter((value: string) => typeof value === 'string'),
				// Denis: emit to the parent to trigger a search for new options
				tap((value: string) => this.searchOptions.emit(value)),
				// Denis: clean up on destroy
				takeUntil(this.destroy$)
			)
			.subscribe();

		super.ngOnInit();
	}
}
