import { FormControl } from '@angular/forms';

/**
 * The form type to search for companies.
 */
export interface CompanySearchFormEntity {
	searchQuery: FormControl<string>;
}

/**
 * The type used for searching a company.
 */
export interface CompanySearchEntity {
	searchQuery: string;
}

/**
 * The optional type to select filtering.
 */
export type PartialCompanySearchEntity = Partial<CompanySearchEntity>;
