import { FacetFilter, SortingEntity } from '@cjm/shared/types';

export interface ProductRouteFilterEntity {
	filters?: string;
	search?: string;
	sorting?: SortingEntity;
	index?: number;
}

export interface ProductRouteFilterObjectEntity {
	filters?: FacetFilter;
	search?: string;
	sorting?: string;
	index?: number;
}
