import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { BpostServices } from './services';

@NgModule({
	imports: [HttpClientModule],
	providers: [BpostServices]
})
export class BpostDataModule {}
