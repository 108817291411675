import { FormControl } from '@angular/forms';

export interface IAdditionalRegistrationDataForm {
	associationCallname: string;
	associationDescription: string;
	associationFoundingDate: string;
}

export interface IAdditionalRegistrationDataFormGroup {
	associationCallname: FormControl<string>;
	associationDescription: FormControl<string>;
	associationFoundingDate: FormControl<string>;
}
