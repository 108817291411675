<cjm-tag-group>
	<ng-container *ngIf="(valueMap | keyvalue)?.length > 0 && selectedValues?.length > 0">
		<cjm-tag
			*ngFor="let value of selectedValues"
			cypressTag="FilterSelectionTags.Item"
			icon="fa-tag"
			tagType="yellow"
			[id]="value.id"
			[clearable]="true"
			(clear)="clearFilter.emit(value)"
			>{{ valueMap[value.id] ? valueMap[value.id] : value.id }}</cjm-tag
		>
	</ng-container>
	<ng-container *ngIf="additionalValues?.length > 0">
		<cjm-tag
			*ngFor="let value of additionalValues"
			cypressTag="FilterSelectionTags.Item"
			icon="fa-tag"
			tagType="yellow"
			[id]="value"
			[clearable]="true"
			(clear)="clearAdditionalValue.emit(value)"
			>{{ value }}</cjm-tag
		>
	</ng-container>
</cjm-tag-group>
