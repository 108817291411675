<ng-container *ngIf="item">
	<a
		*ngIf="item.route; else plainItemTmpl"
		class="c-sidebar-item"
		[ngClass]="{ 'c-sidebar-item--active': item.active }"
		[routerLink]="item.route"
		(click)="toggleAllItems()"
	>
		<ng-container [ngTemplateOutlet]="itemContentTmpl" [ngTemplateOutletContext]="{ disableToggle: true }" />
	</a>
</ng-container>

<ng-template #plainItemTmpl>
	<div class="c-sidebar-item" [ngClass]="{ 'c-sidebar-item--active': item.active }">
		<ng-container [ngTemplateOutlet]="itemContentTmpl" />
	</div>
</ng-template>

<ng-template #itemContentTmpl let-disableToggle="disableToggle">
	<fa-icon [matBadge]="item.badgeCount" [iconType]="item.iconType" [icon]="item.icon" />

	<span *ngIf="!iconOnly">{{ item.text }}</span>

	<!-- Denis: The following button needs to be shown in the mobile view on the active item. -->
	<ng-container *mediaQuery="mediaQueryMax.Desktop">
		<button class="c-sidebar__show-all-button" (click)="disableToggle ? null : toggleAllItems()">
			<fa-icon *ngIf="item.active" icon="fa-chevron-down" />
		</button>
	</ng-container>
</ng-template>
