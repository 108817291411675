import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { QrCodeModule } from 'ng-qrcode';

import { PwaCtaComponent } from './components';
import { PlatformDirective } from './directives';
import { PWATranslationLoader } from './translation.loader';
@NgModule({
	imports: [NgxI18nModule.forChild(PWATranslationLoader), QrCodeModule, RouterModule],
	declarations: [PlatformDirective, PwaCtaComponent],
	exports: [PlatformDirective, PwaCtaComponent]
})
export class PwaUiModule {}
