import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { NgxTableConfigToken, NgxTableModule } from '@studiohyperdrive/ngx-table';
import { InViewportModule } from 'ng-in-viewport';
import { MATOMO_DIRECTIVES } from 'ngx-matomo-client';

import { CypressModule } from '@cjm/cypress/core';
import { DeviceModule } from '@cjm/shared/ui/device';
import { SharedUtilsModule } from '@cjm/shared/utils';

import { sharedUiComponents } from './components';
import { sharedUiPipes } from './pipes';
import { SharedUiTranslationLoader } from './translate.loader';
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		NgxI18nModule.forChild(SharedUiTranslationLoader),
		MatTooltipModule,
		CypressModule,
		DeviceModule,
		SharedUtilsModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		InViewportModule,
		NgxTableModule,
		...MATOMO_DIRECTIVES
	],
	declarations: [...sharedUiComponents, ...sharedUiPipes],
	providers: [
		...sharedUiPipes,
		{
			provide: NgxTableConfigToken,
			useValue: {
				ngxTableClass: 'cjm-table',
				emitValueOnSingleItem: false
			}
		}
	],
	exports: [
		...sharedUiComponents,
		...sharedUiPipes,
		MatTooltipModule,
		CypressModule,
		DeviceModule,
		NgOptimizedImage,
		SharedUtilsModule,
		InViewportModule,
		NgxTableModule
	]
})
export class SharedUiModule {}
