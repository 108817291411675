import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean, ObservableString, pluck } from '@studiohyperdrive/rxjs-utils';
import { UUID } from 'angular2-uuid';
import CryptoES from 'crypto-es';
import { MatomoTracker } from 'ngx-matomo-client';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AuthenticationService } from '@cjm/shared/authentication/auth';
import { BrowserService, SessionService } from '@cjm/shared/core';
import { UserTargetCodeEntity, UserTargetCodeEntityKeys } from '@cjm/shared/types';
import { environment } from 'environments';

import {
	UserCompanyEntity,
	UserCompanyPropertyEntity,
	UserContextKey,
	UserEntity,
	UserSessionEntity
} from '../interfaces';
import { actions, selectors } from '../user.store';

import { UserApiService } from './user.api.service';

@Injectable()
export class UserService {
	private readonly userSessionCreatedSubject$ = new BehaviorSubject<boolean>(false);
	private readonly companyNumberHashSubject$ = new BehaviorSubject<string>('');

	public readonly user$: Observable<UserEntity> = this.store
		.pipe(select(selectors.select))
		.pipe(map((session) => (session ? session.user : null)));
	public readonly loading$: ObservableBoolean = this.store.pipe(select(selectors.selectLoading));
	public readonly uuid: string = UUID.UUID();
	public readonly isCompany$: ObservableBoolean = this.user$.pipe(
		map((user) => user?.isCompany && !user?.isCivilian)
	);
	public sessionDuration: string;

	constructor(
		protected readonly store: Store,
		private readonly apiService: UserApiService,
		private readonly authenticationService: AuthenticationService,
		private readonly sessionService: SessionService,
		private readonly browserService: BrowserService,
		private readonly matomoTracker: MatomoTracker
	) {}

	/**
	 * Create a new user session
	 */
	public createUserSession(): Observable<UserSessionEntity> {
		// Iben: Reset the userRefreshCall Observable
		this.userSessionCreatedSubject$.next(false);

		// Iben: Dispatch the user session to the store
		return dispatchDataToStore(
			actions,
			this.apiService.getUserSession().pipe(
				tap((session) => {
					// Iben: Early exit if the session does not exist
					if (!session) {
						// Denis: Set the custom dimension for the user (Matomo)
						this.setCustomDimension();

						return;
					}

					// Denis: Set the custom dimension for the user (Matomo)
					this.setCustomDimension(session.user.targetCode);

					// Iben: Let the application know that the user session create call has happened
					this.userSessionCreatedSubject$.next(true);

					// Iben: Set the session duration and cookie
					this.sessionDuration = session.cookie.expiresAt;
					this.authenticationService.setAuthenticationCookie(session.cookie.expiresAt);

					// Iben: Set active company number hash
					if (session.user.company) {
						this.companyNumberHashSubject$.next(
							CryptoES.SHA3(session.user.company.number, { outputLength: 224 }).toString()
						);
					}
				}),
				catchError((err) => {
					this.destroyUserSession();

					return throwError(err);
				})
			),
			this.store,
			'set'
		);
	}

	/**
	 * Sets the sas context based on the company number
	 *
	 * @param companyNumber - Number of the company
	 */
	public setSasContext(companyNumber: string): Observable<void> {
		// Iben: If SAS is not enabled and there's no company number we early exit
		if (!environment.sas.enabled || !companyNumber) {
			return;
		}

		// Iben: Set sas context
		return this.apiService.setSasContext(companyNumber);
	}

	/**
	 * Destroy the current user session
	 */
	public destroyUserSession(): void {
		// Iben: Reset the current hash for the companyNumber
		this.companyNumberHashSubject$.next('');

		// Iben: Drop the authentication state
		this.authenticationService.dropAuthentication();

		// Iben: Remove the user from the store
		this.store.dispatch(actions.set({ payload: null }));
	}

	/**
	 * Switch organization
	 */
	public switchOrganisation(): Observable<void> {
		return this.apiService.switchCompany();
	}

	public logOut(): ObservableString {
		return this.authenticationService.logout().pipe(
			tap(() => {
				this.destroyUserSession();
			})
		);
	}

	/**
	 * Sets the user context for a specific key
	 *
	 * @param key - The key for which we wish to save data
	 * @param value - The vale we which to save
	 * @param limit - The amount of time the data should be saved for, when left empty the data will be kept indefinitely
	 */
	public setUserContext(key: UserContextKey, value: any, limit?: number): Observable<unknown> {
		return this.apiService.setUserContext(key, value, limit);
	}

	/**
	 * Returns the user context for a specific key
	 *
	 * @template Context - The type of the data
	 * @param key - The key for which we fetch the data
	 */
	public getUserContext<Context>(key: UserContextKey): Observable<Context> {
		return this.apiService.getUserContext<Context>(key);
	}

	/**
	 * Whether a user has a specific role
	 *
	 * @param allowedRoles - An array of roles the user is allowed to have
	 */
	public hasRole(allowedRoles: UserTargetCodeEntityKeys[]): ObservableBoolean {
		return this.user$.pipe(
			pluck<UserEntity, UserTargetCodeEntity>('targetCode'),
			map((targetCode) => new Set(allowedRoles.map((item) => UserTargetCodeEntity[item])).has(targetCode))
		);
	}

	/**
	 * Returns whether a user has (a) certain company property/properties
	 *
	 * @param properties The different roles that are checked against the user's roles
	 * @param [shouldHaveAllRoles=true] Whether the user should have all roles, or if only one of them is enough. Default is true.
	 * @return  {ObservableBoolean}
	 */
	public userHasCompanyProperties(
		properties: UserCompanyPropertyEntity[],
		shouldHaveAllRoles: boolean = true
	): ObservableBoolean {
		return this.user$.pipe(
			pluck('company'),
			map((company) => {
				if (shouldHaveAllRoles) {
					// Benoit: if shouldHaveAllRoles = true, the user must have every property of the properties array, and they all must be set to true
					return properties.every(
						(property) => company.hasOwnProperty(property) && company[property] === true
					);
				} else {
					// Benoit: if shouldHaveAllRoles = false, the user must have at least one property of the properties array set to true
					return properties.some(
						(property) => company.hasOwnProperty(property) && company[property] === true
					);
				}
			})
		);
	}

	/**
	 * isRegisterableEA
	 *
	 * The isRegisterableEA method will check if the user is logged in as an EA that can be registered
	 */
	public isRegisterableEA(): ObservableBoolean {
		return this.user$.pipe(
			map((user: UserEntity) => {
				if (!user) {
					return true;
				}

				/**
				 * An EA can only be registered once when a vCode has been added to the claim.
				 * To bypass this limitation when testing on production, a bypassRegistrableEACheck option has been added.
				 * By setting this option to true, the check will be bypassed and the EA will be considered as registerable.
				 *
				 * Any user can do this by opening the browser console and running the following command:
				 * `sessionStorage.setItem('cjm.options.bypassRegistrableEACheck', true);`
				 */
				const bypassRegistrableEACheck = this.sessionService.getSessionItem(
					'options.bypassRegistrableEACheck',
					(value: string) => value === 'true'
				);

				if (bypassRegistrableEACheck) {
					return user.targetCode === UserTargetCodeEntity.ECONOMIC_ACTOR;
				}
				// END OF BYPASS

				const hasVCodeProperty = user.company?.hasOwnProperty('vCode');
				const hasVCodeValue = typeof user.company?.vCode === 'string' && user.company?.vCode?.length > 0;

				return user.targetCode === UserTargetCodeEntity.ECONOMIC_ACTOR && hasVCodeProperty && !hasVCodeValue;
			})
		);
	}

	/**
	 * isNonRegistrableEA
	 *
	 * The isNonRegistrableEA method will check if the user is logged in as an EA that cannot be registered
	 */
	public isNonRegistrableEA(): ObservableBoolean {
		return this.user$.pipe(
			map((user: UserEntity) => {
				if (!user) {
					return false;
				}

				const hasNoVCodeProperty = !user.company?.hasOwnProperty('vCode');

				return user.targetCode === UserTargetCodeEntity.ECONOMIC_ACTOR && hasNoVCodeProperty;
			})
		);
	}

	/**
	 * userHasVCode
	 *
	 * The userHasVCode method will check if the user has a valid company and a valid vCode.
	 *
	 * @returns ObservableBoolean
	 */
	public userHasVCode(): ObservableBoolean {
		return this.user$.pipe(
			pluck('company'),
			map((company: UserCompanyEntity) => {
				const vCode = company?.vCode;

				return typeof vCode === 'string' && vCode.length > 0;
			})
		);
	}

	/**
	 * setCustomDimension
	 *
	 * The setCustomDimension method will set the custom dimension to track the targetGroup in Matomo.
	 *
	 * @param targetCode
	 */
	private setCustomDimension(targetCode: UserTargetCodeEntity | 'ANON' = 'ANON'): void {
		this.browserService.runInBrowser(() => {
			this.matomoTracker.setCustomDimension(environment.matomo.dimensions.userCapacity, targetCode);
		});
	}
}
