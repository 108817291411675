import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { transformJsonToObject } from '@cjm/v-loket/shared';

import { AdvicePageTextEntity } from '../../../data/';
import { BackgroundVariants } from '../../interfaces';

@Component({
	selector: 'vloket-advice-detail-page-richtext',
	templateUrl: './advice-detail-page-richtext.component.html',
	styleUrls: ['./advice-detail-page-richtext.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdviceDetailPageRichtTextComponent {
	@HostBinding('class.c-advice-detail-page-richtext-block') private readonly rootClass: boolean = true;

	@Input({
		required: true,
		transform: (value: string) => {
			return transformJsonToObject<AdvicePageTextEntity>(value);
		}
	})
	public data: AdvicePageTextEntity;

	public readonly backgroundVariants: typeof BackgroundVariants = BackgroundVariants;
}
