<cjm-layout-container
	*ngIf="data"
	class="c-advice-detail-page-richtext-block"
	layoutType="full-width"
	[class]="'c-advice-detail-page-richtext-block--' + backgroundVariants[data.variant]"
>
	<ng-container main>
		<h2 *ngIf="data.title" class="c-vloket-header c-vloket-header--h3">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ data.title }}
			</span>
		</h2>
		<div *ngIf="data.text" class="c-advice-detail-page-richtext-block__content u-wysiwyg">
			<div [outerHTML]="data.text"></div>
		</div>
	</ng-container>
</cjm-layout-container>
