import { marker } from '@ngneat/transloco-keys-manager/marker';

export const sharedI18nKeys = {
	Loading: marker('loading'),
	Offline: marker('offline'),
	PageNotFound: marker('page-not-found'),
	LoadingError: marker('loading-error'),
	BusyRetry: marker('busy-retry'),
	Actions: {
		MoreInfo: marker('actions.more-info'),
		MoreInformation: marker('actions.more-information'),
		ProductSearch: {
			Button: {
				Text: marker('actions.product-search.button.text'),
				Title: marker('actions.product-search.button.title')
			}
		},
		CasesSearch: {
			Text: marker('actions.cases-search.text'),
			Title: marker('actions.cases-search.title')
		},
		LetsStart: marker('actions.lets-start'),
		More: marker('actions.more'),
		TryAgain: marker('actions.try-again'),
		Search: marker('actions.search'),
		ClearAll: marker('actions.clear-all'),
		LoadMore: marker('actions.load-more'),
		Request: {
			Text: marker('actions.request.text'),
			Title: marker('actions.request.title')
		},
		Sort: marker('actions.sort'),
		FilterToggle: {
			Show: {
				Text: marker('actions.filter-toggle.show.text'),
				Title: marker('actions.filter-toggle.show.title')
			},
			Hide: {
				Text: marker('actions.filter-toggle.hide.text'),
				Title: marker('actions.filter-toggle.hide.title')
			}
		},
		ResultsToggle: {
			Show: {
				Text: marker('actions.results-toggle.show.text'),
				Title: marker('actions.results-toggle.show.title')
			}
		},
		ThemesFilterToggle: {
			Show: {
				Text: marker('actions.themes-filter-toggle.show.text'),
				Title: marker('actions.themes-filter-toggle.show.title')
			},
			Hide: {
				Text: marker('actions.themes-filter-toggle.hide.text'),
				Title: marker('actions.themes-filter-toggle.hide.title')
			}
		},
		Delete: {
			Text: marker('actions.delete.text'),
			Title: marker('actions.delete.title')
		},
		DeleteAll: {
			Text: marker('actions.delete-all.text'),
			Title: marker('actions.delete-all.title')
		}
	},
	Common: {
		Next: marker('common.next'),
		Previous: marker('common.previous'),
		Close: marker('common.close'),
		Empty: {
			Address: marker('common.empty.address'),
			NoData: marker('common.empty.no-data')
		},
		Via: marker('common.via'),
		Login: marker('common.login'),
		Back: marker('common.back'),
		Address: {
			AdditionPrefix: 'common.address.additionPrefix'
		},
		Menu: marker('common.menu'),
		Prepositions: {
			In: marker('common.prepositions.in')
		},
		Yes: marker('common.yes'),
		No: marker('common.no'),
		Or: marker('common.or'),
		OrganizationNumber: marker('common.organization-number'),
		OrganizationNumberToolTip: marker('common.organization-number-tooltip')
	},
	SaveOnExit: {
		Modal: {
			Title: marker('save-on-exit.modal.title'),
			Description: marker('save-on-exit.modal.description'),
			DescriptionInvalid: marker('save-on-exit.modal.description-invalid'),
			Save: marker('save-on-exit.modal.save'),
			Discard: marker('save-on-exit.modal.discard'),
			Cancel: marker('save-on-exit.modal.cancel')
		}
	},
	PageTitles: {
		Home: marker('page-titles.home'),
		Associations: marker('page-titles.associations'),
		Offers: marker('page-titles.offers'),
		AboutUs: marker('page-titles.about-us'),
		Partners: marker('page-titles.partners'),
		Faq: marker('page-titles.faq'),
		Contact: marker('page-titles.contact'),
		DetailOnMobile: marker('page-titles.detail-on-mobile'),
		VLoket: {
			Offers: marker('page-titles.v-loket.offers'), // Offers title overwrite for V-loket
			Advice: marker('page-titles.v-loket.advice'),
			MyAssociation: marker('page-titles.v-loket.my-association'),
			Product: marker('page-titles.v-loket.product'),
			SearchAssociations: marker('page-titles.v-loket.search-associations'),
			StartRegistration: marker('page-titles.v-loket.start-registration'),
			SuccessRegistration: marker('page-titles.v-loket.success-registration'),
			BecomeRepresentative: marker('page-titles.v-loket.become-representative')
		}
	},
	Cookies: {
		Video: marker('cookies.video'),
		ReadMore: marker('cookies.read-more'),
		Agree: marker('cookies.agree')
	},
	Notices: {
		RegisterKbo: {
			Title: marker('notices.register-KBO.title'),
			Message: marker('notices.register-KBO.message')
		}
	},
	SnackBars: {
		ErrorTitle: marker('snack-bars.error-title'),
		SuccessTitle: marker('snack-bars.success-title'),
		NoticeTitle: marker('snack-bars.notice-title')
	},
	BigFooter: {
		Register: marker('big-footer.register'),
		FindAssociation: marker('big-footer.find-association'),
		FindOffer: marker('big-footer.find-offer'),
		LegalInfo: marker('big-footer.legal-info'),
		AdminInfo: marker('big-footer.admin-info'),
		AllThemes: marker('big-footer.all-themes'),
		Newsletter: marker('big-footer.newsletter')
	}
};
