import { FacetFilter, FacetFilterResult } from '@cjm/shared/types';

/**
 * Converts the selected facets to an API friendly setup
 *
 * @param facetFilter - The selected facets
 */
export function convertFacetFilterToFacetFilterResult(facetFilter: FacetFilter): FacetFilterResult[] {
	const result: FacetFilterResult[] = [];

	for (const [facetNaam, namenNietVerfijnbareOnderwerpen] of Array.from(Object.entries(facetFilter))) {
		result.push({
			facetNaam,
			namenNietVerfijnbareOnderwerpen
		});
	}

	return result;
}

/**
 * Converts the selected facets from the API to a frontend entity
 *
 * @param facetFilterResults - The selected facets
 */
export function convertFacetFilterResultsToFacetFilter(facetFilterResults: FacetFilterResult[]): FacetFilter {
	const result: FacetFilter = {};

	for (const { facetNaam, namenNietVerfijnbareOnderwerpen } of facetFilterResults) {
		result[facetNaam] = namenNietVerfijnbareOnderwerpen;
	}

	return result;
}
