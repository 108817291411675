<ng-container *ngIf="initialized$ | async">
	<input
		class="c-input c-input--as-textfield"
		type="text"
		matInput
		[placeholder]="placeholder"
		[formControl]="form"
		[matAutocomplete]="auto"
	/>

	<mat-autocomplete
		#auto="matAutocomplete"
		[hideSingleSelectionIndicator]="true"
		(optionSelected)="valueSelected.emit($event)"
	>
		<mat-option *ngFor="let option of options" [value]="option.value">
			{{ option.label }}
		</mat-option>
	</mat-autocomplete>
</ng-container>
