export interface AssociationCardInfo {
	id: string; // Denis: The number of the association will be stored in this prop
	organisationId?: string; // Denis: The KBO number of the association will be stored in this optional prop
	name: string;
	type: string;
	labels: string[];
	location: string;
	representatives: MainRepresentative[];
}

interface MainRepresentative {
	name: string;
	email?: string;
	phone?: string;
}
