import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { IconType } from './fa-icon.const';

@Component({
	selector: 'fa-icon',
	template: `<i
		matTooltipClass="c-mat-tooltip"
		[matTooltipPosition]="toolTipPosition"
		[class]="iconType + ' ' + icon"
		[matTooltipDisabled]="!toolTip"
		[matTooltip]="toolTip"
	></i>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaIconComponent {
	@Input() icon: string;
	@Input() toolTip: string;
	@Input() iconType: `${IconType}` = IconType.solid;
	@Input() toolTipPosition: TooltipPosition = 'above';
}
