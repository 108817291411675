import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonClasses } from '@cjm/shared/ui/common';

import { AssociationContactItem } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vloket-association-detail-contact-list-item',
	templateUrl: './association-detail-contact-list-item.component.html',
	styleUrls: ['./association-detail-contact-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationDetailContactListItemComponent {
	@Input() public item: AssociationContactItem;
	public readonly buttonClasses = ButtonClasses;
	public readonly i18nKeys = I18nKeys;
}
