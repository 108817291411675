import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HasFeatureDirective } from './directives';
import { HasFeaturePipe } from './pipes';

@NgModule({
	imports: [CommonModule],
	declarations: [HasFeatureDirective, HasFeaturePipe],
	exports: [HasFeaturePipe, HasFeatureDirective]
})
export class FeatureUiModule {}
