import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { MyAssociationDetailResultEntity, MyAssociationEntity, parseAssociation } from '@cjm/v-loket/shared';

@Injectable()
export class MyAssociationAPIService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * getMyAssociation
	 *
	 * The getMyAssociation method calls /vereniging/mijn-vereniging
	 * and returns an Observable with the MyAssociationEntity response
	 * wrapped inside of it.
	 *
	 * @returns Observable: MyAssociationEntity
	 */
	public getMyAssociation(sequence?: number, context?: HttpContext): Observable<MyAssociationEntity> {
		const params =
			typeof sequence === 'undefined' || sequence === null ? null : new HttpParams().set('sequentie', sequence);

		return this.httpClient
			.get<MyAssociationDetailResultEntity>(
				VLoketEndpoints.Associations.GetMyAssociation(),
				params,
				true,
				context
			)
			.pipe(map(parseAssociation<MyAssociationEntity>));
	}
}
