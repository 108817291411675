import {
	AfterViewInit,
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnInit,
	QueryList,
	ViewChildren
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormAccessor, FormAccessor } from '@studiohyperdrive/ngx-forms';
import { filter } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { CountryOptions } from './country-select.const';
import { CountryValues } from './country-select.types';

@Component({
	selector: 'cjm-country-select',
	templateUrl: './country-select.component.html',
	styleUrls: ['./country-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CountrySelectComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => CountrySelectComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => CountrySelectComponent)
		}
	]
})
export class CountrySelectComponent
	extends FormAccessor<CountryValues, FormControl<CountryValues>>
	implements OnInit, AfterViewInit
{
	@ViewChildren('otherField') otherField: QueryList<ElementRef>;
	@Input() public label: string;
	@Input() public otherLabel: string;
	@Input() public otherPlaceholder: string;

	public readonly countryOptions: typeof CountryOptions = CountryOptions;
	public readonly countryOptionValues: CountryOptions[] = Object.values(CountryOptions);
	public readonly countrySelectControl: FormControl<CountryOptions> = new FormControl<CountryOptions>(
		CountryOptions.Belgium
	);

	// Denis: see @studiohyperdrive/ngx-forms
	public initForm(): FormControl<CountryValues> {
		return new FormControl(CountryOptions.Belgium);
	}

	// Denis: see @studiohyperdrive/ngx-forms
	public writeValue(value: CountryValues): void {
		super.writeValue(value);

		// Denis: if the representativeRolField is falsy (empty or undefined),
		// set the countrySelectControl to Belgium as default selection.
		if (!value) {
			this.countrySelectControl.patchValue(CountryOptions.Belgium);

			return;
		}

		// Denis: if the value can be found in the CountryOptions enum,
		// set the countrySelectControl to the selected value.
		if (this.countryOptionValues.includes(value as CountryOptions)) {
			this.countrySelectControl.patchValue(value as CountryOptions);

			return;
		}

		// Denis: if another value is provided,
		// set the countrySelectControl to the Other value to show the custom input field.
		// Do not emit the event to prevent the value to be overwritten.
		this.countrySelectControl.patchValue(CountryOptions.Other, { emitEvent: false });
	}

	public ngOnInit() {
		super.ngOnInit();

		this.initialized$
			.pipe(
				filter((initialized: boolean) => initialized),
				switchMap(() => this.countrySelectControl.valueChanges),
				tap((value: CountryOptions) => {
					if (value === CountryOptions.Other) {
						this.form.patchValue('');

						return;
					}

					this.form.patchValue(value);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.otherField.changes
			.pipe(
				tap(() => this.setFocus()),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	public setFocus(): void {
		if (this.otherField.length > 0) {
			this.otherField.first.nativeElement.focus();
		}
	}
}
