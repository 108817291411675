import { AddressEntity } from '@cjm/shared/types';

/**
 * parseLocationString
 *
 * The parseLocationString function will check if the address is provided and then parse it into a string;
 *
 * @param address - The address entity to parse.
 * @returns The formatted location string.
 */
export function parseLocationString(address: AddressEntity): string {
	/**
	 * If the address entity is falsy, returns undefined.
	 */
	if (!address) {
		return;
	}

	/**
	 * If the address entity does not have any street, number, zipcode, or municipality,
	 * returns undefined.
	 */
	if (!address.street && !address.number && !address.zipcode && !address.municipality) {
		return;
	}

	/**
	 * If the address entity has street or number, but does not have municipality or zipcode,
	 * returns undefined.
	 */
	if ((address.street || address.number) && !address.municipality && !address.zipcode) {
		return;
	}

	/**
	 * If the address entity does not have street or number, but has municipality or zipcode,
	 * returns a formatted location string with zipcode and/or municipality.
	 */
	if ((!address.street || !address.number) && (address.zipcode || address.municipality)) {
		return `${address.zipcode ? address.zipcode + ' ' : ''}${address.municipality ? address.municipality : ''}`;
	}

	/**
	 * If the address entity has street, number, and optionally zipcode and municipality,
	 * returns a formatted location string with street, number, zipcode and municipality.
	 */

	// If the address entity does not have a box, returns a formatted location string without box.
	if (!address.box) {
		return `${address.street} ${address.number}, ${address.zipcode ? address.zipcode + ' ' : ''}${
			address.municipality ? address.municipality : ''
		}`;
	}
	//	If the address entity has a box, returns a formatted location string with box.
	return `${address.street} ${address.number} bus ${address.box}, ${address.zipcode ? address.zipcode + ' ' : ''}${
		address.municipality ? address.municipality : ''
	}`;
}
