import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { isNil } from 'lodash';

import { BrowserService } from '@cjm/shared/core';

@Directive({
	selector: '[mediaQuery]'
})
export class MediaQueryDirective implements OnDestroy {
	private prevCondition: boolean = null;

	private mql: MediaQueryList;
	private mqlListener: EventListenerOrEventListenerObject; // reference kept for cleaning up in ngOnDestroy()

	constructor(
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<unknown>,
		private readonly browserService: BrowserService,
		private readonly cdRef: ChangeDetectorRef
	) {}

	@Input({ required: true }) set mediaQuery(newMediaQuery: string) {
		this.browserService.runInBrowser(({ browserWindow }) => {
			if (!this.mql) {
				this.mql = browserWindow.matchMedia(newMediaQuery);

				/* Register for future events */
				this.mqlListener = (mq: MediaQueryListEvent) => {
					this.onMediaMatchChange(mq.matches);
				};
				this.mql.addEventListener('change', this.mqlListener);
			}

			this.onMediaMatchChange(this.mql.matches);
		});
	}

	private onMediaMatchChange(matches: boolean) {
		if (matches && (isNil(this.prevCondition) || !this.prevCondition)) {
			this.prevCondition = true;
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else if (!matches && (isNil(this.prevCondition) || this.prevCondition)) {
			this.prevCondition = false;
			this.viewContainer.clear();
		}

		// Iben: We trigger a change detection cycle to make sure that onPush components also work with this directive
		this.cdRef.detectChanges();
	}

	// Lifecycle methods
	// ------------------------------------------------------------------------- /
	public ngOnDestroy(): void {
		this.browserService.runInBrowser(() => {
			this.mql.removeEventListener('change', this.mqlListener);
			this.mql = this.mqlListener = null;
		});
	}
}
