import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormAccessor } from '@studiohyperdrive/ngx-forms';

@Component({
	selector: 'cjm-radio-toggle',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioToggleComponent), // tslint:disable-line:no-use-before-declare
			multi: true
		}
	],
	templateUrl: './radio-toggle.component.html',
	styleUrls: ['./radio-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioToggleComponent extends FormAccessor {
	@HostBinding('class.c-radio-toggle') private readonly hasRootClass: boolean = true;
	/**
	 * The key value pair of the buttons.
	 * The key will be emitted and the value will be displayed.
	 */
	@Input({ required: true }) public values: Record<string, string>;
	/**
	 * The name of the group of these radio buttons. This has to be unique.
	 */
	@Input({ required: true }) public name: string;
	/**
	 * Allow the options to take the full available width.
	 */
	@Input() public fullWidth: boolean = false;

	// Abstract methods
	// ==============================
	public initForm(): FormControl<string> {
		return new FormControl<string>(null);
	}

	/*
	 * This is used to override the default comparator function of keyvalue pipe in angular - https://angular.io/api/common/KeyValuePipe
	 *
	 * Github issue: https://github.com/angular/angular/issues/42490
	 */
	public asIsOrder(): number {
		return 0;
	}
}
