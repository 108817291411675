<div
	*ngIf="toasterTitle || toasterMessage"
	class="c-toaster"
	[ngClass]="[toasterType, hasBackgroundColor ? 'has-background-color' : '']"
>
	<div class="c-toaster__content">
		<div *ngIf="toasterIcon || toasterSubtitle" class="c-toaster__content__subtitle u-margin-bottom-sm">
			<fa-icon
				*ngIf="toasterIcon"
				class="c-toaster__icon"
				[icon]="toasterIcon.icon"
				[iconType]="toasterIcon.type"
				[ngClass]="{ 'has-custom-icon': customToasterIcon }"
			/>
			<span *ngIf="toasterSubtitle" class="c-vloket-text--light" cypressTag="Toaster.Subtitle">
				{{ toasterSubtitle }}
			</span>
		</div>
		<p *ngIf="toasterTitle" class="c-toaster__content__title u-margin-bottom-sm" cypressTag="Toaster.Title">
			{{ toasterTitle }}
		</p>
		<p
			*ngIf="toasterMessage"
			class="c-toaster__content__message"
			cypressTag="Toaster.Message"
			[innerHTML]="toasterMessage"
		></p>
		<div *ngIf="toasterLinks?.length > 0" class="c-toaster__content__links">
			<cjm-link
				*ngFor="let toasterLink of toasterLinks"
				class="u-margin-top"
				cypressTag="Toaster.Link"
				classes="c-toaster__content__link"
				[ngClass]="{
					'c-toaster__content__link--primair': toasterLink.actionType === actionTypes.PRIMARY,
					'c-toaster__content__link--secondair': toasterLink.actionType === actionTypes.SECONDARY
				}"
				[type]="toasterLink.type"
				[to]="toasterLink.url"
				[title]="toasterLink.title"
				[icon]="toasterLink.icon"
				[keepInTab]="toasterLink.keepInTab"
				[noDecoration]="true"
			>
				{{ toasterLink.text }}
			</cjm-link>
		</div>
	</div>

	<cjm-button
		*ngIf="isClosable"
		cypressTag="Toaster.Close"
		[classes]="buttonClasses.Link"
		[title]="i18nKeys.Common.Close | translate"
		(handleClick)="closeButtonClicked.emit()"
	>
		<fa-icon icon="fa-solid fa-xmark" />
	</cjm-button>
</div>
