import { FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEqualWith, uniqWith } from 'lodash';

/**
 * hasDuplicatesValidator
 *
 * The hasDuplicatesValidator validator will check if there are duplicate values within the control.
 * It does so by checking if each value within the control is unique, based upon a given customizer function.
 *
 * @param customizer
 * @returns { hasDuplicateValue: true }
 */
export const createHasDuplicatesValidator: <FormControlType = never>(
	// Denis: copy/pasted the customizer interface from the internal Lodash interfaces.
	customizer: (
		value: any,
		other: any,
		indexOrKey: string | number | symbol | undefined,
		parent: any,
		otherParent: any,
		stack: any
	) => boolean | undefined
) => ValidatorFn = <FormControlType>(customizer) => {
	return (control: FormArray<FormControl<FormControlType>>): ValidationErrors => {
		const comparator = (a: FormControlType, b: FormControlType): boolean => isEqualWith(a, b, customizer);
		const originalValues = control.getRawValue();
		const uniqueValues = uniqWith(originalValues, comparator);

		if (originalValues.length > uniqueValues.length) {
			return { hasDuplicateValues: true };
		}

		return null;
	};
};
