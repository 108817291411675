<ng-container *ngIf="mode === representativeFormMode.Create" [ngTemplateOutlet]="createTmpl" />

<ng-container *ngIf="mode === representativeFormMode.Edit" [ngTemplateOutlet]="editTmpl" />

<ng-container *ngIf="mode === representativeFormMode.Invite" [ngTemplateOutlet]="inviteTmpl" />

<ng-container *ngIf="mode === representativeFormMode.Request" [ngTemplateOutlet]="requestTmpl" />

<!--
	#createTmpl

	The template used for creating a representative.
	This will not show contactfields when creating a representative for EA.
-->
<ng-template #createTmpl>
	<fieldset *ngIf="initialized$ | async" class="c-vloket-form__fieldset" [formGroup]="form">
		<h3 *ngIf="showTitle" [ngClass]="titleClass">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">
				<ng-container *ngIf="titleOverwrite">
					{{ titleOverwrite }}
				</ng-container>
				<ng-container *ngIf="!titleOverwrite">
					{{
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset.Title
							| translate
					}}
					{{ index + 1 }}
				</ng-container>
			</span>
		</h3>

		<fieldset class="c-vloket-form__fieldset__subset--no-spacing">
			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeFirstnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeFirstnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeFirstnameField"
						cypressTag="RepresentativeForm.Firstname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeFirstnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeFirstnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper
					id="representativeSurnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeSurnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeSurnameField"
						cypressTag="RepresentativeForm.Surname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeSurnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeSurnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>

			<fieldset *ngIf="!isKBO" [ngClass]="{ 'c-vloket-form__fieldset__subset--split-equal': !mailOnly }">
				<cjm-input-wrapper
					id="representativeEmailField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeEmailField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeEmailField"
						cypressTag="RepresentativeForm.Email.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeEmailField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeEmailField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativeEmailField">
						<div ngxError="extendedEmail" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsEmail | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper
					*ngIf="!mailOnly"
					id="representativePhoneField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativePhoneField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativePhoneField"
						cypressTag="RepresentativeForm.Phone.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativePhoneField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativePhoneField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativePhoneField">
						<div ngxError="invalidPhoneNumber" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsPhone | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>

			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeRRNField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeRRNField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeRRNField"
						cypressTag="RepresentativeForm.RRN.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeRRNField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeRRNField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativeRRNField">
						<div ngxError="invalidINSZ" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsINSZ | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>
		</fieldset>
	</fieldset>
</ng-template>

<!--
	#editTmpl

	The template used for editing a representative.
	This will disable certain fields when used for an EA.
-->
<ng-template #editTmpl>
	<fieldset *ngIf="initialized$ | async" class="c-vloket-form__fieldset" [formGroup]="form">
		<h4 class="c-vloket-header c-vloket-header--h4">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">
				{{
					i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset.GeneralInfoTitle
						| translate
				}}
			</span>
		</h4>

		<fieldset class="c-vloket-form__fieldset">
			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeFirstnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeFirstnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeFirstnameField"
						cypressTag="RepresentativeForm.Firstname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeFirstnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeFirstnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper
					id="representativeSurnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeSurnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeSurnameField"
						cypressTag="RepresentativeForm.Surname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeSurnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeSurnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>

			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeCallnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeCallnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeCallnameField"
						cypressTag="RepresentativeForm.Callname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeCallnameField.Placeholder | translate
						"
					/>
				</cjm-input-wrapper>

				<fieldset class="c-vloket-form__fieldset__subset">
					<cjm-input-wrapper
						*ngIf="!isKBO"
						class="u-margin-bottom"
						id="roleSelectControl"
						[label]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeRoleField.Label | translate
						"
					>
						<select #input cypressTag="RepresentativeForm.Type.Input" [formControl]="roleSelectControl">
							<option value="Empty">-</option>
							<option *ngFor="let type of representativeRoleTypes" [value]="type">
								{{ type }}
							</option>
						</select>
					</cjm-input-wrapper>

					<cjm-input-wrapper
						*ngIf="isKBO || (!isKBO && roleSelectControl.value === representativeRoleType.Other)"
						id="representativeRoleField"
						[label]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeRoleField.Options.Other.Label | translate
						"
					>
						<input
							#input
							type="text"
							formControlName="representativeRoleField"
							cypressTag="RepresentativeForm.Role.Input"
							[placeholder]="
								i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
									.RepresentativeRoleField.Options.Other.Placeholder | translate
							"
						/>
					</cjm-input-wrapper>
				</fieldset>
			</fieldset>
		</fieldset>

		<ng-container>
			<h4 class="c-vloket-header c-vloket-header--h4">
				<span class="c-vloket-text-marking c-vloket-text-marking--underline">
					{{
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset.ContactInfoTitle
							| translate
					}}
				</span>
			</h4>
			<fieldset class="c-vloket-form__fieldset__subset--no-spacing">
				<cjm-input-wrapper
					id="representativeEmailField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeEmailField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeEmailField"
						cypressTag="RepresentativeForm.Email.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeEmailField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeEmailField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativeEmailField">
						<div ngxError="extendedEmail" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsEmail | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper
					id="representativePhoneField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativePhoneField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativePhoneField"
						cypressTag="RepresentativeForm.Phone.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativePhoneField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativePhoneField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativePhoneField">
						<div ngxError="invalidPhoneNumber" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsPhone | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
				<cjm-input-wrapper
					id="representativeMobilePhoneField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeMobilePhoneField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeMobilePhoneField"
						cypressTag="RepresentativeForm.MobilePhone.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeMobilePhoneField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeMobilePhoneField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativeMobilePhoneField">
						<div ngxError="invalidPhoneNumber" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsPhone | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>

			<fieldset class="c-vloket-form__fieldset__subset">
				<cjm-input-wrapper
					id="representativeSocialMedia"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeSocialMedia.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeSocialMedia"
						cypressTag="RepresentativeForm.SocialMedia.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeSocialMedia.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeSocialMedia">
						<div ngxError="invalidURL" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsURL | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>
		</ng-container>
	</fieldset>
</ng-template>

<!--
	#inviteTmpl

	The template used for inviting a representative.
-->
<ng-template #inviteTmpl>
	<fieldset *ngIf="initialized$ | async" class="c-vloket-form__fieldset" [formGroup]="form">
		<fieldset class="c-vloket-form__fieldset">
			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeFirstnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeFirstnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeFirstnameField"
						cypressTag="RepresentativeForm.Firstname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeFirstnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeFirstnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper
					id="representativeSurnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeSurnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeSurnameField"
						cypressTag="RepresentativeForm.Surname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeSurnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeSurnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>

			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeRRNField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeRRNField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeRRNField"
						cypressTag="RepresentativeForm.RRN.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeRRNField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeRRNField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativeRRNField">
						<div ngxError="invalidINSZ" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsINSZ | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>
		</fieldset>

		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<cjm-input-wrapper
				id="representativeEmailField"
				[label]="
					i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
						.RepresentativeEmailField.Label | translate
				"
			>
				<input
					#input
					type="text"
					formControlName="representativeEmailField"
					cypressTag="RepresentativeForm.Email.Input"
					[placeholder]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeEmailField.Placeholder | translate
					"
				/>

				<ng-container description ngxErrors="representativeEmailField">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>

				<ng-container description ngxErrors="representativeEmailField">
					<div ngxError="extendedEmail" showWhen="dirty">
						{{ i18nKeys.FormLabels.FormatAsEmail | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
		</fieldset>

		<fieldset>
			<cjm-input-wrapper
				id="representativeInviteMessage"
				[label]="
					i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
						.RepresentativeInviteMessage.Label | translate
				"
			>
				<textarea
					#input
					formControlName="representativeInviteMessage"
					rows="10"
					cypressTag="RepresentativeForm.InviteMessage.Input"
				></textarea>
			</cjm-input-wrapper>
		</fieldset>
	</fieldset>
</ng-template>

<!--
	#requestTmpl

	The template used for requesting to be a representative.
-->
<ng-template #requestTmpl>
	<fieldset *ngIf="initialized$ | async" class="c-vloket-form__fieldset" [formGroup]="form">
		<fieldset class="c-vloket-form__fieldset">
			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeFirstnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeFirstnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeFirstnameField"
						cypressTag="RepresentativeForm.Firstname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeFirstnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeFirstnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper
					id="representativeSurnameField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeSurnameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeSurnameField"
						cypressTag="RepresentativeForm.Surname.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeSurnameField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeSurnameField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>

			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="representativeRRNField"
					[label]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeRRNField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="representativeRRNField"
						cypressTag="RepresentativeForm.RRN.Input"
						[placeholder]="
							i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
								.RepresentativeRRNField.Placeholder | translate
						"
					/>

					<ng-container description ngxErrors="representativeRRNField">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>

					<ng-container description ngxErrors="representativeRRNField">
						<div ngxError="invalidINSZ" showWhen="dirty">
							{{ i18nKeys.FormLabels.FormatAsINSZ | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>
		</fieldset>

		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<cjm-input-wrapper
				id="representativeEmailField"
				[label]="
					i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
						.RepresentativeEmailField.Label | translate
				"
			>
				<input
					#input
					type="text"
					formControlName="representativeEmailField"
					cypressTag="RepresentativeForm.Email.Input"
					[placeholder]="
						i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
							.RepresentativeEmailField.Placeholder | translate
					"
				/>

				<ng-container description ngxErrors="representativeEmailField">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>

				<ng-container description ngxErrors="representativeEmailField">
					<div ngxError="extendedEmail" showWhen="dirty">
						{{ i18nKeys.FormLabels.FormatAsEmail | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
		</fieldset>

		<fieldset>
			<cjm-input-wrapper
				id="representativeInviteMessage"
				[label]="
					i18nKeys.SharedAssociations.Forms.RepresentativeData.MainRepresentativeFieldset
						.RepresentativeInviteMessage.Label | translate
				"
			>
				<textarea
					#input
					formControlName="representativeInviteMessage"
					rows="8"
					cypressTag="RepresentativeForm.InviteMessage.Input"
				></textarea>

				<ng-container description ngxErrors="representativeInviteMessage">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
		</fieldset>
	</fieldset>
</ng-template>
