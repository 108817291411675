import { FacetFilter, IndexedPagination } from '@cjm/shared/types';

export interface AdviceOverviewPageDataEntity {
	content: AdviceDetailPageEntity[];
	pagination: IndexedPagination;
}

export interface AdviceDetailPageEntity {
	id: string;
	title: string;
	description: string;
	heroImage: AdvicePageImageEntity;
	keywords: AdvicePageKeywordEntity[];
	lastUpdated: string;
	readingTime: string;
}

export interface AdvicePageKeywordEntity {
	id: string;
	name: string;
}

export interface AdvicePageImageEntity {
	type: string;
	url: string;
	alt: string;
	height: string;
	width: string;
}

export interface AdvicePagesFilter {
	filters?: FacetFilter;
	size?: number;
	index?: string | number;
	recommended?: boolean;
}
