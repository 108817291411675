import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'cjm-modal',
	templateUrl: 'modal.component.html'
})
export class ModalComponent {
	@Input() public hideHeader = false;
	@Input() public hideFooter = false;
	@Input() public hideCloseButton = false;
	@Input() public isOpen: boolean;
	@Input() public small: boolean;
	@Input() public title: string;
	@Output() public handleClose = new EventEmitter();

	public handleClickClose() {
		this.handleClose.emit();
	}
}
