import { VloketLink } from '@cjm/shared/ui/common';

import { TimelineItemDescriptionEntity } from '../timeline-item/timeline-item.types';

export interface TimelineItemEntity {
	isCurrent?: boolean;
	isUpcoming?: boolean;
	isFirst?: boolean;
	isLast?: boolean;
	step?: number;
	title: string;
	date?: string;
	descriptions?: TimelineItemDescriptionEntity[];
	links?: VloketLink[];
}
