<cjm-button
	classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }}"
	type="button"
	[title]="
		(active
			? i18nKeys.SharedAssociations.PrimaryButton.Remove.Title
			: i18nKeys.SharedAssociations.PrimaryButton.Set.Title
		) | translate
	"
	(click)="active ? removeAsPrimary.emit(id) : setAsPrimary.emit(id)"
>
	<!-- Denis: $any is used because typescript can't match 'fa-solid' | 'fa-regular' to `${IconType}` in the template -->
	<fa-icon
		icon="fa-star"
		[ngClass]="{ 'c-primary-button--active': active }"
		[iconType]="$any(active ? 'fa-solid' : 'fa-regular')"
	/>
	<ng-container *ngIf="!iconOnly">
		{{
			(active
				? i18nKeys.SharedAssociations.PrimaryButton.Remove.Text
				: i18nKeys.SharedAssociations.PrimaryButton.Set.Text
			) | translate
		}}
	</ng-container>
</cjm-button>
