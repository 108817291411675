import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { combineLatest, filter, map, of, tap } from 'rxjs';

import { FeatureKeys, FeatureService } from '../../data';

/**
 * Check if we can route to this route based on the provided feature
 *
 * @param  routeSnapshot - The provided route snapshot
 */
export const HasFeatureGuard: CanActivateFn = (routeSnapshot: ActivatedRouteSnapshot): ObservableBoolean => {
	// Iben: Fetch all injectables
	const featureService: FeatureService = inject(FeatureService);
	const route: ActivatedRoute = inject(ActivatedRoute);
	const router: Router = inject(Router);

	// Iben: Check if the feature is enabled for the environment
	const feature: FeatureKeys = routeSnapshot.data?.feature;
	const redirectTo: string = routeSnapshot.data?.redirectTo;

	// Iben: Early exit if there's no feature provided
	if (!feature) {
		return of(true);
	}

	// Iben: If there's a feature provided, we check if we have the feature
	return combineLatest([featureService.featuresSet$, featureService.hasFeature(feature)]).pipe(
		filter(([featuresHaveBeenSet]) => featuresHaveBeenSet),
		tap(([, canNavigate]) => {
			// Iben: Continue if the feature is enabled
			if (canNavigate) {
				return;
			}

			// Iben: Redirect if the feature is not enabled
			router.navigate([redirectTo], { relativeTo: route });
		}),
		map(([, canNavigate]) => canNavigate)
	);
};
