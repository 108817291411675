<ng-container *ngIf="isOnline$ | async; else offlineTmpl">
	<cjm-loader *ngIf="loading; else contentTmpl" [label]="label"> </cjm-loader>

	<ng-template #contentTmpl>
		<ng-container *ngIf="!error; else errorTmpl">
			<ng-content></ng-content>
		</ng-container>
	</ng-template>

	<ng-template #errorTmpl>
		<cjm-layout-container layoutType="full-width">
			<div class="c-alert" main [class]="errorStyle">
				<p class="icon-alert">
					{{ errorText }}
				</p>
			</div>
		</cjm-layout-container>
	</ng-template>
</ng-container>

<ng-template #offlineTmpl>
	<cjm-layout-container layoutType="full-width">
		<div class="c-alert is-information" main>
			<p class="icon-alert">
				{{ offlineLabel | translate }}
			</p>
		</div>
	</cjm-layout-container>
</ng-template>
