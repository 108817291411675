<!--
	Denis: The full version will show subamount & amount
	and mark the subamount with the yellow indicator.
-->
<ng-container *ngIf="!condensed">
	<ng-container *ngIf="subAmount > -1">
		<strong class="c-vloket-text-marking c-vloket-text-marking--underline">{{ subAmount }}</strong>
		{{ i18nKey.Of | translate }}
	</ng-container>

	<ng-container *ngIf="amount === 0">
		<ng-container *ngIf="!showNoResultsLabel; else noResultsLabelTmpl">
			{{ i18nKey.MultipleResults | translate : { amount: amount } }}
		</ng-container>
	</ng-container>

	<ng-container *ngIf="amount === 1">
		{{ i18nKey.SingleResult | translate : { amount: amount } }}
	</ng-container>

	<ng-container *ngIf="amount > 1">
		{{ i18nKey.MultipleResults | translate : { amount: amount } }}
	</ng-container>
</ng-container>

<!--
	Denis: The condensed version will only the amount
	and mark it with the yellow indicator.
-->
<ng-container *ngIf="condensed">
	<ng-container *ngIf="amount === 0">
		<ng-container *ngIf="!showNoResultsLabel; else noResultsLabelTmpl">
			{{ i18nKey.MultipleResults | translate : { amount: amount } }}
		</ng-container>
	</ng-container>

	<ng-container *ngIf="amount === 1">
		<strong class="c-vloket-text-marking c-vloket-text-marking--underline">{{ amount }}</strong>
		{{ i18nKey.SingleResultWoAmount | translate }}
	</ng-container>

	<ng-container *ngIf="amount > 1">
		<strong class="c-vloket-text-marking c-vloket-text-marking--underline">{{ amount }}</strong>
		{{ i18nKey.MultipleResultsWoAmount | translate }}
	</ng-container>
</ng-container>

<!-- TEMPLATES -->
<ng-template #noResultsLabelTmpl>
	{{ i18nKey.NoResults | translate }}
</ng-template>
