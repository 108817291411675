import { FormControl } from '@angular/forms';

export interface SendCopyForm {
	isActive: boolean;
	recipient: string;
}

export interface SendCopyFormGroup {
	isActive: FormControl<boolean>;
	recipient: FormControl<string>;
}
