export class DownloadableDocumentEntity {
	name: string;
	id: string;
}

export class DownloadableDocumentResultEntity {
	id: string;
	naam: string;
}

export type DownloadingRecord = Record<string, boolean>;
