<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
	<ul *ngIf="breadcrumbs.length > 0" class="c-page-overview">
		<li *ngFor="let route of breadcrumbs; let last = last" class="c-page-overview__item">
			<a
				*ngIf="route.allowNav"
				class="c-page-overview__link u-outline u-font-bold"
				cypressTag="Breadcrumbs.Breadcrumb"
				[class.is-active]="last"
				[routerLink]="route.routePath"
				[title]="route.title | translate"
			>
				<ng-container *mediaQuery="mediaQueryMin.Desktop">
					{{ route.title | translate }}
				</ng-container>
				<ng-container *mediaQuery="mediaQueryMax.Desktop">
					{{ (route.mobileTitle ? route.mobileTitle : route.title) | translate }}
				</ng-container>
			</a>

			<a
				*ngIf="!route.allowNav"
				class="c-page-overview__link u-outline u-font-bold"
				[class.is-active]="last"
				[title]="route.title | translate"
			>
				<ng-container *mediaQuery="mediaQueryMin.Desktop">
					{{ route.title | translate }}
				</ng-container>
				<ng-container *mediaQuery="mediaQueryMax.Desktop">
					{{ (route.mobileTitle ? route.mobileTitle : route.title) | translate }}
				</ng-container>
			</a>
		</li>
	</ul>
</ng-container>
