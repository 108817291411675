import { Component, HostBinding } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { isNil } from 'lodash';
import { Observable, tap } from 'rxjs';

import { PageImageUrlType, MetaService } from '@cjm/shared/core';

import { AdviceDetailPageFacade, AdviceDetailPageDataEntity } from '../../../data';

@Component({
	selector: 'vloket-advices-page',
	templateUrl: './advice-detail-page.component.html',
	styleUrls: ['./advice-detail-page.component.scss'],
	providers: [AdviceDetailPageFacade]
})
export class AdviceDetailPageComponent {
	@HostBinding('class.c-advice-detail') private readonly hasRootClass: boolean = true;

	public readonly detailPageData$: Observable<AdviceDetailPageDataEntity> =
		this.adviceDetailPageFacade.adviceDetailPageData$.pipe(
			tap((page) => {
				if (isNil(page)) {
					return;
				}

				this.metaService.updateMetaData({
					title: page.title,
					description: page.description,
					pageUrl: this.router.url,
					imageUrl: { path: page.heroImage.url, type: PageImageUrlType.external }
				});
			})
		);
	public readonly detailPageComponents$: Observable<SafeHtml> =
		this.adviceDetailPageFacade.adviceDetailPageComponents$;

	constructor(
		private adviceDetailPageFacade: AdviceDetailPageFacade,
		private readonly router: Router,
		private readonly metaService: MetaService
	) {}
}
