import { AssociationSource, RepresentativeStatus } from '../const';
import {
	AssociationActivityEntity,
	AssociationActivityResultEntity,
	AssociationContactEntity,
	AssociationContactResultEntity,
	AssociationDetailMetaDataEntity,
	AssociationDetailMetaDataResultEntity,
	AssociationDetailResultEntity,
	AssociationLocationCoordinatesEntity,
	AssociationLocationCoordinatesResultEntity,
	AssociationLocationEntity,
	AssociationLocationResultEntity,
	AssociationNamesEntity,
	AssociationNamesResultEntity,
	AssociationRelationResultEntity,
	AssociationRepresentativeEntity,
	AssociationRepresentativeResultEntity,
	MyAssociationDetailMetaDataResultEntity,
	MyAssociationDetailProgressEntity,
	MyAssociationDetailProgressResultEntity,
	MyAssociationDetailProgressTaskEntity,
	MyAssociationDetailProgressTaskResultEntity,
	MyAssociationDetailResultEntity,
	MyAssociationLocationResultEntity
} from '../interfaces';

export const checkAndParseArray = <InputDataType, ReturnDataType>(
	data: InputDataType[] = [],
	parser
): ReturnDataType[] => {
	if (!parser || data.length === 0) {
		return [];
	}

	return data.map(parser);
};

export const parseAssociationRelation = (relation: AssociationRelationResultEntity) => ({
	otherAssociation: {
		kboNumber: relation.andereVereniging.kboNummer,
		name: relation.andereVereniging.naam,
		vCode: relation.andereVereniging.vCode
	},
	type: relation.type
});

export const parseAssociationName = (name: AssociationNamesResultEntity): AssociationNamesEntity => {
	if (!name) {
		return;
	}

	return {
		full: name.volledig,
		short: name.afgekort,
		callName: name.roepnaam
	};
};

export const parseAssociationLocation = (location: AssociationLocationResultEntity): AssociationLocationEntity => {
	if (!location) {
		return;
	}

	return {
		...((location as MyAssociationLocationResultEntity).bron
			? {
					source: (location as MyAssociationLocationResultEntity).bron
			  }
			: {}),
		id: String(location.objectId),
		fullAddress: location.adres,
		name: location.naam,
		zipcode: location.postcode,
		municipality: location.gemeente,
		street: location.straatnaam,
		number: location.huisnummer,
		box: location.busnummer,
		country: location.land,
		type: location.type,
		coordinates: parseCoordinates(location.coordinaten),
		isPrimary: location.isPrimair
	};
};

export const parseCoordinates = (
	coordinates: AssociationLocationCoordinatesResultEntity
): AssociationLocationCoordinatesEntity => {
	if (!coordinates) {
		return;
	}

	return {
		latitude: coordinates.breedtegraad,
		longitude: coordinates.lengtegraad,
		boundingBox: {
			northEast: {
				latitude: coordinates.begrenzingskader.noordoost.breedtegraad,
				longitude: coordinates.begrenzingskader.noordoost.lengtegraad
			},
			southWest: {
				latitude: coordinates.begrenzingskader.zuidwest.breedtegraad,
				longitude: coordinates.begrenzingskader.zuidwest.lengtegraad
			}
		}
	};
};

export const parseAssociationActivity = (activity: AssociationActivityResultEntity): AssociationActivityEntity => {
	if (!activity) {
		return;
	}

	return {
		name: activity.naam
	};
};

export const parseMyAssociationProgressTask = (
	tasks: MyAssociationDetailProgressTaskResultEntity[]
): MyAssociationDetailProgressTaskEntity[] => {
	if (!Array.isArray(tasks) || tasks.length === 0) {
		return [];
	}

	return tasks.map((task: MyAssociationDetailProgressTaskResultEntity) => ({
		'@type': task['@type'],
		description: task.beschrijving,
		property: task.eigenschap,
		isCompleted: task.isVoltooid
	}));
};

export const parseMyAssociationProgress = (
	progress: MyAssociationDetailProgressResultEntity
): MyAssociationDetailProgressEntity => {
	if (!progress) {
		return;
	}

	return {
		'@type': progress['@type'],
		percentage: progress.percentage,
		tasks: parseMyAssociationProgressTask(progress.taken)
	};
};

export const parseAssociationMetaData = (
	metaData: AssociationDetailMetaDataResultEntity | MyAssociationDetailMetaDataResultEntity
): AssociationDetailMetaDataEntity => {
	if (!metaData) {
		return;
	}

	return {
		lastModificationDate: metaData.laatsteWijzigingsdatum ? new Date(metaData.laatsteWijzigingsdatum) : null,
		isPublic: !!(metaData as MyAssociationDetailMetaDataResultEntity).isPubliekBeschikbaar,
		versionNumber: metaData.versienummer,
		...((metaData as MyAssociationDetailMetaDataResultEntity).compleetheid
			? {
					progress: parseMyAssociationProgress(
						(metaData as MyAssociationDetailMetaDataResultEntity).compleetheid
					)
			  }
			: {})
	};
};

export const parseAssociationContact = (contact: AssociationContactResultEntity): AssociationContactEntity => {
	if (!contact) {
		return;
	}

	const {
		isPrimair: isPrimary,
		type: contactType,
		beschrijving: description,
		waarde: contactValue,
		objectId: objectId,
		bron: source
	} = contact;

	return {
		'@type': contact['@type'],
		isPrimary,
		contactType,
		description,
		contactValue,
		objectId,
		source: AssociationSource[source]
	};
};

export const parseAssociationRepresentative = (
	representative: AssociationRepresentativeResultEntity
): AssociationRepresentativeEntity => {
	if (!representative) {
		return;
	}

	return {
		id: String(representative.objectId),
		source: representative.bron as AssociationSource,
		insz: representative.insz,
		firstname: representative.voornaam,
		lastname: representative.achternaam,
		callname: representative.roepnaam,
		role: representative.rol,
		isPrimary: representative.isPrimair,
		email: representative.email,
		phone: representative.telefoon,
		mobilePhone: representative.mobiel,
		socialMedia: representative.socialMedia,
		isCurrentUser: representative.isHuidigeGebruiker,
		// Denis: The representative is always active
		status: RepresentativeStatus.ACTIVE
	};
};

export const parseAssociation = <AssociationType>(
	data: MyAssociationDetailResultEntity | AssociationDetailResultEntity
): AssociationType => {
	if (!data) {
		return;
	}

	return {
		id: data['@id'],
		associationNumber: data.verenigingsnummer,
		...((data as MyAssociationDetailResultEntity).ondernemingsnummer
			? {
					organisationNumber: (data as MyAssociationDetailResultEntity).ondernemingsnummer
			  }
			: {}),
		associationType: {
			code: data.type.code,
			description: data.type.beschrijving
		},
		...((data as MyAssociationDetailResultEntity).bron
			? {
					source: (data as MyAssociationDetailResultEntity).bron
			  }
			: {}),
		names: parseAssociationName(data.namen),
		locations: checkAndParseArray<AssociationLocationResultEntity, AssociationLocationEntity>(
			data.locaties,
			parseAssociationLocation
		),
		mainActivities: checkAndParseArray<AssociationActivityResultEntity, AssociationActivityEntity>(
			data.hoofdactiviteiten,
			parseAssociationActivity
		),
		description: data.omschrijving,
		startDate: data.startdatum ? new Date(data.startdatum) : null,
		targetAudience: data.doelgroep
			? {
					minAge: data.doelgroep.minimumleeftijd,
					maxAge: data.doelgroep.maximumleeftijd
			  }
			: null,
		kboNumber: data.kbonummer,
		status: data.status,
		metaData: parseAssociationMetaData(data.metadata),
		contactInfoList: checkAndParseArray<AssociationContactResultEntity, AssociationContactEntity>(
			data.contactgegevens,
			parseAssociationContact
		),
		...((data as MyAssociationDetailResultEntity).vertegenwoordigers
			? {
					representatives: checkAndParseArray<
						AssociationRepresentativeResultEntity,
						AssociationRepresentativeEntity
					>((data as MyAssociationDetailResultEntity).vertegenwoordigers, parseAssociationRepresentative)
			  }
			: {})
	} as AssociationType;
};
