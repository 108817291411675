<a
	*ngIf="product"
	class="c-product-grid-item"
	[routerLink]="[
		'/',
		i18nService.currentLanguage,
		offersRoutePaths.Offers,
		offersRoutePaths.OffersDetail,
		product.id
	]"
>
	<div class="c-product-grid-item__service-provider">
		<fa-icon icon="fa-building" iconType="fa-regular" />
		<div class="c-product-grid-item__service-provider__partner-list">
			<div *ngFor="let partner of product.partners" class="c-product-grid-item__service-provider__partner">
				<span
					class="c-vloket-header--subtitle c-vloket-text-marking c-vloket-text-marking--underline c-product-grid-item__service-provider_link"
					>{{ partner.name }}</span
				>
			</div>
		</div>
	</div>

	<div class="c-product-grid-item__top">
		<h5 class="c-vloket-header c-vloket-header--h5 c-product-grid-item__title">{{ product.name }}</h5>
		<div class="u-limit-line--3 c-product-grid-item__rich-text" [innerHtml]="product.description"></div>
	</div>

	<div class="c-product-grid-item__bottom">
		<cjm-tag tagType="light" icon="fa-tag" iconType="fa-solid">{{ product.type }}</cjm-tag>
	</div>
</a>
