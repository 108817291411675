import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'vloket-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {
	@HostBinding('class.c-vloket-page-header') public readonly hasPageHeaderClass: boolean = true;

	@Input() public assetUrl: string;
}
