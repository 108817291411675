<cdk-accordion class="example-accordion">
	<vloket-timeline-item
		*ngFor="let item of timelineItems; let index = index"
		[index]="index"
		[title]="item.title"
		[date]="item.date"
		[descriptions]="item.descriptions"
		[links]="item.links"
		[step]="item.step"
		[isUpcoming]="item.isUpcoming"
		[isCurrent]="item.isCurrent"
		[isFirst]="item.isFirst"
		[isLast]="item.isLast"
		[isCondensed]="isCondensed"
	/>
</cdk-accordion>
