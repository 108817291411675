import { combineReducers } from '@ngrx/store';
import { createBaseStoreAssets, createEntityAdapterStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';
import { FacetEntity, IndexedPagination, ProductEntity, SortingEntity } from '@cjm/shared/types';

const offeredProductEntityStoreAssets = createEntityAdapterStoreAssets<ProductEntity>({
	slice: `${StoreSlice.Products}.products`
});
const offeredProductFacetsStoreAssets = createEntityAdapterStoreAssets<FacetEntity>({
	slice: `${StoreSlice.Products}.facets`
});
const productSortingStoreAssets = createEntityAdapterStoreAssets<SortingEntity>({
	slice: `${StoreSlice.Products}.sorting`
});
const selectDetailProductAssets = createBaseStoreAssets<ProductEntity>({
	slice: `${StoreSlice.Products}.productDetail`
});
const productsPaginationStoreAssets = createBaseStoreAssets<IndexedPagination>({
	slice: `${StoreSlice.Products}.productsPagination`
});

export const actions = {
	products: offeredProductEntityStoreAssets.actions,
	facets: offeredProductFacetsStoreAssets.actions,
	sorting: productSortingStoreAssets.actions,
	productDetail: selectDetailProductAssets.actions,
	productsPagination: productsPaginationStoreAssets.actions
};

export const selectors = {
	products: offeredProductEntityStoreAssets.selectors,
	facets: offeredProductFacetsStoreAssets.selectors,
	sorting: productSortingStoreAssets.selectors,
	productDetail: selectDetailProductAssets.selectors,
	productsPagination: productsPaginationStoreAssets.selectors
};

export const reducers = combineReducers({
	products: offeredProductEntityStoreAssets.reducers,
	facets: offeredProductFacetsStoreAssets.reducers,
	sorting: productSortingStoreAssets.reducers,
	productDetail: selectDetailProductAssets.reducers,
	productsPagination: productsPaginationStoreAssets.reducers
});
