<cjm-layout-container
	*ngIf="data"
	class="c-advice-detail-page-recommended-block"
	layoutType="full-width"
	[class]="'c-advice-detail-page-recommended-block--' + backgroundVariants[data.variant]"
>
	<ng-container main>
		<h2 *ngIf="data.title" class="c-vloket-header c-vloket-header--h3">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ data.title }}
			</span>
		</h2>
		<div *ngIf="data.pages" class="c-advice-detail-page-recommended-block__pages-container">
			<vloket-advice-card *ngFor="let page of data.pages" [page]="page" />
		</div>
	</ng-container>
</cjm-layout-container>
