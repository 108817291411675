<div class="c-product-detail-user-actions">
	<cjm-link
		type="external"
		[title]="action.name"
		[classes]="
			(solidButton
				? buttonClasses.RegularFullWidth + ' ' + 'c-button--blue'
				: buttonClasses.OutlineFullWidth + ' ' + 'c-button--blue-outline') +
			' ' +
			buttonClasses.Action
		"
		[to]="action.link"
	>
		<span class="u-truncate">
			{{ action.name }}
		</span>
	</cjm-link>
</div>
