export interface LocalitySearchResult {
	address: {
		detectedLanguage: string;
		string: string;
		searchBarString: string;
		municipalityName: string;
		postalCode: string;
		localityName: string;
	};
	messages: [
		{
			key: string;
			args: string[];
		}
	];
}

export interface StreetSearchResult {
	address: {
		detectedLanguage: string;
		string: string;
		searchBarString: string;
		streetName: string;
		municipalityName: string;
		postalCode: string;
		localityName: string;
	};
}

export interface StreetNumberSearchResult {
	address: {
		latitude: number;
		longitude: number;
		detectedLanguage: string;
		string: string;
		searchBarString: string;
		houseNumber: string;
		streetName: string;
		municipalityName: string;
		postalCode: string;
		localityName: string;
	};
}

export interface BoxNumberSearchResult {
	address: {
		latitude: number;
		longitude: number;
		detectedLanguage: string;
		string: string;
		searchBarString: string;
		boxNumber: string;
		houseNumber: string;
		streetName: string;
		municipalityName: string;
		postalCode: string;
		localityName: string;
	};
}
