import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';
import { AdvicePagesApiService, advicePagesReducers } from '@cjm/v-loket/repositories';

import { AdvicePageResolvers } from './resolver';
import { AdvicePageServices } from './services';
import { reducers } from './store';

@NgModule({
	imports: [
		StoreModule.forFeature(StoreSlice.Advice, reducers),
		StoreModule.forFeature(StoreSlice.Advices, advicePagesReducers)
	],
	providers: [AdvicePageServices, AdvicePageResolvers, AdvicePagesApiService]
})
export class AdvicePageDataModule {}
