// TODO: Iben: Export these in the package
import { BasicEntityAdapterActions } from '@studiohyperdrive/ngx-store/lib/store/interfaces';

export interface CJMAuthenticationStoreActions {
	authenticated: BasicEntityAdapterActions;
	anonymous: BasicEntityAdapterActions;
}

export interface CJMAuthenticationStoreSelectors {
	anonymous: any;
	authenticated: any;
}
