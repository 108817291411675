import { FacetEntity, NonRefinableFacetSubjectEntity, RefinableFacetSubjectEntity } from '@cjm/shared/types';

/**
 * parseFacetMap
 *
 * The parseFacetMap method will parse the facet options to key/value string pairs.
 * This mapping can be used for filtering tags.
 *
 * @param facets
 * @returns Record<string, string>
 */
export const parseFacetMap = (facets: FacetEntity[] = []): Record<string, string> => {
	if (!Array.isArray(facets) || facets.length === 0) {
		return {};
	}

	return facets.reduce((acc: Record<string, string>, facet: FacetEntity) => {
		if (!facet.isRefinable) {
			return {
				...acc,
				...facet.items.reduce(
					(acc: Record<string, string>, item: NonRefinableFacetSubjectEntity) => ({
						...acc,
						[item.id]: item.name
					}),
					{}
				)
			};
		}

		if (facet.isRefinable) {
			return {
				...acc,
				...facet.items.reduce(
					(acc: Record<string, string>, item: RefinableFacetSubjectEntity) => ({
						...acc,
						...item.refinements.reduce(
							(acc: Record<string, string>, refinement: NonRefinableFacetSubjectEntity) => ({
								...acc,
								[refinement.id]: refinement.name
							}),
							{}
						)
					}),
					{}
				)
			};
		}

		return acc;
	}, {});
};
