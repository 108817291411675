import { ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { filter, mergeMap, Observable, pipe, UnaryFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This is a wrapper around the Todd Motto suggested workaround to getting the route data
 * from the primary router outlet.
 * See: https://ultimatecourses.com/blog/dynamic-page-titles-angular-2-router-events
 *
 * @param route{ActivatedRoute}
 * @returns UnaryFunction<Observable<any>, Observable<any>>
 */
export const getRouteData = (route: ActivatedRoute): UnaryFunction<Observable<any>, Observable<any>> => {
	return pipe(
		filter((event: RouterEvent) => event instanceof NavigationEnd),
		map(() => route),
		map((route: ActivatedRoute) => {
			while (route.firstChild) route = route.firstChild;

			return route;
		}),
		filter((route: ActivatedRoute) => route.outlet === 'primary'),
		mergeMap((route: ActivatedRoute) => route.data)
	);
};
