import { AddressEntity, FacetResult } from '@cjm/shared/types';
import { IconType } from '@cjm/shared/ui/common';

import {
	AssociationContactItemType,
	AssociationLocationType,
	AssociationRelationType,
	AssociationSource,
	MyAssociationProgressProperty
} from '../const';

/**
 * Association interfaces for API responses (in NL)
 */
export interface AssociationNamesResultEntity {
	'@type': string;
	volledig: string;
	afgekort: string;
	roepnaam?: null;
}

export interface AssociationLocationCoordinatesResultEntity {
	'@type': 'Coordinaten';
	breedtegraad: number;
	lengtegraad: number;
	begrenzingskader: {
		noordoost: {
			breedtegraad: number;
			lengtegraad: number;
		};
		zuidwest: {
			breedtegraad: number;
			lengtegraad: number;
		};
	};
}

export interface AssociationLocationResultEntity {
	'@type': string;
	objectId: number;
	adres: string;
	naam: string;
	postcode: string;
	gemeente: string;
	straatnaam: string;
	huisnummer: string;
	busnummer: string;
	land: string;
	type: AssociationLocationType;
	coordinaten: AssociationLocationCoordinatesResultEntity;
	isPrimair: boolean;
}

export interface MyAssociationLocationResultEntity extends AssociationLocationResultEntity {
	bron: string;
}

export interface AssociationActivityResultEntity {
	'@type': string;
	naam: string;
}

export interface AssociationLinksResultEntity {
	detail: string;
}

export interface AssociationDetailMetaDataResultEntity {
	laatsteWijzigingsdatum?: string;
	versienummer: string;
}

export interface AssociationContactResultEntity {
	'@type': 'Contactgegeven';
	type: AssociationContactItemType;
	waarde: string;
	beschrijving: string;
	isPrimair: boolean;
	objectId: number;
	bron: string;
}

export interface AssociationRepresentativeResultEntity {
	'@type': string;
	bron: string;
	objectId: number;
	insz: string;
	voornaam: string;
	achternaam: string;
	roepnaam: string;
	rol: string;
	isPrimair: boolean;
	email: string;
	mobiel: string;
	telefoon: string;
	socialMedia: string;
	isHuidigeGebruiker: boolean;
}

export interface AssociationTypeResultEntity {
	'@type': string;
	code: string;
	beschrijving: string;
}

export interface AssociationRelationResultEntity {
	'@type': 'Relatie';
	type: AssociationRelationType;
	andereVereniging: {
		kboNummer: string;
		vCode: string;
		naam: string;
	};
}

export interface AssociationResultEntity {
	verenigingsnummer: string;
	ondernemingsnummer?: string;
	type: AssociationTypeResultEntity;
	namen: AssociationNamesResultEntity;
	locaties: AssociationLocationResultEntity[];
	hoofdactiviteiten: AssociationActivityResultEntity[];
	activiteiten: AssociationActivityResultEntity[];
	doelgroep?: AssociationTargetAudienceResultEntity;
	links: AssociationLinksResultEntity;
	relaties: AssociationRelationResultEntity[];
}

export interface AssociationDetailResultEntity {
	'@id': string;
	'@type': string;
	verenigingsnummer: string;
	ondernemingsnummer?: string;
	type: AssociationTypeResultEntity;
	namen: AssociationNamesResultEntity;
	locaties: AssociationLocationResultEntity[];
	hoofdactiviteiten: AssociationActivityResultEntity[];
	// Denis: "activiteiten" does not exist on "/mijn-vereniging" response
	activiteiten?: AssociationActivityResultEntity[];
	omschrijving: string;
	startdatum: string;
	doelgroep?: AssociationTargetAudienceResultEntity;
	kbonummer: string;
	status: string;
	metadata: AssociationDetailMetaDataResultEntity;
	contactgegevens: AssociationContactResultEntity[];
}

export interface MyAssociationDetailProgressTaskResultEntity {
	'@type': 'Compleetheidtaak';
	eigenschap: MyAssociationProgressProperty;
	beschrijving: string;
	isVoltooid: boolean;
}

export interface MyAssociationDetailProgressResultEntity {
	'@type': 'Compleetheid';
	percentage: number;
	taken: MyAssociationDetailProgressTaskResultEntity[];
}

export interface MyAssociationDetailMetaDataResultEntity extends AssociationDetailMetaDataResultEntity {
	compleetheid: MyAssociationDetailProgressResultEntity;
	isPubliekBeschikbaar: boolean;
}

export interface MyAssociationDetailResultEntity extends AssociationDetailResultEntity {
	bron: string;
	locaties: MyAssociationLocationResultEntity[];
	vertegenwoordigers: AssociationRepresentativeResultEntity[];
	metadata: MyAssociationDetailMetaDataResultEntity;
}

export interface AssociationCollectionResult {
	paginatie?: {
		index: number;
		limiet: number;
		totaalAantal: number;
	};
	inhoud: {
		elementen: AssociationResultEntity[];
	};
	facets?: {
		elementen: FacetResult[];
	};
}

export interface AssociationTargetAudienceResultEntity {
	minimumleeftijd?: number;
	maximumleeftijd?: number;
}

/**
 * Association interfaces for parsed responses (in EN)
 */
export interface AssociationNamesEntity {
	full: string;
	short: string;
	callName?: string;
}

export interface AssociationLocationCoordinatesEntity {
	latitude: number;
	longitude: number;
	boundingBox: {
		northEast: {
			latitude: number;
			longitude: number;
		};
		southWest: {
			latitude: number;
			longitude: number;
		};
	};
}

export interface AssociationLocationEntity extends AddressEntity {
	id: string;
	name: string;
	fullAddress: string;
	type: AssociationLocationType;
	coordinates?: AssociationLocationCoordinatesEntity;
}

export interface MyAssociationLocationEntity extends AssociationLocationEntity {
	source: AssociationSource;
}

export interface AssociationActivityEntity {
	name: string;
}

export interface AssociationLinksEntity {
	detail: string;
}

export interface AssociationDetailMetaDataEntity {
	lastModificationDate?: Date;
	versionNumber?: string;
	isPublic?: boolean;
}

export interface AssociationContactItem {
	label: string;
	icon?: string;
	iconType?: IconType;
	url?: string;
	bold?: boolean;
}

export interface AssociationContactEntity {
	'@type': 'Contactgegeven';
	contactType: AssociationContactItemType;
	contactValue: string;
	description: string;
	isPrimary: boolean;
	objectId: number;
	source: AssociationSource;
}

export interface AssociationRepresentativeEntity {
	source: AssociationSource;
	id: string;
	insz: string;
	firstname: string;
	lastname: string;
	callname: string;
	role: string;
	isPrimary: boolean;
	email: string;
	phone: string;
	mobilePhone: string;
	socialMedia: string;
	status?: string;
	isCurrentUser: boolean;
}

export interface AssociationTypeEntity {
	code: string;
	description: string;
}

export interface AssociationRelationEntity {
	type: AssociationRelationType;
	otherAssociation: {
		kboNumber: string;
		vCode: string;
		name: string;
	};
}

export interface AssociationEntity {
	id: string;
	organisationNumber?: string;
	type: AssociationTypeEntity;
	names: AssociationNamesEntity;
	locations: AssociationLocationEntity[];
	mainActivities: AssociationActivityEntity[];
	targetAudience?: AssociationTargetAudienceEntity;
	links: AssociationLinksEntity;
	relations: AssociationRelationEntity[];
}

export interface AssociationDetailEntity {
	id: string;
	associationNumber: string;
	organisationNumber?: string;
	associationType: AssociationTypeEntity;
	names: AssociationNamesEntity;
	locations: AssociationLocationEntity[];
	mainActivities: AssociationActivityEntity[];
	description: string;
	startDate: Date;
	targetAudience?: AssociationTargetAudienceEntity;
	kboNumber: string;
	status: string;
	metaData: AssociationDetailMetaDataEntity;
	contactInfoList: AssociationContactEntity[];
}

export interface MyAssociationDetailProgressTaskEntity {
	'@type': 'Compleetheidtaak';
	property: MyAssociationProgressProperty;
	description: string;
	isCompleted: boolean;
}

export interface MyAssociationDetailProgressEntity {
	'@type': 'Compleetheid';
	percentage: number;
	tasks: MyAssociationDetailProgressTaskEntity[];
}

export interface MyAssociationDetailMetaDataEntity extends AssociationDetailMetaDataEntity {
	progress: MyAssociationDetailProgressEntity;
}

export interface MyAssociationEntity extends AssociationDetailEntity {
	source: AssociationSource;
	locations: MyAssociationLocationEntity[];
	representatives: AssociationRepresentativeEntity[];
	isPublic: boolean;
	metaData: MyAssociationDetailMetaDataEntity;
}

export interface AssociationTargetAudienceEntity {
	minAge?: number;
	maxAge?: number;
}
