import { ChangeDetectionStrategy, Component, forwardRef, HostBinding } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormAccessor, FormAccessor, NgxValidators } from '@studiohyperdrive/ngx-forms';

import { I18nKeys } from '../../../i18n';

import { SendCopyForm, SendCopyFormGroup } from './send-copy.types';

@Component({
	selector: 'vloket-send-copy',
	templateUrl: './send-copy.component.html',
	styleUrls: ['./send-copy.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SendCopyComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SendCopyComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => SendCopyComponent)
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendCopyComponent extends FormAccessor<SendCopyForm, FormGroup<SendCopyFormGroup>> {
	@HostBinding('class.c-send-copy') private readonly hasRootClass: boolean = true;

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	// Denis: overwrite the default updateAllValueAndValidity of the FormAccessor to make sure it does emit an event.
	public updateAllValueAndValidity(): void {
		if (!this.form) {
			return;
		}

		// Denis: run updateValueAndValidity for all internal controls
		Object.values(this.form.controls).forEach((control: FormControl) => {
			// Denis: set emitEvent to false on the single controls to avoid multiple events being emitted.
			control.updateValueAndValidity({ emitEvent: false });
		});
		// Denis: run updateValueAndValidity once on the form itself to emit a single event
		this.form.updateValueAndValidity();
	}

	public initForm(): FormGroup<SendCopyFormGroup> {
		return new FormGroup(
			{
				isActive: new FormControl<boolean>(false),
				recipient: new FormControl<string>('', [NgxValidators.extendedEmail])
			},
			[NgxValidators.dependedRequired(['recipient'], 'isActive', (isActive: boolean): boolean => isActive)]
		);
	}
}
