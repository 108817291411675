import {
	CallToActionEntity,
	CallToActionEnum,
	CallToActionResult,
	CallToActionResultEnum,
	IPDCAddressCollectionEntity,
	IPDCContactCollectionEntityResult,
	IPDCContactEntityResult,
	IPDCProductEntity,
	IPDCProductEntityResult,
	IPDCTextCollectionEntity,
	IPDCTextCollectionEntityResult,
	IPDCTextEntityResult,
	ProductEntity,
	ProductEntityResult,
	ProductPartnerEntity,
	ProductPartnerResult
} from '@cjm/shared/types';
import { parseLocationString } from '@cjm/shared/utils';

const CallToActionEnumMap = {
	[CallToActionResultEnum.AanvraagStarten]: CallToActionEnum.START_CASE,
	[CallToActionResultEnum.MeerInfoBekijken]: CallToActionEnum.MORE_INFO,
	[CallToActionResultEnum.ToegangVragen]: CallToActionEnum.REQUEST_ACCESS,
	[CallToActionResultEnum.Aanmelden]: CallToActionEnum.LOGIN
};

function convertToProductCTA(ctaResult: CallToActionResult): CallToActionEntity {
	return {
		name: ctaResult.naam,
		annotations: ctaResult.annotaties,
		link: ctaResult.actieLink,
		action: CallToActionEnumMap[ctaResult.doelVanDeActie]
	};
}

function convertProductPartnerResultToProductPartner(partnerResult: ProductPartnerResult[]): ProductPartnerEntity[] {
	const items = Array.isArray(partnerResult) ? partnerResult : [];

	return items.map((partner) => {
		return {
			name: partner.naam,
			url: partner.url
		};
	});
}

function convertIPDCAddressCollectionResultToIPDCTextCollection(
	addressCollectionResult: IPDCContactCollectionEntityResult
): IPDCAddressCollectionEntity {
	if (!addressCollectionResult) {
		return;
	}

	const elements = Array.isArray(addressCollectionResult?.elementen) ? addressCollectionResult?.elementen : [];

	return {
		'@type': addressCollectionResult['@type'],
		elements: elements.map((contactInfo: IPDCContactEntityResult) => {
			// Denis: parse the address string
			const address = parseLocationString(
				contactInfo.adres
					? {
							street: contactInfo.adres.straat,
							number: contactInfo.adres.nummer,
							zipcode: contactInfo.adres.postcode,
							municipality: contactInfo.adres.gemeente,
							box: contactInfo.adres.bus
					  }
					: null
			);

			return {
				'@type': contactInfo['@type'],
				address,
				email: contactInfo.emailadres,
				hours: contactInfo.openingsuren,
				phone: contactInfo.telefoonnummer,
				website: {
					url: contactInfo.website,
					text: contactInfo.website
				}
			};
		})
	};
}

function convertIPDCTextCollectionResultToIPDCTextCollection(
	textCollectionResult: IPDCTextCollectionEntityResult
): IPDCTextCollectionEntity {
	if (!textCollectionResult) {
		return;
	}

	const elements = Array.isArray(textCollectionResult?.elementen) ? textCollectionResult?.elementen : [];

	return {
		'@type': textCollectionResult['@type'],
		elements: elements.map((text: IPDCTextEntityResult) => ({
			'@type': text['@type'],
			text: text.tekst
		}))
	};
}

function convertIPDCProductResultToIPDCPRoduct(productResult: IPDCProductEntityResult): IPDCProductEntity {
	if (!productResult) {
		return;
	}

	return {
		'@type': productResult['@type'],
		productId: productResult.productID,
		regulations: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.regelgeving),
		exceptions: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.uitzonderingen),
		terms: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.voorwaarden),
		procedures: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.procedures),
		costs: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.kosten),
		financialBenifits: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.financieleVoordelen),
		contactInfo: convertIPDCAddressCollectionResultToIPDCTextCollection(productResult.contactgegevens)
	};
}

export function convertProductResultToProduct(
	productResult: Partial<ProductEntityResult>,
	annotations?: string[]
): ProductEntity {
	return {
		name: productResult.naam,
		partners: convertProductPartnerResultToProductPartner([...(productResult.partners || [])]),
		link: productResult.meerInfoLink,
		description: productResult.omschrijving,
		id: productResult.id,
		actions: [...(productResult.gebruikersacties?.elementen || [])].map((cta) => convertToProductCTA(cta)),
		...(annotations ? { annotations } : {}),
		infoLinks: (productResult.infoLinks?.elementen || []).map((item) => {
			return { name: item.naam, link: item.url };
		}),
		tags: (productResult.labels?.elementen || []).map((item) => {
			return {
				name: item.naam,
				description: item.omschrijving
			};
		}),
		type: productResult.type,
		ipdcProduct: convertIPDCProductResultToIPDCPRoduct(productResult.ipdcProduct),
		metaData: {
			lastModificationDate: productResult.metadata?.laatsteWijzigingsdatum
		}
	};
}
