import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';

export const sharedCompanyI18nKeys = {
	...sharedI18nKeys,
	Companies: {
		Info: {
			Title: marker('companies.info.title'),
			CheckYourData: marker('companies.info.check-your-data'),
			Form: marker('companies.info.form'),
			LegalStatus: marker('companies.info.legal-status'),
			StartDate: marker('companies.info.start-date'),
			RegisteredOffice: marker('companies.info.registered-office'),
			RegisteredName: marker('companies.info.registered-name'),
			ShortName: marker('companies.info.short-name'),
			Number: marker('companies.info.number'),
			CompanyName: marker('companies.info.company-name')
		}
	}
};
