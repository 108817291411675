import { createBaseStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';
import { AssociationEntity } from '@cjm/v-loket/shared';

import { AssociationStoreSlice } from './association.store.types';

export const {
	actions: associationActions,
	reducers: associationReducers,
	selectors: associationSelectors
} = createStoreAssets<AssociationStoreSlice>(StoreSlice.Association, [
	{
		subSlice: 'detail',
		generator: createBaseStoreAssets<AssociationEntity>
	}
]);
