import { FormControl, FormRecord } from '@angular/forms';

export interface IEaRegistrationDataForm {
	associationType: string;
	associationCallname: string;
	mainActivities: Record<string, boolean>;
}

export interface IEaRegistrationDataFormGroup {
	associationType: FormControl<string>;
	associationCallname: FormControl<string>;
	mainActivities: FormRecord<FormControl<boolean>>;
}
