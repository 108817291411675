// interfaces for POST /vereniging
import { MyAssociationDetailProgressEntity, MyAssociationDetailProgressResultEntity } from '@cjm/v-loket/shared';

import { IEARegistration, IRegistration } from '../../ui';

export type IPostRegistrationPayload = IRegistration;
export type IEaRegistrationPostPayload = IEARegistration;

// POST PAYLOAD INTERFACES
export interface IPostRegistrationPayloadNL {
	naam: string;
	korteNaam?: string;
	korteBeschrijving?: string;
	startDatum?: string; // yyyy-MM-dd
	kboNummer?: string;
	correspondentieadres: {
		straat: string;
		nummer: string;
		bus: string;
		postcode: string | number;
		gemeente: string;
		land: string;
	};
	hoofdvertegenwoordigers: {
		emailadres: string;
		telefoonnummer: string;
		inschrijvenOpNieuwsbrief: boolean;
	}[];
	uitnodigingen: {
		voornaam: string;
		achternaam: string;
		emailadres: string;
		telefoonnummer: string;
		insz: string;
		kopieEmailadres: string;
	}[];
	hoofdactiviteiten: string[];
}

export interface EARegistirationPOSTPayload {
	roepnaam?: string;
	korteBeschrijving?: string;
	hoofdactiviteiten: string[];
}

// RESPONSE INTERFACES
export interface PostRegistrationNotificationResponseEnity {
	emailNaarUitgenodigdeVerzonden: boolean;
	emailkopieNaarUitnodigerVerzonden: boolean;
}

export interface PostRegistrationResponseEntity {
	'@type': 'Verenigingscreatie';
	'@id': string;
	verenigingsnummer: string;
	metadata?: {
		compleetheid: MyAssociationDetailProgressResultEntity;
		notificaties: PostRegistrationNotificationResponseEnity[];
	};
}

export interface PostRegistrationEntity {
	'@type': 'Verenigingscreatie';
	'@id': string;
	associationNumber: string;
	metaData: {
		progress: MyAssociationDetailProgressEntity;
	};
}

// DUPLICATE CHECK INTERFACES
export interface IPostRegistrationDuplicateAssociation {
	'@type': 'VerenigingDuplicaat';
	'@id': string;
	verenigingsnummer: string;
	type: {
		'@type': 'Verenigingstype';
		code: string;
		beschrijving: string;
	};
	namen: {
		'@type': 'Namen';
		volledig: string;
		afgekort: string;
	};
	locaties: {
		'@type': 'Verenigingsadres';
		soort: string;
		naam: string;
		adres: string;
		postcode: string;
		gemeente: string;
		isPrimair: boolean;
	}[];
	hoofdactiviteiten: {
		'@type': 'Hoofdactiviteit';
		naam: string;
	}[];
}

export interface IPostRegistrationConflictResponse {
	'@type': 'VerenigingscreatieConflict';
	bevestigingstoken: string;
	verenigingen: IPostRegistrationDuplicateAssociation[];
}
