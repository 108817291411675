import { BaseStoreAssets } from '@studiohyperdrive/ngx-store';

import { AssociationDetailEntity, RepresentativeStatus } from '@cjm/v-loket/shared';

export interface RepresentativeInvitedEntity {
	'@type': 'Uitgenodigde';
	insz: string;
	firstname: string;
	lastname: string;
	email: string;
}

export interface RepresentativeInviterEntity {
	'@type': 'Uitnodiger';
	id: number;
}

export interface RepresentativeInvitationEntity {
	'@type': 'Uitnodiging';
	'@id': string;
	id: string;
	vCode: string;
	associationDetail: AssociationDetailEntity;
	message: string;
	status: RepresentativeStatus;
	timestamp: string;
	inviter: RepresentativeInviterEntity;
	invited: RepresentativeInvitedEntity;
}

export type InvitationStoreSlice = {
	detail: BaseStoreAssets<RepresentativeInvitationEntity>;
};
