import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CallToActionEntity } from '@cjm/shared/types';
import { ButtonClasses, VloketLink } from '@cjm/shared/ui/common';

import { ProductDetailPartnerInfo } from '../product-detail-info-list/product-detail-info-list.types';

@Component({
	selector: 'vloket-product-detail-sidebar',
	templateUrl: './product-detail-sidebar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetaiSidebarComponent {
	@Input() public partnerInfo: ProductDetailPartnerInfo | ProductDetailPartnerInfo[];
	@Input() public actions: CallToActionEntity[] = [];
	@Input() public referralLink: VloketLink;
	@Input() public caseDetailsLink: VloketLink;

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
}
