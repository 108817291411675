import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { AdvicePageResultEntity } from '@cjm/v-loket/repositories';

@Injectable()
export class AdvicePageApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * getAdvicePage
	 *
	 * The getAdvicePage method gets the detail page from the API by id.
	 *
	 * @param id
	 * @returns Observable:AdvicePageResultEntity
	 */
	public getAdvicePage(id: string): Observable<AdvicePageResultEntity> {
		return this.httpClient.get<AdvicePageResultEntity>(VLoketEndpoints.ContentPages.SinglePage(id));
	}
}
