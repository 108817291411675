<cjm-layout-container
	*ngIf="associationData$ | async as associationData"
	class="c-vloket-success__container"
	layoutType="full-width"
	asidePosition="right"
	alignItems="end"
	[preventSpacing]="['top', 'right']"
>
	<ng-container main>
		<h1 class="c-vloket-header c-vloket-header--h1">
			<span
				class="c-vloket-text-marking c-vloket-text-marking--line-through"
				[innerHTML]="
					(associationData.type === associationTypes.FV
						? i18nKeys.Registration.Success.Title.Association
						: i18nKeys.Registration.Success.Title.KBOAssociation
					) | translate
				"
			>
			</span>
		</h1>

		<div
			*ngIf="associationData.type === associationTypes.FV"
			class="c-vloket-text--lg u-margin-bottom-xl"
			[innerHTML]="i18nKeys.Registration.Success.BodyHTML | translate"
		></div>

		<cjm-alert
			[alertMessage]="i18nKeys.Registration.Success.DelayNotice | translate"
			[alertType]="alertType.Info"
			[isClosable]="false"
		/>

		<vloket-association-card
			classes="u-margin-top-xl u-margin-bottom-lg"
			[cardInfo]="associationData"
		></vloket-association-card>

		<vloket-progress-bar
			*ngIf="myAssociationPercentage$ | async as percentage"
			[title]="
				i18nKeys.Registration.Success.ProgressBarTitle
					| translate : { number: percentage, association: associationData.name }
			"
			[progress]="percentage"
		/>

		<vloket-icon-list *ngIf="myAssociationProgress$ | async as tasks" class="u-margin-top-lg" [items]="tasks" />

		<div
			*ngIf="
				associationData.type === associationTypes.FV || associationData.type === associationTypes.AFD;
				else nonFVActions
			"
			class="u-margin-top-xl c-vloket-success__cta-buttons"
		>
			<cjm-button
				cjmUserEIDLogin
				[withWarning]="true"
				[warningLabels]="authRedirectFVDataWarning"
				[callback]="associationDetailsCallbackUrl"
				[capHint]="acmTargetGroups.VER"
				[codeHint]="associationData.id"
				[classes]="buttonClasses.RegularFullWidth + ' ' + 'c-button--blue'"
				[title]="i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Title | translate"
			>
				{{ i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Text | translate }}
			</cjm-button>
			<cjm-button
				cjmUserEIDLogin
				[withWarning]="true"
				[warningLabels]="authRedirectFVProductsWarning"
				[callback]="ProductsCallbackUrl"
				[capHint]="acmTargetGroups.VER"
				[codeHint]="associationData.id"
				[classes]="buttonClasses.OutlineFullWidth + ' ' + 'c-button--blue-outline'"
				[title]="i18nKeys.Registration.Success.CtaButtons.ToProducts.Title | translate"
			>
				{{ i18nKeys.Registration.Success.CtaButtons.ToProducts.Text | translate }}
			</cjm-button>
		</div>
	</ng-container>
</cjm-layout-container>
<div class="c-vloket-success__visual"></div>

<ng-template #nonFVActions>
	<div class="u-margin-top-xl c-vloket-success__cta-buttons">
		<cjm-button
			cjmUserEIDIdentitySwitch
			[withWarning]="true"
			[warningLabels]="authRedirectKBODataWarning"
			[callback]="associationDetailsCallbackUrl"
			[classes]="buttonClasses.RegularFullWidth + ' ' + 'c-button--blue'"
			[title]="i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Title | translate"
		>
			{{ i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Text | translate }}
		</cjm-button>
		<cjm-button
			cjmUserEIDIdentitySwitch
			[withWarning]="true"
			[warningLabels]="authRedirectKBOProductsWarning"
			[callback]="ProductsCallbackUrl"
			[classes]="buttonClasses.OutlineFullWidth + ' ' + 'c-button--blue-outline'"
			[title]="i18nKeys.Registration.Success.CtaButtons.ToProducts.Title | translate"
		>
			{{ i18nKeys.Registration.Success.CtaButtons.ToProducts.Text | translate }}
		</cjm-button>
	</div>
</ng-template>
