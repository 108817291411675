import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { tap } from 'rxjs/operators';

import { UserService } from '@cjm/shared/user';

export const HasCompanyGuard: CanActivateFn = (snapshot: ActivatedRouteSnapshot): ObservableBoolean => {
	// Iben: Fetch all injectables
	const userService: UserService = inject(UserService);
	const router: Router = inject(Router);
	const route: ActivatedRoute = inject(ActivatedRoute);

	return userService.isCompany$.pipe(
		tap((hasCompany) => {
			if (!hasCompany) {
				// Iben: Navigate to the provided redirectLink in case the user has no company
				router.navigate([snapshot.data.redirectTo], { relativeTo: route });
			}
		})
	);
};
