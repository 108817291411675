<cjm-backdrop>
	<div class="c-modal" [ngClass]="{ 'c-modal--small': small }">
		<div *ngIf="small && !hideCloseButton" class="c-modal__close">
			<button class="c-button-secondary icon-only" (click)="handleClickClose()">
				<span class="icon-close"></span>
			</button>
		</div>
		<div *ngIf="!hideHeader" class="c-modal__header">
			<h3 class="c-modal__title">{{ title }}</h3>

			<button *ngIf="!small" class="c-button-secondary icon-only" (click)="handleClickClose()">
				<span class="icon-close"></span>
			</button>
		</div>

		<div class="c-modal__body">
			<ng-content select="[modalBody]"></ng-content>
		</div>

		<div *ngIf="!hideFooter" class="c-modal__footer">
			<ng-content select="[modalFooter]"></ng-content>
		</div>
	</div>
</cjm-backdrop>
