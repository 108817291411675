import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'cjm-results',
	templateUrl: './results.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsComponent {
	/**
	 * Number of results
	 */
	@Input() public amount: number = 0;
	/**
	 * Number of results
	 */
	@Input() public subAmount: number;
	/**
	 * Whether to should show a "No results" label in case the amount is 0; default true
	 */
	@Input() public showNoResultsLabel: boolean = true;
	/**
	 * Whether to show the subtotal and total, or just the subtotal
	 */
	@Input() public condensed: boolean = false;

	public i18nKey = I18nKeys.Results;
}
