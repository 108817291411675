import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';

@Injectable({
	providedIn: 'root'
})
export class CJMCookieService {
	constructor(private readonly cookieService: CookieService) {}

	/**
	 * Put an object or string as a cookie
	 *
	 * @param key - Key of the cookie
	 * @param value - Value of the cookie
	 */
	public put(key: string, value: string, options?: CookieOptions) {
		this.cookieService.put(key, value, options);
	}

	/**
	 * Fetch the value of a cookie based on the provided key
	 *
	 * @param key - Key of the cookie
	 */
	public get(key: string): string {
		return this.cookieService.get(key);
	}

	/**
	 * Remove a cookie
	 *
	 * @param key - The key of the cookie
	 */
	public remove(key: string) {
		this.cookieService.remove(key);
	}
}
