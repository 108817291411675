import { DuplicateCheckPageComponent } from './duplicate-check/duplicate-check.component';
import { RedirectAuthenticationComponent } from './redirect-authentication/redirect-authentication.component';
import { RedirectRegistrationComponent } from './redirect-registration/redirect-registration.component';
import { RegisterEaPageComponent } from './register-ea/register-ea.component';
import { RegisterPageComponent } from './register/register.component';
import { StartPageComponent } from './start/start.component';
import { SuccessPageComponent } from './success/success.component';

export const RegistrationPages = [
	DuplicateCheckPageComponent,
	RegisterPageComponent,
	StartPageComponent,
	SuccessPageComponent,
	RegisterEaPageComponent,
	RedirectAuthenticationComponent,
	RedirectRegistrationComponent
];

export * from './duplicate-check/duplicate-check.component';
export * from './register/register.component';
export * from './register-ea/register-ea.component';
export * from './start/start.component';
export * from './success/success.component';
export * from './redirect-authentication/redirect-authentication.component';
export * from './redirect-registration/redirect-registration.component';
