import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseFormAccessor, FormAccessor, NgxValidators } from '@studiohyperdrive/ngx-forms';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs';

import { INSZValidator, PhoneValidator, URLValidator, PhoneValidatorOptions } from '@cjm/shared/ui/forms';

import { I18nKeys } from '../../../i18n';

import { RepresentativeFormMode, RepresentativeRoleType } from './representative-form.const';
import { IRepresentativeForm, IRepresentativeFormGroup } from './representative-form.types';

@Component({
	selector: 'vloket-representative-form',
	templateUrl: './representative-form.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RepresentativeFormComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => RepresentativeFormComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => RepresentativeFormComponent)
		}
	]
})
export class RepresentativeFormComponent
	extends FormAccessor<IRepresentativeForm, FormGroup<IRepresentativeFormGroup>>
	implements OnInit
{
	/**
	 * The showTitle prop can be used to hide the title in the creation mode.
	 *
	 * Defaults to true
	 */
	@Input() public showTitle: boolean = true;
	/**
	 * The titleClass prop can be used to change the title class to match the surrounding form.
	 *
	 * Defaults to `c-vloket-header c-vloket-header--h3`
	 */
	@Input() public titleClass: string = 'c-vloket-header c-vloket-header--h3';
	/**
	 * The titleOverwrite prop can be used to provide a custom title value.
	 * This will overwrite the default "representative-title + count" value.
	 */
	@Input() public titleOverwrite: string;
	/**
	 * mode
	 *
	 * The mode property will allow the parent to set which template to use for the form.
	 *
	 * Defaults to 'create'
	 */
	@Input() public mode: `${RepresentativeFormMode}` = RepresentativeFormMode.Create;
	/**
	 * mode
	 *
	 * The mailOnly property will hide all non-email fields on the create view.
	 *
	 * Defaults to false
	 */
	@Input() public mailOnly: boolean = false;
	/**
	/**
	 * isKBO
	 *
	 * The isKBO property will remove requirements and set a different set of validators
	 * and disabled states if representative data is from KBO.
	 */
	@Input() public isKBO: boolean = false;
	/**
	 * The index Input prop can be used to set the index of the current representative.
	 */
	@Input() public index: number = 0;

	public roleSelectControl: FormControl<RepresentativeRoleType | string>;
	public readonly representativeRoleTypes: string[] = Object.values(RepresentativeRoleType);
	public readonly representativeRoleType: typeof RepresentativeRoleType = RepresentativeRoleType;
	public readonly representativeFormMode: typeof RepresentativeFormMode = RepresentativeFormMode;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	public ngOnInit(): void {
		this.roleSelectControl = new FormControl<RepresentativeRoleType | string>(
			{
				value: 'Empty',
				disabled: this.isKBO
			},
			{
				validators: [...(this.mode !== RepresentativeFormMode.Edit && !this.isKBO ? [Validators.required] : [])]
			}
		);

		this.roleSelectControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				tap((value: RepresentativeRoleType | 'Empty') => {
					if (value === 'Empty') {
						this.form.get('representativeRoleField').patchValue('');
						return;
					}
					if (value === this.representativeRoleType.Other) {
						this.form.get('representativeRoleField').enable();

						if (
							Object.values(this.representativeRoleType).includes(
								this.form.get('representativeRoleField').getRawValue()
							)
						) {
							this.form.get('representativeRoleField').reset();
						}

						return;
					}

					this.form.get('representativeRoleField').disable();
					this.form.get('representativeRoleField').patchValue(value);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		super.ngOnInit();
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(): FormGroup {
		return this.buildBasicFormGroup();
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public writeValue(value: IRepresentativeForm): void {
		super.writeValue(value);

		// Denis: if the representativeRolField is falsy (empty or undefined),
		// set the roleSelectControl it to the Empty state.
		if (!value?.representativeRoleField) {
			this.roleSelectControl.patchValue('Empty', { emitEvent: false });

			return;
		}

		// Denis: if the representativeRolField has a value that can be found in the RepresentativeRoleType enum,
		// set the roleSelectControl to the selected value.
		if (
			Object.values(this.representativeRoleType).includes(value.representativeRoleField as RepresentativeRoleType)
		) {
			this.roleSelectControl.patchValue(value.representativeRoleField, { emitEvent: false });

			return;
		}

		// Denis: if the representativeRolField has another value,
		// set the roleSelectControl to the Other value to show the role field.
		this.roleSelectControl.patchValue(this.representativeRoleType.Other, { emitEvent: false });
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public onChangeMapper(): IRepresentativeForm {
		// Denis: Because some fields might be disabled, we need the rawValue.
		return this.form.getRawValue();
	}

	// Iben: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	protected emitValueWhenDisableFieldsUsingInput(): boolean {
		// Iben: We don't want to emit these changes so that there's no dirty state given when we do this on init
		return false;
	}

	/**
	 * buildBasicFormGroup
	 *
	 * The buildBasicFormGroup method will set up a new for with all the basic fields.
	 *
	 * @private
	 * @returns FormGroup
	 */
	private buildBasicFormGroup(): FormGroup {
		const form = new FormGroup({
			objectId: new FormControl<string>(null),
			source: new FormControl<string>(null),
			isPrimary: new FormControl<boolean>(false),
			/**
			 * Disabled by-default (not from parent overwrites):
			 * - edit mode
			 *
			 * Required:
			 * - always
			 */
			representativeFirstnameField: new FormControl<string>(
				{
					value: '',
					disabled: this.mode === RepresentativeFormMode.Edit
				},
				{
					validators: [Validators.required],
					updateOn: 'blur'
				}
			),
			/**
			 * Disabled by-default (not from parent overwrites):
			 * - edit mode
			 *
			 * Required:
			 * - always
			 */
			representativeSurnameField: new FormControl<string>(
				{
					value: '',
					disabled: this.mode === RepresentativeFormMode.Edit
				},
				{
					validators: [Validators.required],
					updateOn: 'blur'
				}
			),
			/**
			 * Disabled by-default (not from parent overwrites):
			 * - on KBOs
			 *
			 * Optional field
			 */
			representativeCallnameField: new FormControl<string>(
				{
					value: '',
					disabled: this.isKBO
				},
				{
					updateOn: 'blur'
				}
			),
			/**
			 * Disabled by-default (not from parent overwrites):
			 * - on KBOs
			 *
			 * Optional field
			 */
			representativeRoleField: new FormControl<RepresentativeRoleType | string>(
				{
					value: '',
					disabled: this.isKBO
				},
				{
					updateOn: 'blur'
				}
			),
			/**
			 * Required:
			 * - for non-KBOs
			 * - in invite mode
			 * - in create mode
			 */
			representativeEmailField: new FormControl<string>('', {
				validators: [
					...(!this.isKBO && this.mode !== RepresentativeFormMode.Edit ? [Validators.required] : []),
					NgxValidators.extendedEmail
				],
				updateOn: 'blur'
			}),
			/**
			 * Required:
			 * - for non-KBOs
			 * - in invite mode
			 * - in create mode
			 */
			representativePhoneField: new FormControl<string>('', {
				validators: [
					...(this.mode === RepresentativeFormMode.Create && !this.mailOnly ? [Validators.required] : []),
					PhoneValidator()
				],
				updateOn: 'blur'
			}),
			/**
			 * Optional field
			 */
			representativeMobilePhoneField: new FormControl<string>('', {
				validators: [PhoneValidator(PhoneValidatorOptions.MOBILE)],
				updateOn: 'blur'
			}),
			/**
			 * Optional field
			 */
			representativeSocialMedia: new FormControl<string>('', {
				validators: [URLValidator()],
				updateOn: 'blur'
			}),
			/**
			 * Optional field
			 */
			representativeRRNField: new FormControl<string>('', {
				validators: [
					...(this.mode !== RepresentativeFormMode.Edit ? [Validators.required] : []),
					INSZValidator
				],
				updateOn: 'blur'
			}),
			/**
			 * Required:
			 * - in invite mode
			 * - in request mode
			 *
			 * Not available in other modes.
			 */
			representativeInviteMessage: new FormControl<string>('', {
				validators: [
					...(this.mode === RepresentativeFormMode.Invite || this.mode === RepresentativeFormMode.Request
						? [Validators.required]
						: [])
				],
				updateOn: 'blur'
			})
		});

		return form;
	}
}
