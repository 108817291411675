import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingPageComponent } from './pages';

@NgModule({
	// Modules
	imports: [CommonModule, NgOptimizedImage],
	// Components
	declarations: [LoadingPageComponent],
	exports: [LoadingPageComponent]
})

// Exports
// -------------------------------------------------------------------------- /
export class LoadingUiModule {}
