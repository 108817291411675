import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';

import { Language } from '@cjm/shared/i18n';
import { environment } from 'environments';

import { BrowserService } from './browser.service';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	// Dependency injection
	constructor(private _router: Router, private readonly browserService: BrowserService) {
		this.lang = this.DEFAULT_LANGUAGE;

		// Create a sessionUUID for identifying anonymous users.
		this.uuid = this.getSessionItem('uuid');

		if (!this.uuid) {
			this.uuid = UUID.UUID();
			this.setSessionItem('uuid', this.uuid);
		}
	}

	private STORAGE_PREFIX = 'cjm';
	private ALLOWED_LANGUAGES = ['nl'];
	private DEFAULT_LANGUAGE = 'nl';

	private lang: string;
	private uuid: string;

	public setSessionItem = (key: string, value) => {
		if (sessionStorage) {
			try {
				sessionStorage.setItem(this.STORAGE_PREFIX + '.' + key, value);
			} catch (e) {
				if (e.code === 22) {
					sessionStorage.clear();
				}
			}
		}
	};

	public getSessionItem = <ValueType = string>(key: string, parseFn?: (arg: string) => ValueType) => {
		if (sessionStorage) {
			try {
				const value = sessionStorage.getItem(this.STORAGE_PREFIX + '.' + key);

				return parseFn ? parseFn(value) : value;
			} catch (e) {
				if (e.code === 22) {
					sessionStorage.clear();
				}
			}
		}
	};

	private _isAllowedLanguage = (lang: string) => {
		return ~this.ALLOWED_LANGUAGES.indexOf(lang);
	};

	// Set the language
	set language(lang: Language) {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Update the html lang
			browserDocument.documentElement.lang = lang;
		});

		// Save the language in the Session
		this.setSessionItem('language', lang);
	}

	// Get the current language
	get language(): Language {
		// Get the language from the session
		let language = this.getSessionItem('language');

		// No session language is set,
		// fetch the language from the document
		if (!language) {
			this.browserService.runInBrowser(({ browserDocument }) => {
				language = browserDocument.documentElement.lang;
			});
		}

		return language as Language;
	}

	// User UUID
	// ---
	get userUUID() {
		return this.uuid;
	}

	// Get the local domain
	getLocalDomain = () => {
		return environment.domain + '/';
	};

	// GeolocationService
	getLocation = () => {
		return new Promise(function (resolve, reject) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve(position);
					},
					() => {
						reject();
					}
				);
			} else {
				reject();
			}
		});
	};

	getACMIDMDomain = () => {
		return environment.acmidm.protocol + '://' + environment.acmidm.hostname;
	};

	get api() {
		return environment.api.fullPath;
	}
}
