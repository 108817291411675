import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';

import { reducers } from './company.store';
import { CompanyApiService, CompanyService } from './services';

@NgModule({
	providers: [CompanyService, CompanyApiService],
	imports: [StoreModule.forFeature(StoreSlice.Company, reducers)]
})
export class CompanyDataModule {}
