import { ChangeDetectionStrategy, Component, forwardRef, HostBinding } from '@angular/core';
import { FormControl, FormGroup, FormRecord, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormAccessor, DataFormAccessor } from '@studiohyperdrive/ngx-forms';
import { takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FacetEntity, NonRefinableFacetSubjectEntity, RefinableFacetSubjectEntity } from '@cjm/shared/types';
import { ButtonClasses } from '@cjm/shared/ui/common';

import { I18nKeys } from '../../../i18n';

import { FilterBarForm, FilterBarFormGroup, RefineableFacetValue } from './filter-bar.types';

@Component({
	selector: 'vloket-filter-bar',
	templateUrl: './filter-bar.component.html',
	styleUrls: ['./filter-bar.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterBarComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => FilterBarComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => FilterBarComponent)
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarComponent extends DataFormAccessor<FacetEntity[], FilterBarForm, FilterBarFormGroup> {
	@HostBinding('class.c-filter-bar') private readonly hasRootClass: boolean = true;

	public allFacets: FacetEntity[] = [];
	public refinableFacetState: Record<string, boolean> = {};
	public refinableFacetControls: Record<string, RefineableFacetValue> = {};
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(data: FacetEntity[]): FilterBarFormGroup {
		this.allFacets = !Array.isArray(data) ? [] : data;
		this.refinableFacetState = this.parseRefinableFacetState(this.allFacets);

		this.setUpRefinableControls(this.allFacets);

		const controls = this.allFacets.reduce((acc, curr: FacetEntity) => {
			const results: { [key: string]: FormControl<boolean> } = Object.assign(
				{},
				...(!curr.isRefinable
					? curr.items.map((item: NonRefinableFacetSubjectEntity) => ({
							[item.id]: new FormControl<boolean>({
								value: false,
								disabled: !item.checked && item.amount === 0
							})
					  }))
					: curr.items
							.map((item: RefinableFacetSubjectEntity) =>
								item.refinements.map((refinement) => ({
									[refinement.id]: new FormControl<boolean>(false)
								}))
							)
							.flat())
			);

			return {
				...acc,
				[curr.id]: new FormRecord(results)
			};
		}, {});

		return new FormGroup(controls);
	}

	/**
	 * toggleRefinableFacet
	 *
	 * The toggleRefinableFacet method will toggle the corresponding value to the filter id
	 *
	 * @param id
	 */
	public toggleRefinableFacet(id: string): void {
		this.refinableFacetState[id] = !this.refinableFacetState[id];
	}

	/**
	 * toggleAllRefinements
	 *
	 * The toggleAllRefinements will find all child ids for a refinable facet
	 * and select/deselect them all.
	 *
	 * @param value
	 * @param parentId
	 */
	public toggleAllRefinements(value: boolean, parentId: string): void {
		const facetControl = this.refinableFacetControls[parentId];

		const values = facetControl.children.reduce(
			(acc, curr) => ({
				...acc,
				[curr]: value
			}),
			{}
		);

		this.form.patchValue({
			[facetControl.facetId]: values
		});
	}

	/**
	 * setUpRefinableControls
	 *
	 * The setUpRefinableControls method map and loop over all refinable facet controls
	 * and set up subscriptions to select/deselect all children.
	 */
	private setUpRefinableControls(facets: FacetEntity[]): void {
		this.refinableFacetControls = facets.reduce(
			(acc: { [key: string]: RefineableFacetValue }, curr: FacetEntity) =>
				!curr.isRefinable
					? acc
					: {
							...acc,
							...Object.assign(
								{},
								...curr.items.map((item: RefinableFacetSubjectEntity) => {
									// Denis: if the current parent has no refinements, it should be disabled.
									const disabled = item.refinements.length === 0;
									// Denis: if it has refinements and all of them are checked, it should be checked.
									const currentValue =
										!disabled &&
										item.refinements.filter(
											(refinement: NonRefinableFacetSubjectEntity) => !refinement.checked
										).length === 0;
									// Denis: create a new control.
									const control = new FormControl<boolean>(currentValue);

									// Denis: disable the control if needed.
									if (disabled) {
										control.disable();
									}

									// Denis: an object is returned with the control, the children and the facetId.
									// This is needed in the template.
									return {
										[item.id]: {
											control,
											children: item.refinements.map((refinement) => refinement.id),
											facetId: curr.id
										}
									};
								})
							)
					  },
			{}
		);

		Object.keys(this.refinableFacetControls).forEach((key: string) => {
			this.refinableFacetControls[key].control.valueChanges
				.pipe(
					tap((value: boolean) => this.toggleAllRefinements(value, key)),
					takeUntil(this.destroy$)
				)
				.subscribe();
		});
	}

	/**
	 * parseRefinableFacetState
	 *
	 * The parseRefinableFacetState method will check for refinable facets and map them
	 * to keep track of which ones are unfolded.
	 *
	 * @param facets
	 * @returns { [id: string]: boolean }
	 */
	private parseRefinableFacetState(facets: FacetEntity[] = []): Record<string, boolean> {
		return facets.reduce(
			(acc, curr) =>
				!curr.isRefinable
					? acc
					: {
							...acc,
							...Object.assign(
								{},
								...curr.items.map((item: RefinableFacetSubjectEntity) => ({
									[item.id]: false
								}))
							)
					  },
			{}
		);
	}
}
