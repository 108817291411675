<ul *ngIf="max > 1" class="c-paging c-paging--center">
	<!--
		The pager will be layout as following:
		<- min ... x x x ... max ->
	-->

	<ng-container [ngTemplateOutlet]="prevArrowTmpl" />

	<ng-container *ngIf="showSkipToFirst" [ngTemplateOutlet]="numberTmpl" [ngTemplateOutletContext]="{ index: min }" />

	<ng-container *ngIf="showSkipToFirstEllipsis" [ngTemplateOutlet]="ellipsisTmpl" />

	<ng-container *ngFor="let index of range" [ngTemplateOutlet]="numberTmpl" [ngTemplateOutletContext]="{ index }" />

	<ng-container *ngIf="showSkipToLastEllipsis" [ngTemplateOutlet]="ellipsisTmpl" />

	<ng-container *ngIf="showSkipToLast" [ngTemplateOutlet]="numberTmpl" [ngTemplateOutletContext]="{ index: max }" />

	<ng-container [ngTemplateOutlet]="nextArrowTmpl" />
</ul>

<ng-template #prevArrowTmpl>
	<li
		class="c-paging__arrow c-paging__arrow--left"
		role="button"
		[class.c-paging__arrow--disabled]="current === min"
		(click)="prevIndex()"
	>
		<span class="icon-arrow-big-right"></span>
	</li>
</ng-template>

<ng-template #ellipsisTmpl>
	<li class="c-paging__number">...</li>
</ng-template>

<ng-template #numberTmpl let-index="index">
	<li class="c-paging__number" [class.is-active]="index === current" (click)="goToIndex(index)">
		{{ index }}
	</li>
</ng-template>

<ng-template #nextArrowTmpl>
	<li
		class="c-paging__arrow c-paging__arrow--right"
		role="button"
		[class.c-paging__arrow--disabled]="current === max"
		(click)="nextIndex()"
	>
		<span class="icon-arrow-big-right"></span>
	</li>
</ng-template>
