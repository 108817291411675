import { RouterModule, Routes } from '@angular/router';
import { TranslationLoaderGuard, TranslationLoaderResolver } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';

import { AdviceDetailPageResolver } from '../data';

import { AdviceDetailPageComponent, AdviceOverviewPageComponent } from './pages';

const routes: Routes = [
	{
		path: VLoketAppRoutePaths.AdviceDetail,
		component: AdviceDetailPageComponent,
		canActivate: [TranslationLoaderGuard],
		resolve: {
			translations: TranslationLoaderResolver,
			data: AdviceDetailPageResolver
		}
	},
	{
		path: VLoketAppRoutePaths.Empty,
		component: AdviceOverviewPageComponent,
		canActivate: [TranslationLoaderGuard],
		resolve: {
			translations: TranslationLoaderResolver
		}
	}
];

// Lazy-routes
export const AdviceRoutes = RouterModule.forChild(routes);
