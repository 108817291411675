import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { LinkType } from '@cjm/shared/ui/common';
import { AssociationContactEntity, AssociationContactItemType } from '@cjm/v-loket/shared';

@Component({
	selector: 'vloket-contact-item',
	templateUrl: 'contact-item.component.html',
	styleUrls: ['./contact-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactItemComponent {
	@HostBinding('class.c-contact-item') private readonly hasRootClass: boolean = true;
	@Input() contactItem: AssociationContactEntity;

	public readonly contactItemType: typeof AssociationContactItemType = AssociationContactItemType;
	public readonly linkType: typeof LinkType = LinkType;
}
