import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TimelineItemEntity } from './timeline.types';

@Component({
	selector: 'vloket-timeline',
	templateUrl: './timeline.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent {
	@HostBinding('class.c-timeline') private readonly hasTimelineclass: boolean = true;
	/**
	 * timelineItems
	 *
	 * Provide the timeline items as an array of TimelineItemEntity.
	 *
	 * Default value: []
	 */
	@Input() public timelineItems: TimelineItemEntity[] = [];
	/**
	 * isCondensed
	 *
	 * Mark if the timeline item should be shown in its condensed form.
	 *
	 * Default value: false
	 */
	@Input() public isCondensed: boolean = false;
}
