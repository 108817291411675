<fieldset *ngIf="initialized$ | async" [formGroup]="form">
	<fieldset
		*ngIf="mainActivities?.length > 0"
		class="c-vloket-form__fieldset c-vloket-form__fieldset__extend-bottom-spacing"
	>
		<h3 class="c-vloket-header c-vloket-header--h4">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">{{
				i18nKeys.Registration.RegisterEa.BasicData.GeneralFieldset.ActivitiesTitle | translate
			}}</span>
		</h3>

		<vloket-checkbox-group
			formControlName="mainActivities"
			[data]="mainActivities"
			[minAmount]="1"
			[maxAmount]="3"
			[hintLabel]="i18nKeys.FormLabels.SelectMinMaxValues | translate : { min: 1, max: 3 }"
			[minAmountLabel]="i18nKeys.FormLabels.SelectMinOneValue | translate"
			[maxAmountLabel]="i18nKeys.FormLabels.SelectMaxValues | translate : { max: 3 }"
		/>
	</fieldset>
</fieldset>
