import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { SharedUiModule } from '@cjm/shared/ui/common';
import { SharedFormsModule } from '@cjm/shared/ui/forms';
import { SharedUtilsModule } from '@cjm/shared/utils';

import { components } from './components';
import { SharedCompanyTranslationLoader } from './translate.loader';

@NgModule({
	imports: [
		CommonModule,
		SharedUiModule,
		NgxI18nModule.forChild(SharedCompanyTranslationLoader),
		SharedUtilsModule,
		SharedFormsModule
	],
	declarations: [...components],
	exports: [...components]
})
export class SharedCompanyModule {}
