import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';

import { FeatureUiModule } from '@cjm/shared/features';
import { SharedUiModule } from '@cjm/shared/ui/common';

import { Components } from './components';

@NgModule({
	imports: [MatBadgeModule, CommonModule, RouterModule, FeatureUiModule, SharedUiModule],
	declarations: [Components],
	exports: [SharedUiModule, Components]
})
export class SharedUiSidebarModule {}
