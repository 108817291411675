export enum VLoketAppRoutePaths {
	// General
	Empty = '',
	Fallback = '**',
	Language = ':language',
	Loket = 'loket',
	Privacy = 'cookiebeleid',
	Id = ':id',
	Slug = ':slug',
	vCode = ':vCode',
	Add = 'toevoegen',
	Edit = 'bewerken',
	Invite = 'uitnodigen',
	EmptyParam = 'leeg',

	// Registration
	Registration = 'registratie',
	RegistrationStart = 'start',
	RegistrationRedirectAuthentication = 'redirect-auth',
	RegistrationForm = 'registreren',
	EARegistrationForm = 'kbo-vereniging-registreren',
	RegistrationRedirectRegistration = 'redirect-register',
	RegistrationSuccess = 'succes',
	RegistrationDuplicateCheck = 'duplicaten',

	// Products
	Offers = 'aanbod',
	OffersDetail = 'product',

	// Associations
	Associations = 'verenigingen',
	AssociationDetail = VLoketAppRoutePaths.Id,

	// My Association
	MyAssociation = 'mijn-vereniging',
	Dashboard = 'overzicht',
	AssociationData = 'verenigingsgegevens',
	AssociationContact = 'contact',
	AssociationLocation = 'locatie',
	AssociationRepresentatives = 'vertegenwoordigers',
	Divisions = 'afdelingen',
	CaseOverview = 'dossiers',
	Log = 'logboek',
	Welcome = 'welkom',
	Settings = 'instellingen',

	// About
	About = 'over-ons',

	// Info
	Info = 'pagina',

	// Advice
	Advice = 'advies',
	AdviceDetail = VLoketAppRoutePaths.Id,

	// Error handling
	Redirects = 'fout',
	AuthenticationFailed = 'authenticatiemiddel',
	Maintenance = 'onderhoud',
	Offline = 'offline',
	NoResources = 'ontbrekende-onderdelen',
	NotFound = 'niet-gevonden',
	ServerError = 'er-ging-iets-mis',
	Stop = 'stop',

	// Representative
	Representative = 'vertegenwoordiger',
	Invitations = 'uitnodigingen',
	JoinUs = 'kom-erbij',
	InvitationError = 'niet-beschikbaar',
	// Denis: eslint exception added to maintain the duplicate value without breaking the linter
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	InvitationSuccess = 'succes',
	Requests = 'aanvragen',

	// Global Search
	Search = 'zoeken'
}
