import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';

import { GetNotificationsApiResponseEntity } from './notification.api.types';

@Injectable()
export class NotificationApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {}

	/**
	 * getNotifications
	 *
	 * The getNotifications method gets the notifications from the API.
	 *
	 * @returns Observable<GetNotificationsApiResponseEntity>
	 */
	public getNotifications(): Observable<GetNotificationsApiResponseEntity> {
		return this.httpClient.get(VLoketEndpoints.Notifications.GetNotifications());
	}
}
