<cjm-input-wrapper class="u-margin-bottom" id="countrySelectControl" [label]="label">
	<select #input cypressTag="CountrySelect.Select.Input" [formControl]="countrySelectControl">
		<option *ngFor="let type of countryOptionValues" [value]="type">
			{{ type }}
		</option>
	</select>
</cjm-input-wrapper>

<cjm-input-wrapper
	*ngIf="countrySelectControl.value === countryOptions.Other"
	id="form"
	cypressTag="CountrySelect.Other.Input"
	[label]="otherLabel"
>
	<input #otherField #input type="text" [formControl]="form" [placeholder]="otherPlaceholder" />
</cjm-input-wrapper>
