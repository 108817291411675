import { AssociationCardInfo } from '../../../ui';
import { AssociationResultEntity, AssociationActivityResultEntity, AssociationLocationEntity } from '../../interfaces';
import { mainLocationSelector } from '../main-location-selector/main-location-selector.util';
import { parseAssociationLocation } from '../parse-association';

/**
 * mapAssociationCardInfo
 *
 * The mapAssociationCardInfo method parses the association result to an association card info entity.
 *
 * @param associationData
 * @returns AssociationCardInfo
 */
export const mapAssociationCardInfo = (associationData: AssociationResultEntity): AssociationCardInfo => {
	if (!associationData) {
		return;
	}

	return {
		id: associationData.verenigingsnummer,
		organisationId: associationData.ondernemingsnummer ? associationData.ondernemingsnummer : '',
		name: associationData.namen.afgekort ? associationData.namen.afgekort : associationData.namen.volledig,
		type: associationData.type.beschrijving,
		labels: associationData.hoofdactiviteiten.map((activity: AssociationActivityResultEntity) => activity.naam),
		location:
			!associationData.locaties || associationData.locaties.length !== 0
				? (
						mainLocationSelector(
							associationData.locaties.map(parseAssociationLocation)
						) as AssociationLocationEntity
				  )?.fullAddress
				: '',
		representatives: []
	};
};
