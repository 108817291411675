<cjm-content class="c-welcome-register-kbo-modal__container" [loading]="companyLoading$ | async">
	<main class="c-welcome-register-kbo-modal__main">
		<h1 class="c-vloket-header c-vloket-header--h1 u-margin-bottom-sm">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ i18nKeys.Registration.RegisterEaModal.Title | translate }}
			</span>
		</h1>

		<p class="c-vloket-text--lg u-margin-bottom-xl">
			{{ i18nKeys.Registration.RegisterEaModal.Description | translate }}
		</p>

		<h2 class="c-vloket-header c-vloket-header--h4">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">
				{{ i18nKeys.Registration.RegisterEaModal.MainActivities.Title | translate }}
			</span>
		</h2>

		<form class="c-vloket-form">
			<vloket-checkbox-group
				*ngIf="mainActivities$ | async as mainActivities"
				[formControl]="mainActivitiesControl"
				[data]="mainActivities"
				[minAmount]="1"
				[maxAmount]="3"
				[hintLabel]="i18nKeys.FormLabels.SelectMinMaxValues | translate : { min: 1, max: 3 }"
				[minAmountLabel]="i18nKeys.FormLabels.SelectMinOneValue | translate"
				[maxAmountLabel]="i18nKeys.FormLabels.SelectMaxValues | translate : { max: 3 }"
			/>

			<div class="c-vloket-form__actions">
				<cjm-button
					type="submit"
					[title]="i18nKeys.Registration.RegisterEa.FormActions.RegisterAssociation.Title | translate"
					[classes]="buttonClasses.LinkButton"
					(handleClick)="validateAndSubmit()"
				>
					{{ i18nKeys.Registration.RegisterEa.FormActions.RegisterAssociation.Text | translate }}
				</cjm-button>

				<cjm-button
					type="submit"
					[title]="i18nKeys.Registration.RegisterEaModal.NotNowButton.Title | translate"
					[classes]="buttonClasses.LinkNoDecoration"
					(handleClick)="closeModal(company().number)"
				>
					{{ i18nKeys.Registration.RegisterEaModal.NotNowButton.Text | translate }}
				</cjm-button>
			</div>
		</form>
	</main>

	<aside class="c-welcome-register-kbo-modal__aside">
		<cjm-company-info *ngIf="company() as company" [company]="company" [isUserCompany]="true" />
	</aside>

	<button class="c-welcome-register-kbo-modal__close-button" (click)="closeModal(company().number)">
		<fa-icon icon="fa-xmark" iconType="fa-solid"></fa-icon>
	</button>
</cjm-content>
