import { FormControl, FormRecord } from '@angular/forms';

export interface CheckboxValue {
	name: string;
	id: string;
}

export interface CheckboxGroup {
	values: Record<string, boolean>;
}

export type CheckboxGroupFormGroup = FormRecord<FormControl<boolean>>;
