<cjm-toaster
	[toasterType]="alertTypes.Warning"
	[toasterTitle]="i18nKeys.Notices.RegisterKbo.Title | translate"
	[toasterMessage]="
		i18nKeys.Notices.RegisterKbo.Message
			| translate : { associationName: data.name }
			| withRouterLinks
				: [
						{
							dataLinkId: 'myAssociation',
							link: [i18nService.currentLanguage, vloketAppRoutes.MyAssociation]
						}
				  ]
	"
	[isClosable]="true"
	[hasBackgroundColor]="true"
	(closeButtonClicked)="closeSnackbar()"
/>
