export enum ComponentTypes {
	Text = 'Tekst',
	Tiles = 'Tegels',
	ImageAndText = 'AfbeeldingEnTekst',
	Divider = 'Scheidingslijn',
	Links = 'Linken',
	Roadmap = 'Stappenplan',
	Recommendation = 'AanbevolenAdviezen'
}

export enum CustomComponents {
	Text = 'vlo-advice-detail-page-richtext',
	Tiles = 'vlo-advice-detail-page-tile-block',
	ImageAndText = 'vlo-advice-detail-page-tile-split',
	Divider = 'vlo-advice-detail-page-divider',
	Links = 'vlo-advice-detail-page-link-block',
	Roadmap = 'vlo-advice-detail-page-roadmap',
	Recommendation = 'vlo-advice-detail-page-recomended-block'
}

export const CustomComponentSelectors: Record<ComponentTypes, CustomComponents> = {
	[ComponentTypes.Text]: CustomComponents.Text,
	[ComponentTypes.Tiles]: CustomComponents.Tiles,
	[ComponentTypes.ImageAndText]: CustomComponents.ImageAndText,
	[ComponentTypes.Divider]: CustomComponents.Divider,
	[ComponentTypes.Links]: CustomComponents.Links,
	[ComponentTypes.Roadmap]: CustomComponents.Roadmap,
	[ComponentTypes.Recommendation]: CustomComponents.Recommendation
};
