import { CompanyBranchEntity } from '../interfaces';

export const COMPANY_FAKE_BRANCH: CompanyBranchEntity = {
	number: '2038.671.279',
	names: {
		commercial: 'Frietje',
		short: 'FZK',
		public: 'Het Frietje'
	},
	address: {
		street: 'Kortrijksesteenweg',
		number: '1070',
		zipcode: '9051',
		municipality: 'Gent',
		coordinates: {
			lat: 5,
			long: 5
		},
		box: '',
		country: 'België'
	}
};
