<div *ngIf="partnerInfo?.name || partnerInfo?.contactName" class="c-detail-list__titles">
	<fa-icon class="c-detail-list__icon" icon="fa-building" iconType="fa-regular" />
	<div>
		<div *ngIf="partnerInfo.name" class="c-detail-list__title">
			<strong class="c-vloket-text-marking c-vloket-text-marking--line-through">{{ partnerInfo?.name }}</strong>
		</div>
		<div *ngIf="partnerInfo.contactName" class="c-detail-list__title">
			<span class="c-vloket-header--subtitle c-vloket-text-marking c-vloket-text-marking--line-through">{{
				partnerInfo?.contactName
			}}</span>
		</div>
	</div>
</div>
<ul>
	<li *ngIf="partnerInfo?.division" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-users" iconType="fa-solid" />
		{{ partnerInfo?.division }}
	</li>
	<li *ngIf="partnerInfo?.address" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-location-dot" iconType="fa-solid" />
		{{ partnerInfo?.address }}
	</li>
	<li *ngIf="partnerInfo?.phone" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-phone" iconType="fa-solid" />
		<cjm-link type="phone" [title]="partnerInfo?.phone" [to]="partnerInfo?.phone">
			{{ partnerInfo?.phone }}
		</cjm-link>
	</li>
	<li *ngIf="partnerInfo?.hours" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-clock" iconType="fa-regular" />
		{{ partnerInfo?.hours }}
	</li>
	<li *ngIf="partnerInfo?.website && partnerInfo.website?.url" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-up-right-from-square" iconType="fa-solid" />
		<cjm-link type="external" [title]="partnerInfo?.website.text" [to]="partnerInfo?.website.url">
			{{ partnerInfo?.website.text || partnerInfo.website?.url }}
		</cjm-link>
	</li>
	<li *ngIf="partnerInfo?.url" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-up-right-from-square" iconType="fa-solid" />
		<cjm-link type="external" [title]="partnerInfo?.url" [to]="partnerInfo?.url">
			{{ partnerInfo?.url }}
		</cjm-link>
	</li>
	<li *ngIf="partnerInfo?.email" class="c-detail-list__item">
		<!-- TODO: the fill/ outline from the icons may defer from the design and should be checked -->
		<fa-icon class="c-detail-list__icon" icon="fa-envelope" iconType="fa-regular" />
		<cjm-link type="email" [title]="partnerInfo?.email" [to]="partnerInfo?.email">
			{{ partnerInfo?.email }}
		</cjm-link>
	</li>
</ul>
