import { Pipe, PipeTransform } from '@angular/core';

import { AddressEntity } from '@cjm/shared/types';

import { parseLocationString } from '../../utils';

@Pipe({
	name: 'oneLineAddress'
})
export class OneLineAddressPipe implements PipeTransform {
	/**
	 * Transforms an AddressEntity object into a formatted string representation.
	 * @param value - The AddressEntity object to transform.
	 * @param notation - The notation style to use for the transformation. Defaults to 'full'.
	 * @param showCountry - Whether to include the country in the transformed string. Defaults to false.
	 * @returns The transformed string representation of the AddressEntity object.
	 */
	public transform(
		value: AddressEntity,
		notation: 'full' | 'abbreviated' = 'full',
		showCountry: boolean = false
	): string {
		//  If no value was provided, return an empty object
		if (!value) {
			return '';
		}

		if (notation === 'abbreviated') {
			return `${value.zipcode} - ${value.municipality}`;
		}

		// It's possible that the country is not set, so we need to check for it to avoid displaying 'undefined'
		if (notation === 'full' && showCountry && value.country) {
			return `${parseLocationString(value)} (${value.country})`;
		}

		return parseLocationString(value);
	}
}
