import { Component, Input } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { PwaService } from '@cjm/shared/pwa';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'cjm-content',
	templateUrl: './cjm-content.component.html'
})
export class CJMContentComponent {
	public readonly isOnline$: ObservableBoolean = this.pwaService.isOnline$;
	public readonly offlineLabel: string = I18nKeys.Offline;

	@Input() public label: string;
	@Input() public loading: boolean = true;
	@Input() public error: boolean = false;
	@Input() public errorText: string;
	@Input() public errorStyle: 'is-information' | 'is-warning' = 'is-warning';

	constructor(private readonly pwaService: PwaService) {}
}
