import { ChangeDetectionStrategy, Component, TemplateRef, ContentChild, Input, Optional } from '@angular/core';

import { MediaQueryMin, MediaQueryMax, ScreenSize } from '@cjm/shared/types';

@Component({
	selector: 'cjm-table-wrapper',
	templateUrl: './cjm-table-wrapper.component.html',
	styleUrls: ['./cjm-table-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CJMTableWrapperComponent {
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	@ContentChild('tableTmpl') public tableTemplate: TemplateRef<unknown>;
	@ContentChild('listItemTmpl') public listItemTemplate: TemplateRef<unknown>;

	/**
	 * The data to display in the table.
	 */
	@Input() public data: unknown[] = [];
	/**
	 * Whether the data is being fetched or not.
	 */
	@Input() public loading: boolean = false;
	/**
	 * Whether the fetching of the data resulted in an error or not.
	 */
	@Input() public error: boolean = false;
	/**
	 * The text to display whenever the error is `true`.
	 */
	@Input() public errorText: string;
	/**
	 * If needed, the width at which the table turns into a container with cards
	 * can be overridden.
	 *
	 * **Note:** Only change if absolutely necessary. This will create an uneven feel
	 * in the application.
	 */
	@Input() @Optional() public mediaQueryOverride: keyof typeof ScreenSize;
}
