import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * EmailValidator
 *
 * This validator uses Validators.pattern to validate an email address with the following regex pattern:
 * /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
 */
export const EmailValidator = (control: FormControl): ValidationErrors | null => {
	if (!control.value) {
		return null;
	}

	return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value)
		? null
		: { invalidEmail: { valid: false } };
};
