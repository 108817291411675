import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { ProductEntity } from '@cjm/shared/types';

@Component({
	selector: 'vloket-product-grid-item',
	templateUrl: './product-grid-item.component.html',
	styleUrls: ['./product-grid-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class ProductGridItemComponent {
	@HostBinding('class.c-product-grid-item') private readonly hasProductGridClass: boolean = true;
	@Input({ required: true }) public product: ProductEntity;

	public readonly offersRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(protected i18nService: I18nService) {}
}
