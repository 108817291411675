<div class="c-alert" [ngClass]="alertType">
	<fa-icon class="c-alert__icon" [icon]="alertIcon.icon" [iconType]="alertIcon.type" />
	<div class="c-alert__content">
		<p *ngIf="alertTitle" class="c-alert__content__header">{{ alertTitle }}</p>
		<p *ngIf="alertMessage" class="c-alert__content__message" [innerHTML]="alertMessage"></p>
	</div>

	<cjm-button
		*ngIf="isClosable"
		[classes]="buttonClasses.Link"
		[title]="i18nKeys.Common.Close | translate"
		(handleClick)="buttonClicked.emit()"
	>
		<fa-icon icon="fa-solid fa-xmark" />
	</cjm-button>
</div>
