<cjm-layout-container
	*ngIf="data"
	class="c-advice-detail-page-tile-block"
	layoutType="full-width"
	[class]="'c-advice-detail-page-tile-block--' + backgroundVariants[data.variant]"
>
	<ng-container main>
		<h2 *ngIf="data.title" class="c-vloket-header c-vloket-header--h3">{{ data.title }}</h2>
		<span *ngIf="data.text" class="c-advice-detail-page-tile-block__content" [outerHTML]="data.text"></span>
		<ul *ngIf="data.tiles" class="c-advice-detail-page-tile-block__tiles-list">
			<li *ngFor="let tile of data.tiles" class="c-advice-detail-page-tile-block__tile">
				<h3 *ngIf="tile.title" class="c-vloket-header c-vloket-header--h6">{{ tile.title }}</h3>
				<span *ngIf="tile.text" [outerHTML]="tile.text"></span>
				<cjm-link
					*ngIf="tile.link as link"
					classes="{{ buttonClasses.LinkButton }} u-margin-top"
					[to]="link.url"
					[type]="link.type"
					[title]="link.title"
				>
					{{ link.text }}
				</cjm-link>
			</li>
		</ul>
	</ng-container>
</cjm-layout-container>
