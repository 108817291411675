import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { concatMap, delay, filter, from, map, mergeMap, of, tap } from 'rxjs';

import { SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';

import { GetNotificationsApiResponseEntity } from '../../interfaces';
import { NotificationApiService } from '../../services';
import { notificationActions, notificationSelectors } from '../../store';
import { parseNotificationResults } from '../../utils';

@Injectable()
export class NotificationFacade extends StoreService {
	public notifications$: ObservableArray<MatSnackBarConfig & { id: string }> = this.selectFromStore(
		notificationSelectors.notifications
	);

	constructor(
		protected readonly store: Store,
		private readonly notificationApiService: NotificationApiService,
		private readonly snackBarService: SnackBarService
	) {
		super(store);
	}

	/**
	 * init
	 *
	 * The init method will trigger a request to the API to fetch the notifications and parse & store them.
	 *
	 * @returns ObservableBoolean
	 */
	public init(): ObservableBoolean {
		return dispatchDataToStore(
			notificationActions.notifications,
			this.notificationApiService.getNotifications().pipe(
				map((result: GetNotificationsApiResponseEntity) => {
					return parseNotificationResults(result.elementen);
				})
			),
			this.store
		).pipe(map(() => true));
	}

	/**
	 * emitGlobalNotifications
	 *
	 * The emitGlobalNotifications method will select the parsed notifications from the store and
	 * show them with an interval of 7s.
	 *
	 * @returns ObservableBoolean
	 */
	public emitGlobalNotifications(): ObservableBoolean {
		return this.notifications$.pipe(
			// Denis: Only proceed if there are notifications available.
			filter((notifications) => Array.isArray(notifications) && notifications.length > 0),
			// Denis: The following mergeMap combined with `from` will cause rxjs to emit each notification separately.
			mergeMap(from),
			// Denis: In the contactMap we provide additional logic to delay every notification after the first one.
			concatMap((notification: MatSnackBarConfig & { id: string }, index: number) =>
				index === 0 ? of(notification) : of(notification).pipe(delay(7000))
			),
			// Denis: Open the toast notification for each notification.
			tap((notification: MatSnackBarConfig & { id: string }) => {
				this.snackBarService.openFromComponent(SnackBarComponent, notification);
			}),
			// Denis: convert the stream to a boolean to avoid using the notification data in the subscription.
			map(() => true)
		);
	}
}
