import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { VloketLinkIcon } from '@cjm/shared/ui/common';

import { BigFooterEntity } from './vloket-big-footer.interface';

@Component({
	selector: 'cjm-vloket-big-footer',
	templateUrl: './vloket-big-footer.component.html',
	styleUrls: ['./vloket-big-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VloketBigFooterComponent {
	@HostBinding('class.c-vloket-big-footer') private readonly rootClass: boolean = true;

	@Input({ required: true }) public data: BigFooterEntity[];

	public readonly linkIcon: VloketLinkIcon = {
		icon: 'fa-chevron-right',
		type: 'fa-solid',
		position: 'left'
	};
}
