import { MatDialogRef } from '@angular/material/dialog';

export interface ModalEntity<ComponentType> {
	reference: MatDialogRef<ComponentType>;
	component: ComponentType;
}

export interface ModalOptionsEntity {
	disableClose: boolean;
	closeOnNavigation: boolean;
	hasBackdrop: boolean;
}
