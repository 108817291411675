import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';

import { ToastService } from './services';
import { reducers } from './toast.store';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Toast, reducers), MatSnackBarModule],
	providers: [ToastService],
	exports: [MatSnackBarModule]
})
export class ToastDataModule {}
