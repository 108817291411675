import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedUiModule } from '@cjm/shared/ui/common';
import { VloketSharedModule } from '@cjm/v-loket/shared';

import { AdvicePageDataModule } from '../data';
import { AdviceI18nModule } from '../i18n';

import { AdviceRoutes } from './advice.routing.module';
import { components } from './components';
import { pages } from './pages';

@NgModule({
	// Modules
	imports: [
		CommonModule,
		SharedUiModule,
		VloketSharedModule,
		AdvicePageDataModule,
		AdviceRoutes,
		AdviceI18nModule,
		RouterModule
	],
	// Components
	declarations: [...components, ...pages]
})

// Exports
// -------------------------------------------------------------------------- /
export class AdviceModule {}
