<fieldset
	*ngIf="(initialized$ | async) && givenValues.length > 0"
	class="c-vloket-form__fieldset c-vloket-form__fieldset__extend-bottom-spacing"
	[formGroup]="form"
>
	<div *ngIf="hintLabel" class="c-input__hint">
		{{ hintLabel }}
	</div>

	<fieldset class="c-vloket-form__fieldset__subset--2-col">
		<cjm-checkbox
			*ngFor="let value of givenValues"
			[title]="value.name"
			[label]="value.name"
			[formControlName]="value.id"
			[id]="value.id"
			>{{ value.name }}</cjm-checkbox
		>
	</fieldset>

	<ng-container *ngIf="form.dirty">
		<div
			*ngIf="form.errors?.tooLittleValues && minAmountLabel"
			class="c_input__description is-error u-no-margin-right u-no-margin-left"
		>
			{{ minAmountLabel }}
		</div>

		<div
			*ngIf="form.errors?.tooManyValues && maxAmountLabel"
			class="c_input__description is-error u-no-margin-right u-no-margin-left"
		>
			{{ maxAmountLabel }}
		</div>
	</ng-container>
</fieldset>
