export const Themes = {
	PURCHASE_HUUR: 'aankoop_huur',
	ADMINISTRATION: 'administratie',
	EVENT: 'evenement',
	TAXATION: 'fiscaliteit',
	LEGAL: 'juridisch',
	COURSE: 'opleiding',
	INSURANCE: 'verzekering',
	STAFF: 'personeel',
	VLO: 'verenigingsloket'
};
