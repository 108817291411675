import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { Themes } from './advice-page-theme-tag.const';

@Component({
	selector: 'vloket-advice-page-theme-tag',
	templateUrl: './advice-page-theme-tag.component.html',
	styleUrls: ['./advice-page-theme-tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvicePageThemeTagComponent {
	@HostBinding('class.c-theme-icon') private readonly hasRootClass: boolean = true;

	@Input({ required: true }) public tagId: String;

	public theme: typeof Themes = Themes;
}
