import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'cjm-loading-modal',
	templateUrl: './loading-modal.component.html',
	styleUrls: ['./loading-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingModalComponent {
	@HostBinding('class.c-loading-modal') private readonly hasRootClass: boolean = true;
}
