import { AdviceDetailPageEntity } from '@cjm/v-loket/shared';

import { AdvicePageResultEntity } from '../../services';
import { pageImageParser } from '../page-image-parser/page-image-parser.util';

/**
 * advicePageMapper converts AdvicePageResultEntity objects in to AdviceDetailPageEntity objects
 *
 * @param {AdvicePageResultEntity[]} pages
 *
 */
export const advicePageMapper = (pages: AdvicePageResultEntity[]): AdviceDetailPageEntity[] => {
	const items = Array.isArray(pages) && pages.length > 0 ? pages : [];

	return items.map(
		(page: AdvicePageResultEntity): AdviceDetailPageEntity => ({
			id: page.id,
			title: page.titel,
			description: page.omschrijving,
			readingTime: page.leestijd,
			lastUpdated: page.bijgewerkt,
			heroImage: pageImageParser(page.afbeelding),
			keywords: page.trefwoorden.map((keyword) => ({
				id: keyword.id,
				name: keyword.naam
			}))
		})
	);
};
