import { AssociationCardInfo, IRepresentativeForm } from '@cjm/v-loket/shared';

import { IBasicRegistrationDataForm } from '../../ui/components';
import { IEARegistration as IEARegistration, IRegistration } from '../../ui/interfaces';
import { PostRegistrationResponseEntity } from '../interfaces';

export type IPostRegistrationResponseEntity = PostRegistrationResponseEntity;

export type IRegisterContactData = IBasicRegistrationDataForm;
export type IRegisterRepresentative = IRepresentativeForm;

export type IRegisterFormDataEntity = IRegistration;
export type IEARegisterFormDataEntity = IEARegistration;

export type IRegisterAssociationData = AssociationCardInfo;
