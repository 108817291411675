import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormAccessor, DataFormAccessor } from '@studiohyperdrive/ngx-forms';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { IMainActivity } from '@cjm/v-loket/repositories';

import { I18nKeys } from '../../../i18n';

import { IEaRegistrationDataForm, IEaRegistrationDataFormGroup } from './ea-registration-data-form.types';

@Component({
	selector: 'vloket-ea-registration-data-form',
	templateUrl: './ea-registration-data-form.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EaRegistrationDataFormComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => EaRegistrationDataFormComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => EaRegistrationDataFormComponent)
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EaRegistrationDataFormComponent extends DataFormAccessor<
	IMainActivity[],
	IEaRegistrationDataForm,
	FormGroup<IEaRegistrationDataFormGroup>
> {
	public mainActivities: IMainActivity[] = [];
	public associationCallnameHasErrors$: ObservableBoolean;
	public associationDescriptionHasErrors$: ObservableBoolean;

	public readonly i18nKeys = I18nKeys;

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(data: IMainActivity[]): FormGroup {
		this.mainActivities = !Array.isArray(data) || data.length === 0 ? [] : data;

		return this.buildEaFormGroup();
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public onChangeMapper(): IEaRegistrationDataForm {
		// Denis: Because some fields might be disabled, we need the rawValue.
		return this.form.getRawValue();
	}

	/**
	 * buildEaFormGroup
	 *
	 * The buildEaFormGroup method will set up a new for with all the Ea fields.
	 *
	 * For the mainActivities field, it will use the buildMainActivitiesFormArray method
	 * to set up a FormArray that has a control for each checkbox
	 *
	 * @private
	 * @returns FormGroup
	 */
	private buildEaFormGroup(): FormGroup {
		return new FormGroup({
			associationType: new FormControl<string>(''),
			associationCallname: new FormControl<string>('', {
				updateOn: 'blur'
			}),
			mainActivities: new FormControl(null)
		});
	}
}
