import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { DeviceModule } from '@cjm/shared/ui/device';

import { BreadcrumbComponent } from './components';

@NgModule({
	imports: [CommonModule, RouterModule, NgxI18nModule.forChild(), DeviceModule],
	declarations: [BreadcrumbComponent],
	exports: [BreadcrumbComponent, DeviceModule]
})
export class BreadcrumbModule {}
