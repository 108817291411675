import { ProductEntity, ProductEntityResult, CJMResult } from '@cjm/shared/types';

export interface RecommendationsApiResult extends CJMResult<RecommendedProductEntityResult, 'Aanbevelingen'> {
	voortgang: RecommendedProductsProgressResultEntity;
}

export interface RecommendedProductEntityResult {
	product: ProductEntityResult;
	aanbevelingen: RecommendationEntityResult[];
}

export interface RecommendedProductEntity {
	product: ProductEntity;
	recommendations: RecommendationEntity[];
}

export interface RecommendationEntityResult {
	sterkte: RecommendationStrengthResult;
	beoordeling: RecommendationRatingResult;
	organisatie: {
		nummer: string;
	};
}

export interface RecommendationEntity {
	strength: RecommendationStrength;
	rating: RecommendationRating;
	organizationNumber: string;
}

export type RecommendationRatingByProduct = Record<string, RecommendationRating>;

export enum RecommendationStrengthResult {
	'Zeker wel' = 'zeker wel',
	'Eerder wel' = 'eerder wel',
	'Eerder niet' = 'eerder niet'
}

export enum RecommendationStrength {
	SURE = 'SURE',
	POTENTIAL = 'POTENTIAL',
	NOT = 'NOT'
}

export enum RecommendationRatingResult {
	Positief = 'Positief',
	Negatief = 'Negatief',
	Neutraal = 'Neutraal'
}

export enum RecommendationRating {
	POSITIVE = 'POSITIVE',
	NEGATIVE = 'NEGATIVE',
	NEUTRAL = 'NEUTRAL'
}

export interface RecommendedProductsProgressResultEntity {
	isCompleet: boolean;
	verwerktAantal: number;
	totaalAantal: number;
}

export interface RecommendedProductsProgressEntity {
	isComplete: boolean;
	totalAmount: number;
	processedAmount: number;
}
