import { createBaseStoreAssets, createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';
import { FacetEntity, IndexedPagination } from '@cjm/shared/types';
import { AdviceDetailPageEntity } from '@cjm/v-loket/shared';

import { AdvicePagesStoreSlices } from './advice-pages.store.types';

export const {
	actions: advicePagesActions,
	reducers: advicePagesReducers,
	selectors: advicePagesSelectors
} = createStoreAssets<AdvicePagesStoreSlices>(StoreSlice.Advices, [
	{
		subSlice: 'advicePages',
		generator: createEntityAdapterStoreAssets<AdviceDetailPageEntity>
	},
	{
		subSlice: 'adviceFacets',
		generator: createEntityAdapterStoreAssets<FacetEntity>
	},
	{
		subSlice: 'advicePagination',
		generator: createBaseStoreAssets<IndexedPagination>
	}
]);
