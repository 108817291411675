import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CallToActionEntity, CallToActionEnum } from '@cjm/shared/types';
import { ButtonClasses } from '@cjm/shared/ui/common';

@Component({
	selector: 'vloket-product-detail-user-actions',
	templateUrl: 'product-detail-user-actions.component.html',
	styleUrls: ['./product-detail-user-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetailUserActionsComponent {
	@Input() public set callToAction(action: CallToActionEntity) {
		this.action = action;
		this.solidButton =
			this.action.action === CallToActionEnum.LOGIN || this.action.action === CallToActionEnum.START_CASE;
	}

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public action: CallToActionEntity;
	public solidButton: boolean = false;
}
