import { isValidAssociationNumber } from '../is-valid-association-number/is-valid-association-number.util';
import { isValidCompanyNumber } from '../is-valid-company-number/is-valid-company-number.util';

/**
 * Check if the provided hint is valid
 *
 * @private
 * @param base64Hint - The base64 encoded hint
 */
export const isValidHint = (base64Hint: string): boolean => {
	const hintJson = atob(base64Hint);
	const isString = Object.prototype.toString.call(hintJson) === '[object String]';
	if (!isString) {
		return false;
	}

	try {
		const data = JSON.parse(hintJson);
		const allowedCaps = ['BUR', 'EA', 'GID', 'LB', 'VER'];

		if (
			data['code_hint'] &&
			!isValidCompanyNumber(data['code_hint']) &&
			!isValidAssociationNumber(data['code_hint'])
		) {
			return false;
		}

		if (
			data['via_hint'] &&
			!isValidCompanyNumber(data['via_hint']) &&
			!isValidAssociationNumber(data['code_hint'])
		) {
			return false;
		}

		if (data['cap_hint'] && !allowedCaps.includes(data['cap_hint'])) {
			return false;
		}

		const allowedKeys = ['cap_hint', 'code_hint', 'via_hint'];
		const hasInvalidKey = !Object.keys(data).every((e) => allowedKeys.includes(e));

		if (hasInvalidKey) {
			return false;
		}
	} catch (e) {
		return false;
	}

	return true;
};
