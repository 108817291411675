import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { validateContent } from '@studiohyperdrive/rxjs-utils';
import { catchError, Observable, retry } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseLocationString } from '@cjm/shared/utils';
import { AssociationApiService } from '@cjm/v-loket/repositories';
import { AssociationDetailEntity, mainLocationSelector } from '@cjm/v-loket/shared';

import { IEARegisterFormDataEntity, IRegisterFormDataEntity, IRegisterAssociationData } from '../../interfaces';
import { actions, selectors } from '../../store/registration.store';

@Injectable()
export class SuccessPageResolver extends StoreService {
	private readonly registerFormData$: Observable<IRegisterFormDataEntity | IEARegisterFormDataEntity> =
		this.selectFromStore(selectors.registerFormData);

	constructor(protected readonly store: Store, protected readonly associationApiService: AssociationApiService) {
		super(store);
	}

	public resolve(route: ActivatedRouteSnapshot): Observable<IRegisterAssociationData> {
		// Denis: Get the vCode from the route params
		const vCode = route?.params?.vCode;

		// Denis: if there is no vCode in the route, abort further logic
		if (!vCode) {
			return;
		}

		return dispatchDataToStore(
			actions.associationData,
			// Denis: Get the association detail based on the provided vCode
			this.associationApiService
				.getAssociationDetail(vCode)
				.pipe(
					// Denis: Retry 5 times with a small delay in between attempts
					retry({
						count: 5,
						delay: 500
					})
				)
				.pipe(
					// Denis: If the attempts do fail, use the formData
					catchError(() =>
						this.registerFormData$.pipe(
							// Denis: Map the formData to a minimal set of data as a fallback
							map(
								(formData: IRegisterFormDataEntity | IEARegisterFormDataEntity) =>
									({
										associationNumber: vCode,
										names: {
											full: (formData as IRegisterFormDataEntity)?.basicInfo?.associationName
										},
										associationType: {
											code: formData.basicInfo.associationType
										}
									} as Partial<AssociationDetailEntity>)
							)
						)
					),
					// Denis: Only proceed if the detail data is present
					validateContent(),
					// Denis: Map the provided data to the IRegisterSuccessData format
					map((detail: AssociationDetailEntity) => ({
						id: detail.associationNumber,
						organisationId: detail.organisationNumber,
						name: detail.names.full,
						type: detail.associationType.description,
						labels: (Array.isArray(detail.mainActivities) ? detail.mainActivities : []).map(
							({ name }) => name
						),
						location: parseLocationString(mainLocationSelector(detail.locations)),
						representatives: []
					}))
				),
			this.store
		);
	}
}
