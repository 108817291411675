<div class="c-preloader">
	<ng-container *ngIf="imageSrc; else loaderTmpl">
		<img class="c-preloader__logo-image" priority [ngSrc]="imageSrc" />
		<span class="c-preloader__info">{{ loadingText }}</span>
	</ng-container>

	<ng-template #loaderTmpl>
		<span class="icon-flanders c-preloader__logo"></span>
		<span class="c-preloader__info">{{ loadingText }}</span>
	</ng-template>
</div>
