import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';

import { CompanyEntity, CompanyResultEntity } from '../interfaces';
import { convertCompanyResultToCompany } from '../utils';

@Injectable()
export class CompanyApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		this.httpClient.setIncludeLanguage(false);
	}

	/**
	 * Get the data of a company
	 *
	 * @param number - Number of the company
	 * @param context - HttpContext
	 */
	public getCompany(number: string, context?: HttpContext): Observable<CompanyEntity> {
		return this.httpClient
			.get<CompanyResultEntity>(VLoketEndpoints.Company.GetCompany(number), {}, true, context)
			.pipe(map((result) => convertCompanyResultToCompany(result)));
	}
}
