@if(!disabled) { @switch (type) { @case (linkTypes.internal) {
<a [class]="classes" [routerLink]="to" [title]="title" [fragment]="fragment" [queryParams]="queryParams">
	<ng-template [ngTemplateOutlet]="linkContent"></ng-template>
</a>
} @case (linkTypes.external) { @if (keepInTab) {
<a matomoClickCategory="links" matomoClickAction="clicked" [class]="classes" [href]="to" [title]="title">
	<ng-template [ngTemplateOutlet]="linkContent"></ng-template>
</a>
} @else {
<a
	target="_blank"
	matomoClickCategory="links"
	matomoClickAction="clicked"
	[class]="classes"
	[href]="to"
	[title]="title"
>
	<ng-template [ngTemplateOutlet]="linkContent"></ng-template>
</a>
} } @case (linkTypes.email) {
<a [class]="classes" [title]="title" [href]="'mailto:' + to">
	<ng-template [ngTemplateOutlet]="linkContent"></ng-template>
</a>
} @case (linkTypes.phone) {
<a [class]="classes" [title]="title" [href]="'tel:' + to">
	<ng-template [ngTemplateOutlet]="linkContent"></ng-template>
</a>
} } } @else {
<span class="c-vloket-text--light">
	<ng-template [ngTemplateOutlet]="linkContent"></ng-template>
</span>
}

<ng-template #linkContent>
	<fa-icon
		*ngIf="icon && (!icon.position || icon.position === 'left')"
		class="c-cjm-link__icon-left"
		[icon]="icon.icon"
		[iconType]="icon.type"
	/>
	<ng-content></ng-content>
	<fa-icon
		*ngIf="icon && icon.position === 'right'"
		class="c-cjm-link__icon-right"
		[icon]="icon.icon"
		[iconType]="icon.type"
	/>
	<cjm-info *ngIf="tooltip" class="u-margin-left-sm" [description]="tooltip" [tooltipPosition]="'right'" />
</ng-template>
