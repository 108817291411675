import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseFormAccessor, FormAccessor } from '@studiohyperdrive/ngx-forms';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { BehaviorSubject, filter, first, Observable, switchMap, takeUntil, tap } from 'rxjs';

import {
	AddressService,
	BoxNumberSearchResult,
	BpostApiResult,
	LocalitySearchResult,
	StreetNumberSearchResult,
	StreetSearchResult
} from '@cjm/shared/bpost';
import { AutoCompleteOption } from '@cjm/shared/ui/forms';
import { AssociationLocationType } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';

import { ILocationForm, ILocationFormGroup } from './location-form.types';

@Component({
	selector: 'vloket-location-form',
	templateUrl: './location-form.component.html',
	styleUrls: ['./location-form.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => LocationFormComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => LocationFormComponent),
			multi: true
		},
		{
			provide: BaseFormAccessor,
			useExisting: forwardRef(() => LocationFormComponent)
		}
	]
})
export class LocationFormComponent
	extends FormAccessor<ILocationForm, FormGroup<ILocationFormGroup>>
	implements OnInit
{
	@HostBinding('class.c-location-form') private readonly hasPrimaryButtonClass: boolean = true;
	/**
	 * Should the location form also show the description field.
	 *
	 * Default: true
	 */
	@Input() public showDescription: boolean = true;
	/**
	 * Should the location form also show the location-type select.
	 *
	 * Default: true
	 */
	@Input() public showLocationType: boolean = true;

	public readonly locationTypes: AssociationLocationType[] = [
		AssociationLocationType.activities,
		AssociationLocationType.correspondence
	];

	/**
	 * Denis: localities auto-complete
	 */
	private currentLocalityOptions: LocalitySearchResult[] = [];
	private readonly localitiesSubject$: BehaviorSubject<AutoCompleteOption[]> = new BehaviorSubject<
		AutoCompleteOption[]
	>([]);
	public readonly localities$: Observable<AutoCompleteOption[]> = this.localitiesSubject$.asObservable();
	/**
	 * Denis: streets auto-complete
	 */
	private readonly streetsSubject$: BehaviorSubject<AutoCompleteOption[]> = new BehaviorSubject<AutoCompleteOption[]>(
		[]
	);
	public readonly streets$: Observable<AutoCompleteOption[]> = this.streetsSubject$.asObservable();
	/**
	 * Denis: street numbers auto-complete
	 */
	private readonly streetNumbersSubject$: BehaviorSubject<AutoCompleteOption[]> = new BehaviorSubject<
		AutoCompleteOption[]
	>([]);
	public readonly streetNumbers$: Observable<AutoCompleteOption[]> = this.streetNumbersSubject$.asObservable();
	/**
	 * Denis: box numbers auto-complete
	 */
	private readonly boxNumbersSubject$: BehaviorSubject<AutoCompleteOption[]> = new BehaviorSubject<
		AutoCompleteOption[]
	>([]);
	public readonly boxNumbers$: Observable<AutoCompleteOption[]> = this.boxNumbersSubject$.asObservable();

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	constructor(private readonly bpostAddressService: AddressService, private readonly i18nService: I18nService) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.initialized$
			.pipe(
				filter((initialized: boolean) => initialized),
				switchMap(() => this.form.get('type').valueChanges),
				// startWith([undefined, this.form.get('type').value]),
				tap((current: AssociationLocationType) => {
					const description = this.form.get('description').value;
					const correspondenceDefaultDescription: string = this.i18nService.getTranslation(
						this.i18nKeys.SharedAssociations.Forms.Location.Description.CorrespondenceAddressPrefill
					);

					if (current === AssociationLocationType.correspondence && !description) {
						this.form.get('description').patchValue(correspondenceDefaultDescription);
					}

					if (
						current === AssociationLocationType.activities &&
						description === correspondenceDefaultDescription
					) {
						this.form.get('description').patchValue('');
					}
				}),

				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(): FormGroup {
		return new FormGroup({
			objectId: new FormControl<string>(''),
			type: new FormControl<AssociationLocationType>(AssociationLocationType.activities, {
				validators: this.showLocationType ? [Validators.required] : []
			}),
			description: new FormControl<string>('', {
				updateOn: 'blur'
			}),
			zipcode: new FormControl<string>('', {
				validators: [Validators.required]
			}),
			locality: new FormControl<string>('', {
				validators: [Validators.required]
			}),
			country: new FormControl<string>('', {
				validators: [Validators.required]
			}),
			street: new FormControl<string>('', {
				validators: [Validators.required]
			}),
			number: new FormControl<string>('', {
				validators: [Validators.required]
			}),
			box: new FormControl<string>(''),
			isPrimary: new FormControl<boolean>(false)
		});
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public onChangeMapper(): ILocationForm {
		// Denis: Because some fields might be disabled, we need the rawValue.
		return this.form.getRawValue();
	}

	// Iben: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	protected emitValueWhenDisableFieldsUsingInput(): boolean {
		// Iben: We don't want to emit these changes so that there's no dirty state given when we do this on init
		return false;
	}

	/**
	 * searchLocalities
	 *
	 * The searchLocalities method will connect to the Bpost API and fetch localities.
	 * It will then format them to the AutoCompleteOption and push them on the localities$ observable.
	 *
	 * @param q
	 */
	public searchLocalities(q: string): void {
		if (!q) {
			this.localitiesSubject$.next([]);

			return;
		}

		this.bpostAddressService
			.getLocality(q)
			.pipe(
				first(),
				tap((result: BpostApiResult<LocalitySearchResult>) => {
					this.currentLocalityOptions = result.response.topSuggestions;
				}),
				tap((result: BpostApiResult<LocalitySearchResult>) => {
					this.localitiesSubject$.next(
						result.response.topSuggestions.map((suggestion: LocalitySearchResult) => ({
							label: `${suggestion?.address?.postalCode} - ${suggestion?.address?.municipalityName} (${suggestion?.address?.localityName})`,
							value: suggestion.address.localityName
						}))
					);
				})
			)
			.subscribe();
	}

	/**
	 * handleLocalityAutoCompleteSelection
	 *
	 * The handleLocalityAutoCompleteSelection method will patch the selected locality
	 * to the zipcode and locality formfields.
	 *
	 * @param event
	 */
	public handleLocalityAutoCompleteSelection(event: MatAutocompleteSelectedEvent): void {
		const locality = event?.option?.value;
		const zipcode = this.currentLocalityOptions.find(
			(option: LocalitySearchResult) => option.address.localityName === locality
		).address.postalCode;

		this.form.get('zipcode').patchValue(zipcode);
		this.form.get('locality').patchValue(locality);
	}

	/**
	 * searchStreets
	 *
	 * The searchStreets method will connect to the Bpost API and fetch streets.
	 * It will then format them to the AutoCompleteOption and push them on the streets$ observable.
	 *
	 * @param q
	 */
	public searchStreets(q: string): void {
		const postalCode = this.form.get('zipcode').getRawValue();
		const municipalityName = this.form
			.get('locality')
			.getRawValue()
			// Denis: Remove GRAR formatted locality name
			.replace(/\(.*?\)/, '')
			// Denis: The BPost api cannot handle trailing spaces
			.trim();

		if (!q || !postalCode || !municipalityName) {
			this.localitiesSubject$.next([]);

			return;
		}

		this.bpostAddressService
			.getStreet(q, postalCode, municipalityName)
			.pipe(
				first(),
				tap((result: BpostApiResult<StreetSearchResult>) => {
					this.streetsSubject$.next(
						result.response.topSuggestions.map((suggestion: StreetSearchResult) => ({
							label: suggestion.address.streetName,
							value: suggestion.address.streetName
						}))
					);
				})
			)
			.subscribe();
	}

	/**
	 * searchStreetNumbers
	 *
	 * The searchStreetNumbers method will connect to the Bpost API and fetch street numbers.
	 * It will then format them to the AutoCompleteOption and push them on the streetNumbers$ observable.
	 *
	 * @param q
	 */
	public searchStreetNumbers(q: string): void {
		const postalCode = this.form.get('zipcode').getRawValue();
		const municipalityName = this.form
			.get('locality')
			.getRawValue()
			// Denis: Remove GRAR formatted locality name
			.replace(/\(.*?\)/, '')
			// Denis: The BPost api cannot handle trailing spaces
			.trim();
		const street = this.form.get('street').getRawValue();

		if (!q || !postalCode || !municipalityName || !street) {
			this.localitiesSubject$.next([]);

			return;
		}

		this.bpostAddressService
			.getStreetNumber(q, postalCode, municipalityName, street)
			.pipe(
				first(),
				tap((result: BpostApiResult<StreetNumberSearchResult>) => {
					this.streetNumbersSubject$.next(
						result.response.topSuggestions.map((suggestion: StreetNumberSearchResult) => ({
							label: suggestion.address.houseNumber,
							value: suggestion.address.houseNumber
						}))
					);
				})
			)
			.subscribe();
	}

	/**
	 * searchBoxNumbers
	 *
	 * The searchBoxNumbers method will connect to the Bpost API and fetch box numbers.
	 * It will then format them to the AutoCompleteOption and push them on the boxNumbers$ observable.
	 *
	 * @param q
	 */
	public searchBoxNumbers(q: string): void {
		const postalCode = this.form.get('zipcode').getRawValue();
		const municipalityName = this.form
			.get('locality')
			.getRawValue()
			// Denis: Remove GRAR formatted locality name
			.replace(/\(.*?\)/, '')
			// Denis: The BPost api cannot handle trailing spaces
			.trim();
		const street = this.form.get('street').getRawValue();
		const number = this.form.get('number').getRawValue();

		if (!q || !postalCode || !municipalityName || !street || !number) {
			this.localitiesSubject$.next([]);

			return;
		}

		this.bpostAddressService
			.getBoxNumber(q, postalCode, municipalityName, street, number)
			.pipe(
				first(),
				tap((result: BpostApiResult<BoxNumberSearchResult>) => {
					this.boxNumbersSubject$.next(
						result.response.topSuggestions.map((suggestion: BoxNumberSearchResult) => ({
							label: suggestion.address.boxNumber,
							value: suggestion.address.boxNumber
						}))
					);
				})
			)
			.subscribe();
	}
}
