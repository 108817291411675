import { UserTargetCodeEntity } from '@cjm/shared/types';

// Iben: Backend interfaces
export interface UserSessionEntityResult {
	'@type': string;
	cookie: UserCookieEntityResult;
	metaGegevens: UserMetaEntityResult;
	gebruiker: UserEntityResult;
}

export interface UserCookieEntityResult {
	vervaltijdstip: string;
}

export interface UserMetaEntityResult {
	vervaltijdstip: string;
	laatsteAanmelding: string;
	laatsteGebruikt: string;
	levensduur: string;
	vorigeAanmelding: string;
	startSessie: string;
}

export interface UserEntityResult {
	voornaam: string;
	familienaam: string;
	insz?: string;
	alsBurger: boolean;
	alsOrganisatie: boolean;
	taal: string;
	id: string;
	organisatie?: UserCompanyEntityResult;
	doelgroepCode: UserTargetCodeEntity;
}

export interface UserCompanyEntityResult {
	nummer: string;
	naam: string;
	vCode: string;
	isWettelijkVertegenwoordiger: boolean;
	isMandaatBeheerder: boolean;
	isSanctieregisterToegankelijk: boolean;
}

// Iben: Frontend interfaces

export interface UserSessionEntity {
	cookie: UserCookieEntity;
	metaData: UserMetaEntity;
	user: UserEntity;
}

export interface UserCookieEntity {
	expiresAt: string;
}

export interface UserMetaEntity {
	expiresAt: string;
	lastLoggedInAt: string;
	previousLoggedInAt: string;
	lastUsedAt: string;
	lifetime: string;
	sessionStartedAt: string;
}

export interface UserEntity {
	firstName: string;
	name: string;
	insz?: string;
	isCivilian: boolean;
	isCompany: boolean;
	language: string;
	id: string;
	company?: UserCompanyEntity;
	targetCode: UserTargetCodeEntity;
}

export interface UserCompanyEntity {
	number: string;
	name: string;
	vCode?: string;
	isLegalRepresentative: boolean;
	isMandateAdministrator: boolean;
	hasSanctionsAccess: boolean;
}

export type UserCompanyPropertyEntity = keyof UserCompanyEntity;
