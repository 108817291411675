import { AssociationLocationType } from '../../const';
import { AssociationLocationEntity } from '../../interfaces';

/**
 * Finds the main location from an array of association-locations.
 *
 * This process will follow the following priority
 * 1. If there is only one location return that location
 * 2. Primary location
 * 3. KBO HQ
 * 4. Correspondence address
 * 5. First activity location
 * 6. null (no location)
 *
 */
export function mainLocationSelector(locations: Array<AssociationLocationEntity>) {
	// Abdurrahman: if there is only one location, return that location
	if (locations.length === 1) {
		return locations[0];
	}

	// Abdurrahman: if there is a primary location, return primary location
	const primaryLocation = (locations || []).find((location: AssociationLocationEntity) => location?.isPrimary);

	if (primaryLocation) {
		return primaryLocation;
	}

	// Abdurrahman: if there is no primary location, look for the headquarter location.
	const headquarterLocation = (locations || []).find(
		(location: AssociationLocationEntity) => location?.type === AssociationLocationType.headquarter
	);

	if (headquarterLocation) {
		return headquarterLocation;
	}

	// Abdurrahman: if there is no headquarter location, look for the correspondence location.
	const correspondenceLocation = (locations || []).find(
		(location: AssociationLocationEntity) => location?.type === AssociationLocationType.correspondence
	);

	if (correspondenceLocation) {
		return correspondenceLocation;
	}

	// Abdurrahman: if there is no correspondence location, look for the activity locations.
	const activitiyLocations = (locations || []).find(
		(location: AssociationLocationEntity) => location?.type === AssociationLocationType.activities
	);

	if (activitiyLocations) {
		return activitiyLocations;
	}

	// Abdurrahman: if there is no location, return null
	return null;
}
