import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ButtonClasses } from '@cjm/shared/ui/common';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vloket-primary-button',
	templateUrl: './primary-button.component.html',
	styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent {
	@HostBinding('class.c-primary-button') private readonly hasPrimaryButtonClass: boolean = true;
	/**
	 * The iconOnly attribute will show the button without a label.
	 *
	 * Default: false
	 */
	@Input() public iconOnly: boolean = false;
	/**
	 * The id provided in this attribute will be included in the setAsPrimary event.
	 */
	@Input() public id: string | number;
	/**
	 * The active attribute will mark the button as active when set to true.
	 *
	 * Default: false
	 */
	@Input() public active: boolean = false;
	@Output() public setAsPrimary: EventEmitter<string | number> = new EventEmitter<string | number>();
	@Output() public removeAsPrimary: EventEmitter<string | number> = new EventEmitter<string | number>();

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
}
