<vloket-product-detail-info-list
	*ngFor="let partner of partnerInfo | toArray"
	cypressTag="Products.Detail.SidebarInfo"
	[partnerInfo]="partner"
/>

<vloket-product-detail-user-actions
	*ngFor="let action of actions"
	cypressTag="Products.Detail.SidebarUserAction"
	[callToAction]="action"
/>

<cjm-link
	*ngIf="referralLink"
	[classes]="buttonClasses.LinkButton + ' ' + buttonClasses.Action"
	[to]="referralLink.url"
	[title]="referralLink.text"
	[type]="referralLink.type"
	[icon]="referralLink.icon"
	>{{ referralLink.text }}</cjm-link
>

<cjm-link
	*ngIf="caseDetailsLink"
	[classes]="buttonClasses.LinkButtonOutline + ' ' + buttonClasses.Action"
	[to]="caseDetailsLink.url"
	[title]="caseDetailsLink.title"
	[type]="caseDetailsLink.type"
	>{{ caseDetailsLink.text }}</cjm-link
>
