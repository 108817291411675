import { AbstractControl, FormArray, FormControl } from '@angular/forms';

import { IRepresentativeForm, SendCopyForm } from '@cjm/v-loket/shared';

import { IBasicRegistrationDataForm } from '../../components';

export interface IRegistration {
	basicInfo: IBasicRegistrationDataForm;
	representatives: IRepresentativeForm[];
	invites: IRepresentativeForm[];
	sendCopy: SendCopyForm;
	subscribeToNewsletter: boolean;
}

export interface IRegistrationForm extends Record<string, AbstractControl> {
	basicInfo: FormControl<IBasicRegistrationDataForm>;
	representatives: FormArray<FormControl<IRepresentativeForm>>;
	invites: FormArray<FormControl<IRepresentativeForm>>;
	sendCopy: FormControl<SendCopyForm>;
	subscribeToNewsletter: FormControl<boolean>;
}
