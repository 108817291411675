import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';

export const RedirectI18nKeys = {
	...sharedI18nKeys,
	AuthenticationFailed: {
		Failed: {
			Title: marker('authentication-failed.failed.title'),
			Text: marker('authentication-failed.failed.text'),
			Button: marker('authentication-failed.failed.button')
		},
		LoginThreshholdExceeded: {
			Title: marker('authentication-failed.login-threshhold-exceeded.title'),
			Text: marker('authentication-failed.login-threshhold-exceeded.text'),
			Button: marker('authentication-failed.login-threshhold-exceeded.button')
		},
		Invalid: {
			Title: marker('authentication-failed.invalid.title'),
			Text: marker('authentication-failed.invalid.text'),
			Button: marker('authentication-failed.invalid.button')
		}
	},
	Maintenance: {
		Title: marker('maintenance.title'),
		Subtitle: marker('maintenance.subtitle'),
		Text: marker('maintenance.text'),
		Button: {
			Title: marker('maintenance.button.title'),
			Text: marker('maintenance.button.text')
		},
		Update: {
			Title: marker('maintenance.update.title'),
			Text: marker('maintenance.update.text'),
			Continue: marker('maintenance.update.continue'),
			ReadUpdates: marker('maintenance.update.read-updates')
		}
	},
	Disconnected: {
		Title: marker('disconnected.title'),
		Subtitle: marker('disconnected.subtitle'),
		Text: marker('disconnected.text'),
		Button: {
			Title: marker('disconnected.button.title'),
			Text: marker('disconnected.button.text')
		}
	},
	NotFound: {
		Title: marker('not-found.title'),
		Subtitle: marker('not-found.subtitle'),
		Text: marker('not-found.text'),
		Button: {
			Title: marker('not-found.button.title'),
			Text: marker('not-found.button.text')
		}
	},
	SomethingWentWrong: {
		Title: marker('something-went-wrong.title'),
		Subtitle: marker('something-went-wrong.subtitle'),
		Text: marker('something-went-wrong.text'),
		Button: {
			Title: marker('something-went-wrong.button.title'),
			Text: marker('something-went-wrong.button.text')
		}
	},
	InvitationError: {
		Title: marker('invitation-error.title'),
		Subtitle: marker('invitation-error.subtitle'),
		Text: marker('invitation-error.text')
	},
	Stop: {
		Title: marker('stop.title'),
		Text: marker('stop.text'),
		Steps: {
			Title: marker('stop.steps.title'),
			One: marker('stop.steps.one'),
			Two: marker('stop.steps.two')
		},
		Button: {
			Title: marker('stop.button.title'),
			Text: marker('stop.button.text')
		}
	}
};
