import { marker } from '@ngneat/transloco-keys-manager/marker';

export const AdviceI18nKeys = {
	AdviceOverview: {
		Intro: marker('advice-overview.intro'),
		Notices: {
			UnregistrableEa: marker('advice-overview.notices.unregistrable-ea')
		}
	},
	AdviceDetail: {
		MinReadingTime: marker('advice-detail.min-reading-time'),
		LastUpdate: marker('advice-detail.last-update')
	}
};
