import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableArray, ObservableNumber } from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { filter, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import {
	FacetEntity,
	FacetFilter,
	FacetsResult,
	IndexedPagination,
	ProductEntity,
	ProductEntityResult,
	ProductsFilter,
	SortingEntity,
	SortingResultEntity,
	CJMResult
} from '@cjm/shared/types';
import { UserService } from '@cjm/shared/user';
import {
	convertFacetFilterToFacetFilterResult,
	convertFacetResultToFacet,
	convertPagination,
	convertCJMResult
} from '@cjm/shared/utils';

import { OfferedProductCollectionResult } from '../interfaces';
import { convertProductResultToProduct } from '../utils';

@Injectable()
export class ProductApiService {
	constructor(private readonly httpClient: CJMHttpClientService, private readonly userService: UserService) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * Creates the query params based on the provided filters
	 *
	 *  @param filter - The selected filter, index and searchQuery to filter the products/facets
	 */
	private getQueryParams(filter: ProductsFilter): HttpParams {
		return new HttpParams({
			fromObject: {
				...(filter.filters && !isEmpty(filter.filters)
					? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filter.filters)) }
					: {}),
				...(filter.searchQuery ? { zoek: filter.searchQuery } : {}),
				...(filter.size ? { limiet: filter.size } : {}),
				...(filter.index ? { index: filter.index } : {}),
				...(filter.sorting ? { sorteeroptie: filter.sorting } : {})
			}
		});
	}

	/**
	 * Fetches the facets from the API
	 *
	 * @param filters - The selected filters
	 */
	public getFacets(filters: FacetFilter, searchQuery: string): ObservableArray<FacetEntity> {
		return this.userService.loading$.pipe(
			filter((loading) => !loading),
			take(1),
			switchMap(() => {
				const params = new HttpParams({
					fromObject: {
						...(!isEmpty(filters)
							? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filters)) }
							: {}),
						...(searchQuery ? { zoek: searchQuery } : {})
					}
				});

				return this.httpClient.get<FacetsResult>(VLoketEndpoints.Products.Facets(), params);
			}),
			map(({ elementen }) => elementen.map((facet) => convertFacetResultToFacet(facet)))
		);
	}

	/**
	 * getOfferedProductsTotalCount
	 *
	 * The getOfferedProductsTotalCount method will call the offered products endpoint without filters and return the total count.
	 *
	 * @returns ObservableNumber
	 */
	public getOfferedProductsTotalCount(): ObservableNumber {
		return this.getOfferedProducts().pipe(map(({ pagination }) => pagination?.totalAmount));
	}

	/**
	 * Get offered products from the API
	 *
	 * @param productsFilter - The selected filter, index and searchQuery to filter the products
	 */
	public getOfferedProducts(
		productsFilter: ProductsFilter = {}
	): Observable<{ products: ProductEntity[]; pagination?: IndexedPagination }> {
		return this.userService.loading$.pipe(
			filter((loading) => !loading),
			take(1),
			switchMap(() => {
				const params = this.getQueryParams(productsFilter);

				return this.httpClient.get<OfferedProductCollectionResult>(
					VLoketEndpoints.Products.GetProducts(),
					params
				);
			}),
			map(({ inhoud, volgendePagina, paginatie }) => {
				return {
					products: inhoud.elementen.map((value) => convertProductResultToProduct(value.product)),
					...(paginatie ? { pagination: convertPagination(paginatie) } : {})
				};
			})
		);
	}

	/**
	 * Fetch specific product based on id
	 */
	public getProductDetail(id: string): Observable<ProductEntity> {
		return this.userService.loading$.pipe(
			filter((loading) => !loading),
			take(1),
			switchMap(() => this.httpClient.get<ProductEntityResult>(VLoketEndpoints.Products.Detail(id))),
			map((product) => convertProductResultToProduct(product))
		);
	}

	/**
	 * Fetch all the sorting options for products
	 */
	public getSortingOptions(): ObservableArray<SortingEntity> {
		return this.userService.loading$.pipe(
			filter((loading) => !loading),
			take(1),
			switchMap(() =>
				this.httpClient.get<CJMResult<SortingResultEntity, 'SorteerOpties'>>(VLoketEndpoints.Products.Sorting())
			),
			convertCJMResult<'SorteerOpties', SortingResultEntity, SortingEntity>((item) => {
				return {
					name: item.naam,
					id: item.id
				};
			}),
			catchError((err) => {
				// Iben: Log the error as a warning
				console.warn(err);

				// Iben: Return an empty array because not getting sorting options shouldn't break the frontend
				return of([]);
			})
		);
	}
}
