import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserEntity, UserService } from '@cjm/shared/user';

@Injectable()
export class UserResolver {
	constructor(private userService: UserService) {}

	public resolve(): Observable<UserEntity> {
		// Resolve the user data
		return this.userService.createUserSession().pipe(map(({ user }) => user));
	}
}
