import { Injectable } from '@angular/core';

import { ModalStates, Modal } from '@cjm/shared/types';

@Injectable()
export class ModalService {
	public state: ModalStates = {
		iomFeedbackSuccess: false,
		iomFeedbackFailure: false,
		iomNegativeFeedback: false,
		iomRefine: false,
		iomRefineSuccess: false,
		authLogoutSuccess: false,
		authSessionTimeout: false,
		loginThresholdExceeded: false
	};

	public updateState = (key: Modal, value: boolean) => (this.state[key] = value);
}
