import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'vloket-no-results',
	templateUrl: './no-results.component.html',
	styleUrls: ['./no-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoResultsComponent {
	@HostBinding('class.c-no-results') private readonly hasRootClass: boolean = true;

	@Input({ required: true }) public title: string;
	@Input({ required: true }) public description: string;
	@Input() public subtitle: string;
	@Input() public subDescription: string;
}
