import { Injectable } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { AbstractLanguageProviderService } from '@cjm/shared/core';
import { Language } from '@cjm/shared/types';

@Injectable()
export class LanguageProviderService extends AbstractLanguageProviderService {
	constructor(private readonly i18nService: I18nService) {
		super();
	}

	public get currentLanguage(): Language {
		return this.i18nService.currentLanguage as Language;
	}
}
