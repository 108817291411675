export interface NotificationLinkResultEntity {
	'@type': 'Actie';
	annotaties: string[];
	naam: string;
	actieLink: string;
	doelVanDeActie: string;
}

export interface NotificationResultEntity {
	'@type': 'Notificatie';
	bericht: string;
	ernst: 'info' | 'error';
	link: NotificationLinkResultEntity;
	naam: string;
}

export interface GetNotificationsApiResponseEntity {
	'@type': 'NotificatieVerzameling';
	elementen: NotificationResultEntity[];
}
