import { createBaseStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';

import { InvitationStoreSlice, RepresentativeInvitationEntity } from './invitation.store.types';

export const {
	actions: invitationActions,
	reducers: invitationReducers,
	selectors: invitationSelectors
} = createStoreAssets<InvitationStoreSlice>(StoreSlice.RepresentativeInvitations, [
	{
		subSlice: 'detail',
		generator: createBaseStoreAssets<RepresentativeInvitationEntity>
	}
]);
