import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserEIDIdentitySwitchDirective, UserEIDLoginDirective } from './directives';

/**
 * Brecht: The soul purpose of this AuthUIModule is to share directives and NOT components
 */
@NgModule({
	imports: [CommonModule],
	declarations: [UserEIDLoginDirective, UserEIDIdentitySwitchDirective],
	exports: [UserEIDLoginDirective, UserEIDIdentitySwitchDirective]
})
export class AuthUiModule {}
