import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	OnInit,
	Output,
	TemplateRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';

import { CypressTagsPaths } from '@cjm/cypress/shared';
import { OnDestroyComponent } from '@cjm/shared/types';

const INPUT_CONTROL_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SelectComponent), // tslint:disable-line:no-use-before-declare
	multi: true
};

@Component({
	selector: 'cjm-select',
	templateUrl: './select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [INPUT_CONTROL_ACCESSOR]
})
export class SelectComponent extends OnDestroyComponent implements ControlValueAccessor, OnInit {
	private onTouch: Function = () => {}; // tslint:disable-line:no-empty
	private onModelChange: Function = (_: any) => {}; // tslint:disable-line:no-empty

	public readonly control = new FormControl<string>('');

	/**
	 * **HostBinding**
	 *
	 * Whether the select should be horizontal.
	 */
	@HostBinding('class.c-select__horizontal') @Input({ required: true }) private horizontal: boolean;
	/**
	 * The name of the input
	 */
	@Input() public inputName = '';
	/**
	 * The items to display in the select.
	 */
	@Input({ required: true }) public items: unknown[] = [];
	/**
	 * The key that needs to match in the options.
	 *
	 * Default value is `id`
	 */
	@Input() public matchingKey: string = 'id';
	/**
	 * The placeholder for the select.
	 */
	@Input() public placeholder: string;
	/**
	 * Whether ot not an empty value should be added.
	 */
	@Input() public allowEmpty: boolean = false;
	/**
	 * The label to show if there are no items.
	 */
	@Input() public emptyLabel: string;
	/**
	 * The cypress tag for the input.
	 */
	@Input() public inputCypressTag: CypressTagsPaths;

	/**
	 * Emits when the select has changed.
	 */
	@Output() public readonly selectChanged = new EventEmitter();

	/**
	 * Optional template to style the items in the list.
	 */
	@ContentChild('itemTmpl', { static: true }) itemTemplate: TemplateRef<any>;

	constructor(private readonly cdRef: ChangeDetectorRef) {
		super();
	}

	public writeValue(value: any): void {
		this.control.patchValue(value, { emitEvent: false });
		this.cdRef.detectChanges();
	}

	public registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		!isDisabled ? this.control.enable({ emitEvent: false }) : this.control.disable({ emitEvent: false });
	}

	public ngOnInit(): void {
		this.control.valueChanges
			.pipe(
				tap((value) => {
					this.onModelChange(value);
					this.onTouch();
					this.selectChanged.emit();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
