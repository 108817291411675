import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { Language } from '@cjm/shared/i18n';
import { PwaService } from '@cjm/shared/pwa';
import { environment } from 'environments';

import { SharedPWAi18nKeys } from '../../../i18n';

@Component({
	selector: 'cjm-pwa-cta',
	templateUrl: './pwa-cta.component.html',
	styleUrls: ['./pwa-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaCtaComponent {
	@HostBinding('class') private readonly rootClass = 'c-carousel';
	@Input() set language(language: Language) {
		this.qrCodeUrl = `${environment.domain}/${language}/pwa`;
	}

	public readonly i18nKeys = SharedPWAi18nKeys.PWA;
	public readonly pageLink: string[] = ['pwa'];
	public readonly hasPwaInstallFunctionality: boolean = this.pwaService.hasPwaInstallFunctionality;
	public qrCodeUrl: string;

	constructor(private readonly pwaService: PwaService) {}

	public installApp() {
		this.pwaService.promptPwaInstall();
	}
}
