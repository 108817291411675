import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { isArray } from 'lodash';
import { takeUntil, tap } from 'rxjs';

import { OnDestroyComponent } from '@cjm/shared/types';
import { UserService } from '@cjm/shared/user';

import { UserCompanyPropertyEntity } from './../../data/interfaces/user.interface';

/**
 * Based upon `*ngIf`. See https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
 */
@Directive({
	selector: '[hasCompanyRole]'
})
export class HasCompanyRoleDirective extends OnDestroyComponent implements OnDestroy {
	private thenTemplateRef: TemplateRef<any> | null = null;
	private thenViewRef: EmbeddedViewRef<any> | null = null;
	private elseTemplateRef: TemplateRef<any> | null = null;
	private elseViewRef: EmbeddedViewRef<any> | null = null;
	private allowedRoles: UserCompanyPropertyEntity[] = [];
	private hasAllRoles: boolean = true;

	constructor(
		private readonly userService: UserService,
		templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
		super();
		this.thenTemplateRef = templateRef;
	}

	@Input() public set hasCompanyRole(role: UserCompanyPropertyEntity | UserCompanyPropertyEntity[]) {
		this.allowedRoles = isArray(role) ? role : [role];
		this.updateView();
	}

	@Input() public set hasCompanyRoleElse(ngTemplate: TemplateRef<any>) {
		this.elseTemplateRef = ngTemplate;
		this.elseViewRef = null;
		this.updateView();
	}

	// Benoit: via this input parameter, we define if the user should have all the specified company roles,
	// or if one of them is enough
	@Input() public set hasCompanyRoleAll(all: boolean) {
		this.hasAllRoles = all;
		this.updateView();
	}

	private updateView(): void {
		this.userService
			.userHasCompanyProperties(this.allowedRoles, this.hasAllRoles)
			.pipe(
				tap((isAllowed: boolean) => {
					if (isAllowed) {
						if (!this.thenViewRef) {
							this.viewContainer.clear();
							this.elseViewRef = null;
							if (this.thenTemplateRef) {
								this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
							}
						}
					} else {
						if (!this.elseViewRef) {
							this.viewContainer.clear();
							this.thenViewRef = null;
							if (this.elseTemplateRef) {
								this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
							}
						}
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
