import { createBaseStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';

import { AdviceDetailPageDataEntity } from '../facades';

import { AdviceStoreSlices } from './advice.store.types';

export const { actions, reducers, selectors } = createStoreAssets<AdviceStoreSlices>(StoreSlice.Advice, [
	{
		subSlice: 'adviceDetailPageData',
		generator: createBaseStoreAssets<AdviceDetailPageDataEntity>
	}
]);
