import { FormControl } from '@angular/forms';

export interface AutoCompleteOption {
	label: string;
	value: string;
}

export interface IAutoCompleteForm {
	input: string;
}

export interface IAutoCompleteFormGroup {
	input: FormControl<string>;
}
