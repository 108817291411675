/**
 * provideURLProtocol
 *
 * The provideURLProtocol util will check if a a provided string has a http or https protocol.
 * If not it will add a https:// protocol and return the string.
 *
 * @param value
 * @returns string
 */
export const provideURLProtocol = (value: string): string => {
	if (!value || typeof value !== 'string') {
		return value;
	}

	return /^(?:http|https)(?:\:\/\/)+/.test(value) ? value : `https://${value}`;
};
