<span class="c-filter__field" [class.c-checkbox__partial]="isPartialChecked">
	<input
		class="c-filter__input"
		#input
		type="checkbox"
		cypressTag="General.Input.Checkbox"
		[id]="id"
		[checked]="isChecked"
		[disabled]="disabled"
		[title]="i18nKeys.Input.Checkbox.Title | translate : { label: title }"
		(keydown.enter)="enterPressed()"
		(change)="onChange($any($event).target.checked)"
		(blur)="onTouched()"
	/>

	<span *ngIf="!isPartialChecked" class="c-filter__icon icon-checkmark"></span>
</span>

<label
	class="c-filter__label"
	[attr.for]="id"
	[title]="i18nKeys.Input.Checkbox.Title | translate : { label: label ? label : title }"
>
	<ng-content></ng-content>
</label>
