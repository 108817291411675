export interface ProductDetailPartnerInfo {
	name?: string;
	division?: string;
	contactName?: string;
	address?: string;
	phone?: string;
	hours?: string;
	website?: { url: string; text: string };
	url?: string;
	email?: string;
}
