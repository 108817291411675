import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BaseComponent } from './components';
import { providerServices } from './services';

@NgModule({
	imports: [CommonModule, RouterModule],
	providers: [...providerServices],
	declarations: [BaseComponent],
	exports: [BaseComponent]
})
export class VLoketCoreModule {}
