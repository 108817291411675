<cdk-accordion-item
	#accordionItem="cdkAccordionItem"
	role="button"
	tabindex="0"
	[attr.id]="'accordion-header-' + index"
	[attr.aria-expanded]="accordionItem.expanded"
	[attr.aria-controls]="'accordion-body-' + index"
	[expanded]="isCurrent"
	[disabled]="isCondensed || !(descriptions | isNotEmpty : ['title', 'date'])"
>
	<div
		class="c-timeline__item"
		[ngClass]="{
			'c-timeline__item--upcoming': isUpcoming,
			'c-timeline__item--first': isFirst,
			'c-timeline__item--current': isCurrent,
			'c-timeline__item--last': isLast,
			'c-timeline__item--full': !isCondensed,
			'c-timeline__item--condensed': isCondensed,
			'c-timeline__item--expanded': accordionItem.expanded
		}"
		(click)="accordionItem.toggle()"
	>
		<span class="c-timeline__item__step-indicator">
			<ng-container *ngIf="step">{{ step }}</ng-container>
		</span>

		<div class="c-timeline__item__header">
			<div>
				<strong *ngIf="title" class="c-timeline__item__title">{{ title }}</strong>
				<p *ngIf="date" class="c-vloket-text--s">{{ date | date : 'd MMMM y' }}</p>
			</div>

			<fa-icon
				*ngIf="!isCondensed && descriptions | arrayContainsOne : ['title', 'date']"
				class="c-timeline__item__accordion-indicator"
				[icon]="accordionItem.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
			/>
		</div>
	</div>

	<ng-container *ngFor="let description of descriptions">
		<div
			*ngIf="description | isNotEmpty : ['title', 'date']"
			class="c-timeline__description"
			role="region"
			[style.display]="isCondensed || accordionItem.expanded ? '' : 'none'"
			[attr.id]="'accordion-body-' + index"
			[attr.aria-labelledby]="'accordion-header-' + index"
		>
			<span class="c-timeline__description__step-indicator"></span>
			<div>
				<strong *ngIf="description.title">{{ description.title }}</strong>
				<p *ngIf="description.date" class="c-vloket-text--s">{{ description.date | date : 'd MMMM y' }}</p>
				<p *ngIf="description.content" class="c-vloket-text--s u-margin-bottom">{{ description.content }}</p>
				<div class="c-timeline__description__alert">
					<cjm-alert
						*ngIf="description?.alert"
						[alertTitle]="
							isCondensed
								? (i18nKeys.SharedAssociations.Timeline.ActionRequiredLabel | translate)
								: description.alert
						"
						[isClosable]="false"
						[alertType]="'is-warning'"
					/>
				</div>
				<p *ngIf="links?.length && isCurrent">
					<cjm-link
						*ngFor="let link of links"
						[classes]="buttonClasses.LinkButton"
						[to]="link.url"
						[title]="link.text"
						[type]="link.type"
						[icon]="link.icon"
						>{{ link.text }}</cjm-link
					>
				</p>
			</div>
		</div>
	</ng-container>
</cdk-accordion-item>
