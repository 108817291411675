<fieldset *ngIf="form" class="c-send-copy__form" [formGroup]="form">
	<cjm-checkbox
		class="u-no-margin-bottom"
		id="isActive"
		formControlName="isActive"
		cypressTag="SendCopy.IsActive.Input"
		[title]="i18nKeys.SharedAssociations.Forms.SendCopy.Label | translate"
		[label]="i18nKeys.SharedAssociations.Forms.SendCopy.Label | translate"
		>{{ i18nKeys.SharedAssociations.Forms.SendCopy.Label | translate }}</cjm-checkbox
	>
	<cjm-input-wrapper
		*ngIf="form.get('isActive').value"
		class="c-send-copy__form__email u-no-margin-bottom"
		id="recipient"
	>
		<input
			#input
			type="text"
			formControlName="recipient"
			cypressTag="SendCopy.Recipient.Input"
			[placeholder]="i18nKeys.SharedAssociations.Forms.SendCopy.Placeholder | translate"
		/>

		<ng-container description ngxErrors="recipient">
			<div ngxError="required" showWhen="dirty">
				{{ i18nKeys.FormLabels.IsRequired | translate }}
			</div>
		</ng-container>

		<ng-container description ngxErrors="recipient">
			<div ngxError="extendedEmail" showWhen="dirty">
				{{ i18nKeys.FormLabels.FormatAsEmail | translate }}
			</div>
		</ng-container>
	</cjm-input-wrapper>
</fieldset>
