import { Pipe, PipeTransform } from '@angular/core';

import { IconType } from '../../components/fa-icon/fa-icon.const';

import { SocialMediaFaIcon, SocialMediaIcon, SocialMediaPlatform } from './social-media-icon.pipe.types';

@Pipe({
	name: 'socialMediaIcon'
})
export class SocialMediaIconPipe implements PipeTransform {
	/**
	 * Returns correct icon for given url of social media platform
	 *
	 * @param value - The url string
	 */
	transform(value: string): SocialMediaIcon {
		if (!value || typeof value !== 'string' || value === '') {
			return;
		}

		const platform = Object.values(SocialMediaPlatform).find((platform: string) => value.includes(platform));

		if (!platform) {
			return {
				iconType: IconType.regular,
				icon: SocialMediaFaIcon.fallback
			};
		}

		const iconKey = Object.keys(SocialMediaPlatform)[Object.values(SocialMediaPlatform).indexOf(platform)];

		return {
			iconType: IconType.brands,
			icon: SocialMediaFaIcon[iconKey]
		};
	}
}
