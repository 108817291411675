import { Injectable } from '@angular/core';

import { AbstractScriptsProviderService } from '@cjm/shared/core';

@Injectable()
export class ScriptsProviderService extends AbstractScriptsProviderService {
	public get scripts(): Record<'name' | 'url', string>[] {
		return [
			{
				name: 'Widget Script Burgerprofiel Prod',
				url: 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js'
			},
			{
				name: 'Widget Script Burgerprofiel TNI',
				url: 'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js'
			}
		];
	}
}
