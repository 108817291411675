import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeBe from '@angular/common/locales/nl-BE';
import { ErrorHandler, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nService, NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { CookieModule } from 'ngx-cookie';
import { provideMatomo, withRouter } from 'ngx-matomo-client';

import { AcmHeaderModule } from '@cjm/shared/authentication/acm';
import { AuthDataModule, AuthUiModule } from '@cjm/shared/authentication/auth';
import { SharedCompanyModule } from '@cjm/shared/company';
import { ErrorService, SharedCoreModule } from '@cjm/shared/core';
import { FeatureUiModule } from '@cjm/shared/features';
import { LoadingUiModule } from '@cjm/shared/loading';
import { NotificationsDataModule } from '@cjm/shared/notifications';
import { ProductDataModule } from '@cjm/shared/products';
import { RedirectsUiModule } from '@cjm/shared/redirects';
import { AbstractI18nService, Language } from '@cjm/shared/types';
import { BreadcrumbModule } from '@cjm/shared/ui/breadcrumb';
import { SharedUiModule } from '@cjm/shared/ui/common';
import { SharedUiSidebarModule } from '@cjm/shared/ui/sidebar';
import { UserDataModule, UserModule } from '@cjm/shared/user';
import { VLoketCoreModule } from '@cjm/v-loket/core';
import { RegistrationModule } from '@cjm/v-loket/registration';
import { VloketSharedModule } from '@cjm/v-loket/shared';
import { environment } from '~environment';

registerLocaleData(localeBe);

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { VLoketTranslationLoader } from './translation.loader';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		AcmHeaderModule,
		VLoketCoreModule,
		BrowserAnimationsModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		SharedUiModule,
		BreadcrumbModule,
		LoadingUiModule,
		UserDataModule,
		AuthDataModule,
		AuthUiModule,
		FeatureUiModule,
		UserModule.forRoot(),
		StoreModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			connectInZone: true
		}),
		NgxI18nModule.forRoot(
			{
				defaultLanguage: Language.NL,
				availableLanguages: [Language.NL, Language.EN],
				defaultAssetPaths: ['./assets/i18n/shared/i18n/']
			},
			VLoketTranslationLoader
		),
		SharedCoreModule,
		ProductDataModule,
		CookieModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.hasServiceWorker,
			// Register the ServiceWorker as soon as the app is stable
			// or after 10 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:10000'
		}),
		VloketSharedModule,
		SharedUiSidebarModule,
		SharedCompanyModule,
		RedirectsUiModule,
		RegistrationModule,
		NotificationsDataModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:10000'
		})
	],
	providers: [
		{
			provide: LOCALE_ID,
			useValue: 'nl-BE'
		},
		{
			provide: AbstractI18nService,
			useClass: I18nService
		},
		{
			provide: ErrorHandler,
			useClass: ErrorService
		},
		{ provide: LOCALE_ID, useValue: 'nl-BE' },
		provideMatomo(
			{
				trackerUrl: environment.matomo.trackerUrl,
				siteId: environment.matomo.siteId
			},
			withRouter()
		)
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
