<cjm-layout-container
	*ngIf="data"
	class="c-advice-detail-page-split-block"
	[preventSpacing]="['top', 'bottom']"
	[class]="'c-advice-detail-page-split-block--' + splitVariants[data.variant]"
	[layoutType]="splitVariants[data.variant] && data?.image?.url ? 'split-equal' : 'full-width'"
	[asidePosition]="splitVariants[data.variant] === splitVariants.AfbeeldingLinks ? 'left' : 'right'"
>
	<div
		*ngIf="splitVariants[data.variant] === splitVariants.AfbeeldingLinks"
		class="c-advice-detail-page-split-block__image"
		asideLeft
		[style.background-image]="'url(' + data?.image?.url + ')'"
	></div>
	<div class="c-advice-detail-page-split-block__content u-wysiwyg" main>
		<h2 *ngIf="data.title" class="c-vloket-header c-vloket-header--h3">{{ data.title }}</h2>
		<span *ngIf="data.text" [outerHTML]="data.text"></span>
	</div>
	<div
		*ngIf="splitVariants[data.variant] === splitVariants.AfbeeldingRechts"
		class="c-advice-detail-page-split-block__image"
		asideRight
		[style.background-image]="'url(' + data?.image?.url + ')'"
	></div>
</cjm-layout-container>
