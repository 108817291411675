export enum Language {
	EN = 'en',
	NL = 'nl'
}

export enum Languages {
	nl_BE = 'nl-BE'
}

export enum CodeToLanguage {
	'nl-BE' = 'nl'
}
