enum VLoketBaseUrl {
	ActiveCases = 'lopende-dossiers',
	AdvicePages = 'adviezen-pagina',
	Advices = 'adviezen',
	Associations = 'vereniging',
	Authentication = 'logout',
	CaseOverview = 'lopende-dossiers-pagina',
	ActionRequired = 'actie-vereist',
	Company = 'onderneming',
	ContactItems = 'contactgegevens',
	ContentPages = 'paginas/naam',
	Divisions = 'afdelingen',
	Facets = 'facets',
	GlobalSearch = 'algemeen-zoeken',
	InTheSpotlight = 'in-de-kijker',
	Invites = 'uitnodigingen',
	InvitesWithRequests = 'aanvragen-en-uitnodigingen',
	Requests = 'aanvragen',
	KBO = 'kbo',
	Locations = 'locaties',
	Log = 'logboek',
	MainActivities = 'hoofdactiviteiten',
	MyAssociation = 'mijn-vereniging',
	Notifications = 'notificaties',
	Products = 'aangeboden-producten',
	Representatives = 'vertegenwoordigers',
	Search = 'zoeken',
	SortOptions = 'sorteeropties',
	Status = 'status',
	User = 'gebruiker',
	UserContext = 'gebruikerscontext',
	UserSession = 'gebruikerssessie'
}

export const VLoketEndpoints: Partial<Record<keyof typeof VLoketBaseUrl, Record<string, (...args: any) => string>>> = {
	ActiveCases: {
		Detail: (caseSource: number, caseNumber: string) => `${VLoketBaseUrl.ActiveCases}/${caseSource}/${caseNumber}`,
		ActionRequired: () => `${VLoketBaseUrl.ActiveCases}/${VLoketBaseUrl.ActionRequired}`
	},
	AdvicePages: {
		GetAdvicePages: () => VLoketBaseUrl.AdvicePages,
		Facets: () => `${VLoketBaseUrl.Advices}/${VLoketBaseUrl.Facets}`
	},
	Associations: {
		PostAssociation: () => VLoketBaseUrl.Associations,
		PostKBOAssociation: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.KBO}`,
		GetAssociations: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.Search}`,
		GetAssociationDetail: (id: string) => `${VLoketBaseUrl.Associations}/${id}`,
		Sorting: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.SortOptions}`,
		GetMainActivities: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MainActivities}`,
		GetMyAssociation: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}`,
		BaseKBOMyAssociation: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/kbo`,
		// Contact items
		PostContactItem: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.ContactItems}`,
		PatchContactItem: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.ContactItems}/${id}`,
		PatchKBOContactItem: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.KBO}/${VLoketBaseUrl.ContactItems}/${id}`,
		DeleteContactItem: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.ContactItems}/${id}`,
		// Representatives
		PostRepresentative: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Representatives}`,
		PatchRepresentative: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Representatives}/${id}`,
		PatchKBORepresentative: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.KBO}/${VLoketBaseUrl.Representatives}/${id}`,
		DeleteRepresentative: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Representatives}/${id}`,
		// Representative invites
		GetRepresentativeInvite: (id: string) => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.Invites}/${id}`,
		GetRepresentativeInvites: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Invites}`,
		GetRepresentativeInvitesWithRequests: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.InvitesWithRequests}`,
		RequestRepresentativeInvite: (vCode: string) => `${VLoketBaseUrl.Associations}/${vCode}/aanvragen`,
		AcceptRepresentativeInvites: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.Invites}/${id}/aanvaarden`,
		DeclineRepresentativeInvites: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.Invites}/${id}/weigeren`,
		PostRepresentativeInvite: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Invites}`,
		RevokeRepresentativeInvite: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Invites}/${id}/intrekken`,
		RepresentativeInvitesActionRequired: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Invites}/${VLoketBaseUrl.ActionRequired}`,
		RepresentativeInvitesWithRequestsActionRequired: () =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.InvitesWithRequests}/${VLoketBaseUrl.ActionRequired}`,
		// Representative requests
		GrantRepresentativeRequest: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.Requests}/${id}/aanvaarden`,
		DenyRepresentativeRequest: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.Requests}/${id}/weigeren`,
		SubmitRepresentativeRequest: (vCode: string) =>
			`${VLoketBaseUrl.Associations}/${vCode}/${VLoketBaseUrl.Requests}`,
		// Locations
		PostLocation: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Locations}`,
		PatchLocation: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Locations}/${id}`,
		PatchKBOLocation: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.KBO}/${VLoketBaseUrl.Locations}/${id}`,
		DeleteLocation: (id: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Locations}/${id}`,
		GetLog: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Log}`,
		// Divisions
		GetDivisions: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Divisions}`,
		GetDivision: (vCode: string) =>
			`${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Divisions}/${vCode}`,
		PostDivision: () => `${VLoketBaseUrl.Associations}/${VLoketBaseUrl.MyAssociation}/${VLoketBaseUrl.Divisions}`
	},
	Authentication: {
		LogoutWitEId: () => 'logout',
		Logout: () => 'gebruiker/afmelden'
	},
	CaseOverview: {
		Overview: () => `${VLoketBaseUrl.CaseOverview}`
	},
	Company: {
		GetCompany: (companyNumber: string) => `${VLoketBaseUrl.Company}/${companyNumber}`
	},
	ContentPages: {
		SinglePage: (pageId: string) => `${VLoketBaseUrl.ContentPages}/${pageId}`
	},
	GlobalSearch: {
		GlobalSearch: () => VLoketBaseUrl.GlobalSearch
	},
	InTheSpotlight: {
		InTheSpotlight: () => VLoketBaseUrl.InTheSpotlight
	},
	Notifications: {
		GetNotifications: () => VLoketBaseUrl.Notifications
	},
	Products: {
		Facets: () => `${VLoketBaseUrl.Products}/${VLoketBaseUrl.Facets}`,
		Detail: (id: string) => `${VLoketBaseUrl.Products}/detail/${id}`,
		Sorting: () => `${VLoketBaseUrl.Products}/sorteeropties`,
		GetProducts: () => 'aangeboden-producten-pagina'
	},
	Status: {
		Status: () => VLoketBaseUrl.Status
	},
	User: {
		UserContext: (id: string) => `${VLoketBaseUrl.UserContext}/${id}`,
		UserSession: () => VLoketBaseUrl.UserSession
	}
};
