import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'cjm-hamburger',
	templateUrl: 'hamburger.component.html',
	styleUrls: ['./hamburger.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HamburgerComponent {
	@HostBinding('class') private readonly rootClass = 'u-flex';

	@Input() public isActive: boolean;
	@Output() public handleClick = new EventEmitter();

	public readonly i18nKeys = I18nKeys;

	public handleButtonClick(): void {
		this.handleClick.emit();
	}
}
