import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments';

import { BpostApiResult } from '../../interfaces';

import {
	BoxNumberSearchResult,
	LocalitySearchResult,
	StreetNumberSearchResult,
	StreetSearchResult
} from './address.types';

@Injectable()
export class AddressService {
	constructor(private readonly httpClient: HttpClient) {}

	/**
	 * getLocality
	 *
	 * The getLocality method will fetch the localities from the Bpost API.
	 *
	 * @param q
	 * @param maxNumberOfSuggestions
	 * @param Observable:BpostApiResult:LocalitySearchResult
	 */
	public getLocality(
		q: string,
		maxNumberOfSuggestions: number = 5
	): Observable<BpostApiResult<LocalitySearchResult>> {
		const params = new HttpParams()
			.set('q', q)
			.set('maxNumberOfSuggestions', maxNumberOfSuggestions)
			.set('_format', 'json');

		const headers = new HttpHeaders().set('x-api-key', environment.bpost.apiKey);

		return this.httpClient.get<BpostApiResult<LocalitySearchResult>>(
			`${environment.bpost.host}${environment.bpost.autoCompletePath}/locality`,
			{
				params,
				headers
			}
		);
	}

	/**
	 * getStreet
	 *
	 * The getStreet method will fetch the streets from the Bpost API.
	 *
	 * @param q
	 * @param postalCode
	 * @param locality
	 * @param maxNumberOfSuggestions
	 * @param Observable:BpostApiResult:StreetSearchResult
	 */
	public getStreet(
		q: string,
		postalCode: string,
		locality: string,
		maxNumberOfSuggestions: number = 5
	): Observable<BpostApiResult<StreetSearchResult>> {
		const params = new HttpParams()
			.set('q', q)
			.set('postalCode', postalCode)
			.set('locality', locality)
			.set('maxNumberOfSuggestions', maxNumberOfSuggestions)
			.set('_format', 'json');

		const headers = new HttpHeaders().set('x-api-key', environment.bpost.apiKey);

		return this.httpClient.get<BpostApiResult<StreetSearchResult>>(
			`${environment.bpost.host}${environment.bpost.autoCompletePath}/street`,
			{
				params,
				headers
			}
		);
	}

	/**
	 * getStreetNumber
	 *
	 * The getStreetNumber method will fetch the streets numbers from the Bpost API.
	 *
	 * @param q
	 * @param postalCode
	 * @param locality
	 * @param maxNumberOfSuggestions
	 * @param street
	 * @param Observable:BpostApiResult:StreetSearchResult
	 */
	public getStreetNumber(
		q: string,
		postalCode: string,
		locality: string,
		street: string,
		maxNumberOfSuggestions: number = 5
	): Observable<BpostApiResult<StreetNumberSearchResult>> {
		const params = new HttpParams()
			.set('q', q)
			.set('postalCode', postalCode)
			.set('locality', locality)
			.set('street', street)
			.set('maxNumberOfSuggestions', maxNumberOfSuggestions)
			.set('_format', 'json');

		const headers = new HttpHeaders().set('x-api-key', environment.bpost.apiKey);

		return this.httpClient.get<BpostApiResult<StreetNumberSearchResult>>(
			`${environment.bpost.host}${environment.bpost.autoCompletePath}/streetNumber`,
			{
				params,
				headers
			}
		);
	}

	/**
	 * getBoxNumber
	 *
	 * The getBoxNumber method will fetch the box numbers from the Bpost API.
	 *
	 * @param q
	 * @param postalCode
	 * @param locality
	 * @param maxNumberOfSuggestions
	 * @param street
	 * @param streetNumber
	 * @param Observable:BpostApiResult:StreetSearchResult
	 */
	public getBoxNumber(
		q: string,
		postalCode: string,
		locality: string,
		street: string,
		streetNumber: string,
		maxNumberOfSuggestions: number = 5
	): Observable<BpostApiResult<BoxNumberSearchResult>> {
		const params = new HttpParams()
			.set('q', q)
			.set('postalCode', postalCode)
			.set('locality', locality)
			.set('street', street)
			.set('streetNumber', streetNumber)
			.set('maxNumberOfSuggestions', maxNumberOfSuggestions)
			.set('_format', 'json');

		const headers = new HttpHeaders().set('x-api-key', environment.bpost.apiKey);

		return this.httpClient.get<BpostApiResult<BoxNumberSearchResult>>(
			`${environment.bpost.host}${environment.bpost.autoCompletePath}/boxNumber`,
			{
				params,
				headers
			}
		);
	}
}
