import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { Observable } from 'rxjs';

import { bpostHost, sasEndPoint } from './end-points';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
	constructor(private readonly i18nService: I18nService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Denis: do not set these headers on the Bpost API requests. They cause CORS errors.
		if (req.url === sasEndPoint || req.url.indexOf(bpostHost) > -1) {
			return next.handle(req);
		}

		const defaultHeadersReq = req.clone({
			headers: req.headers
				.set('Accept-Language', this.i18nService.currentLanguage)
				.set('Content-Type', 'application/json')
				.set('User-UUID', '')
		});

		return next.handle(defaultHeadersReq);
	}
}
