import { VloketLinkIcon } from '@cjm/shared/ui/common';

export enum AlertType {
	Warning = 'is-warning',
	Error = 'is-error',
	Success = 'is-success',
	Info = 'is-information'
}

export const AlertIcons: Record<AlertType, VloketLinkIcon> = {
	[AlertType.Warning]: { type: 'fa-solid', icon: ' fa-circle-exclamation' },
	[AlertType.Error]: { type: 'fa-solid', icon: ' fa-circle-xmark' },
	[AlertType.Success]: { type: 'fa-solid', icon: ' fa-circle-check' },
	[AlertType.Info]: { type: 'fa-solid', icon: 'fa-circle-info' }
};
