export enum EnvironmentType {
	LOCAL = 'local',
	DEV = 'dev',
	TEST = 'test',
	RELEASE = 'release',
	ACCEPTANCE = 'acceptance',
	PRODUCTION = 'production',
	BACKEND = 'backend'
}

export type LoketType = 'vLoket';
