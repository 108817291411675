import { FormControl } from '@angular/forms';

import { RepresentativeRoleType } from './representative-form.const';

export interface IRepresentativeForm {
	objectId?: string;
	source?: string;
	isPrimary?: boolean;
	representativeFirstnameField: string;
	representativeSurnameField: string;
	representativeCallnameField: string;
	representativeRoleField: RepresentativeRoleType | string;
	representativeEmailField: string;
	representativePhoneField: string;
	representativeMobilePhoneField: string;
	representativeSocialMedia: string;
	representativeRRNField: string;
	representativeInviteMessage?: string;
}

export interface IRepresentativeFormGroup {
	objectId?: FormControl<string>;
	source?: FormControl<string>;
	isPrimary?: FormControl<boolean>;
	representativeFirstnameField: FormControl<string>;
	representativeSurnameField: FormControl<string>;
	representativeCallnameField: FormControl<string>;
	representativeRoleField: FormControl<RepresentativeRoleType | string>;
	representativeEmailField: FormControl<string>;
	representativePhoneField: FormControl<string>;
	representativeMobilePhoneField: FormControl<string>;
	representativeSocialMedia: FormControl<string>;
	representativeRRNField: FormControl<string>;
	representativeInviteMessage?: FormControl<string>;
}
