import { merge } from '@studiohyperdrive/utils';

import {
	RepresentativeInvitationEntity,
	RepresentativeInvitedEntity,
	RepresentativeInvitedResultEntity,
	RepresentativeInviterEntity,
	RepresentativeInviteResultEntity,
	RepresentativeInviterResultEntity
} from '@cjm/v-loket/repositories';
import { AssociationDetailEntity, parseAssociation } from '@cjm/v-loket/shared';

/**
 * parseInviter
 *
 * The parseInviter util parses the RepresentativeInviterResultEntity into a RepresentativeInviterEntity.
 *
 * @param {RepresentativeInviterResultEntity} inviter - The inviter to parse.
 * @returns {RepresentativeInviterEntity|undefined} The parsed inviter entity.
 */
export const parseInviter = (inviter: RepresentativeInviterResultEntity): RepresentativeInviterEntity => {
	if (!inviter) {
		return;
	}

	return merge({}, ['@type', inviter['@type']], ['id', inviter.objectId]);
};

/**
 * parseInvited
 *
 * The parseInvited util parses the invited representative information.
 *
 * @param {RepresentativeInvitedResultEntity} invited - The invited representative information.
 * @returns {RepresentativeInvitedEntity} - The parsed invited representative data.
 */
export const parseInvited = (invited: RepresentativeInvitedResultEntity): RepresentativeInvitedEntity => {
	if (!invited) {
		return;
	}

	return merge(
		{},
		['@type', invited['@type']],
		['insz', invited.insz],
		['firstname', invited.voornaam],
		['lastname', invited.achternaam],
		['email', invited.email]
	);
};

/**
 * parseInvitation
 *
 * The parseInvitation util parses a RepresentativeInviteResultEntity to a RepresentativeInvitationEntity.
 *
 * @param {RepresentativeInviteResultEntity} invitation - The invitation to parse.
 * @returns {RepresentativeInvitationEntity} - The parsed invitation.
 */
export const parseInvitation = (invitation: RepresentativeInviteResultEntity): RepresentativeInvitationEntity => {
	if (!invitation) {
		return;
	}

	const associationDetail = parseAssociation<AssociationDetailEntity>(invitation.vereniging);

	return {
		...merge(
			{},
			['@type', invitation['@type']],
			['@id', invitation['@id']],
			['id', invitation.objectId],
			['vCode', invitation.vCode],
			['message', invitation.boodschap],
			['status', invitation.status],
			['timestamp', invitation.tijdstip]
		),
		associationDetail,
		inviter: parseInviter(invitation.uitnodiger),
		invited: parseInvited(invitation.uitgenodigde)
	};
};
