import { AdviceDetailPageDividerComponent } from './advice-detail-page-divider/advice-detail-page-divider.component';
import { AdviceDetailPageHeaderComponent } from './advice-detail-page-header/advice-detail-page-header.component';
import { AdviceDetailPageLinkBlockComponent } from './advice-detail-page-link-block/advice-detail-page-link-block.component';
import { AdviceDetailPageRecommendedBlockComponent } from './advice-detail-page-recommended-block/advice-detail-page-recommended-block.component';
import { AdviceDetailPageRichtTextComponent } from './advice-detail-page-richtext/advice-detail-page-richtext.component';
import { AdviceDetailPageRoadmapComponent } from './advice-detail-page-roadmap/advice-detail-page-roadmap.component';
import { AdviceDetailPageSplitComponent } from './advice-detail-page-split/advice-detail-page-split.component';

export const components = [
	AdviceDetailPageHeaderComponent,
	AdviceDetailPageDividerComponent,
	AdviceDetailPageSplitComponent,
	AdviceDetailPageRoadmapComponent,
	AdviceDetailPageRichtTextComponent,
	AdviceDetailPageRecommendedBlockComponent,
	AdviceDetailPageLinkBlockComponent
];

export { AdviceDetailPageHeaderComponent };
export { AdviceDetailPageDividerComponent };
export { AdviceDetailPageSplitComponent };
export { AdviceDetailPageRoadmapComponent };
export { AdviceDetailPageRichtTextComponent };
export { AdviceDetailPageRecommendedBlockComponent };
export { AdviceDetailPageLinkBlockComponent };
