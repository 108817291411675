import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AssociationActivityEntity } from '../../../data';

@Component({
	selector: 'vloket-association-detail-activities',
	templateUrl: './association-detail-activities.component.html',
	styleUrls: ['./association-detail-activities.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationDetailActivitiesComponent {
	@Input() public activities: AssociationActivityEntity[];
}
