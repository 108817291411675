import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconListItem } from './icon-list.types';

@Component({
	selector: 'vloket-icon-list',
	templateUrl: './icon-list.component.html',
	styleUrls: ['./icon-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconListComponent {
	@Input() public items: IconListItem[] = [];
}
