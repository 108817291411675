import { Component, HostBinding, Input } from '@angular/core';

import { ButtonClasses } from '@cjm/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

import { ErrorComponentInput } from './error.component.interface';

@Component({
	selector: 'cjm-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
	@HostBinding('class.c-error') private readonly hasRootClass: boolean = true;

	@Input({ required: true }) public pageData: ErrorComponentInput;

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly i18nKeys: typeof RedirectI18nKeys = RedirectI18nKeys;
}
