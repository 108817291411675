import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { sharedI18nKeys } from '@cjm/shared/i18n';
import { ButtonClasses, VloketLinkIcon } from '@cjm/shared/ui/common';

import { AlertIcons, AlertType } from './alert.const';

@Component({
	selector: 'cjm-alert',
	templateUrl: './alert.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {
	public readonly buttonClasses = ButtonClasses;
	public readonly alertTypes = AlertType;
	public readonly i18nKeys = sharedI18nKeys;
	public alertIcon: VloketLinkIcon;

	/**
	 * Title of the alert
	 */
	@Input() public alertTitle: string;

	/**
	 * Message for the alert
	 */
	@Input() public alertMessage: string;

	/**
	 * Alert type options
	 */
	@Input() public alertType: `${AlertType}` = AlertType.Info;

	/**
	 * Determines whether the alert component can be closed or not. It is by default set to `true`.
	 * If it is set to false, then the alert component will not have a close button and cannot be dismissed.
	 */
	@Input() public isClosable: boolean = true;

	/**
	 * Event fired when the close button is clicked on the alert.
	 */
	@Output() public readonly buttonClicked: EventEmitter<string> = new EventEmitter<'close' | 'dismiss'>();

	public ngOnInit(): void {
		this.alertIcon = AlertIcons[this.alertType];
	}
}
