import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { IconType } from '@cjm/shared/ui/common';

import { IconClassType, IconListItem } from '../../../ui';
import { MyAssociationProgressProperty, DetailInfoSection } from '../../const';
import { MyAssociationDetailProgressTaskEntity } from '../../interfaces';

/**
 * parseProgressTasksToIconListItems
 *
 * The parseProgressTasksToIconListItems helper will parse the progress tasks to a list of IconListItems.
 *
 * @param tasks the array of tasks
 * @param lang the current language (from the i18nService)
 * @param provideRoutesForUncompleteTasks should the parser provide a router-link for the uncompleted items?
 */
export const parseProgressTasksToIconListItems = (
	tasks: MyAssociationDetailProgressTaskEntity[] = [],
	lang: string,
	provideRoutesForUncompleteTasks: boolean = true
): IconListItem[] =>
	tasks.map((task: MyAssociationDetailProgressTaskEntity) => ({
		icon: task.isCompleted ? 'fa-check' : '',
		iconType: IconType.solid,
		text: task.description,
		iconClass: IconClassType.Light,
		...(!task.isCompleted && provideRoutesForUncompleteTasks ? parseProgressTaskRoute(task.property, lang) : [])
	}));

/**
 * parseProgressTaskRoute
 *
 * The parseProgressTaskRoute helper will create route config for the icon-list
 * by checking the task property and matching it to the correct fragment.
 *
 * @param property
 * @param lang
 * @returns {
 * 	to: string | string[];
 * 	fragment: string
 * }
 */
export const parseProgressTaskRoute = (
	property: MyAssociationProgressProperty,
	lang: string
): {
	to: string[] | string;
	fragment?: string;
} => {
	const to: string[] = ['/', lang, VLoketAppRoutePaths.MyAssociation, VLoketAppRoutePaths.AssociationData];

	switch (property) {
		case MyAssociationProgressProperty.ContactInfo:
			return {
				to,
				fragment: `section-${DetailInfoSection.Contact}`
			};

		case MyAssociationProgressProperty.Locations:
			return {
				to,
				fragment: `section-${DetailInfoSection.Location}`
			};

		case MyAssociationProgressProperty.Representatives:
			return {
				to: ['/', lang, VLoketAppRoutePaths.MyAssociation, VLoketAppRoutePaths.AssociationRepresentatives]
			};

		// Denis: all remaining checklist items need to lead to the basic info edit
		default:
			return {
				to: [...to, VLoketAppRoutePaths.Edit]
			};
	}
};
