import { ChangeDetectorRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
	LinkReference,
	WITH_ROUTER_LINKS_CONFIG,
	WithRouterLinkPipe,
	WithRouterLinksConfig
} from '@studiohyperdrive/ngx-utils';

@Pipe({
	name: 'translateWithRouterLinks'
})
export class TranslateWithRouterLinksPipe implements PipeTransform {
	private withRouterLinksPipe: WithRouterLinkPipe;
	private translatePipe: TranslatePipe;

	constructor(
		@Inject(WITH_ROUTER_LINKS_CONFIG) private readonly config: WithRouterLinksConfig,
		private readonly sanitizer: DomSanitizer,
		private readonly translate: TranslateService,
		private readonly _ref: ChangeDetectorRef
	) {
		this.withRouterLinksPipe = new WithRouterLinkPipe(config, sanitizer);
		this.translatePipe = new TranslatePipe(translate, _ref);
	}

	public transform(value: string, linkReferences: LinkReference[] = [], loading: boolean = false): any {
		if (loading) {
			return;
		}

		return this.withRouterLinksPipe.transform(this.translatePipe.transform(value), linkReferences);
	}
}
