import { FormControl, FormGroup, FormRecord } from '@angular/forms';

export type FilterBarForm = Record<string, Record<string, boolean>>;

export type FilterBarFormGroup = FormGroup<{
	[key: string]: FormRecord<FormControl<boolean>>;
}>;

export interface RefineableFacetValue {
	control: FormControl<boolean>;
	children: string[];
	facetId: string;
}
