<ng-container *mediaQuery="mediaQueryMax.Desktop">
	<ng-container *ngIf="!preventCollapse">
		<cjm-hamburger
			*ngIf="showHamburger; else hamburgerFallbackTmpl"
			[isActive]="hasIsOpenClass"
			(handleClick)="handleMenuButtonClick()"
		></cjm-hamburger>
	</ng-container>
</ng-container>

<nav class="c-menu__list-container" aria-describedby="main-navigation">
	<ul class="c-menu__list u-reset-list">
		<li
			*ngFor="let item of menuItems"
			class="c-menu__container-item"
			[cypressTag]="item.cypressTag"
			[class.c-menu__container-item--show-active]="showActiveItem"
			[routerLinkActive]="['is-active']"
			[routerLinkActiveOptions]="{ exact: !!item.exact }"
		>
			<a
				class="c-menu__link u-outline"
				[class.c-menu__link--show-active]="showActiveItem"
				[routerLink]="item.routerLink"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: !!item.exact }"
				(focusClick)="closeMenu()"
			>
				<ng-template
					*ngIf="itemTemplate; else noItemTmpl"
					[ngTemplateOutlet]="itemTemplate"
					[ngTemplateOutletContext]="{ $implicit: item.id }"
				>
				</ng-template>

				<ng-template #noItemTmpl>
					{{ item.id }}
				</ng-template>
			</a>
		</li>
	</ul>
</nav>
<div class="c-menu__overlay" (handleClick)="closeMenu()"></div>

<ng-template #hamburgerFallbackTmpl>
	<a class="c-menu__link u-outline" (click)="handleMenuButtonClick()">
		{{ menuLabel }}
	</a>
</ng-template>
