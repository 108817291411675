<cjm-tag-group class="u-margin-top u-margin-bottom">
	<!-- min: 0 - max: 150: Not set -->
	<cjm-tag
		*ngIf="
			showNotSet &&
			targetAudience.minAge <= associationTargetAudience.MIN_AGE &&
			targetAudience.maxAge >= associationTargetAudience.DEFAULT_MAX_AGE
		"
		tagType="gray"
	>
		{{ i18nKeys.TargetAudience.Age.Default | translate }}
	</cjm-tag>

	<!-- min: 0 - max: 149: No restriction -->
	<cjm-tag
		*ngIf="
			targetAudience.minAge <= associationTargetAudience.MIN_AGE &&
			targetAudience.maxAge === associationTargetAudience.MAX_AGE
		"
		tagType="gray"
	>
		{{ i18nKeys.TargetAudience.Age.NoAgeRestriction | translate }}
	</cjm-tag>

	<!-- min: +0 - max: -149: min & max restriction -->
	<cjm-tag
		*ngIf="
			targetAudience.minAge > associationTargetAudience.MIN_AGE &&
			targetAudience.maxAge < associationTargetAudience.MAX_AGE
		"
		tagType="gray"
	>
		{{
			i18nKeys.TargetAudience.Age.FromTo
				| translate : { minAge: targetAudience.minAge, maxAge: targetAudience.maxAge }
		}}
	</cjm-tag>

	<!-- min: +0 - max: +149: min restriction -->
	<cjm-tag
		*ngIf="
			targetAudience.minAge > associationTargetAudience.MIN_AGE &&
			targetAudience.maxAge >= associationTargetAudience.MAX_AGE
		"
		tagType="gray"
	>
		{{ i18nKeys.TargetAudience.Age.From | translate : { minAge: targetAudience.minAge } }}
	</cjm-tag>

	<!-- min: -0 - max: -149: max restriction -->
	<cjm-tag
		*ngIf="
			targetAudience.minAge <= associationTargetAudience.MIN_AGE &&
			targetAudience.maxAge < associationTargetAudience.MAX_AGE
		"
		tagType="gray"
	>
		{{ i18nKeys.TargetAudience.Age.UpTo | translate : { maxAge: targetAudience.maxAge } }}
	</cjm-tag>
</cjm-tag-group>
