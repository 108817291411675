<cjm-layout-container
	*ngIf="link"
	class="c-vloket-newsletter"
	classesMain="c-vloket-newsletter__main"
	layoutType="full-width"
>
	<ng-container main>
		<div class="c-vloket-newsletter__main__content">
			<h3 class="c-vloket-header c-vloket-header--h3">{{ i18nKeys.Newsletter.Title | translate }}</h3>
			<p class="u-margin-bottom-lg">
				{{ i18nKeys.Newsletter.Text | translate }}
			</p>
			<cjm-link
				type="external"
				[classes]="buttonClasses.LinkButton"
				[to]="link.url"
				[noDecoration]="true"
				[icon]="link.icon"
				[title]="link.text | translate"
			>
				<span>{{ link.title | translate }}</span>
			</cjm-link>
		</div>
		<cjm-img *ngIf="imgLink" class="c-vloket-newsletter__main__img" [src]="imgLink" />
	</ng-container>
</cjm-layout-container>
