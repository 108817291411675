import {
	CallToActionEntity,
	CallToActionResult,
	FacetFilter,
	CJMElementsResult,
	CJMIdMeta,
	CJMResult,
	CJMTypeMeta
} from '@cjm/shared/types';

import { ProductPartnerEntity, ProductPartnerResult } from './product-partner.entity';

// External Interfaces
// ------------------------------------------------------------------------- /
export interface IPDCTextEntityResult {
	'@type': 'Tekst';
	tekst: string;
}

export interface IPDCAddressEntityResult {
	'@type': 'Adres';
	straat: string;
	nummer: string;
	bus: string;
	postcode: string;
	gemeente: string;
	land: string;
}

export interface IPDCContactEntityResult {
	'@type': string;
	adres: IPDCAddressEntityResult;
	emailadres: string;
	openingsuren: string;
	telefoonnummer: string;
	website: string;
}

export interface IPDCTextCollectionEntityResult {
	'@type': 'TekstVerzameling';
	elementen: IPDCTextEntityResult[];
}

export interface IPDCContactCollectionEntityResult {
	'@type': 'ContactgegevensVerzameling';
	elementen: IPDCContactEntityResult[];
}

export interface IPDCProductEntityResult {
	'@type': 'IpdcProduct';
	productID: string;
	regelgeving: IPDCTextCollectionEntityResult;
	uitzonderingen: IPDCTextCollectionEntityResult;
	voorwaarden: IPDCTextCollectionEntityResult;
	procedures: IPDCTextCollectionEntityResult;
	kosten: IPDCTextCollectionEntityResult;
	financieleVoordelen: IPDCTextCollectionEntityResult;
	contactgegevens: IPDCContactCollectionEntityResult;
}

export interface ProductEntityMetaDataResult {
	laatsteWijzigingsdatum: string;
}

export interface ProductEntityResult extends CJMTypeMeta<'Product'>, CJMIdMeta {
	id: string;
	naam: string;
	omschrijving: string;
	meerInfoLink: string;
	partners: ProductPartnerResult[];
	gebruikersacties: {
		elementen: CallToActionResult[];
	};
	infoLinks?: CJMResult<{ url: string; naam: string }>;
	labels?: CJMElementsResult<ProductTagEntityResult>;
	type?: string;
	ipdcProduct?: IPDCProductEntityResult;
	metadata: ProductEntityMetaDataResult;
}

// Translated interfaces
export interface IPDCTextEntity {
	'@type': 'Tekst';
	text: string;
}

export interface IPDCContactEntity {
	'@type': string;
	address: string;
	email: string;
	hours: string;
	phone: string;
	website: { url: string; text: string };
}

export interface IPDCTextCollectionEntity {
	'@type': 'TekstVerzameling';
	elements: IPDCTextEntity[];
}

export interface IPDCAddressCollectionEntity {
	'@type': 'ContactgegevensVerzameling';
	elements: IPDCContactEntity[];
}

export interface IPDCProductEntity {
	'@type': 'IpdcProduct';
	productId: string;
	regulations: IPDCTextCollectionEntity;
	exceptions: IPDCTextCollectionEntity;
	terms: IPDCTextCollectionEntity;
	procedures: IPDCTextCollectionEntity;
	costs: IPDCTextCollectionEntity;
	financialBenifits: IPDCTextCollectionEntity;
	contactInfo: IPDCAddressCollectionEntity;
}

export interface ProductEntityMetaDataEntity {
	lastModificationDate: string;
}

export interface ProductEntity {
	id: string;
	name: string;
	description: string;
	link: string;
	actions: CallToActionEntity[];
	partners: ProductPartnerEntity[];
	annotations?: string[];
	infoLinks?: ProductInfoLinkEntity[];
	tags?: ProductTagEntity[];
	type?: string;
	ipdcProduct?: IPDCProductEntity;
	metaData: ProductEntityMetaDataEntity;
}

export interface ProductInfoLinkEntity {
	link: string;
	name: string;
}

export interface ProductsFilter {
	filters?: FacetFilter;
	searchQuery?: string;
	size?: number;
	index?: string | number;
	sorting?: string;
}

export interface ProductTagEntityResult {
	naam: string;
	omschrijving: string;
}

export interface ProductTagEntity {
	name: string;
	description: string;
}

interface Pagination {
	limit?: number;
	offset: number | string;
}
