import { Params } from '@angular/router';

export interface ErrorComponentInput {
	title: string;
	subtitle?: string;
	text: string;
	visual: string;
	button?: {
		title: string;
		text: string;
		link: string | string[];
		queryParams: Params;
	};
}
