import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconType } from '@cjm/shared/ui/common';

import { ProductDetailPartnerInfo } from './product-detail-info-list.types';

@Component({
	selector: 'vloket-product-detail-info-list',
	templateUrl: './product-detail-info-list.component.html',
	styleUrls: ['./product-detail-info-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetailInfoListComponent {
	@Input() public partnerInfo: ProductDetailPartnerInfo;

	public readonly iconType: typeof IconType = IconType;
}
