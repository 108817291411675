import { Params } from '@angular/router';

import { LinkType } from '@cjm/shared/ui/common';

export interface BigFooterEntity {
	title: string;
	links: {
		title: string;
		url: string[];
		type: `${LinkType}`;
		queryParams?: Params;
	}[];
}
