<button
	[disabled]="isDisabled"
	[className]="classes"
	[ngClass]="{ 'is-disabled': isDisabled, 'c-button--dark-text': darkText }"
	[title]="title"
	[attr.type]="type ? type : null"
	(click)="handleButtonClick()"
>
	<span *ngIf="icon" [className]="overrideIconClass ? icon : 'c-button__icon-before icon-' + icon"> </span>

	<ng-content></ng-content>
</button>
