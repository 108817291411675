import { Language } from '@cjm/shared/types';

/**
 * Provides the current language to the client
 */
export abstract class AbstractLanguageProviderService {
	/**
	 * Returns the current language of the application
	 */
	public abstract get currentLanguage(): Language;
}
