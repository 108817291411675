import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'cjm-tag-group',
	templateUrl: './tag-group.component.html',
	styleUrls: ['./tag-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagGroupComponent {
	@HostBinding('class.c-cjm-tag-group') private readonly hasTagGroupClass: boolean = true;
}
