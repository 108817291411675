import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService } from '@studiohyperdrive/ngx-store';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CompanyEntity, CompanyService } from '@cjm/shared/company';
import { UserEntity, UserService } from '@cjm/shared/user';

@Injectable()
export class RegisterEaPageResolver extends StoreService {
	constructor(
		protected readonly store: Store,
		private readonly userService: UserService,
		private readonly companyService: CompanyService
	) {
		super(store);
	}

	public resolve(): Observable<CompanyEntity> {
		return this.userService.user$.pipe(
			filter((user: UserEntity) => !!user && !!user.company),
			switchMap(({ company }: UserEntity) => this.companyService.getCompany(company.number))
		);
	}
}
