import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { UserService } from '@cjm/shared/user';

/**
 * Based upon `*ngIf`. See https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
 */
@Directive({
	selector: '[isAuthenticated]'
})
export class IsAuthenticatedDirective implements OnDestroy {
	private readonly destroyed$ = new Subject();

	private thenTemplateRef: TemplateRef<any> | null = null;
	private thenViewRef: EmbeddedViewRef<any> | null = null;
	private elseTemplateRef: TemplateRef<any> | null = null;
	private elseViewRef: EmbeddedViewRef<any> | null = null;
	private shouldBeAuthenticated: boolean = true;

	constructor(
		private readonly userService: UserService,
		templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
		this.thenTemplateRef = templateRef;
	}

	@Input()
	set isAuthenticated(authenticated: boolean) {
		this.shouldBeAuthenticated = authenticated;
		this.updateView();
	}
	@Input()
	set isAuthenticatedElse(ngTemplate: TemplateRef<any>) {
		this.elseTemplateRef = ngTemplate;
		this.elseViewRef = null;
		this.updateView();
	}

	ngOnDestroy(): void {
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}

	private updateView() {
		this.userService.user$
			.pipe(
				map(Boolean),
				tap((isAuthenticated) => {
					if (
						(isAuthenticated && this.shouldBeAuthenticated) ||
						(!isAuthenticated && !this.shouldBeAuthenticated)
					) {
						if (!this.thenViewRef) {
							this.viewContainer.clear();
							this.elseViewRef = null;
							if (this.thenTemplateRef) {
								this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
							}
						}
					} else {
						// tslint:disable-line:no-collapsible-if
						if (!this.elseViewRef) {
							this.viewContainer.clear();
							this.thenViewRef = null;
							if (this.elseTemplateRef) {
								this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
							}
						}
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
