import { FormControlStatus } from '@angular/forms';
import { OperatorFunction, pipe } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

/**
 * checkForStatus
 *
 * The checkForStatus operator will set a debounceTime of 300
 * and then check whether the formControlStatus in the stream matches the one that's requested.
 *
 * @param {FormControlStatus} hasStatus - a value of the FormControlStatus type
 * @param {number} debounce - timing for debounce in ms, defaults to 300
 * @returns {boolean}
 */
export function checkForStatus(
	hasStatus: FormControlStatus,
	debounce: number = 300
): OperatorFunction<FormControlStatus, boolean> {
	return pipe(
		debounceTime(debounce),
		map((status: FormControlStatus) => status === hasStatus)
	);
}
