export interface CJMIdMeta {
	'@id': string;
}

export interface CJMTypeMeta<T = string> {
	'@type': T;
}

export interface CJMElementsResult<M> {
	elementen: M[];
}

export interface CJMResult<M, T = string> extends CJMIdMeta, CJMTypeMeta<T>, CJMElementsResult<M> {}

export interface CJMPagedResult<M> {
	volgendePagina: string;
	inhoud: CJMElementsResult<M>;
}
