import { of } from 'rxjs';

export const AdviceOverviewPageDataEntityMock = [
	{
		id: 'juridische_vorm',
		title: 'Welke juridische vorm kies ik voor mijn vereniging? nieuwe titel',
		description: '<p>De korte omschrijving van de nieuwe adviespagina</p>\n',
		heroImage: {
			url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/teaser/benjamin-wedemeyer-HHx_8m6-YNk-unsplash_250.jpeg',
			alt: 'Hiker and his dog standing on the mountain top with wide panorama view during sunset',
			height: '758',
			width: '1920',
			type: 'teaser'
		},
		keywords: [
			{ id: 'aankoop_huur', name: 'Aankoop/huur' },
			{ id: 'administratie', name: 'Administratie' },
			{ id: 'evenement', name: 'Evenement' }
		]
	},
	{
		id: 'een_nieuwe_pagina',
		title: 'een nieuwe pagina',
		description: '<p>Een korte omschrijving van de adviespagina</p>\n',
		heroImage: {
			url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/teaser/images_0.jpeg',
			alt: 'een kadootje',
			height: '225',
			width: '225',
			type: 'teaser'
		},
		keywords: [
			{ id: 'aankoop_huur', name: 'Aankoop/huur' },
			{ id: 'administratie', name: 'Administratie' }
		]
	}
];

export const AdviceOverviewPageFacadeMock = () => ({
	adviceOverviewPageData$: of([AdviceOverviewPageDataEntityMock]),
	init: jasmine.createSpy().and.returnValue(of(true))
});
