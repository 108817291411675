<cjm-layout-container class="p-registration-duplicates__intro" layoutType="split-equal">
	<ng-container main>
		<cjm-button
			classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }} u-margin-bottom-lg"
			[title]="i18nKeys.Registration.DuplicateCheck.EditCTA.Title | translate"
			[isDisabled]="actionInProgress()"
			[routerLink]="['..', this.appRoutePaths.RegistrationForm]"
		>
			<fa-icon icon="fa-pencil" /> {{ i18nKeys.Registration.DuplicateCheck.EditCTA.Text | translate }}
		</cjm-button>

		<h1 class="c-vloket-header c-vloket-header--h1">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ i18nKeys.Registration.DuplicateCheck.Title | translate }}
			</span>
		</h1>

		<!--
			Denis: outerHTML is used to replace the placeholder p-element with the HTML provided by the translation.
			This means that the p-element provided below, will not be rendered in the DOM.
		-->
		<div
			class="c-vloket-text--lg u-margin-bottom-xl"
			[innerHTML]="i18nKeys.Registration.DuplicateCheck.Intro | translate"
		></div>

		<cjm-alert
			[alertMessage]="i18nKeys.Registration.DuplicateCheck.AvoidDuplicatesNotice | translate"
			[alertType]="alertType.Info"
			[isClosable]="false"
		/>
	</ng-container>
</cjm-layout-container>

<ng-container *ngIf="conflictingAssociations() as conflictingAssociations">
	<cjm-layout-container layoutType="full-width" preventSpacing="top">
		<p class="p-registration-duplicates__duplicate-count" main>
			<strong>
				<span class="c-vloket-text-marking c-vloket-text-marking--underline">{{
					conflictingAssociations.length
				}}</span>
			</strong>
			{{
				(conflictingAssociations.length === 1
					? i18nKeys.Registration.DuplicateCheck.ResultInidicator
					: i18nKeys.Registration.DuplicateCheck.ResultsInidicator
				) | translate
			}}
		</p>
	</cjm-layout-container>

	<cjm-layout-container layoutType="full-width" preventSpacing="top">
		<ng-container main>
			<div class="p-registration-duplicates__duplicate-overview">
				<vloket-association-card
					*ngFor="let association of conflictingAssociations"
					classes="u-margin-bottom-lg"
					target="_blank"
					[cardInfo]="association"
					[withLink]="true"
					[link]="['/', i18nService.currentLanguage, appRoutePaths.Associations, association.id]"
				>
					<div class="p-registration-duplicates__duplicate-overview__actions">
						<cjm-link
							[title]="i18nKeys.Registration.DuplicateCheck.RequestMaintainershipCTA.Title | translate"
							[classes]="buttonClasses.LinkButton"
							[to]="[
								'/',
								i18nService.currentLanguage,
								appRoutePaths.Representative,
								appRoutePaths.Requests,
								association.id
							]"
							[queryParams]="{ email: registrationData().representatives[0].representativeEmailField }"
							>{{
								i18nKeys.Registration.DuplicateCheck.RequestMaintainershipCTA.Text | translate
							}}</cjm-link
						>

						<cjm-info
							[description]="i18nKeys.Registration.DuplicateCheck.ToolTip | translate"
							[tooltipPosition]="'above'"
						/>
					</div>
				</vloket-association-card>

				<ng-container *ngIf="confirmationToken() as confirmationToken">
					<cjm-button
						*ngIf="registrationData() as registrationData"
						class="p-registration-duplicates__duplicate-overview__create"
						classes="{{ buttonClasses.LinkNoDecoration }} "
						[title]="i18nKeys.Registration.DuplicateCheck.CreateCTA.Title | translate"
						[isDisabled]="actionInProgress()"
						(handleClick)="createAsNew(registrationData, confirmationToken)"
					>
						<fa-icon
							class="p-registration-duplicates__duplicate-overview__create__icon"
							icon="fa-plus"
							iconType="fa-solid"
						/>

						<span class="p-registration-duplicates__duplicate-overview__create__text">{{
							i18nKeys.Registration.DuplicateCheck.CreateCTA.Text | translate
						}}</span>
					</cjm-button>
				</ng-container>
			</div>

			<cjm-button
				classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }} u-margin-bottom-lg"
				[title]="i18nKeys.Registration.DuplicateCheck.EditCTA.Title | translate"
				[isDisabled]="actionInProgress()"
				[routerLink]="['..', this.appRoutePaths.RegistrationForm]"
			>
				<fa-icon icon="fa-pencil" /> {{ i18nKeys.Registration.DuplicateCheck.EditCTA.Text | translate }}
			</cjm-button>
		</ng-container>
	</cjm-layout-container>
</ng-container>
