import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';

import { I18nKeys } from '../../../i18n';

import { AssociationCardInfo } from './association-card.types';

@Component({
	selector: 'vloket-association-card',
	templateUrl: './association-card.component.html',
	styleUrls: ['association-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationCardComponent {
	@Input() public cardInfo: AssociationCardInfo;
	@Input() public classes: string;
	@Input() public disableShadow: boolean = false;
	@Input() public withLink: boolean = false;
	@Input() public target: '_self' | '_blank' | '_parent' | '_top' = '_self';
	@Input() public link: string | string[];

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly appRoutes: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(public readonly i18nService: I18nService) {}
}
