import { AdditionalRegistrationDataFormComponent } from './additional-registration-data-form/additional-registration-data-form.component';
import { BasicRegistrationDataFormComponent } from './basic-registration-data-form/basic-registration-data-form.component';
import { EaRegistrationDataFormComponent } from './ea-registration-data-form/ea-registration-data-form.component';
import { RegisterKboSnackbarComponent } from './register-kbo-snackbar/register-kbo-snackbar.component';

export const RegistrationComponents = [
	BasicRegistrationDataFormComponent,
	AdditionalRegistrationDataFormComponent,
	EaRegistrationDataFormComponent,
	RegisterKboSnackbarComponent
];

export * from './basic-registration-data-form/basic-registration-data-form.component';
export * from './basic-registration-data-form/basic-registration-data-form.types';

export * from './additional-registration-data-form/additional-registration-data-form.component';
export * from './additional-registration-data-form/additional-registration-data-form.types';

export * from './ea-registration-data-form/ea-registration-data-form.component';
export * from './ea-registration-data-form/ea-registration-data-form.types';

export * from './register-kbo-snackbar/register-kbo-snackbar.component';
