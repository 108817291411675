import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	Products: {
		Loading: {
			Facets: marker('products.loading.facets'),
			Products: marker('products.loading.products')
		},
		Actions: {
			LoadMore: marker('products.actions.load-more'),
			SearchWithoutFilters: marker('products.actions.search-without-filters'),
			Search: marker('products.actions.search'),
			Filter: marker('products.actions.filter'),
			ShowResults: marker('products.actions.show-results'),
			MoreFilters: marker('products.actions.more-filters'),
			LessFilters: marker('products.actions.less-filters'),
			ClearAll: marker('products.actions.clear-all'),
			Sort: marker('products.actions.sort'),
			ReadMoreRequest: marker('products.actions.read-more-request')
		},
		Detail: {
			Via: marker('products.detail.via'),
			RecommendedBy: marker('products.detail.recommended-by'),
			Recommended: marker('products.detail.recommended')
		},
		Empty: marker('products.empty'),
		EmptyAfterFilteredSearch: marker('products.empty-after-filtered-search'),
		NoFilters: marker('products.no-filters'),
		Intro: marker('products.intro'),
		Error: marker('products.error'),
		Recommended: 'products.recommended',
		ServiceProvider: marker('products.service-provider'),
		Recommendations: {
			Title: marker('products.recommendations.title'),
			Description: marker('products.recommendations.description'),
			Recommended: marker('products.recommendations.recommended')
		},
		Select: {
			InputName: marker('products.select.inputname')
		}
	},
	Recommendations: {
		Intro: marker('recommendations.intro'),
		Rating: {
			IsThisRelevant: marker('recommendations.rating.is-this-relevant'),
			Relevant: marker('recommendations.rating.relevant'),
			Irrelevant: marker('recommendations.rating.irrelevant')
		},
		Actions: {
			RemoveAllFeedBack: marker('recommendations.actions.remove-all-feedback'),
			RefineRecommendations: marker('recommendations.actions.refine-recommendations')
		},
		Progress: {
			InComplete: marker('recommendations.progress.incomplete'),
			Complete: {
				NoProducts: marker('recommendations.progress.complete.no-products'),
				SingleProduct: marker('recommendations.progress.complete.single-products'),
				MultipleProducts: marker('recommendations.progress.complete.multiple-products')
			}
		}
	}
};
