export type Modal =
	| 'authLogoutSuccess'
	| 'authSessionTimeout'
	| 'loginThresholdExceeded'
	| 'iomFeedbackSuccess'
	| 'iomFeedbackFailure'
	| 'iomNegativeFeedback'
	| 'iomRefine'
	| 'iomRefineSuccess';

export type ModalStates = {
	[key in Modal]: boolean;
};
