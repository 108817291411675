<form *ngIf="registerForm" class="c-vloket-form" [formGroup]="registerForm">
	<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="bottom">
		<ng-container asideLeft>
			<h2 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.Register.BasicData.Title | translate }}
				</span>
			</h2>
			<p
				class="c-vloket-text--lg"
				[innerHTML]="i18nKeys.Registration.Register.BasicData.Description | translate"
			></p>
		</ng-container>
		<ng-container main>
			<vloket-basic-registration-data-form
				*ngIf="mainActivities$ | async as data"
				#formAccessor
				formControlName="basicInfo"
				[data]="data"
				(initialized$)="basicDataFormInitialized($event)"
			></vloket-basic-registration-data-form>
		</ng-container>
	</cjm-layout-container>

	<cjm-layout-container
		class="p-register__representative-container"
		layoutType="split-equal"
		asidePosition="left"
		preventSpacing="top"
	>
		<ng-container asideLeft>
			<h2 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.Register.RepresentativeData.Title | translate }}
				</span>
			</h2>
			<p class="c-vloket-text--s c-vloket-text--light u-margin-bottom-sm">
				<fa-icon icon="fa-eye-slash" iconType="fa-regular" />
				{{ i18nKeys.Registration.Register.RepresentativeData.Tooltip | translate }}
			</p>
			<p
				class="c-vloket-text--lg"
				[innerHTML]="i18nKeys.Registration.Register.RepresentativeData.Description | translate"
			></p>
		</ng-container>
		<ng-container main formArrayName="representatives">
			<div *ngFor="let item of registerForm | getFormArrayControls : 'representatives'; let i = index">
				<vloket-representative-form
					#formAccessor
					[titleOverwrite]="i18nKeys.Registration.Register.RepresentativeData.YourDataTitle | translate"
					[index]="i"
					[formControlName]="i"
					[disableFields]="
						i === 0
							? ['representativeFirstnameField', 'representativeSurnameField', 'representativeRRNField']
							: []
					"
				></vloket-representative-form>
			</div>
		</ng-container>
		<ng-container main formArrayName="invites">
			<div
				*ngFor="let item of registerForm | getFormArrayControls : 'invites'; let index = index; let last = last"
				class="u-margin-top-xl"
			>
				<vloket-representative-form
					#formAccessor
					[index]="index"
					[formControlName]="index"
					[mailOnly]="true"
				></vloket-representative-form>

				<div class="p-register__representative_actions">
					<cjm-button
						*ngIf="index >= 1"
						type="button"
						classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }}"
						[title]="
							i18nKeys.Registration.Register.RepresentativeData.Actions.RemoveRepresentative.Title
								| translate
						"
						(handleClick)="removeRepresentative(index)"
					>
						<fa-icon icon="fa-trash-can" />
						{{
							i18nKeys.Registration.Register.RepresentativeData.Actions.RemoveRepresentative.Text
								| translate
						}}
					</cjm-button>

					<div class="button-with-info">
						<cjm-button
							*ngIf="last"
							type="button"
							classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }} {{
								last && index < 3 ? '' : buttonClasses.Disabled
							}}"
							[title]="
								i18nKeys.Registration.Register.RepresentativeData.Actions.AddRepresentative.Title
									| translate
							"
							(handleClick)="last && index < 3 ? addRepresentative() : null"
						>
							<fa-icon icon="fa-plus" />
							{{
								i18nKeys.Registration.Register.RepresentativeData.Actions.AddRepresentative.Text
									| translate
							}}
						</cjm-button>

						<!--
							If a tooltip description is provided for a disabled delete,
							do not hide the action, but show the tooltip instead
							~ Denis
						-->
						<cjm-info
							*ngIf="last && index >= 3"
							class="u-margin-left"
							[description]="
								i18nKeys.Registration.Register.RepresentativeData.Actions.AddRepresentative.Tooltip
									| translate
							"
						/>
					</div>
				</div>
			</div>
		</ng-container>
	</cjm-layout-container>

	<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="top">
		<vloket-send-copy main formControlName="sendCopy" />
	</cjm-layout-container>

	<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="top">
		<ng-container asideLeft>
			<h2 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.Register.NewsLetterData.Title | translate }}
				</span>
			</h2>
			<p
				class="c-vloket-text--lg"
				[innerHTML]="i18nKeys.Registration.Register.NewsLetterData.Description | translate"
			></p>
		</ng-container>
		<ng-container main>
			<h3 class="c-vloket-header c-vloket-header--h3">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ i18nKeys.Registration.Register.NewsLetterData.SubscribeToNewsletter.Title | translate }}
				</span>
			</h3>

			<fieldset>
				<cjm-checkbox
					formControlName="subscribeToNewsletter"
					[title]="
						i18nKeys.Registration.Register.NewsLetterData.SubscribeToNewsletter.Checkbox.Title | translate
					"
				>
					{{ i18nKeys.Registration.Register.NewsLetterData.SubscribeToNewsletter.Checkbox.Text | translate }}
				</cjm-checkbox>
			</fieldset>
		</ng-container>
	</cjm-layout-container>

	<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="top">
		<ng-container main>
			<div class="c-vloket-form__actions">
				<cjm-button
					cypressTag="FVRegistration.Actions.Submit"
					[title]="i18nKeys.Registration.Register.FormActions.RegisterAssociation.Title | translate"
					[classes]="buttonClasses.LinkButton"
					[isDisabled]="actionInProgress$ | async"
					(handleClick)="submitRegistration()"
				>
					{{ i18nKeys.Registration.Register.FormActions.RegisterAssociation.Text | translate }}
				</cjm-button>
				<cjm-button
					[classes]="buttonClasses.LinkButtonOutline"
					[title]="i18nKeys.Forms.Actions.Cancel.Title | translate"
					[isDisabled]="actionInProgress$ | async"
					[routerLink]="['..', this.appRoutePaths.RegistrationStart]"
				>
					{{ i18nKeys.Forms.Actions.Cancel.Text | translate }}
				</cjm-button>
			</div>
		</ng-container>
	</cjm-layout-container>
</form>
