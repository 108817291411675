import { FormControl } from '@angular/forms';

import { AssociationLocationType } from '../../../data';

export interface ILocationForm {
	objectId?: string;
	type?: AssociationLocationType;
	description: string;
	zipcode: string;
	locality: string;
	country: string;
	street: string;
	number: string;
	box?: string;
	isPrimary?: boolean;
}

export interface ILocationFormGroup {
	objectId: FormControl<string>;
	type: FormControl<AssociationLocationType>;
	description: FormControl<string>;
	zipcode: FormControl<string>;
	locality: FormControl<string>;
	country: FormControl<string>;
	street: FormControl<string>;
	number: FormControl<string>;
	box?: FormControl<string>;
	isPrimary?: FormControl<boolean>;
}
