import { Directive, OnDestroy, OnInit } from '@angular/core';

import { OnDestroyComponent } from '@cjm/shared/types';

import { HideMenuService } from '../../services';

@Directive()
export abstract class HideMenuComponent extends OnDestroyComponent implements OnInit, OnDestroy {
	constructor(protected readonly hideMenuService: HideMenuService) {
		super();
	}

	public ngOnInit(): void {
		this.hideMenuService.toggleMenu(false);
	}

	public ngOnDestroy(): void {
		this.hideMenuService.toggleMenu(true);
		super.ngOnDestroy();
	}
}
