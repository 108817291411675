import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { CJMResult } from '@cjm/shared/types';

/**
 * Pipeable operator to convert a CJMResult to an array of a CJMInterface
 *
 * @export
 * @template CJMId - Id of the CJMResult
 * @template CJMResultInterface - The interface of the CJMResult
 * @template CJMInterface - The interface of the result
 * @param convertFunction - The convert function
 */
export function convertCJMResult<CJMId, CJMResultInterface, CJMInterface>(
	convertFunction: Function
): OperatorFunction<CJMResult<CJMResultInterface, CJMId>, CJMInterface[]> {
	return map<CJMResult<CJMResultInterface, CJMId>, CJMInterface[]>(({ elementen }) => {
		return elementen.map((item) => convertFunction(item));
	});
}
