import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';

import { BrowserService } from '@cjm/shared/core';
import { MediaQueryMax } from '@cjm/shared/types';

import { CJMMenuItemEntity } from '../../types';

@Component({
	selector: 'cjm-menu',
	templateUrl: 'menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
	@HostBinding('class.c-menu') public hasMenuClass: boolean = true;
	@HostBinding('class.is-open') public hasIsOpenClass: boolean = false;

	@HostBinding('class')
	@Input()
	public classes: string = '';
	/**
	 * An array of items we wish to display in the menu
	 */
	@Input() public menuItems: CJMMenuItemEntity[] = [];
	/**
	 * Prevent the menu from setting an active class on the currently active menu item.
	 *
	 * Default value is set to 'false'.
	 */
	@Input() public showActiveItem: boolean = false;
	/**
	 * Prevent the menu from collapsing on mobile devices.
	 *
	 * Default value is set to 'false'.
	 */
	@HostBinding('class.prevent-collapse')
	@Input()
	public preventCollapse: boolean = false;

	/**
	 * Show hamburger or plain text mobile navigation button.
	 *
	 * Default value is set to 'true'.
	 */
	@Input() public showHamburger: boolean = true;

	/**
	 * If [showHamburger] is set to true. This attribute provides a way to define the text of the button label.
	 *
	 * Default value is set to 'Menu'.
	 */
	@Input() public menuLabel: string = 'Menu';

	/**
	 * A template for content of the a tag
	 */
	@ContentChild('itemTmpl', { static: false })
	public itemTemplate: TemplateRef<any>;
	public selectedItem = undefined;
	public mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	constructor(private readonly browserService: BrowserService) {}

	/**
	 * Toggles the state of the menu. Removes scroll when overlay is active.
	 */
	public handleMenuButtonClick(): void {
		this.hasIsOpenClass = !this.hasIsOpenClass;
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.body.classList.toggle('u-overflow-y--hidden--vp9', this.hasIsOpenClass);
		});
	}
	/**
	 * Closes the menu. Overlay on body is removed to enable scroll.
	 */
	public closeMenu(): void {
		this.hasIsOpenClass = false;
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.body.classList.remove('u-overflow-y--hidden--vp9');
		});
	}
}
