export interface PageData {
	title: string;
	description: string;
	pageUrl?: string;
	imageUrl?: PageImage;
	type?: string;
	author?: string;
	locale?: string;
}

export interface MetaData extends Omit<PageData, 'imageUrl'> {
	imageUrl?: string;
}

export interface PageImage {
	path: string;
	type?: PageImageUrlType;
}

export enum PageImageUrlType {
	internal = 'internal',
	external = 'external'
}
