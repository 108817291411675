import { Injectable } from '@angular/core';
import { NgcCookieConsent, NgcCookieConsentConfig, NgcStatusChangeEvent, NgcHasCookieConsent } from 'ngx-cookieconsent';
import { NgcCookieConsentPopup } from 'ngx-cookieconsent/lib/service';
import { Observable, Subject } from 'rxjs';

import { BrowserService } from '@cjm/shared/core';

import { cookieConsentCookie } from '../constants';

import { CJMCookieService } from './cookie.service';

@Injectable({
	providedIn: 'root'
})
export class CookieConsentService {
	private popupInstance: NgcCookieConsentPopup;
	private readonly statusChangesSubject$ = new Subject<NgcStatusChangeEvent>();
	private cookieConsent: NgcCookieConsent;

	/**
	 * Status changes triggered by the cookie consent popup
	 */
	public readonly statusChange$: Observable<NgcStatusChangeEvent> = this.statusChangesSubject$.asObservable();

	constructor(private readonly browserService: BrowserService, private readonly cookieService: CJMCookieService) {}

	/**
	 * Initializes the CookieConsent package with the provided configuration
	 *
	 * @param cookieConfig - The configuration for the cookie popup
	 */
	public init(cookieConfig: NgcCookieConsentConfig): void {
		// Iben: Early exit if we're not in the browser
		this.browserService.runInBrowser(({ browserWindow }) => {
			// Iben: setup the cookieConsent
			this.cookieConsent = (browserWindow as Window & NgcHasCookieConsent).cookieconsent;

			if (!this.cookieConsent) {
				return;
			}

			// Iben: Overwrite the onStatusChange to listen to the status changes
			const config: NgcCookieConsentConfig = {
				...cookieConfig,
				onStatusChange: (status: string, chosenBefore: boolean) => {
					this.statusChangesSubject$.next({
						status: status,
						chosenBefore: chosenBefore
					} as NgcStatusChangeEvent);
				}
			};

			// Iben: Initialize the cookieConsent and trigger a popupInstance
			this.cookieConsent.initialise(config, (popup: NgcCookieConsentPopup) => (this.popupInstance = popup));
		});
	}

	public closeCookiePopUp() {
		// Iben: Early exit if there's no popupInstance
		if (!this.popupInstance) {
			return;
		}

		// Iben: Close the popup
		this.popupInstance.close(false);
	}

	/**
	 * Returns whether the popup has been answered
	 */
	public hasAnswered(): boolean {
		// Iben: Early exit if there's no popupInstance
		if (!this.popupInstance) {
			return false;
		}

		return this.popupInstance.hasAnswered();
	}

	/**
	 * Accept the cookies
	 *
	 */
	public acceptCookies(): void {
		this.browserService.runInBrowser(() => {
			this.cookieService.put(cookieConsentCookie, 'dismiss');
		});
	}
}
