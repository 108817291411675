import { VloketLink } from '@cjm/shared/ui/common';

import { TileBackgroundVariant } from './tile-block.const';

export interface TileEntity {
	title: string;
	text: string;
	link?: VloketLink;
}

export interface TilesEntity {
	type?: string;
	title: string;
	text: string;
	variant: TileBackgroundVariant;
	tiles: TileEntity[];
}
