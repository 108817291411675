import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { CypressModule } from '@cjm/cypress/core';
import { SharedUiModule } from '@cjm/shared/ui/common';

import { ToastComponents } from './components';

@NgModule({
	imports: [CommonModule, NgxI18nModule.forChild(), CypressModule, SharedUiModule],
	declarations: [ToastComponents],
	exports: [SharedUiModule, ToastComponents]
})
export class ToastUiModule {}
