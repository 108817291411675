import { Route } from '@angular/router';

import { FeatureKeys } from '@cjm/shared/features';
import { UserTargetCodeEntityKeys } from '@cjm/shared/types';

export interface CJMRoute extends Route {
	data?: {
		feature?: FeatureKeys;
		/**
		 * The roles that are (dis)allowed for the
		 * has-role guard.
		 */
		roles?: UserTargetCodeEntityKeys[];
		/**
		 * The location to redirect to.
		 */
		redirect?: string[];
		[key: string]: unknown;
	};
}

export type CJMRoutes = CJMRoute[];
