import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormAccessor } from '@studiohyperdrive/ngx-forms';

@Component({
	selector: 'cjm-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent extends FormAccessor<boolean, FormControl<boolean>> {
	public initForm(): FormControl<boolean> {
		return new FormControl(false);
	}
}
