export * from './product';
export * from './modal';
export * from './optional';
export * from './cjm-result';
export * from './cta';
export * from './address';
export * from './facet-group.entity';
export * from './leaflet.interface';
export * from './rxjs';
export * from './string-record';
export * from './filter-params';
export * from './page.interface';
export * from './product.entity';
export * from './product-partner.entity';
export * from './sorting.entity';
export * from './recommendation-enum-map';
export * from './recommended-products';
export * from './mandates';
export * from './user-target-code.entity';
export * from './downloadable-document.entity';
export * from './pagination';
export * from './loading-record';

export interface ResultsState {
	loading: boolean;
	success: boolean;
	error: boolean;
}
