import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { merge } from '@studiohyperdrive/utils';

import { AlertType } from '@cjm/shared/ui/common';

import { ToasterLink } from '../../components';

export const generateSnackbarConfig = ({
	id,
	message,
	type = AlertType.Info,
	title = '',
	links,
	persistClosure,
	duration = 7000
}: {
	id?: string;
	message: string;
	type?: AlertType;
	title?: string;
	links?: ToasterLink[];
	persistClosure?: 'session' | 'local';
	duration?: number;
}): MatSnackBarConfig => ({
	data: merge(
		{},
		['toasterId', id],
		['toasterType', type],
		['toasterTitle', title],
		['toasterMessage', message],
		['toasterLinks', links],
		['persistClosure', persistClosure]
	),
	duration,
	horizontalPosition: 'center',
	verticalPosition: 'top',
	panelClass: 'c-vloket-snack-bar'
});
