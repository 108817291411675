export enum RepresentativeRoleType {
	Chairman = 'Voorzit(s)ter',
	BoardMember = 'Bestuurslid',
	Treasurer = 'Penningmeester',
	Secretary = 'Secretaris',
	Coordinator = 'Coördinator',
	Other = 'Andere'
}

export enum RepresentativeFormMode {
	Create = 'create',
	Edit = 'edit',
	Invite = 'invite',
	Request = 'request'
}
