export enum UserTargetCodeEntity {
	CIVILIAN = 'BUR',
	ECONOMIC_ACTOR = 'EA',
	LOCAL_GOVERNMENT = 'LB',
	FLEMISH_GOVERNMENT = 'GID',
	ASSOCIATION = 'VER'
}

export type UserTargetCodeEntityKeys = keyof typeof UserTargetCodeEntity;

// TODO: make enum of user target codes
