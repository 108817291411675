import { inject } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { tap } from 'rxjs';

import { UserCompanyPropertyEntity, UserService } from '@cjm/shared/user';

/**
 * Guard that checks if the logged in user has the correct company role. If they don't have the correct role, they get redirected.
 *
 * @param {ActivatedRouteSnapshot} routeSnapshot
 * @return {ObservableBoolean}
 */
export const HasCompanyRoleGuard: CanActivateFn = (routeSnapshot: ActivatedRouteSnapshot): ObservableBoolean => {
	const userService: UserService = inject(UserService);
	const route: ActivatedRoute = inject(ActivatedRoute);
	const router: Router = inject(Router);
	const companyRoles: UserCompanyPropertyEntity[] = routeSnapshot.data?.companyRoles || [];
	const shouldHaveAllCompanyRoles = routeSnapshot.data?.shouldHaveAllCompanyRoles;

	// Benoit: Return whether the user has the correct company role(s).
	return userService.userHasCompanyProperties(companyRoles, shouldHaveAllCompanyRoles).pipe(
		tap((hasCompanyRole) => {
			// Benoit: If the user doesn't have the correct company role, they get redirected to the redirect url given by routeSnapshot.data.redirect
			// If the url is left empty, the user is redirected to home
			if (!hasCompanyRole) {
				router.navigate(routeSnapshot.data.redirect, { relativeTo: route });
			}
		})
	);
};
