<div class="c-input">
	<label>
		<span>
			<ng-content></ng-content>
		</span>
	</label>

	<div>
		<select
			[cypressTag]="inputCypressTag"
			[attr.placeholder]="placeholder"
			[formControl]="control"
			[value]="control.value"
			[name]="inputName"
			[title]="inputName"
		>
			<option *ngIf="allowEmpty" value="">
				<ng-template
					[ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultTmpl"
					[ngTemplateOutletContext]="{ $implicit: '' }"
				>
				</ng-template>
			</option>

			<option *ngFor="let item of items" [value]="matchingKey ? item[matchingKey] : item">
				<ng-template
					[ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultTmpl"
					[ngTemplateOutletContext]="{ $implicit: item }"
				>
				</ng-template>
			</option>
		</select>
	</div>
</div>

<ng-template #defaultTmpl let-item>
	{{ item || emptyLabel }}
</ng-template>
