import { marker } from '@ngneat/transloco-keys-manager/marker';

export const I18nKeys = {
	Forms: {
		Actions: {
			Search: marker('forms.actions.search'),
			Title: marker('forms.actions.title'),
			ClearSearch: marker('forms.actions.clearsearch'),
			ClearFilters: {
				Title: 'forms.actions.clear-filters.title',
				Text: 'forms.actions.clear-filters.text'
			},
			Add: marker('forms.actions.add'),
			CloseFilter: marker('forms.actions.closefilter'),
			Save: {
				Title: 'forms.actions.save.title',
				Text: 'forms.actions.save.text'
			},
			SaveChanges: {
				Title: 'forms.actions.save-changes.title',
				Text: 'forms.actions.save-changes.text'
			},
			Cancel: {
				Title: 'forms.actions.cancel.title',
				Text: 'forms.actions.cancel.text'
			},
			BackToOverview: {
				Title: 'forms.actions.back-to-overview.title',
				Text: 'forms.actions.back-to-overview.text'
			}
		},
		Placeholders: {
			ZipCode: marker('forms.placeholders.zip-code')
		}
	},
	Input: {
		Checkbox: {
			Title: marker('input.checkbox.title')
		}
	},
	Facets: {
		Actions: {
			Filter: marker('facets.actions.filter'),
			LessFilters: marker('facets.actions.less-filters'),
			MoreFilters: marker('facets.actions.more-filters'),
			ShowResults: marker('facets.actions.show-results'),
			ClearAll: marker('facets.actions.clear-all')
		},
		Loading: marker('facets.loading'),
		NoFilters: marker('facets.no-filters'),
		Subnav: marker('facets.subnav')
	},
	Facet: {
		Radio: {
			Title: marker('facet.radio.title')
		}
	},
	FormLabels: {
		OptionalMarker: marker('formLabels.optionalMarker'),
		IsRequired: marker('formLabels.isRequired'),
		SelectMinMaxValues: marker('formLabels.selectMinMaxValues'),
		SelectMinOneValue: marker('formLabels.selectMinOneValue'),
		SelectMaxValues: marker('formLabels.selectMaxValues'),
		FormatAsEmail: marker('formLabels.formatAsEmail'),
		FormatAsPhone: marker('formLabels.formatAsPhone'),
		FormatAsINSZ: marker('formLabels.formatAsINSZ'),
		FormatAsURL: marker('formLabels.formatAsURL'),
		MaxSearchTerms: marker('formLabels.maxSearchTerms'),
		InputMinValue: marker('formLabels.input-min-value'),
		InputMaxValue: marker('formLabels.input-max-value')
	},
	AutoComplete: {
		FallbackPrefix: marker('auto-complete.fallback-prefix')
	}
};
