import { IconType } from '@cjm/shared/ui/common';

import { IconClassType } from './icon-list.const';

export interface IconListItem {
	icon: string;
	iconType: `${IconType}`;
	text: string;
	iconClass: `${IconClassType}`;
	to?: string | string[];
	fragment?: string;
}
