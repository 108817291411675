import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScriptsService, CJMHttpClientService, StatusService, MetaService, SlicingService } from './services';

@NgModule({
	imports: [CommonModule],
	providers: [ScriptsService, StatusService, CJMHttpClientService, MetaService, SlicingService]
})
export class SharedCoreModule {}
