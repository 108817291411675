import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { ModalDialogService } from '@cjm/shared/ui/modal';

/**
 * HideModalGuard
 *
 * The HideModalGuard is a CanDeactivate guard that will close any open modals before continuing the navigation.
 *
 * @constructor
 * @returns boolean
 */
export const HideModalGuard: CanDeactivateFn<never> = (): boolean => {
	const modalService: ModalDialogService = inject(ModalDialogService);

	modalService.closeModal();

	return true;
};
