export enum EloketStoreSlice {
	About = 'about',
	Cases = 'cases',
	Company = 'company',
	Dashboard = 'dashboard',
	EBox = 'e-box',
	Executors = 'executors',
	Faq = 'faq',
	Measures = 'measures',
	Notifications = 'notifications',
	Pages = 'pages',
	Partners = 'partners',
	Products = 'products',
	Recommendations = 'recommendations',
	SDGActivities = 'sdg-activities',
	SDGBranchLocation = 'sdg-branch-location',
	SDGBranchQuestions = 'sdg-branch-questions',
	SDGCreateCompany = 'sdg-create-company',
	SDGGovernmentNotifier = 'sdg-government-notifier',
	Sanctions = 'sanctions',
	SharedMandatesExecutors = 'shared-mandates-executors',
	Survey = 'survey',
	Toast = 'toast',
	User = 'user'
}
