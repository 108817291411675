import { MainLocationPipe } from './main-location/main-location.pipe';
import { ParseAssoctiationActivitiesPipe } from './parse-association-activities.pipe';
import { ParseAssoctiationAddressesPipe } from './parse-association-address.pipe';
import { TranslateWithRouterLinksPipe } from './translate-with-router-links/translate-with-router-links.pipe';

export const pipes = [
	ParseAssoctiationActivitiesPipe,
	ParseAssoctiationAddressesPipe,
	MainLocationPipe,
	TranslateWithRouterLinksPipe
];
