import { FeatureKeys } from '@cjm/shared/features';
import { IconType } from '@cjm/shared/ui/common';

export interface SidebarItemEntity {
	icon: string;
	iconType: `${IconType}`;
	text: string;
	route?: string | string[];
	active: boolean;
	target?: string[];
	badgeCount?: number;
	featureFlag?: FeatureKeys;
}
