/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DefaultHeadersInterceptor } from './default-headers.interceptor';
import { DefaultQueryParamsInterceptor } from './default-query-params.interceptor';
import { ServerErrorInterceptor } from './server-error.interceptor';
import { SessionErrorInterceptor } from './session-error.interceptor';
import { XCSRFInterceptor } from './xcsrf.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: XCSRFInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: DefaultQueryParamsInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: DefaultHeadersInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: SessionErrorInterceptor, multi: true }
];
