import { AbstractControl, FormControl } from '@angular/forms';

import { IEaRegistrationDataForm } from '../../components';

export interface IEARegistration {
	basicInfo: IEaRegistrationDataForm;
}

export interface IEARegistrationForm extends Record<string, AbstractControl> {
	basicInfo: FormControl<IEaRegistrationDataForm>;
}
