import { FacetFilter, SortingEntity } from '@cjm/shared/types';

export interface AssociationsRouteFilterEntity {
	filters?: string;
	name?: string;
	location?: string[];
	sorting?: SortingEntity;
}

export interface AssociationsRouteFilterObjectEntity {
	filters?: FacetFilter;
	name?: string;
	location?: string[];
	sorting?: string;
	index?: number;
}
