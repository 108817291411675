<a
	*ngIf="page"
	class="c-advice-card__anchor"
	[routerLink]="['/', i18nService.currentLanguage, appRoutePaths.Advice, page.id]"
>
	<div class="c-advice-card__image_container">
		<img
			*ngIf="page?.heroImage?.url"
			class="c-advice-card__image"
			fill
			[alt]="page?.heroImage?.alt"
			[ngSrc]="page?.heroImage?.url"
		/>
	</div>
	<div class="c-advice-card__content">
		<h3 *ngIf="page.title" class="c-vloket-header c-vloket-header--h6">{{ page.title }}</h3>
		<span *ngIf="page.description" [outerHTML]="page.description"></span>
		<ul *ngIf="page?.keywords" class="c-advice-card__keyword-list u-margin-top-sm">
			<li *ngFor="let keyword of page?.keywords" class="c-advice-card__keyword-list__item">
				<vloket-advice-page-theme-tag [tagId]="keyword.id" />
				<span>
					{{ keyword.name }}
				</span>
			</li>
		</ul>
	</div>
</a>
