import { createBaseStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';
import { MyAssociationEntity } from '@cjm/v-loket/shared';

import { MyAssociationStoreSlice } from './my-association.store.types';

export const {
	actions: myAssociationActions,
	reducers: myAssociationReducers,
	selectors: myAssociationSelectors
} = createStoreAssets<MyAssociationStoreSlice>(StoreSlice.MyAssociation, [
	{
		subSlice: 'myAssociationData',
		generator: createBaseStoreAssets<MyAssociationEntity>
	}
]);
