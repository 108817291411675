import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { SnackBarService } from '@cjm/shared/ui/toast';

/**
 * HideModalGuard
 *
 * The HideModalGuard is a CanDeactivate guard that will close any open modals before continuing the navigation.
 *
 * @constructor
 * @returns boolean
 */
export const HideSnackBarGuard: CanDeactivateFn<never> = (): boolean => {
	const snackBarService: SnackBarService = inject(SnackBarService);

	snackBarService.dismissOnDeactivate();

	return true;
};
