<cjm-layout-container
	*ngIf="data"
	class="c-advice-detail-page-link-block"
	layoutType="full-width"
	[class]="'c-advice-detail-page-link-block--' + backgroundVariants[data.variant]"
>
	<ng-container main>
		<h2 *ngIf="data.title" class="c-vloket-header c-vloket-header--h3">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ data.title }}
			</span>
		</h2>
		<div *ngIf="data.linkgroups" class="c-advice-detail-page-link-block__link-group-container">
			<ul *ngFor="let linkgroup of data.linkgroups" class="c-advice-detail-page-link-block__link-group">
				<h3 *ngIf="linkgroup.title" class="c-vloket-header c-vloket-header--h4">{{ linkgroup.title }}</h3>
				<li *ngFor="let links of linkgroup.links" class="c-advice-detail-page-link-block__link-item">
					<a class="c-advice-detail-page-link-block__link" [href]="links.url">
						{{ links.title }}
					</a>
				</li>
			</ul>
		</div>
	</ng-container>
</cjm-layout-container>
