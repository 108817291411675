import { Component, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormAccessorContainer } from '@studiohyperdrive/ngx-forms';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { validateContent, ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CompanyEntity, CompanyService } from '@cjm/shared/company';
import { BrowserService, MetaService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { AlertType, ButtonClasses } from '@cjm/shared/ui/common';
import { generateSnackbarConfig, SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity } from '@cjm/shared/user';
import { IMainActivity, AssociationApiService } from '@cjm/v-loket/repositories';
import { DetailInfoSection, IRepresentativeForm } from '@cjm/v-loket/shared';

import { RegisterEaFacade } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { IEaRegistrationDataForm } from '../../components';

import { IEARegistrationForm } from './register-ea.types';

@Component({
	templateUrl: './register-ea.component.html',
	styleUrls: ['./register-ea.component.scss'],
	providers: [RegisterEaFacade]
})
export class RegisterEaPageComponent extends FormAccessorContainer implements OnInit {
	public readonly actionInProgress$: ObservableBoolean = this.registerEaFacade.actionInProgress$;
	private readonly basicInfoFormInitialized$: Subject<boolean> = new Subject<boolean>();
	public readonly isRegistrationSubmitting$: Observable<boolean> = this.registerEaFacade.isRegistrationSubmitting$;
	public readonly mainActivities$: ObservableArray<IMainActivity> = this.getMainActivities();
	public company: Signal<CompanyEntity> = toSignal(
		this.basicInfoFormInitialized$.pipe(
			filter((initialized) => initialized),
			switchMap(() => this.registerEaFacade.user$),
			validateContent({ strict: false }),
			tap((user: UserEntity) => {
				this.representativeControl.patchValue({
					representativeFirstnameField: user.firstName,
					representativeSurnameField: user.name,
					representativeRRNField: user.insz
				} as IRepresentativeForm);
			}),
			switchMap(() => this.companyService.company$),
			validateContent({ strict: false }),
			tap((company: CompanyEntity) => {
				this.registerForm.patchValue({
					basicInfo: {
						associationType: company.form
					} as IEaRegistrationDataForm
				});
			})
		)
	);

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public representativeControl: FormControl<IRepresentativeForm> = new FormControl<IRepresentativeForm>(null);

	constructor(
		private readonly registerEaFacade: RegisterEaFacade,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly snackBarService: SnackBarService,
		private readonly i18nService: I18nService,
		private readonly companyService: CompanyService,
		private readonly associationApiService: AssociationApiService,
		private readonly metaService: MetaService,
		private readonly browserService: BrowserService
	) {
		super();
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities method exposes the getMainActivities method from the AssociationApiService
	 *
	 * @returns Observable:IMainActivities
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.associationApiService.getMainActivities();
	}

	// Abdurrahman: The following form results in an IEaRegistration object
	public registerForm: FormGroup<IEARegistrationForm> = new FormGroup({
		basicInfo: new FormControl<IEaRegistrationDataForm>({} as IEaRegistrationDataForm, {
			validators: [Validators.required]
		})
	});

	public ngOnInit(): void {
		this.metaService.updateMetaData(
			{
				title: this.i18nService.getTranslation(this.i18nKeys.Registration.RegisterEa.Title),
				description: this.i18nService.getTranslation(
					this.i18nKeys.Registration.RegisterEa.AdditionalData.Description
				),
				pageUrl: this.router.url
			},
			// Abdurrahman: this page shouldn't be indexed
			false
		);
	}

	/**
	 * validateAndSubmit
	 *
	 * The validateAndSubmit method will trigger the submitRegistration method on the registerEaFacade with the registerForm value.
	 */
	public validateAndSubmit(): void {
		this.registerForm.markAsDirty();
		this.updateAllValueAndValidity(this.registerForm);
		this.registerForm.updateValueAndValidity();

		if (this.registerForm.invalid) {
			this.snackBarService.openFromComponent(
				SnackBarComponent,
				generateSnackbarConfig({
					title: this.i18nService.getTranslation(
						this.i18nKeys.Registration.RegisterEa.FormNotice.InvalidFormTitle
					),
					message: this.i18nService.getTranslation(
						this.i18nKeys.Registration.RegisterEa.FormNotice.InvalidForm
					),
					type: AlertType.Warning
				})
			);
			this.browserService.scrollToElement('.ng-invalid .c-input__description.is-error').subscribe();

			return;
		}

		this.registerEaFacade
			.submitRegistration(this.registerForm.getRawValue())
			.pipe(
				tap(() => {
					this.router.navigate(['..', this.appRoutePaths.RegistrationRedirectRegistration], {
						relativeTo: this.route
					});
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * basicDataFormInitialized
	 *
	 * The basicDataFormInitialized method will push the initialized value of the basic data form to an internal subject.
	 *
	 * @param isInitialized
	 */
	public basicDataFormInitialized(isInitialized: boolean): void {
		if (!isInitialized) {
			return;
		}

		this.basicInfoFormInitialized$.next(isInitialized);
	}

	protected readonly DetailInfoSection = DetailInfoSection;
}
