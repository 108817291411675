import {
	AdvicePageBackgroundVariant,
	AdvicePageImageAndTextVariant,
	AdvicePageTileBackgroundVariant
} from '../../data';

export enum BackgroundVariantClass {
	White = 'white',
	Gray = 'gray',
	Visual = 'visual'
}

export enum TileBackgroundVariantClass {
	GrayTop = 'gray-top',
	GrayBottom = 'gray-bottom',
	YellowTop = 'yellow-top',
	YellowBottom = 'yellow-bottom'
}

export enum SplitVariantClass {
	ImageOnLeft = 'image-on-left',
	ImageOnRight = 'image-on-right'
}

export const BackgroundVariants: Record<AdvicePageBackgroundVariant, BackgroundVariantClass> = {
	[AdvicePageBackgroundVariant.White]: BackgroundVariantClass.White,
	[AdvicePageBackgroundVariant.Gray]: BackgroundVariantClass.Gray,
	[AdvicePageBackgroundVariant.Visual]: BackgroundVariantClass.Visual
};

export const TileBackgroundVariants: Record<AdvicePageTileBackgroundVariant, TileBackgroundVariantClass> = {
	[AdvicePageTileBackgroundVariant.LightGrayBackgroundOnTop]: TileBackgroundVariantClass.GrayTop,
	[AdvicePageTileBackgroundVariant.LightGrayBackgroundOnBottom]: TileBackgroundVariantClass.GrayBottom,
	[AdvicePageTileBackgroundVariant.YellowBackgroundOnTop]: TileBackgroundVariantClass.YellowTop,
	[AdvicePageTileBackgroundVariant.YellowBackgroundOnBottom]: TileBackgroundVariantClass.YellowBottom
};

export const SplitVariants: Record<AdvicePageImageAndTextVariant, SplitVariantClass> = {
	[AdvicePageImageAndTextVariant.Left]: SplitVariantClass.ImageOnLeft,
	[AdvicePageImageAndTextVariant.Right]: SplitVariantClass.ImageOnRight
};
