// Imports
// -------------------------------------------------------------------------- /
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';

import { HasRoleDirective, IsAuthenticatedDirective, HasCompanyRoleDirective } from './directives';

export const AUTH_EVENTS = new InjectionToken('user.authEvents');
export const AuthEvents = {
	loginSuccess: 'auth-login-success',
	loginFailed: 'auth-login-failed',
	logoutSuccess: 'auth-logout-success',
	sessionTimeout: 'auth-session-timeout',
	notAuthenticated: 'auth-not-authenticated',
	notAuthorized: 'auth-not-authorized'
};

// Decorators
// -------------------------------------------------------------------------- /
@NgModule({
	// Modules
	imports: [CommonModule],
	// Services
	providers: [{ provide: AUTH_EVENTS, useValue: AuthEvents }],
	// Components
	declarations: [IsAuthenticatedDirective, HasRoleDirective, HasCompanyRoleDirective],
	// Re-Export
	exports: [IsAuthenticatedDirective, HasRoleDirective, HasCompanyRoleDirective]
})

// Exports
// -------------------------------------------------------------------------- /
export class UserModule {
	public static forRoot(): ModuleWithProviders<UserModule> {
		return {
			ngModule: UserModule
		};
	}
}
