import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';

import { RepresentativeInviteResultEntity } from '../../interfaces';

@Injectable()
export class InvitationApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 *  getInvitation
	 *
	 *  The getInvitation method retrieves a single representative invite from the server.
	 *
	 * @returns {Observable<RepresentativeInviteResultEntity>} An Observable that emits the representative invitation.
	 */
	public getInvitation(id: string): Observable<RepresentativeInviteResultEntity> {
		return this.httpClient.get(VLoketEndpoints.Associations.GetRepresentativeInvite(id));
	}
}
