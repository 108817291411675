import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs/operators';

import { PwaService } from '@cjm/shared/pwa';
import { PreventNavigationGuard } from '@cjm/shared/types';

/**
 *Allows navigation, but only when the application is offline
 */
export const OfflineGuard: CanActivateFn = (): ObservableBoolean => {
	// Iben: Fetch all injectables
	const pwaService: PwaService = inject(PwaService);

	return PreventNavigationGuard(pwaService.isOnline$.pipe(map((isOnline) => !isOnline)));
};
