import { flatten } from 'lodash';
import * as clean from 'obj-clean';

import { FacetEntity, FacetFilter } from '@cjm/shared/types';

export function convertSelectedFacetsToFacetFilter(facets: FacetEntity[]): FacetFilter {
	const result = {};

	for (const facet of facets) {
		const items = !facet.isRefinable ? facet.items : flatten([...facet.items].map((item) => item['refinements']));

		result[facet.id] = [...items].filter((item) => item.checked).map(({ id }) => id);
	}

	return clean(result, { preserveArrays: false });
}
