import { Routes } from '@angular/router';
import { TranslationLoaderResolver } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { HasRoleGuard, IsAuthenticatedGuard, IsRegisterableEAGuard } from '@cjm/shared/user';

import { RegisterEaPageResolver, SuccessPageResolver } from '../data/resolvers';

import {
	DuplicateCheckPageComponent,
	RegisterEaPageComponent,
	RegisterPageComponent,
	StartPageComponent,
	SuccessPageComponent,
	RedirectAuthenticationComponent,
	RedirectRegistrationComponent
} from './pages';

export const registrationRoutes: Routes = [
	{
		path: VLoketAppRoutePaths.RegistrationStart,
		component: StartPageComponent,
		resolve: {
			translations: TranslationLoaderResolver
		}
	},
	{
		path: `${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/:type`,
		component: RedirectAuthenticationComponent
	},
	{
		path: VLoketAppRoutePaths.RegistrationForm,
		component: RegisterPageComponent,
		resolve: {
			translations: TranslationLoaderResolver
		},
		canActivate: [IsAuthenticatedGuard, HasRoleGuard],
		data: {
			roles: ['CIVILIAN'],
			redirect: [VLoketAppRoutePaths.Redirects, VLoketAppRoutePaths.Stop]
		}
	},
	{
		path: VLoketAppRoutePaths.EARegistrationForm,
		component: RegisterEaPageComponent,
		resolve: {
			translations: TranslationLoaderResolver,
			data: RegisterEaPageResolver
		},
		canActivate: [IsAuthenticatedGuard, IsRegisterableEAGuard],
		data: {
			redirect: [VLoketAppRoutePaths.Redirects, VLoketAppRoutePaths.Stop]
		}
	},
	{
		path: VLoketAppRoutePaths.RegistrationRedirectRegistration,
		component: RedirectRegistrationComponent
	},
	{
		path: VLoketAppRoutePaths.RegistrationDuplicateCheck,
		component: DuplicateCheckPageComponent,
		resolve: {
			translations: TranslationLoaderResolver
		},
		canActivate: [IsAuthenticatedGuard]
	},
	{
		path: `${VLoketAppRoutePaths.RegistrationSuccess}/:vCode`,
		component: SuccessPageComponent,
		resolve: {
			translations: TranslationLoaderResolver,
			data: SuccessPageResolver
		},
		canActivate: [IsAuthenticatedGuard]
	},
	{
		path: '**',
		redirectTo: VLoketAppRoutePaths.RegistrationStart
	}
];
