import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { transformJsonToObject } from '@cjm/v-loket/shared';

import { AdvicePageImageAndTextEntity } from '../../../data';
import { SplitVariants } from '../../interfaces';

@Component({
	selector: 'vloket-advice-detail-page-split',
	templateUrl: './advice-detail-page-split.component.html',
	styleUrls: ['./advice-detail-page-split.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdviceDetailPageSplitComponent {
	@HostBinding('class.c-advice-detail-page-split-block') private readonly rootClass: boolean = true;

	@Input({
		required: true,
		transform: (value: string) => {
			return transformJsonToObject<AdvicePageImageAndTextEntity>(value);
		}
	})
	public data: AdvicePageImageAndTextEntity;

	public readonly splitVariants: typeof SplitVariants = SplitVariants;
}
