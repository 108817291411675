import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DataFormAccessor } from '@studiohyperdrive/ngx-forms';
import { BehaviorSubject, combineLatest, filter, startWith, switchMap, takeUntil, tap } from 'rxjs';

import { CheckboxGroup, CheckboxGroupFormGroup, CheckboxValue } from './checkbox-group.types';

@Component({
	selector: 'vloket-checkbox-group',
	templateUrl: './checkbox-group.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxGroupComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => CheckboxGroupComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxGroupComponent
	extends DataFormAccessor<CheckboxValue[], CheckboxGroup, CheckboxGroupFormGroup>
	implements OnInit
{
	/**
	 * An attribute to provide values
	 */
	@Input() public values: CheckboxValue[] = [];
	/**
	 * The minAmount enables the parent component to set a minimum amount of
	 * values that need to be selected
	 *
	 * Default is 0, 0 will correspond with no validation.
	 */
	@Input() public minAmount: number = 0;
	/**
	 * The minAmountLabel allows the parent to set the error label for the minAmount validation.
	 */
	@Input() public minAmountLabel: string;
	/**
	 * The maxAmount enables the parent component to set a maximum amount of
	 * values that need to be selected
	 *
	 * Default is 0, 0 will correspond with no validation.
	 */
	@Input() public set maxAmount(value: number) {
		this.maxAmount$.next(value || 0);
	}
	/**
	 * he maxAmountLabel allows the parent to set the error label for the maxAmount validation.
	 */
	@Input() public maxAmountLabel: string;
	/**
	 * The hintLabel allows the parent to set the hint text
	 */
	@Input() public hintLabel: string;

	public givenValues = [];

	public maxAmount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	public ngOnInit() {
		this.initializedSubject$
			.pipe(
				filter((initialized) => initialized),
				switchMap(() =>
					combineLatest([this.form.valueChanges.pipe(startWith(this.form.value)), this.maxAmount$])
				),
				tap(([values]) => {
					this.toggleUncheckedFields(values);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	public writeValue(value): void {
		super.writeValue(value);

		this.toggleUncheckedFields(value);
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(data: CheckboxValue[]): CheckboxGroupFormGroup {
		return this.buildValuesFormGroup(data);
	}

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	// TODO: set return type
	public onChangeMapper(): any {
		// Denis: Because some fields might be disabled, we need the rawValue.
		return this.form.getRawValue();
	}

	/**
	 * buildValuesFormGroup
	 *
	 * The buildValuesFormGroup method will check the provided values and,
	 * if there are values present, it will set up a new FormGroup with controls
	 * for each value.
	 *
	 * If the values are not a valid array or an empty array. It will return
	 * an empty FormGroup to avoid the component from breaking.
	 *
	 * @param {IMainActivity[]} values
	 * @private
	 * @returns FormArray:FormControl:string
	 */
	private buildValuesFormGroup(values: CheckboxValue[]): FormGroup<{
		[key: string]: FormControl<boolean>;
	}> {
		if (!Array.isArray(values) || values.length === 0) {
			this.givenValues = [];

			return new FormGroup<{
				[key: string]: FormControl<boolean>;
			}>({});
		}

		this.givenValues = [...values];

		return new FormGroup<{
			[key: string]: FormControl<boolean>;
		}>(
			values.reduce(
				(acc, cur: CheckboxValue) => ({
					...acc,
					[cur.id]: new FormControl<boolean>(false)
				}),
				{}
			),
			{
				validators: [this.minMaxValues()]
			}
		);
	}

	/**
	 * minMaxValues
	 *
	 * The minMaxValues method will check the values FormArray to see check if
	 * the min and max required amount of selected values is respected.
	 *
	 * @private
	 * @returns (control: FormArray) => ValidationErrors
	 */
	private minMaxValues(): (
		control: FormGroup<{
			[key: string]: FormControl<boolean>;
		}>
	) => ValidationErrors {
		return (
			control: FormGroup<{
				[key: string]: FormControl<boolean>;
			}>
		): ValidationErrors => {
			const groupValue = control.getRawValue();
			const values = Object.keys(groupValue).filter((key: string) => !!groupValue[key]);

			const tooLittle = this.minAmount > 0 && values.length < this.minAmount;

			if (tooLittle) {
				return {
					tooLittleValues: true
				};
			}

			if (values.length > this.maxAmount$.getValue()) {
				return {
					tooManyValues: true
				};
			}

			return null;
		};
	}

	private toggleUncheckedFields(value: { [key: string]: boolean }): void {
		if (!this.form || !value) {
			return;
		}

		const selectedAmount = Object.keys(value).filter((key: string) => value[key]).length;

		if (selectedAmount >= this.maxAmount$.getValue()) {
			Object.keys(this.form.controls).forEach((key: string) => {
				const control = this.form.controls[key];

				if (!control.value) {
					control.disable({ emitEvent: false, onlySelf: true });
				}
			});

			return;
		}

		Object.keys(this.form.controls).forEach((key: string) =>
			this.form.controls[key].enable({ emitEvent: false, onlySelf: true })
		);
	}
}
