import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * URLValidator
 *
 * The URLValidator will use regex
 * to validate the giving input and check if it is a valid URL (http or https prefix only, no ftp(s)).
 *
 * @returns ValidatorFn
 */
export const URLValidator = (): ValidatorFn => {
	const URLPattern = /((?:http|https)(?:\:\/\/)+)?(?:([A-z0-9\-]+)\:([A-z0-9\-]+))?\@?([A-z0-9\-]+)(\.[a-z\.]+)/;

	return (control: FormControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		return URLPattern.test(control.value) ? null : { invalidURL: { valid: false } };
	};
};
