import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { AdviceDetailPageEntity } from '@cjm/v-loket/shared';

@Component({
	selector: 'vloket-advice-card',
	templateUrl: './advice-card.component.html',
	styleUrls: ['./advice-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdviceCardComponent {
	@HostBinding('class.c-advice-card') private readonly hasRootClass: boolean = true;

	/**
	 * pages
	 *
	 * The pages attribute will accept an object of type AdviceDetailPageEntity.
	 */
	@Input() public page: AdviceDetailPageEntity;

	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(public readonly i18nService: I18nService) {}
}
