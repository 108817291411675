import { NgModule } from '@angular/core';

import { MediaQueryDirective } from './directives/media-query.directive';
import { ScreenSizeService } from './services/screen-size.service';

@NgModule({
	imports: [],
	declarations: [MediaQueryDirective],
	providers: [ScreenSizeService],
	exports: [MediaQueryDirective]
})
export class DeviceModule {}
