import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { environment } from 'environments';

/**
 * The SERVER_ERROR_HANDLER HttpContextToken enables us to disable
 * the interception error handling for 5xx errors (redirect to error page).
 *
 * When the token is set to false, the redirect will be skipped and the error will be thrown.
 * This allows us to handle specific requests seperatly from the global error handling.
 */
export const SERVER_ERROR_HANDLER: HttpContextToken<boolean> = new HttpContextToken(() => true);

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
	constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any> {
		// Denis: Get the context
		const enabled: boolean = request.context.get(SERVER_ERROR_HANDLER);

		// Abdurrahman: Check if the request is a call to our API, if not early exit
		if (!request.url.includes(environment.api.hostname)) {
			return next.handle(request);
		}

		// Abdurrahman: If the error returns a 50x, we check if we need to redirect the user to something went wrong page
		return next.handle(request).pipe(
			catchError((response) => {
				// Abdurrahman: If the errorCode above 500 and the handler is enabled (default), we redirect the user
				if (enabled && response.status >= 500 && response.status <= 505) {
					this.router.navigate([VLoketAppRoutePaths.ServerError], {
						state: {
							callbackUrl: this.router.url.split('?')[0],
							callbackUrlQueryParams: this.activatedRoute.snapshot.queryParams
						}
					});
				}

				// Abdurrahman: Return the error in regular cases
				return throwError(response);
			})
		);
	}
}
