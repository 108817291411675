import { AuthenticationFailedTypes } from '@cjm/shared/authentication/auth';

export const getAuthFailType = (queryParams: Record<string, string>): AuthenticationFailedTypes | undefined => {
	const { loginThresholdExceeded, remoteAuthSuccess } = queryParams;
	const invalidClaim = queryParams['context[invalid_claim][acr]'];
	let failType: AuthenticationFailedTypes | undefined;

	if (remoteAuthSuccess === 'false') {
		failType = AuthenticationFailedTypes.Failed;
	}

	if (loginThresholdExceeded === 'true') {
		failType = AuthenticationFailedTypes.ThreshholdExceeded;
	}

	if (invalidClaim) {
		failType = AuthenticationFailedTypes.Invalid;
	}

	return failType;
};
