export interface IndexedPaginationResult {
	index: number;
	limiet: number;
	totaalAantal: number;
}

export interface IndexedPagination {
	limit?: number;
	index?: number;
	totalAmount?: number;
	totalPages?: number;
}
