export enum TileBackgroundVariant {
	YellowBackgroundOnTop = 'GeleAchtergrondBovenaan',
	YellowBackgroundOnBottom = 'GeleAchtergrondOnderaan',
	LightGrayBackgroundOnTop = 'LichtgrijzeAchtergrondBovenaan',
	LightGrayBackgroundOnBottom = 'LichtgrijzeAchtergrondOnderaan'
}

export enum TileBackgroundVariantClass {
	GrayTop = 'gray-top',
	GrayBottom = 'gray-bottom',
	YellowTop = 'yellow-top',
	YellowBottom = 'yellow-bottom'
}

export const TileBackgroundVariants: Record<TileBackgroundVariant, TileBackgroundVariantClass> = {
	[TileBackgroundVariant.LightGrayBackgroundOnTop]: TileBackgroundVariantClass.GrayTop,
	[TileBackgroundVariant.LightGrayBackgroundOnBottom]: TileBackgroundVariantClass.GrayBottom,
	[TileBackgroundVariant.YellowBackgroundOnTop]: TileBackgroundVariantClass.YellowTop,
	[TileBackgroundVariant.YellowBackgroundOnBottom]: TileBackgroundVariantClass.YellowBottom
};
