import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { of } from 'rxjs';

import { ToastEntity } from '../interfaces';
import { actions, selectors } from '../toast.store';

@Injectable()
export class ToastService extends StoreService {
	/**
	 * Array of all toast messages in the store
	 */
	public readonly toastMessages$: ObservableArray<ToastEntity> = this.selectFromStore<ToastEntity[]>(selectors);

	constructor(protected readonly store: Store) {
		super(store);
	}

	public setToastMessage(toast: ToastEntity) {
		return dispatchDataToStore(actions, of([toast]), this.store, 'set');
	}
}
