import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'parseWYSIWYG'
})
/**
 * ParseWYSIWYGPipe
 *
 * Parses an HTML (WYSIWYG) string and performs several operations to make it safer and more consistent for display.
 * This method is useful for sanitizing and standardizing user-generated or third-party WYSIWYG content.
 *
 * The following operations are performed:
 * 1. Removes empty text elements (`<p>`, `<span>`, and `<strong>` elements with no content) to prevent unnecessary whitespace.
 * 2. Decreases `<h1>` to `<h2>`, `<h2>` to `<h3>` and `<h4>` to `<h5>`. This helps to ensure consistent heading hierarchy and avoid conflicts with page header and section headers.
 * 3. Adds unique IDs to all headings (`<h1>` to `<h6>`) based on their text content. This allows for easy linking to specific sections of the content.
 * 4. Adds `target="_blank"` and a class (`icon-open-externally`) to all anchor elements (`<a>`). This ensures that external links open in a new tab and provides a consistent visual style for these links.
 *
 * @param htmlString The HTML string to parse.
 * @returns A sanitized HTML string.
 */
export class ParseWYSIWYGPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(htmlString: string): SafeHtml {
		if (!htmlString || typeof htmlString !== 'string') {
			return;
		}

		const parser: DOMParser = new DOMParser();
		const body: Document = parser.parseFromString(htmlString, 'text/html');
		const headers: NodeListOf<HTMLElement> = body.querySelectorAll('h1, h2, h3, h4, h5, h6');
		const textElements: NodeListOf<HTMLElement> = body.querySelectorAll('p, span, strong');
		const anchors: NodeListOf<HTMLElement> = body.querySelectorAll('a[href]');

		// Remove empty text elements
		textElements.forEach((el: HTMLElement) => el.textContent.trim() === '' && el.parentNode.removeChild(el));

		// Add target="_blank" and a class to anchor elements
		anchors.forEach((el: HTMLElement) => {
			el.setAttribute('target', '_blank');
			el.classList.add('icon-open-externally');
		});

		// Convert headings and add unique IDs
		headers.forEach((el: HTMLElement) => {
			const currentTagLevel: number = Number(el.tagName.slice(1));
			const stylingTagLevel: number = currentTagLevel + 2;

			if (currentTagLevel < 5) {
				const newEl = document.createElement(`h${currentTagLevel + 1}`) as HTMLHeadingElement;

				// Copy heading content to the new heading element
				newEl.textContent = el.textContent;

				// Add unique ID to the new heading element
				newEl.id = `anchor_${el.textContent.split(' ').join('_')}`;

				// Add heading classes
				newEl.classList.add('c-vloket-header', `c-vloket-header--${`h${stylingTagLevel}`}`);

				el.replaceWith(newEl);
			}

			// Remove any tags in heading elements to prevent styling inconsistencies
			el.textContent = el.textContent;

			// Add unique ID to the heading element
			el.id = `anchor_${el.textContent.split(' ').join('_')}`;

			// Add heading classes
			el.classList.add('c-vloket-header', `c-vloket-header--h${stylingTagLevel}`);
		});

		return this.sanitizer.bypassSecurityTrustHtml(body.documentElement.innerHTML);
	}
}
