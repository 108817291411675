import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';
import { AssociationApiService, InvitationApiService } from '@cjm/v-loket/repositories';

import { RegistrationResolvers } from './resolvers';
import { RegistrationServices } from './services';
import { reducers } from './store/registration.store';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Registration, reducers)],
	providers: [AssociationApiService, RegistrationServices, InvitationApiService, RegistrationResolvers]
})
export class RegistrationDataModule {}
