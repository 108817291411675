import { Injectable } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject } from 'rxjs';

import { BrowserService } from './browser.service';

@Injectable()
export class SlicingService {
	private readonly showMenuSubject$ = new BehaviorSubject<boolean>(true);
	private readonly hasHomeClassSubject$ = new BehaviorSubject<boolean>(true);
	private readonly hasELoketDecreasedPaddingClassSubject$ = new BehaviorSubject<boolean>(false);
	private readonly eLoketNavigationOpenSubject$ = new BehaviorSubject<boolean>(false);

	constructor(private readonly browserService: BrowserService) {
		// Iben: Do an initial fetch for the open/closed state of the nav bar
		this.browserService.runInBrowser(() => {
			this.eLoketNavigationOpenSubject$.next(Boolean(JSON.parse(localStorage.getItem('navOpen') || 'true')));
		});
	}

	/**
	 * Visible state of the cjm top menu
	 */
	public readonly showMenu$: ObservableBoolean = this.showMenuSubject$.asObservable();

	/**
	 * Whether or not the 'home' slicing class should be set
	 */
	public readonly hasHomeClass$: ObservableBoolean = this.hasHomeClassSubject$.asObservable();

	/**
	 * Whether or not the 'e-loket-no-padding' slicing class should be set
	 */
	public readonly hasELoketDecreasedPaddingClass$: ObservableBoolean =
		this.hasELoketDecreasedPaddingClassSubject$.asObservable();

	/**
	 * Whether or not the navigation bar on e-loket is open or closed
	 */
	public readonly eLoketNavigationOpen$: ObservableBoolean = this.eLoketNavigationOpenSubject$.asObservable();

	/**
	 * Set the visible state of the cjm top menu
	 *
	 * @param visible - Whether or not the menu should be visible
	 */
	public setShowMenu(visible: boolean): void {
		this.showMenuSubject$.next(visible);
	}

	/**
	 * Set the hasHomeClass state
	 *
	 * @param hasHomeClass - Whether or not the 'home' slicing class should be set
	 */
	public setHasHomeClass(hasHomeClass: boolean): void {
		this.hasHomeClassSubject$.next(hasHomeClass);
	}

	/**
	 * Set the HasELoketDecreasedPaddingClass state
	 *
	 * @param hasELoketDecreasedPaddingClass - Whether or not the 'e-loket-no-padding' slicing class should be set
	 */
	public setHasELoketDecreasedPaddingClass(hasELoketDecreasedPaddingClass: boolean): void {
		this.hasELoketDecreasedPaddingClassSubject$.next(hasELoketDecreasedPaddingClass);
	}

	/**
	 * Toggle whether or not the navigation bar on e-loket is open or not
	 *
	 * @param isOpen - Optional isOpen if we want to set a specific value, else we toggle the current value
	 */
	public toggleELoketNavigationBar(isOpen?: boolean): void {
		// Iben: Check if the nav is open
		const isNavOpen = isOpen === undefined ? !this.eLoketNavigationOpenSubject$.getValue() : isOpen;

		// Iben: Update the subject and the localStorage
		this.eLoketNavigationOpenSubject$.next(isNavOpen);
		this.browserService.runInBrowser(() => {
			localStorage.setItem('navOpen', JSON.stringify(isNavOpen));
		});
	}
}
