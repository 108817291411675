<cjm-layout-container
	class="p-stop"
	layoutType="split-equal"
	asidePosition="right"
	alignItems="start"
	preventSpacing="bottom"
>
	<ng-container main>
		<h1 class="c-vloket-header c-vloket-header--h1">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ i18nKeys.Stop.Title | translate }}
			</span>
		</h1>

		<ul>
			<li class="u-margin-bottom-sm" [innerHTML]="i18nKeys.Stop.Steps.One | translate"></li>
			<li [innerHTML]="i18nKeys.Stop.Steps.Two | translate"></li>
		</ul>

		<h2 class="c-vloket-header c-vloket-header--h4 u-margin-top-xl">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">
				{{ i18nKeys.Stop.Steps.Title | translate }}
			</span>
		</h2>

		<div>
			<p class="u-margin-bottom-lg" [innerHTML]="i18nKeys.Stop.Text | translate"></p>

			<button
				cjmUserEIDIdentitySwitch
				[title]="i18nKeys.Actions.TryAgain | translate"
				[callback]="callbackUrlAfterIdetitySwitch"
				[class]="buttonClasses.LinkButton"
			>
				{{ i18nKeys.Actions.TryAgain | translate }}
			</button>
		</div>
	</ng-container>

	<div class="p-stop__visual" asideRight>
		<cjm-img src="img/stop-page-image.svg" />
	</div>
</cjm-layout-container>
