import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ButtonClasses, LinkType, VloketLink } from '@cjm/shared/ui/common';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vloket-newsletter',
	templateUrl: './vloket-newsletter.component.html',
	styleUrls: ['./vloket-newsletter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VloketNewsletterComponent {
	@HostBinding('class.c-vloket-newsletter') private readonly rootClass: boolean = true;

	public readonly i18nKeys = I18nKeys;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;

	@Input() public imgLink: string = 'img/newsletter-illustration.svg';
	@Input() public link: VloketLink = {
		title: this.i18nKeys.Newsletter.Button.Title,
		url: 'https://vlaanderen.us13.list-manage.com/subscribe?u=7558bdab8114a52b4e9c66d8e&id=dd7358dca0',
		type: LinkType.internal,
		text: this.i18nKeys.Newsletter.Button.Text
	};
}
