import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ImgCardAlignment, ImgCardColorType } from './img-card.const';

@Component({
	selector: 'vloket-img-card',
	templateUrl: './img-card.component.html',
	styleUrls: ['./img-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgCardComponent {
	@HostBinding('class.img-card') private readonly hasContainerClass: boolean = true;
	@HostBinding('class.img-card--yellow') private isYellow: boolean = false;
	@HostBinding('class.img-card--grey') private isGrey: boolean = false;
	/**
	 * The backgroundColor input attribute will determine the background color of the card.
	 *
	 * default is set to 'transparent'
	 */
	@Input() public set backgroundColor(color: `${ImgCardColorType}`) {
		this.isYellow = color === ImgCardColorType.Yellow;
		this.isGrey = color === ImgCardColorType.Grey;
	}
	@HostBinding('class.img-card--borderless')
	/**
	 * The borderless input attribute will determine if the card is presented with a border.
	 *
	 * default is set to false
	 */
	@Input()
	public borderless: boolean = false;
	@HostBinding('class.img-card--img-left')
	/**
	 * The imgLeft input attribute will determine the position of the image.
	 *
	 * default is set to 'true'
	 */
	@Input()
	public imgLeft: boolean = true;
	@HostBinding('class.img-card--img-on-top') private isImgAlignedTop: boolean = false;
	@HostBinding('class.img-card--img-on-bottom') private isImgAlignedEnd: boolean = false;
	/**
	 * The imgAlignment input attribute will determine the alignment of the image.
	 *
	 * default is set to 'center'
	 */
	@Input() public set imgAlignment(alignment: `${ImgCardAlignment}`) {
		this.isImgAlignedTop = alignment === ImgCardAlignment.Start;
		this.isImgAlignedEnd = alignment === ImgCardAlignment.End;
	}
	/**
	/**
	 * The id input attribute will determine the id of the card.
	 *
	 * default is set to 0
	 */
	@Input() public id: number = 0;
	/**
	 * The canClose input attribute will determine if the close button is visible.
	 *
	 * default is set to false
	 */
	@Input() public canClose: boolean = false;
	/**
	 * The imgSrc input attribute will determine the source of the image.
	 *
	 * default is set to ''
	 */
	@Input() public imgSrc: string = '';
	/**
	 * The closeClicked output attribute will emit the id of the card when the close button is clicked.
	 *
	 */
	@Output() public closeClicked = new EventEmitter<number>();
}
