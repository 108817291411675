// Backend interface
export interface CompanyLinkResultEntity {
	uri: string;
	titel: string;
	beperktTotEigenOnderneming?: boolean;
}

// Frontend interface
export interface CompanyLinkEntity {
	uri: string;
	title: string;
	limited: boolean;
}
