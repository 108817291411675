<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title">{{ title }}</h3>
	</div>

	<div class="c-modal__body">
		<p>
			{{ text }}
		</p>
	</div>

	<div class="c-modal__footer">
		<cjm-button
			*ngIf="confirmLabel"
			class="c-modal__action"
			cypressTag="ActionModal.Actions.Confirm"
			[classes]="buttonClasses.RegularFullWidth + ' ' + 'c-button--blue'"
			[title]="confirmLabel"
			(click)="buttonClicked.emit('confirm')"
		>
			{{ confirmLabel }}
		</cjm-button>

		<cjm-button
			*ngIf="cancelLabel"
			class="c-modal__action"
			cypressTag="ActionModal.Actions.Cancel"
			[classes]="buttonClasses.OutlineFullWidth + ' ' + 'c-button--blue-outline'"
			[title]="cancelLabel"
			(click)="buttonClicked.emit('cancel')"
		>
			{{ cancelLabel }}
		</cjm-button>
	</div>
</div>
