import { Injectable } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { CJMCookieService } from '@cjm/shared/cookies';
import { BrowserService, SessionService } from '@cjm/shared/core';
import { ACMTargetGroups } from '@cjm/shared/types';
import { isValidHint } from '@cjm/shared/utils';
import { environment, EnvironmentType } from 'environments';

import { AuthenticationFailedTypes } from '../enums';
import { IdentitySwitchOptionsEntity, LoginAsEconomicActorOptionsEntity, LoginOptionsEntity } from '../interfaces';

import { AuthenticationApiService } from './authentication.api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	private readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
	private readonly authenticationFailedTypeSubject$ = new BehaviorSubject<AuthenticationFailedTypes | undefined>(
		undefined
	);

	public readonly isAuthenticated$: ObservableBoolean = this.isAuthenticatedSubject$.asObservable();
	public readonly authenticationFailed$: Observable<AuthenticationFailedTypes | undefined> =
		this.authenticationFailedTypeSubject$.asObservable();

	constructor(
		private readonly apiService: AuthenticationApiService,
		private readonly sessionService: SessionService,
		private readonly cookieService: CJMCookieService,
		private readonly browserService: BrowserService
	) {
		this.isAuthenticatedSubject$ = new BehaviorSubject(this.cookieService.get('cjm.authenticated') === 'true');
	}

	/**
	 * Get whether the user is authenticated
	 */
	public get authenticated() {
		return this.isAuthenticatedSubject$.getValue();
	}

	/**
	 * Remove all authentication cookies
	 */
	public dropAuthentication() {
		// Iben: Update the authenticated state
		this.isAuthenticatedSubject$.next(false);

		// Iben: Remove the cookies
		this.cookieService.remove('cjm.authenticated');
		this.cookieService.remove('cjm.savedKBO');
	}

	public logout() {
		// Clear the cookies before requesting logout on the server
		this.dropAuthentication();

		// Kaat: Reset the authenticated failed state
		this.authenticationFailedTypeSubject$.next(undefined);

		return this.apiService.logOut();
	}

	/**
	 * Login the user using the ACM login flow
	 *
	 * @param options - Options we wish to use to log in the user
	 */
	public login(options: LoginOptionsEntity) {
		// Iben: Split the options into separate variables
		const { customCallBack, capHint } = options;

		// Brecht: protocol + host
		const baseUrl: string = `${environment.acmidm.protocol}://${environment.acmidm.hostname}`;
		// Brecht: loginPath
		const loginPathUrl: string = `${environment.acmidm.loginPath}`;
		// Brecht: specify all the query parameters
		let queryParams;

		// Iben: allow to add a custom callback
		const callback = customCallBack || `/${this.sessionService.language}`;

		// Brecht: encode callback value
		queryParams = `callback=${encodeURIComponent(callback)}`;

		// Iben: If we are in the local application, we attach the redirect param
		queryParams += this.getLocalRedirectUrl();

		// Iben: If there's a code hint, we add it to the queryParams
		if (capHint) {
			const loginHint = JSON.stringify(
				// TODO: Iben: Find better way to type this to prevent the cast
				capHint === ACMTargetGroups.BUR
					? { cap_hint: capHint }
					: { cap_hint: capHint, code_hint: (options as LoginAsEconomicActorOptionsEntity).codeHint }
			);

			if (isValidHint(btoa(loginHint))) {
				// Brecht: base64 encode loginHint
				queryParams += `&login_hint=${btoa(loginHint)}`;
			}
		}

		// Iben: Login the user when the user is not logged in, else log-out the user and login again with the correct queryParams as directed-switch does not exist
		return this.isAuthenticatedSubject$.pipe(
			take(1),
			tap(() => {
				this.browserService.runInBrowser(({ browserWindow }) => {
					browserWindow.location.href = `${baseUrl}${loginPathUrl}?${queryParams}`;
				});
			})
		);
	}

	/**
	 * Switch the user identity using the ACM identity switch flow
	 *
	 * @param options - Options contains the custom callback url
	 * if we want to redirect the user somewhere else after switch
	 */
	public switch(options: IdentitySwitchOptionsEntity) {
		const { customCallBack } = options;

		// protocol + host
		const baseUrl: string = `${environment.acmidm.protocol}://${environment.acmidm.hostname}`;
		// switchPath
		const switchPathUrl: string = `${environment.acmidm.switchPath}`;
		// specify all the query parameters
		let queryParams;

		// Abdurrahman: allow to add a custom callback
		const callback = customCallBack || `/${this.sessionService.language}`;

		// Abdurrahman: encode callback value
		queryParams = `callback=${encodeURIComponent(callback)}`;

		// Abdurrahman: If we are in the local application, we attach the redirect param
		queryParams += this.getLocalRedirectUrl();

		return this.isAuthenticatedSubject$.pipe(
			take(1),
			tap(() => {
				this.browserService.runInBrowser(({ browserWindow }) => {
					browserWindow.location.href = `${baseUrl}${switchPathUrl}?${queryParams}`;
				});
			})
		);
	}

	/**
	 * Log out with EID
	 */
	public logoutEID() {
		// Iben: Drop authentication cookies
		this.dropAuthentication();

		// Kaat: Reset the authenticated failed state
		this.authenticationFailedTypeSubject$.next(undefined);

		return this.apiService.logOutWithEid(this.getLocalRedirectUrl());
	}

	/**
	 * Set authentication cookie for the user
	 *
	 * @param expiresAt - The time at which the cookie expires
	 * @param authenticated - Whether the user is authenticated
	 */
	public setAuthenticationCookie(expiresAt?: string, authenticated: string = 'true') {
		let cookieOptions = {};

		// Iben: If the cookie should expire, set an expiration date
		if (expiresAt) {
			cookieOptions = { expires: new Date(expiresAt) };
		}

		// Iben: Set a boolean cookie that expires when the Drupal Session cookie expires
		this.cookieService.put(
			'cjm.authenticated', // Key
			authenticated, // Value
			cookieOptions // Cookie Options
		);

		// Iben: Set the authenticated state
		this.isAuthenticatedSubject$.next(true);
	}

	/**
	 * Returns the authenticated cookie
	 */
	public getAuthorizationCookie(): string {
		return this.cookieService.get('cjm.authenticated');
	}

	/**
	 * Indicate that the authentication process has failed
	 */
	public setAuthenticationFailed(failType: AuthenticationFailedTypes): void {
		this.authenticationFailedTypeSubject$.next(failType);
	}

	/**
	 * Returns the redirect url if needed
	 */
	public getLocalRedirectUrl(connector: '&' | '?' = '&'): string {
		// Iben: To connect with the dev environment, we pass the domain when we are in the local version of the app
		return environment.environment === EnvironmentType.LOCAL
			? `${connector}redirect_url=${environment.domain}`
			: '';
	}
}
