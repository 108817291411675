<ng-content select="[topSlot]" />

<form *ngIf="initialized$ | async" [formGroup]="form">
	<div *ngFor="let facet of allFacets" class="c-filter-bar__facet">
		<h5 class="c-filter-bar__facet__title">
			{{
				(i18nKeys.SharedAssociations.FilterBar.Facets | hasOwnProperty : facet.name)
					? (i18nKeys.SharedAssociations.FilterBar.Facets[facet.name] | translate)
					: facet.name
			}}
		</h5>

		<ng-container
			*ngIf="!facet.isRefinable"
			[ngTemplateOutlet]="unrefinableTmpl"
			[ngTemplateOutletContext]="{ facet: facet }"
		></ng-container>

		<ng-container
			*ngIf="facet.isRefinable"
			[ngTemplateOutlet]="refinableTmpl"
			[ngTemplateOutletContext]="{ facet: facet }"
		></ng-container>
	</div>

	<ng-template #unrefinableTmpl let-facet="facet">
		<ul class="c-filter-bar__facet__list" [formGroupName]="facet.id">
			<li
				*ngFor="let item of facet.items"
				class="c-filter-bar__facet__list__item"
				cypressTag="FilterBar.UnrefinableFacets.Item"
				[ngClass]="{ 'c-filter-bar__facet__list__item--no-values': item.amount === 0 }"
			>
				<cjm-checkbox
					[formControlName]="item.id"
					[id]="item.id"
					[title]="item.name"
					[title]="item.name"
					[label]="item.name"
					>{{ item.name }}
					<span class="c-filter-bar__facet__list__item__amount">({{ item.amount }})</span></cjm-checkbox
				>
			</li>

			<li *ngIf="facet?.items?.length === 0" class="c-filter-bar__facet__list__fallback">
				{{ i18nKeys.SharedAssociations.FilterBar.Facets.NoResults | translate }}
			</li>
		</ul>
	</ng-template>

	<ng-template #refinableTmpl let-facet="facet">
		<ul class="c-filter-bar__facet__list" [formGroupName]="facet.id">
			<li *ngFor="let item of facet.items" class="c-filter-bar__facet__list__item">
				<div class="c-filter-bar__facet__checkbox--with-icon">
					<cjm-checkbox
						[formControl]="refinableFacetControls[item.id].control"
						[title]="item.name"
						[label]="item.name"
					>
						{{ item.name }}
					</cjm-checkbox>
					<fa-icon
						[icon]="refinableFacetState[item.id] ? 'fa-minus' : 'fa-plus'"
						(focusClick)="toggleRefinableFacet(item.id)"
					/>
				</div>

				<ul *ngIf="refinableFacetState[item.id]" class="c-filter-bar__facet__list">
					<!-- Denis: $any() is needed here because of the union type for facets -->
					<li *ngFor="let refinement of $any(item).refinements" class="c-filter-bar__facet__list__item">
						<cjm-checkbox [formControlName]="refinement.id" [title]="item.name" [label]="item.name">
							{{ refinement.name }}
							<span class="c-filter-bar__facet__list__item__amount"> ({{ refinement.amount }}) </span>
						</cjm-checkbox>
					</li>

					<li *ngIf="$any(item).refinements?.length === 0" class="c-filter-bar__facet__list__fallback">
						{{ i18nKeys.SharedAssociations.FilterBar.Facets.NoResults | translate }}
					</li>
				</ul>
			</li>
		</ul>
	</ng-template>
</form>
