import { Component, HostBinding, Input } from '@angular/core';

import { AlignItems, AsidePosition, LayoutType, PreventSpacingAt } from './layout.const';

@Component({
	selector: 'cjm-layout-container',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutContainerComponent {
	@HostBinding('class.c-cjm-container') private hasContainerClass: boolean = true;

	@HostBinding('class.c-cjm-container--prevent-spacing') private hasPreventSpacingClass: boolean = false;
	@HostBinding('class.c-cjm-container--prevent-spacing-top') private hasPreventSpacingTopClass: boolean = false;
	@HostBinding('class.c-cjm-container--prevent-spacing-right') private hasPreventSpacingRightClass: boolean = false;
	@HostBinding('class.c-cjm-container--prevent-spacing-bottom') private hasPreventSpacingBottomClass: boolean = false;
	@HostBinding('class.c-cjm-container--prevent-spacing-left') private hasPreventSpacingLeftClass: boolean = false;
	/**
	 * Prevent spacing at top and bottom, pushing surrounding content away from the container.
	 *
	 * Default value is set to 'false'.
	 */
	@Input()
	public set preventSpacing(at: `${PreventSpacingAt}` | `${PreventSpacingAt}`[]) {
		const preventAt = this.parsePreventAt(at);

		this.hasPreventSpacingClass = preventAt.includes(PreventSpacingAt.all);
		this.hasPreventSpacingTopClass = preventAt.includes(PreventSpacingAt.top);
		this.hasPreventSpacingRightClass = preventAt.includes(PreventSpacingAt.right);
		this.hasPreventSpacingBottomClass = preventAt.includes(PreventSpacingAt.bottom);
		this.hasPreventSpacingLeftClass = preventAt.includes(PreventSpacingAt.left);
	}

	@HostBinding('class.c-cjm-container--preserve')
	/**
	 * Whether or not to preserve the selected layout on smaller screen sizes.
	 *
	 * Default is set to 'false'.
	 */
	@Input()
	public preserve: boolean = false;

	@Input()
	public classesMain: string = '';

	@Input()
	public classesAside: string = '';

	@HostBinding('class.c-cjm-container--full-width') private hasFullWidthClass: boolean = false;
	@HostBinding('class.c-cjm-container--split-equal') private hasSplitEqualClass: boolean = true;
	@HostBinding('class.c-cjm-container--split-content-right') private hasSplitContentRightClass: boolean = false;
	@HostBinding('class.c-cjm-container--split-content-left') private hasSplitContentLeftClass: boolean = false;
	/**
	 * Which layout type to render.
	 *
	 * Options are: full-width, s.plit-equal, split-content-right.
	 *
	 * Default is set to 'split-equal'.
	 */
	@Input()
	public set layoutType(type: `${LayoutType}`) {
		switch (type) {
			case LayoutType.fullWidth:
				this.hasFullWidthClass = true;
				this.hasSplitEqualClass = false;
				this.hasSplitContentRightClass = false;
				break;

			case LayoutType.splitContentRight:
				this.hasFullWidthClass = false;
				this.hasSplitEqualClass = false;
				this.hasSplitContentRightClass = true;
				break;

			case LayoutType.splitContentLeft:
				this.hasFullWidthClass = false;
				this.hasSplitEqualClass = false;
				this.hasSplitContentRightClass = false;
				this.hasSplitContentLeftClass = true;

			case LayoutType.splitEqual:
			default:
				this.hasFullWidthClass = false;
				this.hasSplitEqualClass = true;
				this.hasSplitContentRightClass = false;
				break;
		}
	}

	@HostBinding('class.c-cjm-container--align-items--start') private hasAlignStartClass: boolean = true;
	@HostBinding('class.c-cjm-container--align-items--center') private hasAlignCenterClass: boolean = false;
	@HostBinding('class.c-cjm-container--align-items--end') private hasAlignEndClass: boolean = false;

	/**
	 * How to align items within the container.
	 *
	 * Options are: start, center, end.
	 *
	 * Default is set to 'start'.
	 */
	@Input()
	public set alignItems(align: `${AlignItems}`) {
		// Denis: the default should be start so when align is undefined, set the start class to true.
		this.hasAlignStartClass = !align || align === AlignItems.start;
		this.hasAlignCenterClass = align === AlignItems.center;
		this.hasAlignEndClass = align === AlignItems.end;
	}

	/**
	 * Which position the aside content should take.
	 *
	 * Options are: left, right.
	 *
	 * Default is set to 'right'.
	 */
	@Input() public asidePosition: `${AsidePosition}` = AsidePosition.right;

	public readonly AsidePosition: typeof AsidePosition = AsidePosition;

	private parsePreventAt(at: `${PreventSpacingAt}` | `${PreventSpacingAt}`[]): `${PreventSpacingAt}`[] {
		// Denis: if the given item is a string and a value of the PreventSpacingAt enum, return that item wrapped in a new array.
		if (typeof at === 'string' && Object.values<string>(PreventSpacingAt).includes(at)) {
			return [at];
		}

		// Denis: if the above is not valid, check if the
		return !Array.isArray(at)
			? []
			: at.filter((value: `${PreventSpacingAt}`) => Object.values<string>(PreventSpacingAt).includes(value));
	}
}
