import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ProductEntity } from '@cjm/shared/types';

@Component({
	selector: 'vloket-product-grid',
	templateUrl: './product-grid.component.html',
	styleUrls: ['./product-grid.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGridComponent {
	@HostBinding('class.c-product-grid') public readonly hasCProductGridClass: boolean = true;
	@Input() public products: ProductEntity[] = [];
	@Input() public loading: boolean = false;
	@Input() public error: boolean = false;
}
