export enum AssociationLocationType {
	activities = 'Activiteiten',
	correspondence = 'Correspondentie',
	headquarter = 'Maatschappelijke zetel volgens KBO'
}

export enum AssociationContactItemType {
	Email = 'E-mail',
	SocialMedia = 'SocialMedia',
	Phone = 'Telefoon',
	Website = 'Website'
}

export enum AssociationRelationType {
	ChildOf = 'Is afdeling van',
	ParentOf = 'Heeft als afdeling'
}

export enum MyAssociationProgressProperty {
	Name = 'naam',
	ShortDescription = 'korteBeschrijving',
	StartDate = 'startdatum',
	ContactInfo = 'contactgegevens',
	Locations = 'locaties',
	Representatives = 'vertegenwoordigers',
	MainActivities = 'hoofdactiviteitenVerenigingsloket'
}

export enum DetailInfoSection {
	Contact = 'Contact',
	Representative = 'Representative',
	Location = 'Location'
}

export enum AssociationCode {
	FV = 'FV',
	VZW = 'VZW',
	IVZW = 'IVZW',
	PS = 'PS',
	SVON = 'SVON',
	AFD = 'AFD'
}

export enum AssociationType {
	FV = 'Feitelijke vereniging',
	VZW = 'Vereniging zonder winstoogmerk',
	IVZW = 'Internationale vereniging zonder winstoogmerk',
	PS = 'Private stichting',
	SVON = 'Stichting van openbaar nut',
	AFD = 'Afdeling'
}

export enum AssociationSource {
	KBO = 'KBO',
	INITIATOR = 'Initiator'
}

export enum RepresentativeStatus {
	ACTIVE = 'Actief', // Denis: only for invites
	WAITING_FOR_RESPONSE = 'Wacht op antwoord',
	ACCEPTED = 'Aanvaard',
	DECLINED = 'Geweigerd',
	REVOKED = 'Ingetrokken',
	EXPIRED = 'Verlopen'
}

export enum AssociationTargetAudience {
	MIN_AGE = 0,
	MAX_AGE = 149,
	DEFAULT_MAX_AGE = 150
}
