import { IconType, SocialMediaIconPipe } from '@cjm/shared/ui/common';

import { AssociationContactItemType } from '../../../data';

/**
 * parseAssociationContactItemIcon
 *
 * The parseAssociationContactItemIcon helper will return the correct icon for the given contact-item type.
 *
 * @param type
 * @returns string
 */
export const parseAssociationContactItemIcon = (
	type: AssociationContactItemType,
	value?: string
): {
	icon: string;
	iconType: IconType;
} => {
	const socialMediaIcons = new SocialMediaIconPipe();

	switch (type) {
		case AssociationContactItemType.Email:
			return {
				icon: 'fa-envelope',
				iconType: IconType.regular
			};
		case AssociationContactItemType.Phone:
			return {
				icon: 'fa-phone',
				iconType: IconType.solid
			};
		case AssociationContactItemType.Website:
			return {
				icon: 'fa-link',
				iconType: IconType.solid
			};
		case AssociationContactItemType.SocialMedia:
			return socialMediaIcons.transform(value);
		default:
			return {
				icon: '',
				iconType: IconType.regular
			};
	}
};

/**
 * parseAssociationContactItemUrl
 *
 * The parseAssociationContactItemUrl helper will return the correct url for the given contact-item type.
 *
 * @param type
 * @param value
 * @returns string
 */
export const parseAssociationContactItemUrl = (type: AssociationContactItemType, value: string): string => {
	switch (type) {
		case AssociationContactItemType.Email:
			return `mailto:${value}`;
		case AssociationContactItemType.Phone:
			return `tel:${value}`;
		case AssociationContactItemType.Website:
		case AssociationContactItemType.SocialMedia:
		default:
			return value;
	}
};
