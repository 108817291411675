<form *ngIf="initialized$ | async" [formGroup]="form">
	<div *ngFor="let facet of allFacets" class="c-filter-buttons-bar__facet">
		<h2 class="c-vloket-header c-vloket-header--h4 u-margin-bottom-lg">
			<span class="c-vloket-text-marking c-vloket-text-marking--underline">
				{{ facet.name }}
			</span>
		</h2>

		<ng-container
			*ngIf="!facet.isRefinable"
			[ngTemplateOutlet]="unrefinableTmpl"
			[ngTemplateOutletContext]="{ facet: facet }"
		></ng-container>

		<ng-container
			*ngIf="facet.isRefinable"
			[ngTemplateOutlet]="refinableTmpl"
			[ngTemplateOutletContext]="{ facet: facet }"
		></ng-container>
	</div>

	<ng-template #unrefinableTmpl let-facet="facet">
		<ul class="c-filter-buttons-bar__facet__list" [formGroupName]="facet.id">
			<li *ngFor="let item of facet.items" class="c-filter-buttons-bar__facet__list__item">
				<cjm-checkbox
					[formControlName]="item.id"
					[id]="item.id"
					[label]="item.id"
					[title]="item.name"
					[label]="item.name"
				>
					<vloket-advice-page-theme-tag [tagId]="item.id" />
					<p>
						{{ item.name }}
					</p>
				</cjm-checkbox>
			</li>

			<li *ngIf="facet?.items?.length === 0" class="c-filter-buttons-bar__facet__list__fallback">
				{{ i18nKeys.SharedAssociations.FilterBar.Facets.NoResults | translate }}
			</li>
		</ul>
	</ng-template>

	<ng-template #refinableTmpl let-facet="facet">
		<ul class="c-filter-buttons-bar__facet__list" [formGroupName]="facet.id">
			<li *ngFor="let item of facet.items" class="c-filter-buttons-bar__facet__list__item">
				<div class="c-filter-buttons-bar__facet__checkbox--with-icon">
					<cjm-checkbox
						[formControl]="refinableFacetControls[item.id].control"
						[title]="item.name"
						[label]="item.name"
					>
						{{ item.name }}
					</cjm-checkbox>
					<fa-icon
						[icon]="refinableFacetState[item.id] ? 'fa-minus' : 'fa-plus'"
						(focusClick)="toggleRefinableFacet(item.id)"
					/>
				</div>

				<ul *ngIf="refinableFacetState[item.id]" class="c-filter-buttons-bar__facet__list">
					<!-- $any() is needed here because of the union type for facets -->
					<li
						*ngFor="let refinement of $any(item).refinements"
						class="c-filter-buttons-bar__facet__list__item"
					>
						<cjm-checkbox [formControlName]="refinement.id" [title]="item.name" [label]="item.name">
							{{ refinement.name }}
							<span class="c-filter-buttons-bar__facet__list__item__amount">
								({{ refinement.amount }})
							</span>
						</cjm-checkbox>
					</li>

					<li
						*ngIf="$any(item).refinements?.length === 0"
						class="c-filter-buttons-bar__facet__list__fallback"
					>
						{{ i18nKeys.SharedAssociations.FilterBar.Facets.NoResults | translate }}
					</li>
				</ul>
			</li>
		</ul>
	</ng-template>
</form>
