export interface ProductPartner {
	id: string;
	label: string;
	url: string;
}

export interface Product {
	self?: string;
	title: string;
	url: string;
	host: string;
	body: string;
	info: string;
	type: string;
	status: string;
	partner: ProductPartner;
	contact: string;
	thema: string[];
	domain?: string;
	requestUri?: string;
	ipdc?: string;
	maturiteit?: string;
	remarks?: string;
	start?: Date;
	start_valid?: boolean;
	end?: Date;
	end_valid?: boolean;
}

export interface ProductButton {
	text: string;
	uri: string;
	type: string; // TODO: Change to enum type.
	description?: string[];
}

export interface ProductWithButtons {
	product: Product;
	buttons: ProductButton[];
}

export interface ProductWithFeedback {
	product: Product;
}

export interface IOMResponse {
	self?: string;
	recommendedProducts: RecommendedProduct[];
	isComplete: boolean;
	timestamp: string;
	kboId: string;
}

export interface RecommendedProduct extends ProductWithButtons, ProductWithFeedback {
	recommendation: string; // TODO: Change to enum type.
	branches: string[];
}
