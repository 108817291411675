import { combineReducers } from '@ngrx/store';
import { createBaseStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@cjm/shared/store';

import { CompanyEntity } from './interfaces';

const userCompanyAssets = createBaseStoreAssets<CompanyEntity>({
	slice: `${StoreSlice.Company}.company`
});

export const actions = {
	company: userCompanyAssets.actions
};

export const selectors = {
	company: userCompanyAssets.selectors
};

export const reducers = combineReducers({
	company: userCompanyAssets.reducers
});
