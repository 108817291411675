import { Pipe, PipeTransform } from '@angular/core';

import { AssociationLocationEntity, mainLocationSelector } from '../../../data';

@Pipe({
	name: 'mainLocation'
})
export class MainLocationPipe implements PipeTransform {
	/**
	 * Finds the main location from an array of association-locations.
	 *
	 * This process will follow the following priority
	 * 1. If there is only one location return that location
	 * 2. Primary location
	 * 3. KBO HQ
	 * 4. Correspondence address
	 * 5. First activity location
	 * 6. null (no location)
	 *
	 */
	public transform(locations: Array<AssociationLocationEntity>) {
		return mainLocationSelector(locations);
	}
}
