import { of } from 'rxjs';

export const AdviceDetailPageDataEntityMock = {
	id: 'welke_juridische_vorm_kies_ik_voor_mijn_vereniging',
	title: 'Welke juridische vorm kies ik voor mijn vereniging?',
	description: '<p>De korte omschrijving van de nieuwe adviespagina</p>\n',
	heroImage: {
		url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/hero/benjamin-wedemeyer-HHx_8m6-YNk-unsplash.jpeg',
		alt: 'Hiker and his dog standing on the mountain top with wide panorama view during sunset',
		height: '758',
		width: '1920',
		type: 'hero'
	},
	keywords: [
		{ id: 'aankoop_huur', name: 'Aankoop/huur' },
		{ id: 'administratie', name: 'Administratie' },
		{ id: 'evenement', name: 'Evenement' }
	],
	components: [
		{
			type: 'Tekst',
			title: 'Wat is een vereniging?',
			text: '<p>Een vereniging ontstaat wanneer twee of meer mensen beslissen om samen een bepaald belangeloos doel na te streven in een niet-economische context. Er mag winst worden gerealiseerd, maar deze moet worden gebruikt om het gemeenschappelijk doel te realiseren en mag niet worden uitgekeerd aan de oprichters, leden, bestuurders of anderen.</p>\n',
			variant: 'LichtgrijzeAchtergrond'
		},
		{
			type: 'Tegels',
			title: 'Soorten verenigingen',
			text: '<p>Er zijn drie soorten verenigingen, elk met eigen verplichtingen, mogelijkheden en voordelen:</p>\n',
			variant: 'LichtgrijzeAchtergrondBovenaan',
			tiles: [
				{
					title: 'Feitelijke vereniging',
					text: '<p>(vereniging zonder rechtspersoonlijkheid) = zodra twee of meer mensen beslissen om samen een gemeenschappelijk belangeloos doel na te streven, ontstaat ‘automatisch’ een feitelijke vereniging</p>\n'
				},
				{
					title: 'Vereniging zonder winstoogmerk of vzw',
					text: '<p>(vereniging met rechtspersoonlijkheid) = een samenwerking tussen twee of meer natuurlijke personen of rechtspersonen die een belangeloos doel nastreven én die enkele specifieke (rechts)handelingen verrichten die ervoor zorgen dat de leden niet individueel aansprakelijk kunnen gesteld worden voor acties die door de vereniging gesteld worden.</p>\n'
				},
				{
					title: 'Internationale vereniging zonder winstoogmerk of ivzw',
					text: '<p>= een rechtsvorm voor een vzw met een internationaal nut. Ze is bedoeld voor organisaties die internationaal actief zijn.</p>\n'
				}
			]
		},
		{
			type: 'AfbeeldingEnTekst',
			title: 'Juridische gevolgen',
			text: '<p>De keuze van het type vereniging heeft grote juridische, en daaruit voorvloeiend, administratieve en fiscale gevolgen. Kort samengevat:</p>\n<p><strong>Vereniging zonder winstoogmerk</strong></p>\n<ul><li>Formaliteiten bij oprichting, maar makkelijke en goedkope voorwaarden: geen startkapitaal vereist, wel (beperkte) publicatiekosten oprichting, aanpassing statuten of wijziging raad van bestuur</li>\n<li>Persoonlijk vermogen en aansprakelijkheid van de leden wordt beschermd</li>\n<li>Vaak een vereiste voor subsidies, erkenningen, aankopen en dergelijke</li>\n<li>Beperkte belastingverplichtingen:</li>\n<li>Boekhoudkundige, administratieve en fiscale verplichtingen en andere wettelijke verplichtingen bij beheer</li>\n<li>Geschikt als je regelmatig activiteiten organiseert of collectief op een eenvoudige manier geld wil beheren, maar niet uitkeert aan de leden</li>\n</ul><p><strong>Feitelijke vereniging</strong></p>\n<ul><li>Formaliteiten bij oprichting, maar makkelijke en goedkope voorwaarden: geen startkapitaal vereist, wel (beperkte) publicatiekosten oprichting, aanpassing statuten of wijziging raad van bestuur</li>\n<li>Persoonlijk vermogen en aansprakelijkheid van de leden wordt beschermd</li>\n<li>Vaak een vereiste voor subsidies, erkenningen, aankopen en dergelijke</li>\n<li>Beperkte belastingverplichtingen:</li>\n<li>Boekhoudkundige, administratieve en fiscale verplichtingen en andere wettelijke verplichtingen bij beheer</li>\n<li>Geschikt als je regelmatig activiteiten organiseert of collectief op een eenvoudige manier geld wil beheren, maar niet uitkeert aan de leden</li>\n</ul>',
			image: {
				url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/image/super-snapper-VcOjsAUvPUs-unsplash.jpeg',
				alt: 'MacBook Pro with Touchbar on wooden desk with pot plant, shot in black and white.',
				height: '1280',
				width: '1920',
				type: 'vierkant'
			},
			variant: 'AfbeeldingRechts'
		},
		{ type: 'Scheidingslijn', variant: 'GeelVlakOnderaanRechts' },
		{
			type: 'Tegels',
			title: 'Voor- en nadelen',
			text: '<p>De twee meest voorkomende rechtsvormen van een vereniging zijn de feitelijke vereniging en de vzw. De rechtsvorm van de vzw biedt veel voordelen (rechtspersoonlijkheid, duidelijk geheel van regels,…) en een betere bescherming. Daarom wordt vanuit juridisch oogpunt sterk aangeraden om van je vereniging een vzw te maken. Eén van nadelen is wel dat het oprichten en beheren van een vzw een aantal formaliteiten met zich meebrengen. Daarom is het nuttig dat je de kosten, inspanningen en mogelijke risico’s afweegt vóór je een beslissing neemt. Ikorganiseer.be somt de voor- en nadelen duidelijk op in een overzichtelijke tabel.</p>\n',
			variant: 'GeleAchtergrondBovenaan',
			tiles: [
				{
					title: 'Vereniging zonder winstoogmerk',
					text: '<ul><li>Formaliteiten bij oprichting, maar makkelijke en goedkope voorwaarden: geen startkapitaal vereist, wel (beperkte) publicatiekosten oprichting, aanpassing statuten of wijziging raad van bestuur</li>\n<li>Persoonlijk vermogen en aansprakelijkheid van de leden wordt beschermd</li>\n<li>Vaak een vereiste voor subsidies, erkenningen, aankopen en dergelijke</li>\n<li>Beperkte belastingverplichtingen:</li>\n<li>Boekhoudkundige, administratieve en fiscale verplichtingen en andere wettelijke verplichtingen bij beheer</li>\n<li>Geschikt als je regelmatig activiteiten organiseert of collectief op een eenvoudige manier geld wil beheren, maar niet uitkeert aan de leden</li>\n</ul>'
				},
				{
					title: 'Feitelijke vereniging',
					text: '<ul><li>Formaliteiten bij oprichting, maar makkelijke en goedkope voorwaarden: geen startkapitaal vereist, wel (beperkte) publicatiekosten oprichting, aanpassing statuten of wijziging raad van bestuur</li>\n<li>Persoonlijk vermogen en aansprakelijkheid van de leden wordt beschermd</li>\n<li>Vaak een vereiste voor subsidies, erkenningen, aankopen en dergelijke</li>\n<li>Beperkte belastingverplichtingen:</li>\n<li>Boekhoudkundige, administratieve en fiscale verplichtingen en andere wettelijke verplichtingen bij beheer</li>\n<li>Geschikt als je regelmatig activiteiten organiseert of collectief op een eenvoudige manier geld wil beheren, maar niet uitkeert aan de leden</li>\n</ul>'
				}
			]
		},
		{
			type: 'Linken',
			title: 'Linken',
			variant: 'AchtergrondMetVisual',
			linkgroups: [
				{
					title: 'Verenigingsloket links',
					links: [
						{ title: 'Verenigingsloket 1', url: 'https://verenigingsloket.be/' },
						{ title: 'Verenigingsloket 2', url: 'https://verenigingsloket.be/' },
						{ title: 'Verenigingsloket 3', url: 'https://verenigingsloket.be/' }
					]
				},
				{
					title: 'Verenigingsloket links 2',
					links: [
						{ title: 'Verenigingsloket 1', url: 'https://verenigingsloket.be/' },
						{ title: 'Verenigingsloket 2', url: 'https://verenigingsloket.be/' },
						{ title: 'Verenigingsloket 3', url: 'https://verenigingsloket.be/' }
					]
				}
			]
		},
		{
			type: 'Stappenplan',
			title: 'Stappen plan',
			text: "<p><strong>Lorem Ipsum</strong> is slechts een proeftekst uit het drukkerij- en zetterijwezen. Lorem Ipsum is de standaard proeftekst in deze bedrijfstak sinds de 16e eeuw, toen een onbekende drukker een zethaak met letters nam en ze door elkaar husselde om een font-catalogus te maken. Het heeft niet alleen vijf eeuwen overleefd maar is ook, vrijwel onveranderd, overgenomen in elektronische letterzetting. Het is in de jaren '60 populair geworden met de introductie van Letraset vellen met Lorem Ipsum passages en meer recentelijk door desktop publishing software zoals Aldus PageMaker die versies van Lorem Ipsum bevatten.</p>\n",
			steps: [
				{
					title: 'Wat is Lorem Ipsum?',
					text: "<p>Lorem Ipsum is slechts een proeftekst uit het drukkerij- en zetterijwezen. Lorem Ipsum is de standaard proeftekst in deze bedrijfstak sinds de 16e eeuw, toen een onbekende drukker een zethaak met letters nam en ze door elkaar husselde om een font-catalogus te maken. Het heeft niet alleen vijf eeuwen overleefd maar is ook, vrijwel onveranderd, overgenomen in elektronische letterzetting. Het is in de jaren '60 populair geworden met de introductie van Letraset vellen met Lorem Ipsum passages en meer recentelijk door desktop publishing software zoals Aldus PageMaker die versies van Lorem Ipsum bevatten.</p>\n"
				},
				{
					title: 'Waar komt het vandaan?',
					text: '<p>In tegenstelling tot wat algemeen aangenomen wordt is Lorem Ipsum niet zomaar willekeurige tekst. het heeft zijn wortels in een stuk klassieke latijnse literatuur uit 45 v.Chr. en is dus meer dan 2000 jaar oud. Richard McClintock, een professor latijn aan de Hampden-Sydney College in Virginia, heeft één van de meer obscure latijnse woorden, consectetur, uit een Lorem Ipsum passage opgezocht, en heeft tijdens het zoeken naar het woord in de klassieke literatuur de onverdachte bron ontdekt. Lorem Ipsum komt uit de secties 1.10.32 en 1.10.33 van "de Finibus Bonorum et Malorum" (De uitersten van goed en kwaad) door Cicero, geschreven in 45 v.Chr. Dit boek is een verhandeling over de theorie der ethiek, erg populair tijdens de renaissance. De eerste regel van Lorem Ipsum, "Lorem ipsum dolor sit amet..", komt uit een zin in sectie 1.10.32.</p>\n<p>Het standaard stuk van Lorum Ipsum wat sinds de 16e eeuw wordt gebruikt is hieronder, voor wie er interesse in heeft, weergegeven. Secties 1.10.32 en 1.10.33 van "de Finibus Bonorum et Malorum" door Cicero zijn ook weergegeven in hun exacte originele vorm, vergezeld van engelse versies van de 1914 vertaling door H. Rackham.</p>\n'
				},
				{
					title: 'Waarom gebruiken we het?',
					text: '<p>Het is al geruime tijd een bekend gegeven dat een lezer, tijdens het bekijken van de layout van een pagina, afgeleid wordt door de tekstuele inhoud. Het belangrijke punt van het gebruik van Lorem Ipsum is dat het uit een min of meer normale verdeling van letters bestaat, in tegenstelling tot "Hier uw tekst, hier uw tekst" wat het tot min of meer leesbaar nederlands maakt. Veel desktop publishing pakketten en web pagina editors gebruiken tegenwoordig Lorem Ipsum als hun standaard model tekst, en een zoekopdracht naar "lorem ipsum" ontsluit veel websites die nog in aanbouw zijn. Verscheidene versies hebben zich ontwikkeld in de loop van de jaren, soms per ongeluk soms expres (ingevoegde humor en dergelijke).</p>\n'
				},
				{
					title: 'Waar kan ik het vinden?',
					text: '<p>Er zijn vele variaties van passages van Lorem Ipsum beschikbaar maar het merendeel heeft te lijden gehad van wijzigingen in een of andere vorm, door ingevoegde humor of willekeurig gekozen woorden die nog niet half geloofwaardig ogen. Als u een passage uit Lorum Ipsum gaat gebruiken dient u zich ervan te verzekeren dat er niets beschamends midden in de tekst verborgen zit. Alle Lorum Ipsum generators op Internet hebben de eigenschap voorgedefinieerde stukken te herhalen waar nodig zodat dit de eerste echte generator is op internet. Het gebruikt een woordenlijst van 200 latijnse woorden gecombineerd met een handvol zinsstructuur modellen om een Lorum Ipsum te genereren die redelijk overkomt. De gegenereerde Lorum Ipsum is daardoor altijd vrij van herhaling, ingevoegde humor of ongebruikelijke woorden etc.</p>\n'
				}
			]
		},
		{ type: 'Scheidingslijn', variant: 'GeelVlakOnderaanLinks' },
		{
			type: 'Tekst',
			title: null,
			text: '<table><thead><tr><th></th>\n<th>Vereniging zonder winstoogmerk</th>\n<th>Feitelijke vereniging</th>\n<th>Internationale vzw</th>\n</tr></thead><tbody><tr><th>Rechtspersoonlijkheid</th>\n<td>√</td>\n<td>√</td>\n<td>x</td>\n</tr><tr><th>Leden</th>\n<td>Minstens 2</td>\n<td>Minstens 2</td>\n<td>Minstens 2</td>\n</tr><tr><th>Oprichtingsakte</th>\n<td>Authentiek of onderhands</td>\n<td>Authentiek, dus notaris is nodig</td>\n<td>Onderhands, dus geen notaris nodig</td>\n</tr><tr><th>Bestuur</th>\n<td>Minstens 3 bestuurders</td>\n<td>Vrij te bepalen in de statuten</td>\n<td>Vrij te bepalen in de statuten</td>\n</tr><tr><th>Boekhouding</th>\n<td>Eenvoudig/dubbel</td>\n<td>Eenvoudig/dubbel</td>\n<td>Eenvoudig/dubbel</td>\n</tr></tbody></table><p></p>\n',
			variant: 'WitteAchtergrond'
		},
		{
			type: 'Tekst',
			title: 'Voor- en nadelen',
			text: '<p>De twee meest voorkomende rechtsvormen van een vereniging zijn de feitelijke vereniging en de vzw. De rechtsvorm van de vzw biedt veel voordelen (rechtspersoonlijkheid, duidelijk geheel van regels,…) en een betere bescherming. Daarom wordt vanuit <strong>juridisch oogpunt sterk aangeraden</strong> om van je vereniging <strong>een vzw</strong> te maken. Eén van nadelen is wel dat het oprichten en beheren van een vzw een aantal formaliteiten met zich meebrengen. Daarom is het nuttig dat je de kosten, inspanningen en mogelijke risico’s afweegt vóór je een beslissing neemt. <a href="ikorganiseer.be" target="_blank">Ikorganiseer.be</a> somt de voor- en nadelen duidelijk op in een overzichtelijke tabel.</p>\n',
			variant: 'AchtergrondMetVisual'
		},
		{
			type: 'AanbevolenAdviezen',
			title: 'Lees ook',
			variant: 'AchtergrondMetVisual',
			pages: [
				{
					id: 'welke_juridische_vorm_kies_ik_voor_mijn_vereniging',
					title: 'Welke juridische vorm kies ik voor mijn vereniging?',
					description: '<p>De korte omschrijving van de nieuwe adviespagina</p>\n',
					heroImage: {
						url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/teaser/benjamin-wedemeyer-HHx_8m6-YNk-unsplash_250.jpeg',
						alt: 'Hiker and his dog standing on the mountain top with wide panorama view during sunset',
						height: '758',
						width: '1920',
						type: 'teaser'
					},
					keywords: [
						{ id: 'aankoop_huur', name: 'Aankoop/huur' },
						{ id: 'administratie', name: 'Administratie' },
						{ id: 'evenement', name: 'Evenement' }
					]
				},
				{
					id: 'welke_juridische_vorm_kies_ik_voor_mijn_vereniging',
					title: 'Welke juridische vorm kies ik voor mijn vereniging?',
					description: '<p>De korte omschrijving van de nieuwe adviespagina</p>\n',
					heroImage: {
						url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/teaser/benjamin-wedemeyer-HHx_8m6-YNk-unsplash_250.jpeg',
						alt: 'Hiker and his dog standing on the mountain top with wide panorama view during sunset',
						height: '758',
						width: '1920',
						type: 'teaser'
					},
					keywords: [
						{ id: 'aankoop_huur', name: 'Aankoop/huur' },
						{ id: 'administratie', name: 'Administratie' },
						{ id: 'evenement', name: 'Evenement' }
					]
				},
				{
					id: 'welke_juridische_vorm_kies_ik_voor_mijn_vereniging',
					title: 'Welke juridische vorm kies ik voor mijn vereniging?',
					description: '<p>De korte omschrijving van de nieuwe adviespagina</p>\n',
					heroImage: {
						url: 'https://cms.v-loket-dev.district01.be/sites/default/files/advice/teaser/benjamin-wedemeyer-HHx_8m6-YNk-unsplash_250.jpeg',
						alt: 'Hiker and his dog standing on the mountain top with wide panorama view during sunset',
						height: '758',
						width: '1920',
						type: 'teaser'
					},
					keywords: [
						{ id: 'aankoop_huur', name: 'Aankoop/huur' },
						{ id: 'administratie', name: 'Administratie' },
						{ id: 'evenement', name: 'Evenement' }
					]
				}
			]
		}
	]
};

export const AdviceDetailPageFacadeMock = () => ({
	adviceDetailPageData$: of([AdviceDetailPageDataEntityMock]),
	init: jasmine.createSpy().and.returnValue(of(true))
});
