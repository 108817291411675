import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';

import { MediaQueryMax, MediaQueryMin } from '@cjm/shared/types';

import { Breadcrumb, BreadcrumbService } from '../../../data';
@Component({
	selector: 'cjm-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['breadcrumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent {
	/**
	 * The breadcrumbs that currently need displaying.
	 */
	public readonly breadcrumbs$: ObservableArray<Breadcrumb> = this.breadcrumbService.breadcrumbs$;
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	constructor(private readonly breadcrumbService: BreadcrumbService) {}
}
