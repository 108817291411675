import { IconType } from '../../components/fa-icon/fa-icon.const';

export enum SocialMediaPlatform {
	facebook = 'facebook.com',
	messenger = 'messenger.com',
	instagram = 'instagram.com',
	tiktok = 'tiktok.com',
	threads = 'threads.net',
	linkedin = 'linkedin.com',
	youtube = 'youtube.com',
	snapchat = 'snapchat.com',
	discord = 'discord.gg',
	whatsapp = 'wa.me',
	telegram = 't.me',
	twitter = 'twitter.com',
	x = 'x.com'
}

export enum SocialMediaFaIcon {
	facebook = 'fa-facebook-f',
	messenger = 'fa-facebook-messenger',
	instagram = 'fa-instagram',
	tiktok = 'fa-tiktok',
	threads = 'fa-threads',
	linkedin = 'fa-linkedin-in',
	youtube = 'fa-youtube',
	snapchat = 'fa-snapchat',
	discord = 'fa-discord',
	whatsapp = 'fa-whatsapp',
	telegram = 'fa-telegram',
	twitter = 'fa-x-twitter',
	// Because X (formerly twitter) is currently using both domain names
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	x = 'fa-x-twitter',
	fallback = 'fa-hashtag'
}

export interface SocialMediaIcon {
	icon: string;
	iconType: IconType;
}
