<ng-container *ngIf="isActive(); else pageContent">
	<div class="c-sidebar__sidebar-content">
		<div *mediaQuery="mediaQueryMax.Desktop" class="c-sidebar__sidebar-content__header">
			<div
				*ngIf="showAllItems && (sidebarTitle() || sidebarHeaderLink())"
				class="c-sidebar__sidebar-content__header__title"
			>
				<div *ngIf="sidebarTitle()" class="c-vloket-header c-vloket-header--h5 u-no-margin-bottom">
					{{ sidebarTitle() }}
				</div>
				<cjm-link
					*ngIf="sidebarHeaderLink() as link"
					class="c-vloket-text--s c-cjm-link--no-decoration"
					[title]="link.title"
					[to]="link.url"
					[type]="link.type"
					[disabled]="link.disabled"
					[tooltip]="link.tooltip"
					>{{ link.text }}</cjm-link
				>
			</div>
		</div>

		<div *mediaQuery="mediaQueryMin.Desktop" class="c-sidebar__sidebar-content__header">
			<div
				*ngIf="isCollapsed() === false && (sidebarTitle() || sidebarHeaderLink())"
				class="c-sidebar__sidebar-content__header__title"
			>
				<div *ngIf="sidebarTitle()" class="c-vloket-header c-vloket-header--h5 u-no-margin-bottom">
					{{ sidebarTitle() }}
				</div>
				<cjm-link
					*ngIf="sidebarHeaderLink() as link"
					class="c-vloket-text--s c-cjm-link--no-decoration"
					[title]="link.title"
					[to]="link.url"
					[type]="link.type"
					[disabled]="link.disabled"
					[tooltip]="link.tooltip"
					>{{ link.text }}</cjm-link
				>
			</div>

			<button class="c-sidebar__close-button" (click)="collapseSidebar()">
				<fa-icon icon="fa-chevron-left" />
			</button>
		</div>

		<nav *ngIf="asNav; else sidebarContentTmpl">
			<ng-container [ngTemplateOutlet]="sidebarContentTmpl" />
		</nav>
	</div>

	<div *ngIf="!showAllItems" class="c-sidebar__page-content">
		<ng-container [ngTemplateOutlet]="pageContent" />
	</div>
</ng-container>

<ng-template #sidebarContentTmpl>
	<ul
		class="c-sidebar__sidebar-content__items"
		[ngClass]="{ 'c-sidebar__sidebar-content__items--show-all': showAllItems }"
	>
		<ng-container *mediaQuery="mediaQueryMax.Desktop">
			<ng-container *ngFor="let item of sidebarActiveFirst(); let i = index">
				<li *hasFeature="item.featureFlag">
					<cjm-sidebar-item
						*ngIf="showAllItems || i === 0"
						[item]="item"
						(toggleAll)="toggleAllItems()"
					></cjm-sidebar-item>
				</li>
			</ng-container>
		</ng-container>

		<ng-container *mediaQuery="mediaQueryMin.Desktop">
			<ng-container *ngFor="let item of sidebarItems()">
				<li *hasFeature="item.featureFlag">
					<cjm-sidebar-item [item]="item" [iconOnly]="isCollapsed()" />
				</li>
			</ng-container>
		</ng-container>
	</ul>
</ng-template>

<ng-template #pageContent>
	<ng-content />
</ng-template>

<ng-template> </ng-template>
