import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean, validateContent } from '@studiohyperdrive/rxjs-utils';
import { EMPTY, catchError, map, of, take } from 'rxjs';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { AdvicePageResultEntity, pageImageParser } from '@cjm/v-loket/repositories';

import { AdviceDetailPageDataEntity } from '../../facades';
import { AdvicePageApiService } from '../../services';
import { actions } from '../../store';
import { parsePageComponents } from '../../utils';

@Injectable()
export class AdviceDetailPageResolver extends StoreService {
	constructor(
		protected readonly store: Store,
		private readonly advicePageApiService: AdvicePageApiService,
		private router: Router
	) {
		super(store);
	}

	public resolve(route: ActivatedRouteSnapshot): ObservableBoolean {
		const id = route?.params?.id;

		if (!id) {
			return of(false);
		}

		return this.getAdviceDetailPage(id).pipe(
			catchError(() => {
				this.router.navigate([VLoketAppRoutePaths.NotFound]);
				return EMPTY;
			})
		);
	}

	/**
	 * getAdviceDetailPage
	 *
	 * The getAdviceDetailPage method will fetch the requested page through the API and store the data.
	 *
	 * @param page
	 * @param sort
	 *
	 * @returns ObservableBoolean
	 */
	public getAdviceDetailPage(id: string): ObservableBoolean {
		return dispatchDataToStore(
			actions.adviceDetailPageData,
			this.advicePageApiService.getAdvicePage(id).pipe(
				// Validate if the response has content
				validateContent({ strict: true }),
				// Map the data to fit the required format
				map(
					(response: AdvicePageResultEntity): AdviceDetailPageDataEntity => ({
						id: response.id,
						title: response.titel,
						description: response.omschrijving,
						readingTime: response.leestijd,
						lastUpdated: response.bijgewerkt,
						heroImage: pageImageParser(response.afbeelding),
						keywords: response.trefwoorden.map((keyword) => ({ id: keyword.id, name: keyword.naam })),
						components: parsePageComponents(response.componenten)
					})
				)
			),
			this.store
			// Map to a boolean to avoid subscribing to the data
		).pipe(
			take(1),
			map(() => true)
		);
	}
}
