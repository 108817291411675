<div *ngIf="title" class="c-no-results">
	<h4 *ngIf="title" class="c-vloket-header c-vloket-header--h3">
		<span class="c-vloket-text-marking c-vloket-text-marking--underline">{{ title }}</span>
	</h4>
	<div *ngIf="description" class="u-margin-bottom-lg" [innerHTML]="description"></div>
	<h5 *ngIf="subtitle" class="c-vloket-header c-vloket-header--h4">
		<span class="c-vloket-text-marking c-vloket-text-marking--underline">{{ subtitle }}</span>
	</h5>
	<div *ngIf="subDescription" class="c-no-results__description" [innerHTML]="subDescription"></div>
</div>
