import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';

import { actions, selectors } from '../company.store';
import { CompanyEntity } from '../interfaces';

import { CompanyApiService } from './company.api.service';

@Injectable()
export class CompanyService extends StoreService {
	/**
	 * The company of the current user
	 */
	public readonly company$: Observable<CompanyEntity> = this.selectFromStore<CompanyEntity>(selectors.company);
	/**
	 * The loading state of the current user company
	 */
	public readonly companyLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.company);

	constructor(protected readonly store: Store, private readonly apiService: CompanyApiService) {
		super(store);
	}

	/**
	 * Get the full data of a company
	 *
	 * @param number - The number of the company
	 * @param context - HttpContext
	 */
	public getCompany(number: string, context?: HttpContext): Observable<CompanyEntity> {
		return dispatchDataToStore(actions.company, this.apiService.getCompany(number, context), this.store);
	}
}
