import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedUiModule } from '@cjm/shared/ui/common';

import { ModalDataModule } from '../data';

import { SharedUiModals } from './components';

@NgModule({
	imports: [CommonModule, ModalDataModule, SharedUiModule],
	declarations: [...SharedUiModals],
	exports: [...SharedUiModals]
})
export class ModalUiModule {}
