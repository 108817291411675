import { Injectable } from '@angular/core';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionService, CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationApiService {
	constructor(private readonly httpClient: CJMHttpClientService, private readonly sessionService: SessionService) {
		this.httpClient.setIncludeLanguage(false);
	}

	public logOut(): ObservableString {
		return this.httpClient
			.get<{ doorstuurLocatie: string }>(VLoketEndpoints.Authentication.Logout(), {}, true)
			.pipe(map(({ doorstuurLocatie }) => doorstuurLocatie));
	}

	public logOutWithEid(callback: string = ''): Observable<void> {
		return this.httpClient.directGet(
			`${this.sessionService.getACMIDMDomain()}${VLoketEndpoints.Authentication.LogoutWitEId()}${callback}`,
			{},
			true
		);
	}
}
