import { Pages } from '../enums';

/**
 * Used to fetch a page from the Backend that in bound to another page.
 *
 * Example: `footer > deelnemen`
 */
export interface PageEntity extends SinglePageEntity {
	parentId: string;
}

/**
 * Used to fetch individual pages from the Backend.
 * By individual pages, we mean pages that are standalone and do not have a parent (like `footer` for example).
 */
export interface SinglePageEntity {
	title: string;
	text: string;
	id: string;
}

export interface PageNavItemEntity {
	title: string;
	description: string;
	id: string;
}

export interface PageNavItemResultEntity {
	titel: string;
	beschrijving: string;
	'@id': string;
}

export interface PageResultEntity {
	titel: string;
	tekst: string;
	'@id': string;
}

export interface PageNavDataEntity {
	navigation: PageNavItemEntity[];
	id: Pages;
}
