<ng-container class="c-theme-icon" [ngSwitch]="tagId">
	<cjm-img *ngSwitchCase="theme.PURCHASE_HUUR" src="img/advice-theme-icons/VLO-Aankoop-Huur.svg" />
	<cjm-img *ngSwitchCase="theme.ADMINISTRATION" src="img/advice-theme-icons/VLO-Administratie.svg" />
	<cjm-img *ngSwitchCase="theme.TAXATION" src="img/advice-theme-icons/VLO-Fiscaliteit.svg" />
	<cjm-img *ngSwitchCase="theme.LEGAL" src="img/advice-theme-icons/VLO-Juridisch.svg" />
	<cjm-img *ngSwitchCase="theme.EVENT" src="img/advice-theme-icons/VLO-Kalender.svg" />
	<cjm-img *ngSwitchCase="theme.COURSE" src="img/advice-theme-icons/VLO-Opleiding.svg" />
	<cjm-img *ngSwitchCase="theme.INSURANCE" src="img/advice-theme-icons/VLO-Verzekering.svg" />
	<cjm-img *ngSwitchCase="theme.STAFF" src="img/advice-theme-icons/VLO-Personeel.svg" />
	<cjm-img *ngSwitchCase="theme.VLO" src="img/advice-theme-icons/VLO-Verenigingsloket.svg" />
	<fa-icon *ngSwitchDefault icon="fa-tag" iconType="fa-solid" />
</ng-container>
