import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { delay, Observable, of } from 'rxjs';

import { ModalOptionsEntity, ModalEntity } from '@cjm/shared/core';

const defaultModalOptions: ModalOptionsEntity = {
	disableClose: true,
	closeOnNavigation: false,
	hasBackdrop: true
};

@Injectable()
export class ModalDialogService {
	constructor(private readonly matDialog: MatDialog) {}

	/**
	 * Opens and returns the modal instance
	 *
	 * @template ComponentClass
	 * @param component - The class of the component we want to render in the modal
	 * @param options - Options to disable the close, close on navigation and show a backdrop
	 */
	public openModal<ComponentClass>(
		component: ComponentType<ComponentClass>,
		options: ModalOptionsEntity = defaultModalOptions
	): ModalEntity<ComponentClass> {
		// Iben: Open the modal with matDialog
		const modal = this.matDialog.open(component, options);

		// Iben: Return the instance of the component in the modal and the close function
		return {
			reference: modal,
			component: modal.componentInstance
		};
	}

	public instantCloseModal(modalRef?: MatDialogRef<unknown>): void {
		if (!modalRef) {
			this.matDialog.closeAll();

			return;
		}

		modalRef.close();
	}

	public closeModal(modalRef?: MatDialogRef<unknown>): Observable<void> {
		if (!modalRef) {
			return of(this.matDialog.closeAll()).pipe(delay(100));
		}

		return of(modalRef.close()).pipe(delay(100));
	}
}
