import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonClasses } from '@cjm/shared/ui/common';

@Component({
	selector: 'cjm-action-modal',
	templateUrl: './action-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionModalComponent {
	@Input() public title: string;
	@Input() public text: string;
	@Input() public confirmLabel: string;
	@Input() public cancelLabel: string;

	@Output() public buttonClicked = new EventEmitter<'confirm' | 'cancel'>();

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
}
