<fieldset *ngIf="initialized$ | async" class="c-vloket-form__fieldset c-location-form" [formGroup]="form">
	<div class="c-location-form__form-fields">
		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<cjm-input-wrapper
				*ngIf="showLocationType"
				id="type"
				[label]="i18nKeys.SharedAssociations.Forms.Location.LocationType.Label | translate"
			>
				<select #input formControlName="type" cypressTag="LocationForm.Type.Input">
					<option *ngFor="let type of locationTypes" [value]="type">
						{{ type }}
					</option>
				</select>

				<ng-container description ngxErrors="type">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
			<cjm-input-wrapper
				*ngIf="showDescription"
				id="description"
				[tooltip]="i18nKeys.SharedAssociations.Forms.Location.Description.Tooltip | translate"
				[label]="i18nKeys.SharedAssociations.Forms.Location.Description.Label | translate"
			>
				<input
					#input
					type="text"
					formControlName="description"
					cypressTag="LocationForm.Description.Input"
					[placeholder]="i18nKeys.SharedAssociations.Forms.Location.Description.Placeholder | translate"
				/>

				<ng-container description ngxErrors="description">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
		</fieldset>
		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<cjm-input-wrapper
				id="zipcode"
				[isTextfield]="false"
				[label]="i18nKeys.SharedAssociations.Forms.Location.Zipcode.Label | translate"
			>
				<cjm-auto-complete
					cypressTag="LocationForm.Zipcode.Input"
					formControlName="zipcode"
					[options]="localities$ | async"
					[placeholder]="i18nKeys.SharedAssociations.Forms.Location.Zipcode.Placeholder | translate"
					(searchOptions)="searchLocalities($event)"
					(valueSelected)="handleLocalityAutoCompleteSelection($event)"
				/>

				<ng-container description ngxErrors="zipcode">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
			<cjm-input-wrapper
				id="locality"
				[isTextfield]="false"
				[label]="i18nKeys.SharedAssociations.Forms.Location.Locality.Label | translate"
			>
				<cjm-auto-complete
					formControlName="locality"
					cypressTag="LocationForm.Locality.Input"
					[options]="localities$ | async"
					[placeholder]="i18nKeys.SharedAssociations.Forms.Location.Locality.Placeholder | translate"
					(searchOptions)="searchLocalities($event)"
					(valueSelected)="handleLocalityAutoCompleteSelection($event)"
				/>

				<ng-container description ngxErrors="zipcode">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
		</fieldset>
		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<cjm-input-wrapper
				id="street"
				[label]="i18nKeys.SharedAssociations.Forms.Location.Street.Label | translate"
			>
				<cjm-auto-complete
					formControlName="street"
					cypressTag="LocationForm.Street.Input"
					[options]="streets$ | async"
					[placeholder]="i18nKeys.SharedAssociations.Forms.Location.Street.Placeholder | translate"
					(searchOptions)="searchStreets($event)"
				/>

				<ng-container description ngxErrors="street">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>

			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="number"
					[label]="i18nKeys.SharedAssociations.Forms.Location.Number.Label | translate"
				>
					<cjm-auto-complete
						formControlName="number"
						cypressTag="LocationForm.Number.Input"
						[options]="streetNumbers$ | async"
						[placeholder]="i18nKeys.SharedAssociations.Forms.Location.Number.Placeholder | translate"
						(searchOptions)="searchStreetNumbers($event)"
					/>

					<ng-container description ngxErrors="number">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>

				<cjm-input-wrapper id="box" [label]="i18nKeys.SharedAssociations.Forms.Location.Box.Label | translate">
					<cjm-auto-complete
						formControlName="box"
						cypressTag="LocationForm.Box.Input"
						[options]="boxNumbers$ | async"
						[placeholder]="i18nKeys.SharedAssociations.Forms.Location.Box.Placeholder | translate"
						(searchOptions)="searchBoxNumbers($event)"
					/>

					<ng-container description ngxErrors="box">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>
		</fieldset>
		<fieldset class="c-vloket-form__fieldset__subset--split-equal">
			<cjm-input-wrapper
				id="country"
				[label]="i18nKeys.SharedAssociations.Forms.Location.Country.Label | translate"
			>
				<cjm-country-select
					formControlName="country"
					cypressTag="LocationForm.Country.Input"
					[otherPlaceholder]="i18nKeys.SharedAssociations.Forms.Location.Country.Placeholder | translate"
				/>

				<ng-container description ngxErrors="country">
					<div ngxError="required" showWhen="dirty">
						{{ i18nKeys.FormLabels.IsRequired | translate }}
					</div>
				</ng-container>
			</cjm-input-wrapper>
		</fieldset>
	</div>
</fieldset>
