import { Pipe, PipeTransform } from '@angular/core';

import { AssociationDetailEntity } from '../../data';

@Pipe({
	name: 'parseAssociationAddresses'
})
export class ParseAssoctiationAddressesPipe implements PipeTransform {
	public transform(value: AssociationDetailEntity): string {
		if (!value) {
			return '';
		}

		const locations = value.locations || [];

		return locations.map((address) => `${address.zipcode} - ${address.municipality}`).join(', ');
	}
}
