import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormAccessorContainer } from '@studiohyperdrive/ngx-forms';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { validateContent, ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { debounce } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { BrowserService, MetaService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { AlertType, ButtonClasses } from '@cjm/shared/ui/common';
import { generateSnackbarConfig, SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity } from '@cjm/shared/user';
import { IMainActivity } from '@cjm/v-loket/repositories';
import { AssociationType, IRepresentativeForm, SendCopyForm } from '@cjm/v-loket/shared';

import { RegisterFacade } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { IBasicRegistrationDataForm } from '../../components';

import { IRegistration, IRegistrationForm } from './register.types';

@Component({
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [RegisterFacade]
})
export class RegisterPageComponent extends FormAccessorContainer implements OnInit {
	public readonly actionInProgress$: ObservableBoolean = this.registerFacade.actionInProgress$;
	public readonly isRegistrationSubmitting$: Observable<boolean> = this.registerFacade.isRegistrationSubmitting$;
	public readonly mainActivities$: ObservableArray<IMainActivity> = this.registerFacade.getMainActivities();

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly associationTypes: typeof AssociationType = AssociationType;
	public readonly submitRegistration = debounce<() => void>(this.validateAndSubmit.bind(this), 600);

	private readonly basicInfoFormInitialized$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private readonly registerFacade: RegisterFacade,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly snackBarService: SnackBarService,
		private readonly i18nService: I18nService,
		private readonly metaService: MetaService,
		private readonly browserService: BrowserService
	) {
		super();
	}

	// Denis: The following form results in an IRegistration object
	public registerForm: FormGroup<IRegistrationForm> = new FormGroup({
		basicInfo: new FormControl<IBasicRegistrationDataForm>(
			{
				associationType: this.associationTypes.FV,
				associationAddress: {
					country: 'België'
				}
			} as IBasicRegistrationDataForm,
			{
				validators: [Validators.required]
			}
		),
		representatives: new FormArray<FormControl<IRepresentativeForm>>([
			new FormControl(null, {
				validators: [Validators.required]
			})
		]),
		invites: new FormArray<FormControl<IRepresentativeForm>>([
			new FormControl(null, {
				validators: [Validators.required]
			})
		]),
		sendCopy: new FormControl<SendCopyForm>({
			isActive: false,
			recipient: ''
		}),
		subscribeToNewsletter: new FormControl<boolean>(false)
	});

	public ngOnInit(): void {
		this.metaService.updateMetaData(
			{
				title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.Title),
				description: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.BasicData.Description),
				pageUrl: this.router.url
			},
			// Abdurrahman: this page shouldn't be indexed
			false
		);

		// Denis: When the user is logged in, pre-fill the first representative formGroup with their information.
		this.registerFacade.user$
			.pipe(
				validateContent(),
				tap(({ firstName, name, insz }: UserEntity) => {
					(this.registerForm.get('representatives') as FormArray).controls[0].patchValue(
						{
							representativeFirstnameField: firstName,
							representativeSurnameField: name,
							representativeRRNField: insz
						},
						{ emitEvent: false }
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Denis: if there is existing form data, patch it to the form (restore after duplicate check)
		// It is important that we wait for the basic form to be initialized, to make sure the form is fully rendered.
		this.basicInfoFormInitialized$
			.pipe(
				validateContent(),
				switchMap(() => this.registerFacade.existingRegistrationData$),
				first(),
				validateContent(),
				tap((data: IRegistration) => this.registerForm.patchValue(data)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Denis: subscribe to the representatives formArray valueChanges to update the sendCopy formControl
		this.registerForm
			.get('representatives')
			.valueChanges.pipe(
				// Denis: only move forward when the email address changes
				distinctUntilChanged(
					(current: IRepresentativeForm[], previous: IRepresentativeForm[]) =>
						current[0].representativeEmailField === previous[0].representativeEmailField
				),
				// Denis: only move forward when an email address is provided and the sendCopy component is not active.
				filter((representatives: IRepresentativeForm[]) => {
					const email = !!representatives[0].representativeEmailField;
					const sendCopyActive = this.registerForm.get('sendCopy').value?.isActive;

					return email && !sendCopyActive;
				}),
				// Denis: patch the sendCopy formControl with the email address of the first representative
				tap((representatives: IRepresentativeForm[]) => {
					const email = representatives[0].representativeEmailField;

					this.registerForm.get('sendCopy').patchValue(
						{
							isActive: false,
							recipient: email
						},
						{ emitEvent: false }
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.registerForm
			.get('sendCopy')
			.valueChanges.pipe(
				// Denis: only move forward when the sendCopy.isActive value has changed
				distinctUntilChanged(
					(current: SendCopyForm, previous: SendCopyForm) => current.isActive === previous.isActive
				),
				tap((sendCopy: SendCopyForm) => {
					const recipient = this.registerForm.get('representatives').value[0].representativeEmailField;

					// Denis: when the sendCopy component is active and the recipient is not the same as the first representative, update the recipient.
					if (sendCopy.isActive && recipient && sendCopy.recipient !== recipient) {
						this.registerForm.get('sendCopy').patchValue(
							{
								isActive: true,
								recipient
							},
							{ emitEvent: false }
						);
					}

					// Denis: when the sendCopy component is not active, reset the recipient.
					if (!sendCopy.isActive) {
						this.registerForm.get('sendCopy').patchValue(
							{
								isActive: false,
								recipient: ''
							},
							{ emitEvent: false }
						);
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * validateAndSubmit
	 *
	 * The validateAndSubmit method will trigger the submitRegistration method on the registerFacade with the registerForm value.
	 */
	private validateAndSubmit(): void {
		this.registerForm.markAsDirty();
		this.updateAllValueAndValidity(this.registerForm);
		this.registerForm.updateValueAndValidity();

		if (this.registerForm.invalid) {
			this.snackBarService.openFromComponent(
				SnackBarComponent,
				generateSnackbarConfig({
					title: this.i18nService.getTranslation(
						this.i18nKeys.Registration.Register.FormNotice.InvalidFormTitle
					),
					message: this.i18nService.getTranslation(
						this.i18nKeys.Registration.Register.FormNotice.InvalidForm
					),
					type: AlertType.Warning
				})
			);
			this.browserService.scrollToElement('.ng-invalid .c-input__description.is-error').subscribe();

			return;
		}

		this.registerFacade
			.submitRegistration(this.registerForm.getRawValue())
			.pipe(
				tap(() => {
					this.router.navigate(['..', this.appRoutePaths.RegistrationRedirectRegistration], {
						relativeTo: this.route
					});
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * addRepresentative
	 *
	 * The addRepresentative method will push a new FormControl to the representatives FormArray.
	 */
	public addRepresentative(): void {
		(this.registerForm.get('invites') as FormArray).push(new FormControl(null));
	}

	/**
	 * removeRepresentative
	 *
	 * The removeRepresentative method will remove an existing FormControl to the representatives FormArray.
	 */
	public removeRepresentative(index: number): void {
		(this.registerForm.get('invites') as FormArray).removeAt(index);
	}

	/**
	 * basicDataFormInitialized
	 *
	 * The basicDataFormInitialized method will push the initialized value of the basic data form to an internal subject.
	 *
	 * @param isInitialized
	 */
	public basicDataFormInitialized(isInitialized: boolean): void {
		if (!isInitialized) {
			return;
		}

		this.basicInfoFormInitialized$.next(isInitialized);
	}
}
