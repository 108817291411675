import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { AuthUiModule } from '@cjm/shared/authentication/auth';
import { SharedUiModule } from '@cjm/shared/ui/common';

import { ErrorComponent } from './components';
import {
	MaintenancePageComponent,
	NoResourcesComponent,
	NotFoundPageComponent,
	OfflinePageComponent,
	StopPageComponent
} from './pages';
import { AuthenticationFailedPageComponent } from './pages/authentication-failed/authentication-failed.component';
import { RedirectsRoutingModule } from './redirects.routing.module';
import { RedirectsTranslationLoader } from './translation.loader';

@NgModule({
	imports: [
		RedirectsRoutingModule,
		SharedUiModule,
		CommonModule,
		NgxI18nModule.forChild(RedirectsTranslationLoader),
		AuthUiModule
	],
	declarations: [
		MaintenancePageComponent,
		AuthenticationFailedPageComponent,
		OfflinePageComponent,
		NoResourcesComponent,
		NotFoundPageComponent,
		StopPageComponent,
		ErrorComponent
	],
	exports: [
		MaintenancePageComponent,
		AuthenticationFailedPageComponent,
		OfflinePageComponent,
		NoResourcesComponent,
		NotFoundPageComponent,
		StopPageComponent,
		ErrorComponent
	]
})
export class RedirectsUiModule {}
