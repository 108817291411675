import { FacetFilter } from '@cjm/shared/types';

export interface AssociationFilterResultEntity {
	limit: number;
	offset: number;
	q: string;
	filters: string;
}

export interface AssociationFilter {
	filters?: FacetFilter;
	name?: string;
	location?: string[];
	size?: number;
	index?: number;
	sorting?: string;
}

export enum AssociationSearchQueryTypes {
	Name = 'name',
	Location = 'location'
}

export interface AssociationSearchValues {
	name: string;
	location: string;
}

export interface AssociationSearchQuery {
	name: string;
	location: string[];
}

export type AssociationSearchQueryType = AssociationSearchQueryTypes.Name | AssociationSearchQueryTypes.Location;

export type AssociationFacetsFilterResultEntity = Pick<AssociationFilterResultEntity, 'q'>;
