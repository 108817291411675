<ng-container class="c-advice-detail-page-header-wrapper">
	<div
		*ngIf="heroImage || pageTitle"
		class="c-advice-detail-page-header__image"
		[style.background-image]="'url(' + heroImage + ')'"
	></div>
	<cjm-layout-container
		class="c-advice-detail-page-header__content"
		layoutType="split-content-left"
		preventSpacing="bottom"
	>
		<div *ngIf="pageTitle" class="c-advice-detail-page-header__content__main" main>
			<h1 class="c-vloket-header c-vloket-header--h2">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ pageTitle }}
				</span>
			</h1>
			<ul class="c-advice-detail-page-header__content__main__keyword-list">
				<li
					*ngFor="let keyword of keywords"
					class="c-advice-detail-page-header__content__main__keyword-list__item"
				>
					<vloket-advice-page-theme-tag [tagId]="keyword.id" />

					<span>
						{{ keyword.name }}
					</span>
				</li>
			</ul>
		</div>
		<div *ngIf="minReadingTime || lastUpdate" asideRight>
			<div class="c-advice-detail-page-header__content__aside">
				<p *ngIf="minReadingTime" class="c-advice-detail-page-header__content__aside__text">
					<fa-icon class="u-margin-right-sm" [icon]="'fa-clock'" [iconType]="'fa-regular'" />
					{{ minReadingTime }} {{ adviceI18nKeys.AdviceDetail.MinReadingTime | translate }}
				</p>
				<p *ngIf="lastUpdate" class="c-advice-detail-page-header__content__aside__text">
					<fa-icon class="u-margin-right-sm" [icon]="'fa-pencil'" [iconType]="'fa-solid'" />
					{{ adviceI18nKeys.AdviceDetail.LastUpdate | translate }} {{ lastUpdate | date : 'dd/MM/yyyy' }}
				</p>
			</div>
		</div>
	</cjm-layout-container>
</ng-container>
