import { FormControl, FormGroup, FormRecord } from '@angular/forms';

import { ILocationForm, ILocationFormGroup } from '@cjm/v-loket/shared';

export interface IBasicRegistrationDataForm {
	associationType: string;
	associationName: string;
	associationAddress: ILocationForm;
	mainActivities: Record<string, boolean>;
}

export interface IBasicRegistrationDataFormGroup {
	associationType: FormControl<string>;
	associationName: FormControl<string>;
	associationAddress: FormGroup<ILocationFormGroup>;
	mainActivities: FormRecord<FormControl<boolean>>;
}
