export enum VloketStoreSlice {
	// libs/v-loket/associations/src/lib/data/associations.store.ts
	Associations = 'associations',
	// libs/v-loket/registration/src/lib/data/store/registration.store.ts
	Registration = 'registration',
	// libs/v-loket/repositories/src/lib/data/store/association/association.store.ts
	Association = 'association',
	// libs/v-loket/repositories/src/lib/data/store/my-association/my-association.store.ts
	MyAssociation = 'myAssociation',
	// libs/v-loket/my-association/shared/src/lib/data/store/updates/updates.store.ts
	MyAssociationUpdates = 'updates',
	// libs/v-loket/my-association/log/src/lib/data/store/log.store.ts
	MyAssociationLog = 'log',
	// libs/v-loket/my-association/representatives/src/lib/data/store/invite.store.ts
	MyAssociationRepresentativeInvites = 'representative-invites',
	// libs/v-loket/my-association/cases/src/lib/data/store/case/case.store.ts
	MyAssociationCase = 'case',
	// libs/v-loket/my-association/cases/src/lib/data/store/case/case.store.ts
	MyAssociationDivisions = 'divisions',
	// libs/shared/notifications/src/lib/data/store/notifications/notification/notification.store.ts
	Notifications = 'notifications',
	// libs/v-loket/repositories/src/lib/data/store/invitation/invitation.store.ts
	RepresentativeInvitations = 'invitations',
	// libs/v-loket/repositories/src/lib/data/store/advice-pages/advice-pages.store.ts
	Advices = 'advices',
	// libs/v-loket/advice/src/lib/data/store/advice.store.ts
	Advice = 'advice',
	// libs/v-loket/home/src/lib/data/store/home.store.ts
	Home = 'home',
	// libs/v-loket/global-search/src/lib/data/store/search-results.store.ts
	SearchResults = 'search-results',
	// libs/v-loket/global-search/src/lib/data/store/info.store.ts
	InfoPage = 'info-page'
}
