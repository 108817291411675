import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
	selector: 'cjm-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {
	/**
	 * The description to display within the tooltip.
	 *
	 * If no description is provided, there will be no tooltip.
	 */
	@Input() public description: string;

	/**
	 * The position of the tooltip.
	 *
	 * Default value is `below`.
	 */
	@Input() public tooltipPosition: `${TooltipPosition}` = 'below';
}
