import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { AssociationApiService, IMainActivity } from '@cjm/v-loket/repositories';

import { IEARegistration } from '../../../ui';
import {
	PostRegistrationResponseEntity,
	IEaRegistrationPostPayload,
	EARegistirationPOSTPayload
} from '../../interfaces';

@Injectable()
export class RegisterEaAPIService {
	constructor(
		private readonly httpClient: CJMHttpClientService,
		private readonly associationApiService: AssociationApiService
	) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * postEaRegistrationData
	 *
	 * The postEaRegistrationData method performs a POST request to the API on the /vereniging/kbo endpoint.
	 * It takes in the payload formatted to the EARegistirationPOSTPayload.
	 * Return type is set Observable<PostRegistrationResponseEntity> which is a translated version of the API response.
	 *
	 * @param data EARegistirationPOSTPayload
	 * @returns Observable:PostRegistrationResponseEntity
	 */
	public postEaRegistrationData(data: IEARegistration): Observable<PostRegistrationResponseEntity> {
		return this.httpClient.post(
			VLoketEndpoints.Associations.PostKBOAssociation(),
			this.translatePostEaRegistrationPayload(data)
		);
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities method exposes the getMainActivities method from the AssociationApiService
	 *
	 * @returns Observable:IMainActivities
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.associationApiService.getMainActivities();
	}

	/**
	 * translatePostEaRegistrationPayload
	 *
	 * The translatePostEaRegistrationPayload method translates the payload to NL to match the API specs.
	 *
	 * @param data EARegistirationPOSTPayload
	 * @returns IPostAssociationPayloadNL
	 */
	private translatePostEaRegistrationPayload({ basicInfo }: IEaRegistrationPostPayload): EARegistirationPOSTPayload {
		return {
			roepnaam: basicInfo.associationCallname,
			hoofdactiviteiten: Object.keys(basicInfo.mainActivities).reduce(
				(acc, key: string) => (basicInfo.mainActivities[key] ? [...acc, key] : acc),
				[]
			)
		};
	}
}
