import { RouterModule } from '@angular/router';
import { I18nGuard } from '@studiohyperdrive/ngx-i18n';

import { AssociationAuthenticatedGuard, LoginHintGuard } from '@cjm/shared/authentication/auth';
import { MetaGuard } from '@cjm/shared/core';
import { CJMRoute, CJMRoutes, VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { Language } from '@cjm/shared/types';
import { BaseComponent } from '@cjm/v-loket/core';
import { registrationRoutes } from '@cjm/v-loket/registration';

const HOME_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Empty,
	canActivate: [AssociationAuthenticatedGuard],
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/home').then((m) => m.HomeModule)
};

const PRODUCT_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Offers,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/products').then((m) => m.ProductsModule)
};

const LOKET_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Loket,
	redirectTo: VLoketAppRoutePaths.Empty
};
const ASSOCIATIONS_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Associations,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/associations').then((m) => m.AssociationsModule)
};
const MY_ASSOCIATION_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.MyAssociation,
	loadChildren: () => import('@cjm/v-loket/my-association/core').then((m) => m.MyAssociationCoreModule),
	data: {
		showRegisterKBOModal: true
	}
};
const REGISTRATION_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Registration,
	// Denis: The Registration module is no longer lazy loaded so that we can show the modal on the welcome page.
	children: registrationRoutes
};
const ABOUT_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.About,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/about').then((m) => m.AboutModule)
};
const ADVICE_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Advice,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/advice').then((m) => m.AdviceModule)
};
const INFO_PAGE_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Info,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/info').then((m) => m.InfoModule)
};
const GLOBAL_SEARCH_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Search,
	loadChildren: () => import('@cjm/v-loket/global-search').then((m) => m.GlobalSearchModule)
};
const REPRESENTATIVE_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Representative,
	children: [
		{
			path: VLoketAppRoutePaths.Invitations,
			loadChildren: () =>
				import('@cjm/v-loket/representative/invitations').then((m) => m.RepresentativeInvitationsModule)
		},
		{
			path: VLoketAppRoutePaths.Requests,
			loadChildren: () =>
				import('@cjm/v-loket/representative/requests').then((m) => m.RepresentativeRequestsModule)
		}
	]
};

const REDIRECT_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Redirects,
	loadChildren: () => import('@cjm/shared/redirects').then((m) => m.RedirectsUiModule)
};

const EMPTY_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Empty,
	pathMatch: 'full',
	redirectTo: Language.NL
};

const FALLBACK_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Fallback,
	redirectTo: Language.NL
};

const routes: CJMRoutes = [
	EMPTY_PATH,
	{
		path: VLoketAppRoutePaths.Language,
		pathMatch: 'prefix',
		canActivate: [I18nGuard, MetaGuard, LoginHintGuard],
		canActivateChild: [I18nGuard, MetaGuard, LoginHintGuard],
		component: BaseComponent,
		children: [
			HOME_PATH,
			PRODUCT_PATH,
			ASSOCIATIONS_PATH,
			MY_ASSOCIATION_PATH,
			LOKET_PATH,
			REGISTRATION_PATH,
			ABOUT_PATH,
			ADVICE_PATH,
			INFO_PAGE_PATH,
			REPRESENTATIVE_PATH,
			GLOBAL_SEARCH_PATH,
			REDIRECT_PATH,
			FALLBACK_PATH
		]
	},
	FALLBACK_PATH
];

export const AppRoutingModule = RouterModule.forRoot(routes, {
	scrollPositionRestoration: 'enabled',
	// Denis: enable anchor scrolling to make use of fragments within the routing.
	anchorScrolling: 'enabled',
	// Denis: set an offset of 150px on the Y axis
	// to offset the header and provide some margin above the anchor.
	scrollOffset: [0, 150]
});
