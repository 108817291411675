import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { Observable, map } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { FacetFilter, FacetEntity, FacetsResult, IndexedPagination } from '@cjm/shared/types';
import { convertFacetFilterToFacetFilterResult, convertFacetResultToFacet, convertPagination } from '@cjm/shared/utils';
import { AdvicePagesFilter } from '@cjm/v-loket/shared';

import { DEFAULT_ADVICE_SIZE } from './advice-pages.api.const';
import { AdvicePageResultEntity, AdvicePagesDataResultEntity } from './advice-pages.api.service.interfaces';

@Injectable()
export class AdvicePagesApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * getAdvicePages
	 *
	 * The getAdvicePages method gets the overview page from the API.
	 *
	 * @param advicePagesFilter - The selected filter and index to filter the advice pages
	 */
	public getAdvicePages(advicePagesFilter: AdvicePagesFilter = {}): Observable<{
		pages: AdvicePageResultEntity[];
		pagination?: IndexedPagination;
	}> {
		const params = this.getQueryParams(advicePagesFilter);

		return this.httpClient
			.get<AdvicePagesDataResultEntity>(VLoketEndpoints.AdvicePages.GetAdvicePages(), params)
			.pipe(
				map(({ inhoud, paginatie }) => {
					return {
						pages: inhoud.elementen,
						...(paginatie ? { pagination: convertPagination(paginatie) } : {})
					};
				})
			);
	}

	/**
	 * Creates the query params based on the provided filters
	 *
	 *  @param filter - The selected filter and index to filter the advice/facets
	 */
	private getQueryParams(filter: AdvicePagesFilter): HttpParams {
		return new HttpParams({
			fromObject: {
				...(filter.filters && !isEmpty(filter.filters)
					? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filter.filters)) }
					: {}),
				...(filter.size ? { limiet: filter.size } : { limiet: DEFAULT_ADVICE_SIZE }),
				...(filter.index ? { index: filter.index } : {}),
				...(filter.recommended ? { isAangeradenOpDeVoorpagina: filter.recommended } : {})
			}
		});
	}

	/**
	 * Fetches the facets from the API
	 *
	 * @param filters - The selected filters
	 */
	public getFacets(filters: FacetFilter): ObservableArray<FacetEntity> {
		const params = new HttpParams({
			fromObject: {
				...(!isEmpty(filters)
					? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filters)) }
					: {})
			}
		});

		return this.httpClient
			.get<FacetsResult>(VLoketEndpoints.AdvicePages.Facets(), params)
			.pipe(map(({ elementen }) => elementen.map((facet) => convertFacetResultToFacet(facet))));
	}
}
