import { Injectable } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HideMenuService {
	// The subjects below are for internal use and are exposed as public Observables in the lines below.
	private readonly isActiveSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	/**
	 * isActive$ indicates if the menu is currently set to active,
	 * which will tell the app component to render the menu.
	 *
	 * The default value is set to true.
	 */
	public readonly isActive$: ObservableBoolean = this.isActiveSubject$.asObservable();

	public toggleMenu(active: boolean = true): void {
		this.isActiveSubject$.next(active);
	}
}
